import { Http, Response } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LeukoplastJobType } from '../../../../model/product/tena/leukoplast/LeukoplastJobType';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { LeukoplastService } from '../../../../product/tena/leukoplast/Leukoplast.service';
import { LeukoplastBaseTCWCatalogApi } from './LeukoplastBaseTCWCatalogApi';
import { ILeukoplastUserData } from '../../../../model/product/tena/leukoplast/ILeukoplastUserData';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { LeukoplastTCWCatalogApi } from './LeukoplastTCWCatalogApi';
import { ILeukoplastTCWCatalog } from '../../../../model/product/tena/leukoplast/ILeukoplastTCWCatalog';

@Injectable()
export class LeukoplastFulFillmentApi extends LeukoplastBaseTCWCatalogApi<ILeukoplastUserData> {

    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        leukoplastService: LeukoplastService,
        readonly leukoplastService1: LeukoplastService,
        liveStageService: LiveStageService,
        readonly leukoplastCatalogApi: LeukoplastTCWCatalogApi
    ) {
        super(apiErrorHandler, authService, environment, http, leukoplastService, liveStageService);
    }

    getItems(
        countryId: number,
        jobType: LeukoplastJobType,
        pageSize: number,
        startDate: string,
        endDate: string
    ): Observable<ILeukoplastUserData[]> {
        return this.getJson(`/${countryId}/${jobType}/${startDate}/${endDate}`, {
            params: {
                pageSize: pageSize,
                offset: 0
            }
        });
    }

    getMoreItems(
        countryId: number,
        jobType: LeukoplastJobType,
        pageSize: number,
        offset: number,
        startDate: string,
        endDate: string
    ): Observable<ILeukoplastUserData[]> {
        return this.getJson(`/${countryId}/${jobType}/${startDate}/${endDate}`, {
            params: {
                pageSize: pageSize,
                offset: offset
            }
        });
    }

    getCsv(
        countryId: number,
        jobType: LeukoplastJobType,
        startDate: string,
        endDate: string,
        showPersonalData: boolean
    ): Observable<Response> {
        if (showPersonalData) {
            return this.get(`/${countryId}/${jobType}/${startDate}/${endDate}/excel/includepersonaldata`);
        }
        else {
            return this.get(`/${countryId}/${jobType}/${startDate}/${endDate}/excel`);
        }
    }

    protected getBaseUrl() {
        this.leukoplastCatalogApi.getAll().subscribe((res: ILeukoplastTCWCatalog[]) => {
            this.leukoplastService1.countries.next(res);
        });

        return this.TCWBaseURl + 'countryuserdata';
    }
}
