import { Inject, Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IObjectIdModel } from '../../../../model/product/IObjectIdModel';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ActimoveService } from '../../../../product/tena/actimove/Actimove.service';
import { BaseTCWApi } from '../tcw/BaseTCWApi';
import { LiveStageService } from '../../../../environment/LiveStage.service';

@Injectable()
export abstract class ActimoveBaseTCWCatalogApi<T extends IObjectIdModel> extends BaseTCWApi<T> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        readonly actimoveService: ActimoveService,
        liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http, liveStageService);
    }

    protected buildOptionsAsync(options: RequestOptions) {
        // Add selected CatalogId to every request.

        let headers = new Headers(options ? options.headers : undefined);
        let activeCountry = this.actimoveService.getActiveCountry();
        if (activeCountry) {
            headers.append('CatalogId', activeCountry.objectId.toString());
        }

        options = new RequestOptions().merge(options).merge({
            headers: headers
        });

        return super.buildOptionsAsync(options);
    }
}
