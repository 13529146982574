import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { ICrudApi } from 'tridion.web.ui/src/app/angular/api/ICrudApi';
import { IEnvironment } from '../../environment/IEnvironment';
import { IObjectIdModel } from '../../model/product/IObjectIdModel';

export abstract class BaseAuthorizedObjectIdApi<T extends IObjectIdModel>
    extends BaseAuthorizedApi<IEnvironment>
    implements ICrudApi<T, number> {
    getAll(): Observable<T[]> {
        return this.getJson('').pipe(map((items: T[]) => {
            return items.filter(item => {
                return !item.isInActive;
            });
        }));
    }

    getById(objectId: number): Observable<T> {
        return this.getJson('/' + objectId);
    }

    getInactive(): Observable<T[]> {
        return this.getJson('').pipe(map((items: T[]) => {
            return items.filter(item => {
                return item.isInActive;
            });
        }));
    }

    add(item: T): Observable<T> {
        return this.postJson('', item);
    }

    update(item: T): Observable<T> {
        return this.putJson('/' + item.objectId, item);
    }

    activate(item: T): Observable<any> {
        return this.put('/' + item.objectId + '/activate', undefined);
    }

    inactivate(item: T): Observable<any> {
        return this.delete('/' + item.objectId);
    }
}
