import { Pipe, PipeTransform } from '@angular/core';
import { ITpwSample } from '../../../model/product/tena/tpw/ITpwSample';

@Pipe({
    name: 'platformTpwSampleTypePipe'
})
export class TpwSampleTypePipe implements PipeTransform {
    transform(value: ITpwSample[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: ITpwSample) => {
                if (item.categoryName.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
