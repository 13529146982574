import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { IStatsItems } from  '../../../../model/product/stats/IStatsItems'; 

@Injectable()
export class StatsApi extends BaseAuthorizedApi<IEnvironment> {    

    getWaitingTransactions(
        startDate: string,
        endDate: string,
        interval?: string,
        brand?: string
    ): Observable<IStatsItems[]> {        
        return this.getJson('/stats', {
            params: {
                Start: startDate,
                End: endDate,
                Interval: interval,
                Brand: brand                
            }
        });
    } 

   

    protected getBaseUrl() {
        return this.environment.api.stats;          
    }
}
