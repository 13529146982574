import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { IActimoveCatalog } from './../../../../model/product/tena/actimove/IActimoveCatalog.d';

@Injectable()
export class ActimoveCatalogApi extends BaseProductApi<IActimoveCatalog> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscatalog/actimove';
    }
}