import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedGuidApi } from '../../BaseAuthorizedGuidApi';
import { IServerUsageInfo } from '../../../model/monitor/capacity/IServerUsageInfo';

@Injectable()
export class ServerUsageInfoApi extends BaseAuthorizedGuidApi<IServerUsageInfo> {
    getByServerDateRange(
        serverId: string,
        startDate: string,
        endDate: string
    ): Observable<IServerUsageInfo[]> {
        return this.getJson(
            '/server/' + serverId + '/startdate/' + startDate + '/enddate/' + endDate
        );
    }

    protected getBaseUrl() {
        return this.environment.api.monitor.capacity + 'ServerUsageInfo';
    }
}
