import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TenaBlacklistApi } from '../../api/product/tena/tcw/TenaBlacklistApi';
import { ITenaBlacklist } from '../../model/product/tena/tcw/ITenaBlacklist';

@Component({
    templateUrl: 'TenaBlacklist.component.html',
    providers: [TenaBlacklistApi]
})
export class TenaBlacklistComponent
    extends BaseLiveStageCrudList<
        ITenaBlacklist,
        ICrudData<ITenaBlacklist>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tenaBlacklistApi: TenaBlacklistApi
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'Blacklist');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Blacklist - Tena');

        this.filter.observable.subscribe(filter => {
            this.tenaBlacklistApi.getAll().subscribe(blacklists => {
                this.init(blacklists);
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tenaBlacklistApi, this.authService);
    }
}
