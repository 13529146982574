import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITorkWebServiceCredentials } from '../../../model/product/tork/ITorkWebServiceCredentials';

@Injectable()
export class TorkWebServiceCredentialsApi extends BaseProductApi<
    ITorkWebServiceCredentials
> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'tork/lkpwebservicecredentials';
    }
}
