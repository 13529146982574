import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Observable } from 'rxjs';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { TenaService } from '../../../../product/tena/Tena.service';
import { BaseTCWCatalogApi } from './BaseTCWCatalogApi';
import { ITenaExternalSampleRegistration } from '../../../../model/product/tena/tcw/ITenaExternalSampleRegistration';
import { QuietApiErrorHandler } from '../../../error/QuietApiErrorHandler';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { TenaTCWCatalogApi } from './TenaTCWCatalogApi';
import { TenaSegmentApi } from './../TenaSegmentApi';
import { ITenaTCWCatalog } from './../../../../model/product/tena/tcw/ITenaTCWCatalog.d';
import { ITenaSegment } from './../../../../model/product/tena/ITenaSegment.d';

@Injectable()
export class TenaExternalSampleApi extends BaseTCWCatalogApi<
    ITenaExternalSampleRegistration
> {
    constructor(
        apiErrorHandler: QuietApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        tenaService: TenaService,
        readonly tenaService1: TenaService,
        liveStageService: LiveStageService,
        readonly tenaCatalogApi: TenaTCWCatalogApi,
        readonly tenaSegmentsApi: TenaSegmentApi
    ) {
        super(apiErrorHandler, authService, environment, http, tenaService, liveStageService);
    }

    getExternalSampleRegistration(
        countryId: number,
        segmentId: number
    ): Observable<ITenaExternalSampleRegistration> {
        return this.getJson(`/${countryId}/${segmentId}`);
    }

    addExternalSampleRegistration(
        externalSample: ITenaExternalSampleRegistration
    ): Observable<ITenaExternalSampleRegistration> {
        return this.postJson('', externalSample);
    }

    updateExternalSampleRegistration(
        externalSample: ITenaExternalSampleRegistration
    ): Observable<ITenaExternalSampleRegistration> {
        return this.putJson(`/${externalSample.objectId}`, externalSample);
    }

    deleteExternalSampleRegistration(objectId: number) {
        return this.delete(`/${objectId}`);
    }

    protected getBaseUrl() {
        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
            this.tenaService1.countries.next(res);
        });

        this.tenaSegmentsApi.getAll().subscribe((res: ITenaSegment[]) => {
            const consumerSegments = res.filter(segment => segment.isConsumerSegment);
            this.tenaService1.segments.next(consumerSegments);
        });

        return this.TCWBaseURl + 'ExternalSampleRegistration';
    }
}
