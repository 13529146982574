import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IObjectIdModel } from '../../../../model/product/IObjectIdModel';
import { BaseAuthorizedObjectIdApi } from '../../BaseAuthorizedObjectIdApi';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { LiveStageService } from '../../../../environment/LiveStage.service';

@Injectable()
export abstract class BaseTCWApi<
    T extends IObjectIdModel
> extends BaseAuthorizedObjectIdApi<T> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        readonly liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    protected get TCWBaseURl() {
        return this.environment.api.hms[this.liveStageService.get('hms')];
    }

    protected getBaseUrl() {
        return this.TCWBaseURl;
    }
}
