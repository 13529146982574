import { Injectable } from '@angular/core';
import { BaseAuthorizedGuidCrudApi } from '../BaseAuthorizedGuidCrudApi';
import { IElearning } from '../../model/elearning/IElearning';
import { Observable } from 'rxjs';

@Injectable()
export class ElearningApi extends BaseAuthorizedGuidCrudApi<IElearning> {
    public upload(file: FormData, name: string) {
        return this.post('/UploadCourse', file, {
            params: {
                Name: name
            }
        });
    }

    public getAll(): Observable<IElearning[]> {
        return this.getJson('/GetAll');
    }

    inactivate(item: IElearning): Observable<any> {
        return this.delete('/', {
            params: {
                courseId: item.id
            }
        });
    }

    protected getBaseUrl() {
        return this.environment.api.elearning + 'Elearning';
    }
}
