
import {takeUntil} from 'rxjs/operators';
import { AdminTitle } from '../layout/AdminTitle';
import { CountriesService } from '../distributorFinder/Countries.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DistributorCountriesApi } from '../api/distributorFinder/DistributorCountriesApi';
import { ICountry } from '../model/distributorFinder/ICountry';
import { Subject } from 'rxjs';

@Component({
    templateUrl: 'Countries.component.html',
    providers: [DistributorCountriesApi]
})
export class CountriesComponent implements OnInit, OnDestroy {
    countries: ICountry[];
    closeResult: string;
    totalPages: number;
    page = 1;
    limit = 20;
    arr: number[];
    country: ICountry;
    modalVisible: boolean;
    importSuccess: string;
    spinner: boolean;
    confirmModal = false;
    showPublishMessage = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private distributorCountriesApi: DistributorCountriesApi,
        private countriesService: CountriesService
    ) {}

    public editCountry(country: ICountry) {
        this.countriesService.country.next(country);
        this.countriesService.countryNewValues.next(Object.assign({}, country));
    }
    public deleteCountry(country: ICountry) {
        this.distributorCountriesApi
            .inactivate(country).pipe(
            takeUntil(this.unsubscribe))
            .subscribe((res: ICountry) => {
                this.getAll();
            });
    }
    ngOnInit(): void {
        this.adminTitle.setTitle('Countries');

        this.countriesService.country.pipe(takeUntil(this.unsubscribe)).subscribe(country => {
            this.country = country;
        });

        this.countriesService.countries.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(countries => {
                this.countries = countries;
                this.totalPages = Math.ceil(
                    this.countriesService.totalCount / this.limit
                );
                this.arr = Array(this.totalPages);

                if (this.page > this.totalPages) {
                    this.setPage(this.totalPages);
                }
            });

        this.getAll();
    }

    getAll() {
        this.spinner = true;
        this.distributorCountriesApi
            .getCountries(this.limit, this.page === 0 ? 1 : this.page).pipe(
            takeUntil(this.unsubscribe))
            .subscribe(response => {
                this.countriesService.totalCount = response.totalCount;
                this.countriesService.countries.next(response.countries);
                this.spinner = false;
            });
    }

    addNewCountry() {
        this.countriesService.countryNewValues.next(Object.assign({}));
    }

    setPage(page: number) {
        this.page = page;
        this.getAll();
    }

    toggleConfirmModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
            this.showPublishMessage = false;
        }

        this.confirmModal = show;
    }
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
