
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../../environment/LiveStage';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ActimoveWebShopApi } from '../../../api/product/tena/actimove/ActimoveWebShopApi';
import { IActimoveWebShop } from '../../../model/product/tena/actimove/IActimoveWebShop';
import { Subject } from 'rxjs';
import { ActimoveService } from './Actimove.service';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { ActimoveWebShopCatalogApi } from '../../../api/product/tena/actimove/ActimoveWebShopCatalogApi';
import { IActimoveWebShopCatalog } from '../../../model/product/tena/actimove/IActimoveWebShopCatalog';
import { ActimoveSegmentApi } from '../../../api/product/tena/actimove/ActimoveSegmentApi';
import { IActimoveSegment } from '../../../model/product/tena/actimove/IActimoveSegment';

@Component({
    templateUrl: 'ActimoveWebShop.component.html',
    providers: [ActimoveWebShopApi, ActimoveWebShopCatalogApi, ActimoveSegmentApi]
})
export class ActimoveWebShopComponent
    extends BaseLiveStageCrudList<
        IActimoveWebShop,
        ICrudData<IActimoveWebShop>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    countries: IActimoveWebShopCatalog[];
    segments: IActimoveSegment[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private actimoveWebShopApi: ActimoveWebShopApi,
        readonly actimoveCatalogApi: ActimoveWebShopCatalogApi,
        readonly actimoveSegmentApi: ActimoveSegmentApi,
        readonly actimoveService: ActimoveService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'WebShop');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Webshop - Actimove');

        this.actimoveCatalogApi.getAll().subscribe((res: IActimoveWebShopCatalog[]) => {
          this.actimoveService.countries.next(res);
        });

        this.actimoveSegmentApi.getAll().subscribe((res: IActimoveSegment[]) => {
            const isActimoveSegment = res.filter(segment => segment.isActimoveSegment);
            this.actimoveService.segments.next(isActimoveSegment);
        });

        this.actimoveService.segmentChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedSegment => {
                if (selectedSegment) {
                    this.getWebShops();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    getWebShops() {
        this.filter.observable.subscribe(() => {
            this.actimoveWebShopApi
                .getWebShops(
                    this.actimoveService.getActiveCountry().objectId,
                    this.actimoveService.getActiveSegment().objectId
                )
                .subscribe(data => {
                    this.clear();
                    this.init(data);
                });
        });
    }

    saveWebShop(webshopCrud: BaseCrud<IActimoveWebShop, ICrudData<IActimoveWebShop>>) {
        // Set catalog and segment ID for new webshops.
        if (webshopCrud.isNew()) {
            webshopCrud.data.item.catalogObjectId = this.actimoveService.getActiveCountry().objectId;
            webshopCrud.data.item.segmentObjectId = this.actimoveService.getActiveSegment().objectId;
        }

        webshopCrud.save();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.actimoveWebShopApi, this.authService);
    }
}
