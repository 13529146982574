import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ISolrcore } from '../../model/product/ISolrcore';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TorkSolrcoreApi } from '../../api/product/tork/TorkSolrcoreApi';

@Component({
    templateUrl: '../BaseSolr.component.html',
    providers: [TorkSolrcoreApi]
})
export class TorkSolrComponent
    extends BaseLiveStageCrudList<
        ISolrcore,
        ICrudData<ISolrcore>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private torkSolrcoreApi: TorkSolrcoreApi
    ) {
        super(elementRef, 'product', liveStageService, prettySubmitGuard, 'Solr Core');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Solr Cores - Tork');

        this.filter.observable.subscribe(() => {
            this.torkSolrcoreApi.getAll().subscribe(cores => {
                this.init(sortBy(cores, 'core'));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.torkSolrcoreApi, this.authService);
    }
}
