import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { TpwService } from '../../../../gdpr/Tpw.service';
import { BaseTPWSampleApi } from './BaseTPWSampleApi';
import { ITpwKitQuota } from '../../../../model/product/tena/tpw/ITpwKitQuota';

@Injectable()
export class TpwKitQuotaApi extends BaseTPWSampleApi<ITpwKitQuota> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        tpwService: TpwService
    ) {
        super(apiErrorHandler, authService, environment, http, tpwService);
    }

    public getKitQuota(
        countryId: number,
        channelId: number,
        tierId: number
    ): Observable<ITpwKitQuota[]> {
        return this.getJson(
            `?CountryID=${countryId}&ChannelID=${channelId}&TierID=${tierId}`
        );
    }

    add(item: ITpwKitQuota): Observable<ITpwKitQuota> {
        item.Isdeleteflag = false;
        if (item.packageCode === undefined || item.packageCode === null) {
            item.packageCode = '0';
        }

        return this.postJson(
            `?KitSampleID=${item.kitSampleID}&PackageCode=${
                item.packageCode
            }&OrderQuantity=${item.orderQty}&MonthQuantity=${
                item.monthQty
            }&YearQuantity=${item.yearQty}&Isdeleteflag=${item.Isdeleteflag}`,
            null
        );
    }

    inactivate(item: ITpwKitQuota): Observable<any> {
        item.Isdeleteflag = true;

        return this.postJson(
            `?KitSampleID=${item.kitSampleID}&PackageCode=${
                item.packageCode
            }&OrderQuantity=${item.orderQty}&MonthQuantity=${
                item.monthQty
            }&YearQuantity=${item.yearQty}&Isdeleteflag=${item.Isdeleteflag}`,
            null
        );
    }

    protected getBaseUrl() {
        return this.TPWBaseURl + 'KitSample';
    }
}
