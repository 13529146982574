import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Observable } from 'rxjs';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';

import { TpwService } from '../../../../gdpr/Tpw.service';
import { BaseTPWSampleApi } from './BaseTPWSampleApi';
import { ITpwSampleOrder } from '../../../../model/product/tena/tpw/ITpwSampleOrder';

@Injectable()
export class TpwSampleOrderApi extends BaseTPWSampleApi<ITpwSampleOrder> {
    country: any;
    channel: any;
    productfamily: any;
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        tpwService: TpwService
    ) {
        super(apiErrorHandler, authService, environment, http, tpwService);
        this.country = tpwService.getActiveCountry();
        this.channel = tpwService.getActiveChannel();
    }
 
    getsampleOrder(
        categoryCode: string,
        catalogCode: string,
        languageCode: string,
        countryId: number,
        channelId: number,
        tierId: number
    ): Observable<ITpwSampleOrder[]> {
        return this.getJson(
            `?categoryCode=${categoryCode}&catalogCode=${catalogCode}&languageCode=${languageCode}&countryId=${countryId}&channelId=${channelId}&tierId=${tierId}`
        );
    }
 
    add(item: ITpwSampleOrder): Observable<ITpwSampleOrder> {
        item.IsDeleteFlag = false;
        item.productSample.isAvailableForExtendedUser = true;

        this.country = this.tpwService.getActiveCountry();
        this.channel = this.tpwService.getActiveChannel();
        
        return this.postJson(
            `?ProductSampleId=${item.productSample.productSampleID}&CountryId=${this.country.countryId
            }&ChannelId=${this.channel.channelId}&BaseNumber=${item.baseNumber}&TierId=${
            item.productSample.tierID
            }&OrderQuantity=${item.productSample.orderQuantity}&MonthQuantity=${
            item.productSample.monthQuantity
            }&YearQuantity=${
            item.productSample.yearQuantity
            }&IsAvailableForExtendedUser=${
            item.productSample.isAvailableForExtendedUser
            }&Articlenumber=${item.articleNumber}&Categorycode=${
            item.categoryCode
            }&PackageCode=${item.productSample.packageCode}&IsDeleteFlag=${
            item.IsDeleteFlag
            }`,
            null
        );
    }

    inactivate(item: ITpwSampleOrder): Observable<any> {
        item.IsDeleteFlag = true;
        if (item.productSample.packageCode === '' || item.productSample.packageCode === null || item.productSample.packageCode === undefined) {
            item.productSample.packageCode = '0';
        }

        return this.postJson(
            `?ProductSampleId=${item.productSample.productSampleID}&CountryId=${
            this.country.countryId
            }&ChannelId=${this.channel.channelId}&BaseNumber=${item.baseNumber}&TierId=${
            item.productSample.tierID
            }&OrderQuantity=${item.productSample.orderQuantity}&MonthQuantity=${
            item.productSample.monthQuantity
            }&YearQuantity=${
            item.productSample.yearQuantity
            }&IsAvailableForExtendedUser=${
            item.productSample.isAvailableForExtendedUser
            }&Articlenumber=${item.articleNumber}&Categorycode=${
            item.categoryCode
            }&PackageCode=${item.productSample.packageCode}&IsDeleteFlag=${
            item.IsDeleteFlag
            }`,
            null
        );
    }

    protected getBaseUrl() {
        return this.TPWBaseURl + 'SampleProduct';
    }
}
