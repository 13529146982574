
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { ElementRef } from '@angular/core';
import { Country } from '../../model/product/Country';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { Locale, LocaleDescription } from '../../model/product/Locale';
import { MetricSystem, MetricSystemDescription } from '../../model/product/MetricSystem';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { IGuidCatalog } from '../../model/product/IGuidCatalog';
import { BaseAdminCrudList } from '../../crud/list/BaseAdminCrudList';
import { IGuidDomainCatalogMapping } from '../../model/product/IGuidDomainCatalogMapping';
import { keyBy, sortBy } from 'lodash';
import { IGuidDomain } from '../../model/product/IGuidDomain';

export abstract class BaseDistributionHubCatalog<
    I extends IGuidCatalog
> extends BaseAdminCrudList<I, ICrudData<I>> {
    readonly countryOptions = Object.keys(Country);
    readonly LocaleDescription = LocaleDescription;
    readonly localeOptions = Object.keys(Locale);
    readonly MetricSystemDescription = MetricSystemDescription;
    readonly metricSystemOptions = Object.keys(MetricSystem);
    domainsById: { [id: string]: IGuidDomain };
    domains: IGuidDomain[];
    mappings: IGuidDomainCatalogMapping[];
    mappingsByCatalogId: { [id: string]: IGuidDomainCatalogMapping };

    protected constructor(
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        public readonly domainId: string
    ) {
        super(elementRef, prettySubmitGuard, 'Catalog');
    }

    protected createItem() {
        return {
            domainId: ''
        } as I;
    }

    protected initAll(
        catalogs: Observable<IGuidCatalog[]>,
        domains: Observable<IGuidDomain[]>,
        mappings: Observable<IGuidDomainCatalogMapping[]>
    ) {
        const join: Observable<
            [IGuidCatalog[], IGuidDomain[], IGuidDomainCatalogMapping[]]
        > = observableForkJoin(catalogs, domains, mappings);

        join.subscribe(results => {
            const catalogResults = results[0],
                domainResults = results[1],
                mappingResults = results[2];

            this.domains = sortBy(domainResults, 'coreName');
            this.domainsById = keyBy(domainResults, 'id');

            this.mappings = mappingResults;
            this.mappingsByCatalogId = keyBy(mappingResults, 'catalogId');

            catalogResults.forEach(catalog => {
                if (this.mappingsByCatalogId[catalog.id]) {
                    const mapping = this.mappingsByCatalogId[
                        catalog.id
                    ] as IGuidDomainCatalogMapping;
                    catalog.catalogDomainMapping = mapping;
                    catalog.domainId = mapping.domainId;
                    catalog.domain = this.domainsById[catalog.domainId]
                        ? this.domainsById[catalog.domainId].name
                        : catalog.domainId;
                } else {
                    catalog.domainId = null;
                    catalog.domain = null;
                }
            });
            const sortedCatalogs = sortBy(catalogResults, ['countryCode', 'type']) as I[];
            this.init(sortedCatalogs);
        });
    }
}
