/**
 * Based on https://www.state.gov/misc/list/
 */

/* tslint:disable:max-file-line-count */

export enum Country {
    Afghanistan = 'Afghanistan',
    Albania = 'Albania',
    Algeria = 'Algeria',
    Andorra = 'Andorra',
    Angola = 'Angola',
    'Antigua and Barbuda' = 'Antigua and Barbuda',
    Argentina = 'Argentina',
    Armenia = 'Armenia',
    Aruba = 'Aruba',
    Australia = 'Australia',
    Austria = 'Austria',
    Azerbaijan = 'Azerbaijan',
    'Bahamas, The' = 'Bahamas, The',
    Bahrain = 'Bahrain',
    Bangladesh = 'Bangladesh',
    Barbados = 'Barbados',
    Belarus = 'Belarus',
    Belgium = 'Belgium',
    Belize = 'Belize',
    Benin = 'Benin',
    Bhutan = 'Bhutan',
    Bolivia = 'Bolivia',
    'Bosnia and Herzegovina' = 'Bosnia and Herzegovina',
    Botswana = 'Botswana',
    Brazil = 'Brazil',
    Brunei = 'Brunei',
    Bulgaria = 'Bulgaria',
    'Burkina Faso' = 'Burkina Faso',
    Burma = 'Burma',
    Burundi = 'Burundi',
    Cambodia = 'Cambodia',
    Cameroon = 'Cameroon',
    Canada = 'Canada',
    'Cabo Verde' = 'Cabo Verde',
    'Central African Republic' = 'Central African Republic',
    Chad = 'Chad',
    Chile = 'Chile',
    China = 'China',
    Colombia = 'Colombia',
    Comoros = 'Comoros',
    'Congo, Democratic Republic of the' = 'Congo, Democratic Republic of the',
    'Congo, Republic of the' = 'Congo, Republic of the',
    'Costa Rica' = 'Costa Rica',
    "Cote d'Ivoire" = "Cote d'Ivoire",
    Croatia = 'Croatia',
    Cuba = 'Cuba',
    Curacao = 'Curacao',
    Cyprus = 'Cyprus',
    Czechia = 'Czechia',
    Denmark = 'Denmark',
    Djibouti = 'Djibouti',
    Dominica = 'Dominica',
    'Dominican Republic' = 'Dominican Republic',
    'East Timor (see Timor-Leste)' = 'East Timor (see Timor-Leste)',
    Ecuador = 'Ecuador',
    Egypt = 'Egypt',
    'El Salvador' = 'El Salvador',
    'Equatorial Guinea' = 'Equatorial Guinea',
    Eritrea = 'Eritrea',
    Estonia = 'Estonia',
    Ethiopia = 'Ethiopia',
    Fiji = 'Fiji',
    Finland = 'Finland',
    France = 'France',
    Gabon = 'Gabon',
    'Gambia, The' = 'Gambia, The',
    Georgia = 'Georgia',
    Germany = 'Germany',
    Ghana = 'Ghana',
    Greece = 'Greece',
    Grenada = 'Grenada',
    Guatemala = 'Guatemala',
    Guinea = 'Guinea',
    'Guinea-Bissau' = 'Guinea-Bissau',
    Guyana = 'Guyana',
    Haiti = 'Haiti',
    'Holy See' = 'Holy See',
    Honduras = 'Honduras',
    'Hong Kong' = 'Hong Kong',
    Hungary = 'Hungary',
    Iceland = 'Iceland',
    India = 'India',
    Indonesia = 'Indonesia',
    Iran = 'Iran',
    Iraq = 'Iraq',
    Ireland = 'Ireland',
    Israel = 'Israel',
    Italy = 'Italy',
    Jamaica = 'Jamaica',
    Japan = 'Japan',
    Jordan = 'Jordan',
    Kazakhstan = 'Kazakhstan',
    Kenya = 'Kenya',
    Kiribati = 'Kiribati',
    'Korea, North' = 'Korea, North',
    'Korea, South' = 'Korea, South',
    Kosovo = 'Kosovo',
    Kuwait = 'Kuwait',
    Kyrgyzstan = 'Kyrgyzstan',
    Laos = 'Laos',
    Latvia = 'Latvia',
    Lebanon = 'Lebanon',
    Lesotho = 'Lesotho',
    Liberia = 'Liberia',
    Libya = 'Libya',
    Liechtenstein = 'Liechtenstein',
    Lithuania = 'Lithuania',
    Luxembourg = 'Luxembourg',
    Macau = 'Macau',
    Macedonia = 'Macedonia',
    Madagascar = 'Madagascar',
    Malawi = 'Malawi',
    Malaysia = 'Malaysia',
    Maldives = 'Maldives',
    Mali = 'Mali',
    Malta = 'Malta',
    'Marshall Islands' = 'Marshall Islands',
    Mauritania = 'Mauritania',
    Mauritius = 'Mauritius',
    Mexico = 'Mexico',
    Micronesia = 'Micronesia',
    Moldova = 'Moldova',
    Monaco = 'Monaco',
    Mongolia = 'Mongolia',
    Montenegro = 'Montenegro',
    Morocco = 'Morocco',
    Mozambique = 'Mozambique',
    Namibia = 'Namibia',
    Nauru = 'Nauru',
    Nepal = 'Nepal',
    Netherlands = 'Netherlands',
    'New Zealand' = 'New Zealand',
    Nicaragua = 'Nicaragua',
    Niger = 'Niger',
    Nigeria = 'Nigeria',
    'North Korea' = 'North Korea',
    Norway = 'Norway',
    Oman = 'Oman',
    Pakistan = 'Pakistan',
    Palau = 'Palau',
    'Palestinian Territories' = 'Palestinian Territories',
    Panama = 'Panama',
    'Papua New Guinea' = 'Papua New Guinea',
    Paraguay = 'Paraguay',
    Peru = 'Peru',
    Philippines = 'Philippines',
    Poland = 'Poland',
    Portugal = 'Portugal',
    Qatar = 'Qatar',
    Romania = 'Romania',
    Russia = 'Russia',
    Rwanda = 'Rwanda',
    'Saint Kitts and Nevis' = 'Saint Kitts and Nevis',
    'Saint Lucia' = 'Saint Lucia',
    'Saint Vincent and the Grenadines' = 'Saint Vincent and the Grenadines',
    Samoa = 'Samoa',
    'San Marino' = 'San Marino',
    'Sao Tome and Principe' = 'Sao Tome and Principe',
    'Saudi Arabia' = 'Saudi Arabia',
    Senegal = 'Senegal',
    Serbia = 'Serbia',
    Seychelles = 'Seychelles',
    'Sierra Leone' = 'Sierra Leone',
    Singapore = 'Singapore',
    'Sint Maarten' = 'Sint Maarten',
    Slovakia = 'Slovakia',
    Slovenia = 'Slovenia',
    'Solomon Islands' = 'Solomon Islands',
    Somalia = 'Somalia',
    'South Africa' = 'South Africa',
    'South Korea' = 'South Korea',
    'South Sudan' = 'South Sudan',
    Spain = 'Spain',
    'Sri Lanka' = 'Sri Lanka',
    Sudan = 'Sudan',
    Suriname = 'Suriname',
    Swaziland = 'Swaziland',
    Sweden = 'Sweden',
    Switzerland = 'Switzerland',
    Syria = 'Syria',
    Taiwan = 'Taiwan',
    Tajikistan = 'Tajikistan',
    Tanzania = 'Tanzania',
    Thailand = 'Thailand',
    'Timor-Leste' = 'Timor-Leste',
    Togo = 'Togo',
    Tonga = 'Tonga',
    'Trinidad and Tobago' = 'Trinidad and Tobago',
    Tunisia = 'Tunisia',
    Turkey = 'Turkey',
    Turkmenistan = 'Turkmenistan',
    Tuvalu = 'Tuvalu',
    Uganda = 'Uganda',
    Ukraine = 'Ukraine',
    'United Arab Emirates' = 'United Arab Emirates',
    'United Kingdom' = 'United Kingdom',
    'United States' = 'United States',
    Uruguay = 'Uruguay',
    Uzbekistan = 'Uzbekistan',
    Vanuatu = 'Vanuatu',
    Venezuela = 'Venezuela',
    Vietnam = 'Vietnam',
    Yemen = 'Yemen',
    Zambia = 'Zambia',
    Zimbabwe = 'Zimbabwe'
}
