import { Component, ElementRef, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { keyBy, sortBy } from 'lodash';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../../crud/list/BaseLiveStageCrudList';
import getCatalogName from '../../getCatalogName';
import { ICatalog } from '../../../model/product/ICatalog';
import { ICatalogSiteMapMapping } from '../../../model/product/tork/ICatalogSiteMapMapping';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ITorkSiteMap } from '../../../model/product/tork/ITorkSiteMap';
import { LiveStage } from '../../../environment/LiveStage';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../../sortCatalogs';
import { TorkCatalogApi } from '../../../api/product/tork/TorkCatalogApi';
import { TorkCatalogSiteMapMappingApi } from '../../../api/product/tork/TorkCatalogSiteMapMappingApi';
import { TorkSiteMapApi } from '../../../api/product/tork/TorkSiteMapApi';

@Component({
    templateUrl: 'TorkCatalogSiteMapMapping.component.html',
    providers: [TorkCatalogApi, TorkCatalogSiteMapMappingApi, TorkSiteMapApi]
})
export class TorkCatalogSiteMapMappingComponent
    extends BaseLiveStageCrudList<
        ICatalogSiteMapMapping,
        ICrudData<ICatalogSiteMapMapping>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    catalogs: ICatalog[];
    catalogsById: { [id: number]: ICatalog };
    siteMaps: ITorkSiteMap[];
    siteMapsById: { [id: number]: ITorkSiteMap };
    readonly getCatalogName = getCatalogName;

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private torkCatalogApi: TorkCatalogApi,
        private torkCatalogSiteMapMappingApi: TorkCatalogSiteMapMappingApi,
        private torkSiteMapApi: TorkSiteMapApi
    ) {
        super(
            elementRef,
            'product',
            liveStageService,
            prettySubmitGuard,
            'Catalog ⬌ Site Map'
        );
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalog ⬌ Site Maps - Tork');

        this.filter.observable.subscribe(() => {
            forkJoin(
                this.torkCatalogApi.getAll(),
                this.torkSiteMapApi.getAll(),
                this.torkCatalogSiteMapMappingApi.getAll()
            ).subscribe(results => {
                var catalogs = results[0],
                    siteMaps = results[1],
                    mappings = results[2];

                this.catalogs = sortCatalogs(catalogs);
                this.catalogsById = keyBy(catalogs, 'objectId');

                this.siteMaps = sortBy(siteMaps, 'domainName');
                this.siteMapsById = keyBy(siteMaps, 'objectId');

                this.init(
                    sortBy(mappings, mapping => {
                        var catalog = this.catalogsById[mapping.catalogObjectId],
                            siteMap = this.siteMapsById[mapping.siteMapObjectId];

                        return [
                            catalog && catalog.countryName,
                            catalog && catalog.languageName,
                            siteMap && siteMap.domainName
                        ];
                    })
                );
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.torkCatalogSiteMapMappingApi, this.authService);
    }
}
