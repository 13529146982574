import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { IEnvironment } from '../environment/IEnvironment';
import { IReadApi } from 'tridion.web.ui/src/app/angular/api/IReadApi';
import { IGuidModel } from 'tridion.web.ui/src/app/angular/model/IGuidModel';

@Injectable()
export abstract class BaseAuthorizedGuidApi<T extends IGuidModel>
    extends BaseAuthorizedApi<IEnvironment>
    implements IReadApi<T, string> {
    getAll(): Observable<T[]> {
        return this.getJson('');
    }

    getById(id: string): Observable<T> {
        return this.getJson('/' + id);
    }

    getInactive(): Observable<T[]> {
        return this.getJson('/inactive');
    }
}
