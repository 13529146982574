import { ViewChild } from '@angular/core';
import { ChartOptions, TimeDisplayFormat, TimeUnit } from 'chart.js';
import { AdminTitle } from  '../../../layout/AdminTitle';
import { StatsApi } from '../../../api/product/distributionHub/stats/StatsApi';
import { StatisticsInterval } from '../../../model/report/publishing/StatisticsInterval';
import { StatisticsBrand } from '../../../model/report/publishing/StatisticsBrand';
import { StatisticsTimeInterval } from '../../../model/report/publishing/StatisticsTimeInterval';
import toIsoDate from '../../../date/toIsoDate';
import { ChartComponent } from 'angular2-chartjs';
import { Subscription } from 'rxjs';

export abstract class BaseStats<T> {
    @ViewChild('chart',{ read: true, static: false })
    chartComponent: ChartComponent;
    subscription: Subscription;
    brands = Object.values(StatisticsBrand);
    intervals = Object.values(StatisticsInterval);
    timeIntervals = Object.keys(StatisticsTimeInterval);
    interval: TimeUnit = 'day';
    brand = '';
    startDate: string;
    endDate: string;
    today: string;
    timeInterval: '';

    chartData: any = [];

    displayFormats: TimeDisplayFormat = {
        week: '[Week] ww YYYY',
        month: 'MMMM YYYY',
        year: 'YYYY'
    };

    chartOptions: ChartOptions = {
        tooltips: {
            mode: 'index',
            intersect: false
        },
        hover: {
            mode: 'nearest',
            intersect: true
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        isoWeekday: true,
                        unit: this.interval,
                        displayFormats: this.displayFormats,
                        tooltipFormat: this.displayFormats[this.interval]
                    }
                }
            ]
        }
    };

    constructor(
        protected adminTitle: AdminTitle,
        protected statsApi: StatsApi
    ) {
        let endDate = new Date();
        let startDate = new Date(endDate);

        startDate.setDate(startDate.getDate() - 30);
        this.startDate = toIsoDate(startDate);
        this.endDate = toIsoDate(endDate);
        this.today = toIsoDate(new Date());
    }

    setChartOptionInterval() {
        this.chartOptions.scales.xAxes[0].time.unit = this.interval;
        this.chartOptions.scales.xAxes[0].time.tooltipFormat = this.displayFormats[
            this.interval
        ];
    }
}
