
import {map} from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthGuard } from 'tridion.web.ui/src/app/angular/login/adal/AuthGuard';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';
import { UserAccessService } from './UserAccess.service';

@Injectable()
export class AdminAuthGuard extends AuthGuard {
    constructor(authService: AuthService, private userAccessService: UserAccessService) {
        super(authService);
    }

    /**
     * Determine whether the user can see the page.
     *
     * @param route - Current route.
     * @param state - Route the user is trying to go to.
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        var canActivate = super.canActivate(route, state);

        if (canActivate) {
            const requiredAccess = (route.routeConfig as INavbarRoute).requiredAccess;

            canActivate = this.userAccessService.getAccess().pipe(map(userAccess => {
                return UserAccessService.hasAccess(userAccess, requiredAccess);
            }));
        }

        return canActivate;
    }
}
