import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DistributorCountriesApi } from '../../api/distributorFinder/DistributorCountriesApi';
import { DistributorService } from '../../distributorFinder/DistributorFinder.service';
import { ICountry } from '../../model/distributorFinder/ICountry';
import { Router } from '@angular/router';

@Component({
    selector: 'admin-distributor-country-select',
    templateUrl: 'DistributorCountrySelect.component.html',
    providers: [DistributorCountriesApi]
})
export class DistributorCountrySelectComponent implements OnInit {
    countryForm: FormGroup;
    countries: ICountry[];
    @Input()
    selectedCountry: ICountry;
    @Input()
    modalVisible: boolean;
    countryCode: string;
    countryName: string;

    constructor(
        private distributorCountriesApi: DistributorCountriesApi,
        private distributorService: DistributorService,
        private fb: FormBuilder,
        private router: Router,
    ) {
        this.countryCode = '';
        this.countryName = '';
    }
    ngOnInit() {
        this.distributorService.countries.subscribe((countries: ICountry[]) => {
            this.countries = countries;
        });

        this.countryForm = this.fb.group({
            country: new FormControl(
                this.selectedCountry ? this.selectedCountry.code : '',
                Validators.required
            )
        });

        this.distributorCountriesApi.getAll().subscribe((res: ICountry[]) => {
            this.distributorService.countries.next(res);
        });
    }

    selectCountry(country: ICountry) {
        if (this.countryForm.valid) {
            country = this.countries.find((item: ICountry) => {
                if (item.code === this.countryCode && item.name === this.countryName) {
                    return true;
                }
            });

            window.sessionStorage.setItem('selectedCountry', JSON.stringify(country));
            this.distributorService.selectedCountry.next(country);

            this.toggleCountryModal(false);
        }
    }

    toggleCountryModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }

        this.distributorService.countryModalVisible.next(show);
    }

    closeModalAndRedirect(): void {
        this.toggleCountryModal(true);
        this.router.navigate(['/']);
    }
}
