import { Injectable } from '@angular/core';
import { IGuidCatalog } from '../../../model/product/IGuidCatalog';
import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';

@Injectable()
export class DistributionHubCatalogApi extends BaseAuthorizedGuidCrudApi<IGuidCatalog> {
    protected getBaseUrl() {
        return this.environment.api.distributionHub + 'catalog';
    }
}
