
import {map} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep, sortBy } from 'lodash';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { SiteApi } from '../../api/auth/SiteApi';
import { FinancialApi } from '../../api/report/financial/FinancialApi';
import { ShowMoreAdminCrudList } from '../../crud/list/ShowMoreAdminCrudList';
import { ISite } from '../../model/site/ISite';
import { FinancialReportTopic } from '../../model/report/financial/FinancialReportTopic';
import { FinancialReportType } from '../../model/report/financial/FinancialReportType';
import { IFinancial } from '../../model/report/financial/IFinancial';
import { IFinancialFile } from '../../model/report/financial/IFinancialFile';
import { IFinancialFileLink } from '../../model/report/financial/IFinanciaFileLink';
import { AdminTitle } from '../../layout/AdminTitle';
import toIsoDate from '../../date/toIsoDate';

type ReportCrud = BaseCrud<IFinancial, ICrudData<IFinancial>>;
type ReportAttachment = IFinancialFile | IFinancialFileLink;

@Component({
    selector: 'admin-financial-report',
    templateUrl: 'Financial.component.html',
    providers: [SiteApi, FinancialApi]
})
export class FinancialComponent
    extends ShowMoreAdminCrudList<IFinancial, ICrudData<IFinancial>>
    implements OnInit, OnDestroy {
    sites$: BehaviorSubject<ISite[]> = new BehaviorSubject<ISite[]>(null);
    selectedSite: string;
    loadingReports: boolean;
    ckeConfig: any;
    subscription = new Subscription();
    readonly reportTopicOptions = Object.keys(FinancialReportTopic).map(
        (e: any) => FinancialReportTopic[e]
    );
    readonly reportTypeOptions = Object.keys(FinancialReportType).map(
        (e: any) => FinancialReportType[e]
    );
    readonly defaultTime = '07:00';

    constructor(
        prettySubmitGuard: PrettySubmitGuard,
        elementRef: ElementRef,
        private adminTitle: AdminTitle,
        private siteApi: SiteApi,
        private financialApi: FinancialApi
    ) {
        super(elementRef, prettySubmitGuard, 'Financial Report');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Financial reports');
        this.subscription.add(
            this.siteApi
                .getSitesByBrandAccesslevel('essity|essityfinancial').pipe(
                // @ts-ignore @todo
                map(sites => sortBy(sites, 'name')))
                 // @ts-ignore @todo
                .subscribe((sites: ISite[]) => {
                    this.sites$.next(sites);
                })
        );

        this.ckeConfig = {
            allowedContent: false,
            forcePasteAsPlainText: true,
            toolbarGroups: [
                {
                    name: 'financial',
                    groups: ['basicstyles', 'undo', 'clipboard', 'styles']
                }
            ],
            versionCheck: false,
        };
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    loadReports() {
        this.clear();
        if (this.selectedSite) {
            this.loadingReports = true;
            this.subscription.add(
                this.financialApi.getAllReports(this.selectedSite).subscribe(
                    reports => {
                        if (reports) {
                            reports.forEach(report => {
                                report.time = new Date(report.date)
                                    .toTimeString()
                                    .substr(0, this.defaultTime.length);
                                report.date = toIsoDate(report.date);
                            });
                            this.init(reports);
                        }
                    },
                    () => (this.loadingReports = false),
                    () => (this.loadingReports = false)
                )
            );
        }
    }

    save(crud: ReportCrud) {
        if (!crud.isNew()) {
            // Refresh stale temporary data with latest persisted data.
            crud.data.item.files = crud.savedData.item.files;
            crud.data.item.links = crud.savedData.item.links;
        }

        this.subscription.add(crud.save().subscribe());
    }

    protected createCrud(): ReportCrud {
        return new GuidCrud(this.financialApi);
    }

    protected createItem() {
        return {
            websiteId: this.selectedSite,
            files: [],
            links: [],
            time: this.defaultTime
        } as IFinancial;
    }

    private reorderFiles(report: ReportCrud, orderedFileIds: string[]) {
        report.savedData.item.files = this.sort(
            report.savedData.item.files,
            orderedFileIds
        );
        report.data.item.files = cloneDeep(report.savedData.item.files);
    }

    private reorderLinks(report: ReportCrud, orderedLinkIds: string[]) {
        report.savedData.item.links = this.sort(
            report.savedData.item.links,
            orderedLinkIds
        );
        report.data.item.links = cloneDeep(report.savedData.item.links);
    }

    private sort<T extends ReportAttachment>(
        unsortedItems: T[],
        sortedItemIds: string[]
    ): T[] {
        let sortedItems: T[] = [];
        for (let i = 0; i < sortedItemIds.length; i++) {
            for (let j = 0; j < unsortedItems.length; j++) {
                if (unsortedItems[j].id === sortedItemIds[i]) {
                    sortedItems.push(unsortedItems[j]);
                    break;
                }
            }
        }

        return sortedItems;
    }
}
