import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BaseLiveStageCrudList } from '../crud/list/BaseLiveStageCrudList';
import { LiveStage } from '../environment/LiveStage';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { Subject } from 'rxjs';
import { AdminTitle } from '../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { LiveStageService } from '../environment/LiveStage.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ObjectIdCrud } from '../crud/ObjectIdCrud';
import { TpwService } from './Tpw.service';
import { ITpwFullfillmentSetting } from '../model/product/tena/tpw/ITpwFullfillmentSetting';
import { TpwFullfillmentSettingApi } from '../api/product/tena/tpw/TpwFullfillmentSettingApi';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: 'TpwFullfillmentSetting.component.html',
    providers: [TpwFullfillmentSettingApi]
})
export class TpwFullfillmentSettingComponent
    extends BaseLiveStageCrudList<
        ITpwFullfillmentSetting,
        ICrudData<ITpwFullfillmentSetting>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    sortingOrderOptions: number[];
    validEmail = true;
    InvalidEmail = '';
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tpwFullfillmentSettingApi: TpwFullfillmentSettingApi,
        private tpwService: TpwService
    ) {
        super(
            elementRef,
            'sampleorder',
            liveStageService,
            prettySubmitGuard,
            'Fullfillment Setting'
        );
    }

    ngOnInit() {
        this.adminTitle.setTitle('Fullfillment Setting - Tpw');

        this.tpwService.sampleChanged
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(selectedSample => {
                if (selectedSample) {
                    this.clear();
                    this.getFullfillmentSetting();
                }
            });

        this.tpwService.channelChanged
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(selectedChannel => {
                if (selectedChannel) {
                    this.clear();
                    this.getFullfillmentSetting();
                }
            });
    }

    validateEmail(crud: any) {
        crud.data.item.fullFilmentCenter = this.onKeydown(
            crud.data.item.fullFilmentCenter
        );
        var regexp = new RegExp(
            '^([A-za-z0-9][-A-za-z0-9._]+@([-A-Za-z0-9]+[.])+[A-Za-z]{2,5};?)+$'
        );
        if (regexp.test(crud.data.item.fullFilmentCenter)) {
            this.InvalidEmail = '';
            this.validEmail = true;
        } else {
            this.InvalidEmail = 'Enter valid email';
            this.validEmail = false;
        }
    }

    onKeydown(value: string) {
        let emails: string;
        if (value) {
            // Remove any white space
            emails = (value as string).replace(/\s/g, '');
        }

        return emails;
    }

    getFullfillmentSetting() {
        let sample = this.tpwService.getActiveSample();
        let channel = this.tpwService.getActiveChannel();
        if (sample !== null && channel !== null) {
            this.tpwFullfillmentSettingApi
                .getFullfillmentSetting(sample.categoryName, channel.channelId)
                .subscribe(fullfillmentSetting => {
                    this.clear();
                    this.init(fullfillmentSetting);
                });
        }
    }

    get isActionCodeNeeded() {
        let sample = this.tpwService.getActiveSample();

        return sample;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tpwFullfillmentSettingApi, this.authService);
    }
}
