import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidApi } from '../BaseAuthorizedGuidApi';
import { ITestData } from '../../model/testResults/ITestData';
import { IAutomationFilters } from '../../model/testResults/IAutomationFilters';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class TestResultsApi extends BaseAuthorizedGuidApi<ITestData> {
    getTestData(domainId: string, filter: IAutomationFilters): Observable<ITestData> {
        const startDate = moment(filter.startDate)
            .format('DD-MMM-YYYY')
            .toLowerCase();
        const endDate = moment(filter.endDate)
            .format('DD-MMM-YYYY')
            .toLowerCase();
        let url = `/domain/${domainId}`;
        if (filter.website) {
            url += `/website/${filter.website.id}`;
        }
        url += `/startDate${startDate}/endDate${endDate}`;

        return this.getJson(url);
    }
    protected getBaseUrl() {
        return this.environment.api.automation + 'Report';
    }
}
