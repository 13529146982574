
import { Component, ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { find, map, shareReplay } from 'rxjs/operators';
import { sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { SampleBackgroundType } from '../../model/campaign/sample/SampleBackgroundType';
import { SampleBackgroundColor } from '../../model/campaign/sample/SampleBackgroundColor';
import { SendSampleEmailApi } from '../../api/campaign/sample/email/SendSampleEmailApi';
import { ShowMoreAdminCrudList } from '../../crud/list/ShowMoreAdminCrudList';
import { SiteMappingApi } from '../../api/campaign/siteMapping/SiteMappingApi';
import { ISiteMapping } from '../../model/campaign/siteMapping/ISiteMapping';
import { FemcareSampleApi } from '../../api/campaign/sample/FemcareSampleApi';
import { IFemcareSample } from '../../model/campaign/sample/IFemcareSample';

import toIsoDate from '../../date/toIsoDate';

@Component({
    templateUrl: 'FemcareSamples.component.html',
    providers: [SiteMappingApi, FemcareSampleApi, SendSampleEmailApi]
})
export class FemcareSamplesComponent
    extends ShowMoreAdminCrudList<IFemcareSample, ICrudData<IFemcareSample>>
    implements OnInit {
    sites: Observable<ISiteMapping[]>;
    currentSite: ISiteMapping;
    sendEmailUrl: string;
    backgroundColor = SampleBackgroundColor;
    backgroundColorKeys = Object.keys(SampleBackgroundColor);
    backgroundType = SampleBackgroundType;
    backgroundTypeKeys = Object.keys(SampleBackgroundType);
    ckeConfig = {
        allowedContent: false,
        forcePasteAsPlainText: true,
        toolbar: [
            {
                name: 'samples', items: [
                    'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo', '-',
                    'Bold', 'Italic', 'Underline', 'Subscript', 'Superscript', '-',
                    'NumberedList', 'BulletedList', '-',
                    'CopyFormatting', 'RemoveFormat', 'Format', 'Font', 'FontSize', '-',
                    'JustifyLeft', 'JustifyCenter', 'JustifyRight', '-',
                    'Link', 'Unlink'
                ]
            }
        ]
    };

    constructor(
        private adminTitle: AdminTitle,
        private sendEmailApi: SendSampleEmailApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        protected siteMappingsApi: SiteMappingApi,
        protected sampleApi: FemcareSampleApi
    ) {
        super(elementRef, prettySubmitGuard, 'Sample');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Femcare Samples');

        this.sites = this.siteMappingsApi
            .getAll().pipe(
                map(sites => sortBy(sites, 'name')),
                shareReplay());

        this.sampleApi.getAll().subscribe(samples => {
            samples = sortBy(samples, 'sortOrder');

            samples.forEach(sample => {
                sample.startDate = toIsoDate(sample.startDate);
                sample.endDate = toIsoDate(sample.endDate);
            });

            this.init(samples);
        });
    }

    onSubmit(crud: any) {
        let subscription = crud.onSave.subscribe(() => {
            if (crud.isSaved() && crud.savedData.item.sendNotificationEmail) {
                this.generateUrl(crud.savedData.item.websiteId);
                this.sendEmail(this.sendEmailUrl, crud);
            }
            
            subscription.unsubscribe();
        });
    }

    generateUrl(websiteId: string) {
        this.sites.pipe(
            map(siteMappings => siteMappings.find(site => site.name === websiteId)))
            .subscribe(currentSite => {
                this.currentSite = currentSite;

                this.sendEmailUrl = this.currentSite.url + '/samplesbackinstocknotification/';
            });
    }

    sendEmail(url: string, crud: any) {
        this.sendEmailApi.sendEmail(url, crud.data.item).subscribe((res: any) => {
            // No logic needed
        })
    }

    protected createCrud() {
        return new GuidCrud(this.sampleApi);
    }

    protected createItem() {
        return {
            startDate: toIsoDate(new Date())
        } as IFemcareSample;
    }
}
