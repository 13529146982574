import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { IGuidDomain } from '../../model/product/IGuidDomain';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { DistributionHubDomainApi } from '../../api/product/distributionHub/DistributionHubDomainApi';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { BaseAdminCrudList } from '../../crud/list/BaseAdminCrudList';

@Component({
    templateUrl: 'DistributionHubDomains.component.html',
    providers: [DistributionHubDomainApi]
})
export class DistributionHubDomainsComponent
    extends BaseAdminCrudList<IGuidDomain, ICrudData<IGuidDomain>>
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        private distributionHubBrandApi: DistributionHubDomainApi
    ) {
        super(elementRef, prettySubmitGuard, 'Domain');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Domains - Distribution Hub');

        this.distributionHubBrandApi.getAll().subscribe(domains => {
            this.init(sortBy(domains, 'domain'));
        });
    }

    protected createCrud() {
        return new GuidCrud(this.distributionHubBrandApi);
    }
}
