import { Component, Input } from '@angular/core';
import { BaseListHeaderComponent } from '../../../list/BaseListHeader.component';

@Component({
    selector: 'admin-leukoplast-list-header',
    templateUrl: 'LeukoplastListHeader.component.html'
})
export class LeukoplastListHeaderComponent<I> extends BaseListHeaderComponent<I> {
    @Input()
    useSegment = true;
}
