import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IJobstSegment } from '../../../model/product/tena/jobst/IJobstSegment';
import { IJobstTCWCatalog } from '../../../model/product/tena/jobst/IJobstTCWCatalog';

@Injectable()
export class JobstService {
    countryChanged = new Subject<IJobstTCWCatalog>();
    segmentChanged = new Subject<IJobstSegment>();

    countries = new Subject<IJobstTCWCatalog[]>();
    segments = new Subject<IJobstSegment[]>();
    countryModalVisible = new Subject<boolean>();
    segmentModalVisible = new Subject<boolean>();

    getActiveCountry(): IJobstTCWCatalog {
        let country = null;
        let sessionStorage = window.sessionStorage.getItem('jobstSelectedCountry') || window.localStorage.getItem('jobstSelectedCountry');
        if (sessionStorage !== '' && sessionStorage !== null) {
            country = JSON.parse(sessionStorage);
        }

        return country;
    }

    getActiveSegment(): IJobstSegment {
        let segment = null;
        let sessionStorage = window.sessionStorage.getItem('jobstSelectedSegment') || window.localStorage.getItem('jobstSelectedSegment')
        if (sessionStorage !== '' && sessionStorage !== null) {
            segment = JSON.parse(sessionStorage);
        }

        return segment;
    }

    emitSegmentChange() {
        this.segmentChanged.next();
    }

}
