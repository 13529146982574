import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { IActimoveCatalogIdentificationMapping } from '../../../../model/product/tena/actimove/IActimoveCatalogIdentificationMapping';

@Injectable()
export class ActimoveCatalogIdentificationMappingApi extends BaseProductApi<
IActimoveCatalogIdentificationMapping
> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscountrytoidentificationsourcemapping/actimove';
    }
}
