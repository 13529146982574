import { Component, Input } from '@angular/core';
import { BaseListHeaderComponent } from '../../../list/BaseListHeader.component';

@Component({
    selector: 'admin-tena-list-header',
    templateUrl: 'TenaListHeader.component.html'
})
export class TenaListHeaderComponent<I> extends BaseListHeaderComponent<I> {
    @Input()
    useSegment = true;
}
