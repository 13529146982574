import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IJobstSegment } from '../../../../model/product/tena/jobst/IJobstSegment';
import { IDistributor } from '../../../../model/dealerLocator/IDistributor';
import { ICountry } from '../../../../model/distributorFinder/ICountry';

@Injectable()
export class DealerLocatorService {
    selectedCountry = new Subject<ICountry>();
    selectedSegment = new Subject<IJobstSegment>();
    mapOpen = new Subject<boolean>();
    distributor = new Subject<IDistributor>();
    distributorNewValues = new Subject<IDistributor>();
    distributors = new Subject<IDistributor[]>();
    totalCount: number;
    countries = new Subject<ICountry[]>();
    countryModalVisible = new Subject<boolean>();
    segmentChanged = new Subject<IJobstSegment>();

    getActiveCountry() {
        let country = null;
        let sessionStorage = window.sessionStorage.getItem('jobstSelectedCountry') || window.localStorage.getItem('jobstSelectedCountry');
        if (sessionStorage !== '' && sessionStorage !== null) {
            country = JSON.parse(sessionStorage);
        }

        return country;
    }

    getActiveSegment(): IJobstSegment {
        let segment = null;
        let sessionStorage = window.sessionStorage.getItem('jobstSelectedSegment') || window.localStorage.getItem('jobstSelectedSegment')
        if (sessionStorage !== '' && sessionStorage !== null) {
            segment = JSON.parse(sessionStorage);
        }

        return segment;
    }

    emitSegmentChange() {
        this.segmentChanged.next();
    }
}
