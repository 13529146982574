import { Component, ElementRef, OnInit } from '@angular/core';
import { find, sortBy } from 'lodash';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AccessType } from '../../model/auth/AccessType';
import { ActiveDirectoryGroupApi } from '../../api/auth/ActiveDirectoryGroupApi';
import { AdminTitle } from '../../layout/AdminTitle';
import { AuthEntityApi } from '../../api/auth/AuthEntityApi';
import { AuthEntityType, getParentTypes } from '../../model/auth/AuthEntityType';
import { AuthModuleApi } from '../../api/auth/AuthModuleApi';
import { BaseAdminCrudList } from '../../crud/list/BaseAdminCrudList';
import { Brand } from '../../model/auth/login/Brand';
import getUniqueOptions from 'tridion.web.ui/src/app/angular/crud/getUniqueOptions';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { IActiveDirectoryGroup } from '../../model/auth/IActiveDirectoryGroup';
import { IAuthEntity } from '../../model/auth/IAuthEntity';
import { IAuthModule } from '../../model/auth/IAuthModule';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { IRelationalData } from '../../model/auth/IRelationalData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';

@Component({
    templateUrl: 'AuthEntities.component.html',
    providers: [ActiveDirectoryGroupApi, AuthEntityApi, AuthModuleApi]
})
export class AuthEntitiesComponent
    extends BaseAdminCrudList<IAuthEntity, ICrudData<IAuthEntity>>
    implements OnInit {
    allGroups: Observable<IActiveDirectoryGroup[]>;
    allModules: Observable<IAuthModule[]>;
    readonly accessTypeOptions = Object.keys(AccessType);
    readonly authEntityTypeOptions = Object.keys(AuthEntityType);
    readonly brandOptions = Object.keys(Brand);

    constructor(
        private activeDirectoryGroupApi: ActiveDirectoryGroupApi,
        private adminTitle: AdminTitle,
        private authEntityApi: AuthEntityApi,
        private authModuleApi: AuthModuleApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard
    ) {
        super(elementRef, prettySubmitGuard, 'Access Level');
    }

    ngOnInit() {
        this.adminTitle.setTitle(['Access Levels', 'Security']);

        this.authEntityApi.getAll().subscribe(entities => {
            entities.forEach(entity => {
                var groups = entity.assignedGroups;
                if (groups) {
                    entity.assignedGroups = groups.filter(
                        group => !group.inherited && !!group.accessType
                    );
                } else {
                    entity.assignedGroups = [];
                }

                var tools = entity.assignedTools;
                if (tools) {
                    entity.assignedTools = tools.filter(tool => !tool.inherited);
                } else {
                    entity.assignedTools = [];
                }
            });

            this.init(sortBy(entities, 'namePath'));
        });
        this.allGroups = this.activeDirectoryGroupApi
            .getAll().pipe(
            map(allGroups => {
                return sortBy(allGroups, 'name');
            }),
            shareReplay(),);
        this.allModules = this.authModuleApi
            .getAll().pipe(
            map(allModules => {
                return sortBy(allModules, 'name');
            }),
            shareReplay(),);
    }

    getHierarchy(entity: IAuthEntity) {
        var hierarchy: string[] = [],
            items = this.items;

        function add(addEntity: IAuthEntity) {
            hierarchy.unshift(addEntity.name);

            var parentId = addEntity.parentId;
            if (parentId) {
                let parentEntity = find(items, {
                    id: parentId
                });

                if (parentEntity) {
                    add(parentEntity);
                }
            }
        }

        add(entity);

        return hierarchy;
    }

    getHierarchyString(entity: IAuthEntity) {
        return this.getHierarchy(entity).join(' > ');
    }

    getGroupOptions(current: IRelationalData, alreadyUsed: IRelationalData[]) {
        return this.allGroups.pipe(map(groups => {
            return getUniqueOptions(groups, alreadyUsed, current, 'id', 'id');
        }));
    }

    getModuleOptions(current: IRelationalData, alreadyUsed: IRelationalData[]) {
        return this.allModules.pipe(map(modules => {
            return getUniqueOptions(modules, alreadyUsed, current, 'id', 'id');
        }));
    }

    getParentOptions(entity: IAuthEntity) {
        var options: IAuthEntity[];

        var entities = this.items;
        if (entities) {
            let parentTypes = getParentTypes(entity.type);
            if (parentTypes) {
                options = entities.filter(parent => {
                    return parentTypes.includes(parent.type);
                });
            }
        }

        return options;
    }

    isParentOptionsVisible(entity: IAuthEntity) {
        var type = entity.type;

        return !!type && type !== AuthEntityType.Platform;
    }

    protected createCrud() {
        return new GuidCrud(this.authEntityApi);
    }

    protected createItem() {
        return {
            assignedGroups: [],
            assignedTools: []
        } as IAuthEntity;
    }
}
