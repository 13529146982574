import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { IJobstSegment } from '../../../../model/product/tena/jobst/IJobstSegment';

@Injectable()
export class JobstSegmentApi extends BaseProductApi<IJobstSegment> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmssegment/jobst';
    }
}
