import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Routes } from './Routes';

@NgModule({
    imports: [RouterModule.forRoot(Routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
