import { Inject, Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';

import { Observable } from 'rxjs';
import { ILeukoplastWebShop } from '../../../../model/product/tena/leukoplast/ILeukoplastWebShop';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { LeukoplastService } from '../../../../product/tena/leukoplast/Leukoplast.service';
import { LeukoplastBaseTCWCatalogApi } from './LeukoplastBaseTCWCatalogApi';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { LeukoplastWebShopCatalogApi } from '../leukoplast/LeukoplastWebShopCatalogApi';
import { ILeukoplastWebShopCatalog } from '../../../../model/product/tena/leukoplast/ILeukoplastWebShopCatalog';
import { LeukoplastSegmentApi } from './LeukoplastSegmentApi';
import { ILeukoplastSegment } from './../../../../model/product/tena/leukoplast/ILeukoplastSegment.d';

@Injectable()
export class LeukoplastWebShopApi extends LeukoplastBaseTCWCatalogApi<ILeukoplastWebShop> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        leukoplastService: LeukoplastService,
        readonly leukoplastService1: LeukoplastService,
        liveStageService: LiveStageService,
        readonly leukoplastCatalogApi: LeukoplastWebShopCatalogApi,
        readonly leukoplastSegmentsApi: LeukoplastSegmentApi
    ) {
        super(apiErrorHandler, authService, environment, http, leukoplastService, liveStageService);
    }

    getWebShops(countryId: number, segmentId: number): Observable<ILeukoplastWebShop[]> {
        return this.getJson(`/${countryId}/${segmentId}`);
    }

    protected getBaseUrl() {
        this.leukoplastCatalogApi.getAll().subscribe((res: ILeukoplastWebShopCatalog[]) => {
            this.leukoplastService1.countries.next(res);
        });

        this.leukoplastSegmentsApi.getAll().subscribe((res: ILeukoplastSegment[]) => {
            const LeukoplastSegment = res.filter(segment => segment.isLeukoplastSegment);

            this.leukoplastService1.segments.next(LeukoplastSegment);
        });

        return this.TCWBaseURl + 'webshop';
    }
}
