import { cloneDeep } from 'lodash';

import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ICrudApi } from 'tridion.web.ui/src/app/angular/api/ICrudApi';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ObjectIdCrud } from '../../../../crud/ObjectIdCrud';
import { ITenaSampleOrder } from './ITenaSampleOrder';
import { TenaSampleOrderSize } from './TenaSampleOrderSize';
import { CrudMode } from 'tridion.web.ui/src/app/angular/crud/CrudMode';

/**
 * Custom crud for Tena Sample.
 * Tena sample has a special database-stored string for available sizes.
 * It is stored like "'Name1|PackadeCode1;Name2|PackadeCode2",
 * but we want to display it in a pretty way in the UI.
 */
export class TenaSampleOrderCrud extends ObjectIdCrud<
    ITenaSampleOrder,
    ICrudData<ITenaSampleOrder>
> {
    saveSubscription: any;

    constructor(api: ICrudApi<ITenaSampleOrder, any>, authService: AuthService) {
        super(api, authService);
    }

    setMode(mode: CrudMode) {
        super.setMode(mode);
        if (mode === CrudMode.Edit) {
            this.setupAvailableSizes(this.savedData);
        }
    }

    initSaved(initialData: ICrudData<ITenaSampleOrder>) {
        this.setupAvailableSizes(initialData);
        super.initSaved(initialData);
    }

    save() {
        // Override regular save method to prepare sizes for database.

        this.setDbSizesString();
        this.data.item.availableSizesParsed = null;

        // Hook up onSave to setup sizes after save.
        if (!this.saveSubscription) {
            this.saveSubscription = this.onSave.subscribe(() => {
                this.setupAvailableSizes(this.savedData);
            });
        }

        return super.save();
    }

    removeSize(size: TenaSampleOrderSize) {
        this.data.item.availableSizesParsed.splice(
            this.data.item.availableSizesParsed.indexOf(size),
            1
        );
    }

    addSize() {
        this.data.item.availableSizesParsed.push({ packageCode: '', size: '' });
    }

    /**
     * Sets the sizes as string so it can be stored in database
     */
    setDbSizesString() {
        let result = '';
        this.data.item.availableSizesParsed.forEach(x => {
            if (x.size) {
                result += x.size + (x.packageCode ? '|' + x.packageCode : '');
                result += ';';
            }
        });
        result = result.substring(0, result.length - 1);

        this.data.item.availableSizes = result;
    }

    /**
     * Gets all sizes split into an array
     */
    getSizesArray(): string[] {
        return this.data.item.availableSizes
            ? this.data.item.availableSizes.split(';')
            : [];
    }

    private setupAvailableSizes(initialData: ICrudData<ITenaSampleOrder>) {
        if (!initialData.item.availableSizes) {
            initialData.item.availableSizesParsed = [{ packageCode: '', size: '' }];
        } else {
            initialData.item.availableSizesParsed = [];
            initialData.item.availableSizes.split(';').forEach(value => {
                let splitted = value.split('|');
                initialData.item.availableSizesParsed.push({
                    size: splitted[0],
                    packageCode: splitted.length === 2 ? splitted[1] : null
                } as TenaSampleOrderSize);
            });
        }

        // Clone the data to stored data. We don't want reference copy so initial value is kept.
        if (this.data && this.data.item) {
            this.data.item.availableSizesParsed = cloneDeep(
                initialData.item.availableSizesParsed
            );
        }
    }
}
