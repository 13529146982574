
import {shareReplay} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AdminTitle } from '../../layout/AdminTitle';
import { ImportLogsApi } from '../../api/product/femcare/ImportLogsApi';
import toIsoDate from '../../date/toIsoDate';

@Component({
    templateUrl: './ImportLogs.component.html',
    providers: [ImportLogsApi]
})
export class ImportLogsComponent<I> implements OnInit {
    items?: Observable<any>;
    startDate: string;
    endDate: string;

    constructor(private adminTitle: AdminTitle, protected importLogsApi: ImportLogsApi) {}

    ngOnInit() {
        this.adminTitle.setTitle('Product Import Logs - FemCare');
        this.startDate = this.endDate = toIsoDate(new Date());
        this.loadItems();
    }

    loadItems() {
        let startDateTimestamp = +new Date(this.startDate);
        let endDateTimestamp = +new Date(this.endDate);

        if (this.startDate) {
            this.items = this.importLogsApi
                .getItems(this.startDate, this.endDate).pipe(
                shareReplay());
        }

        if (startDateTimestamp > endDateTimestamp) {
            this.endDate = this.startDate;
        }
    }
}
