import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { FinancialFileLinkApi } from '../../api/report/financial/FinancialFileLinkApi';
import { ShowMoreAdminCrudList } from '../../crud/list/ShowMoreAdminCrudList';
import { IFinancialFileLink } from '../../model/report/financial/IFinanciaFileLink';

type LinkCrud = BaseCrud<IFinancialFileLink, ICrudData<IFinancialFileLink>>;

@Component({
    selector: 'admin-financial-report-file-link',
    templateUrl: 'FinancialFileLink.component.html',
    providers: [FinancialFileLinkApi]
})
export class FinancialFileLinkComponent
    extends ShowMoreAdminCrudList<IFinancialFileLink, ICrudData<IFinancialFileLink>>
    implements OnInit, OnDestroy {
    @Input()
    crudIndex: string;
    @Input()
    reportId: string;
    @Input()
    reportFileLinks: IFinancialFileLink[];
    @Output()
    reorder = new EventEmitter<string[]>();
    @Output()
    cancel = new EventEmitter<boolean>();
    orderChanged: boolean;
    dragulaGroup = 'Links';
    orderedLinkIds: string[] = [];
    subscription = new Subscription();
    reorderInProgress: boolean;
    handleClass = 'FinancialFileLink--item__handle';

    constructor(
        prettySubmitGuard: PrettySubmitGuard,
        private financialReportFileLinkApi: FinancialFileLinkApi,
        private dragulaService: DragulaService
    ) {
        super(null, prettySubmitGuard, 'Financial Report Link');
    }

    ngOnInit(): void {
        this.init(this.reportFileLinks);
        this.financialReportFileLinkApi.reportId = this.reportId;
        this.dragulaGroup = this.dragulaGroup + this.crudIndex;
        this.dragulaService.createGroup(this.dragulaGroup, {
            moves: (el, container, handle) => {
                return (
                    handle.classList.contains(this.handleClass) &&
                    !this.reorderInProgress &&
                    !this.hasNewItem()
                );
            }
        });

        this.subscription.add(
            this.dragulaService
                .dropModel(this.dragulaGroup)
                .subscribe(({ targetModel }) => {
                    this.orderChanged = true;
                    this.orderedLinkIds = targetModel.map(
                        (crud: LinkCrud, index: number) => {
                            this.items[index] = crud.savedData.item;

                            return crud.data.item.id;
                        }
                    );
                })
        );
    }

    ngOnDestroy() {
        this.dragulaService.destroy(this.dragulaGroup);
        this.subscription.unsubscribe();
    }

    reorderLinks() {
        this.reorderInProgress = true;
        this.subscription.add(
            this.financialReportFileLinkApi.reorderLinks(this.orderedLinkIds).subscribe(
                () => {
                    this.orderChanged = false;
                    this.reorder.emit(this.orderedLinkIds);
                },
                err => (this.reorderInProgress = false),
                () => (this.reorderInProgress = false)
            )
        );
    }

    discard() {
        this.cancel.emit();
    }

    protected createCrud(): LinkCrud {
        return new GuidCrud(this.financialReportFileLinkApi);
    }
}
