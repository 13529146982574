import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DealerCountriesApi } from '../../../../../api/dealerLocator/DealerCountriesApi';
import { DealerLocatorService } from '../../dealerLocator/DistributorFinder.service';
import { ICountry } from '../../../../../model/dealerLocator/ICountry';
import { Router } from '@angular/router';

@Component({
    selector: 'admin-dealer-country-select',
    templateUrl: 'DistributorCountrySelect.component.html',
    providers: [DealerCountriesApi]
})
export class DealerCountrySelectComponent implements OnInit {
    countryForm: FormGroup;
    countries: ICountry[];
    @Input()
    selectedCountry: ICountry;
    @Input()
    modalVisible: boolean;
    countryCode: string;
    countryName: string;

    constructor(
        private dealerCountriesApi: DealerCountriesApi,
        private dealerLocatorService: DealerLocatorService,
        private fb: FormBuilder,
        private router: Router,
    ) {
        this.countryCode = '';
        this.countryName = '';
    }

    ngOnInit() {
        this.dealerLocatorService.countries.subscribe((countries: ICountry[]) => {
            this.countries = countries;
        });

        this.countryForm = this.fb.group({
            country: new FormControl(
                this.selectedCountry ? this.selectedCountry.code : '',
                Validators.required
            )
        });

        this.dealerCountriesApi.getAll().subscribe((res: ICountry[]) => {
            this.dealerLocatorService.countries.next(res);
        });
    }

    selectCountry(country: ICountry) {
        if (this.countryForm.valid) {
            country = this.countries.find((item: ICountry) => {
                if (item.code === this.countryCode && item.name === this.countryName) {
                    return true;
                }
            });

            window.sessionStorage.setItem('selectedCountry', JSON.stringify(country));
            this.dealerLocatorService.selectedCountry.next(country);

            this.toggleCountryModal(false);
        }
    }

    toggleCountryModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }

        this.dealerLocatorService.countryModalVisible.next(show);
    }

    closeModalAndRedirect(): void {
        this.toggleCountryModal(true);
        this.router.navigate(['/']);
    }
}
