import { Injectable } from '@angular/core';
import { IGuidSolrCoreConfig } from '../../../model/product/distributionHub/IGuidSolrCoreConfig';
import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';
import { Observable } from 'rxjs';

@Injectable()
export class DistributionHubSolrCoreConfigApi extends BaseAuthorizedGuidCrudApi<IGuidSolrCoreConfig> {

    update(item: IGuidSolrCoreConfig): Observable<IGuidSolrCoreConfig> {
        return this.putJson('/' + item.id, item);
    }
    protected getBaseUrl() {
        return this.environment.api.distributionHub + 'solrcoreconfig';
    }
}
