
import {takeUntil} from 'rxjs/operators';
import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { AdminTitle } from '../layout/AdminTitle';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DistributorFinderApi } from '../api/distributorFinder/DistributorFinderApi';
import { DistributorService } from '../distributorFinder/DistributorFinder.service';
import { IDistributor } from '../model/distributorFinder/IDistributor';
import { ICountry } from '../model/distributorFinder/ICountry';
import { DistributorCountrySelectComponent } from './distributorCountrySelect/DistributorCountrySelect.component';
import { Subject } from 'rxjs';
import { AlertService } from 'tridion.web.ui/src/app/angular/layout/alert/Alert.service';
import { IAlert } from 'tridion.web.ui/src/app/angular/layout/alert/IAlert';
import { AlertType } from 'tridion.web.ui/src/app/angular/layout/alert/AlertType';

@Component({
    templateUrl: 'DistributorFinder.component.html',
    providers: [DistributorCountrySelectComponent, DistributorFinderApi]
})
export class DistributorFinderComponent implements OnInit, OnDestroy {
    @ViewChild('browse', {static: false})
    fileUpload: ElementRef;
    distributors: IDistributor[];
    searchForm: FormGroup;
    distributor: IDistributor;
    closeResult: string;
    file: any;
    fileType: string;
    totalPages: number;
    page = 1;
    limit = 20;
    arr: number[];
    country: ICountry;
    modalVisible: boolean;
    importSuccess: string;
    spinner: boolean;
    confirmModal = false;
    publishSpinner = false;
    showPublishMessage = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private distributorFinderApi: DistributorFinderApi,
        private distributorCountry: DistributorCountrySelectComponent,
        private fb: FormBuilder,
        private distributorService: DistributorService,
        private alertService: AlertService
    ) {}

    public edit(distributor: IDistributor) {
        this.distributorService.distributor.next(distributor);
        this.distributorService.distributorNewValues.next(Object.assign({}, distributor));
    }

    public delete(distributor: IDistributor) {
        this.distributorFinderApi
            .inactivate(distributor).pipe(
            takeUntil(this.unsubscribe))
            .subscribe((res: IDistributor) => {
                this.getAll();
            });
    }

    ngOnInit(): void {
        this.adminTitle.setTitle('Distributor Finder');
        this.searchForm = this.fb.group({
            searchFilter: new FormControl('name'),
            searchField: new FormControl(null)
        });
        this.initializeCountryModal();
        this.initializeDistributors();
    }

    initializeDistributors(): void {
        this.distributorService.distributor.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(distributor => {
                this.distributor = distributor;
            });

        this.distributorService.distributors.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(distributors => {
                this.distributors = distributors;
                this.totalPages = Math.ceil(
                    this.distributorService.totalCount / this.limit
                );
                this.arr = Array(this.totalPages);

                if (this.page > this.totalPages) {
                    this.setPage(this.totalPages);
                }
            });
        this.formChanges();
    }
    initializeCountryModal(): void {
        this.distributorService.countryModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.modalVisible = visible;
            });

        this.distributorService.selectedCountry.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedCountry => {
                if (selectedCountry) {
                    this.country = selectedCountry;
                    this.getAll();
                }
            });
        let sessionStorageCountry = window.sessionStorage.getItem('selectedCountry');
        if (sessionStorageCountry !== '' && sessionStorageCountry !== null) {
            this.distributorService.selectedCountry.next(
                JSON.parse(sessionStorageCountry)
            );
            this.distributorCountry.toggleCountryModal(false);
        } else {
            this.distributorCountry.toggleCountryModal(true);
        }
    }

    formChanges(): void {
        this.searchForm.valueChanges.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((val: FormGroup) => {
                this.getAll();
            });
    }

    getAll() {
        this.spinner = true;

        this.distributorFinderApi
            .getDistributors(this.limit, this.page === 0 ? 1 : this.page, this.searchForm).pipe(
            takeUntil(this.unsubscribe))
            .subscribe(response => {
                this.distributorService.totalCount = response.totalCount;
                this.distributorService.distributors.next(response.distributors);
                this.spinner = false;
            });
    }

    addNewDistributor() {
        this.distributorService.distributorNewValues.next(Object.assign({}));
    }

    browseFile() {
        let event = new MouseEvent('click');
        this.fileUpload.nativeElement.dispatchEvent(event);
    }

    browseFileInputChange(event: Event) {
        if (this.fileUpload.nativeElement.files.length > 0) {
            this.file = this.fileUpload.nativeElement.files[0];
            if (
                this.file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                this.fileType = this.file.type;
            }
        }
    }

    importFile() {
        let data = new FormData();
        data.append('file', this.file, this.file.name);
        this.distributorFinderApi
            .importData(data).pipe(
            takeUntil(this.unsubscribe))
            .subscribe(
                (res: any) => {
                    this.importSuccess = res;
                    this.getAll();

                    setTimeout(() => {
                        this.importSuccess = '';
                    }, 4000);
                },
                error => {
                    if(error._body) {
                        let alert: IAlert = {
                            message: '',
                            type: AlertType.error,
                            close: true
                        };

                        alert.message = error._body;
                        
                        this.alertService.setAlert(alert);
                    }
                    
                    this.file = null;
                    this.fileType = '';
                    this.fileUpload.nativeElement.value = null;
                },
                () => {
                    this.file = null;
                    this.fileType = '';
                    this.fileUpload.nativeElement.value = null;
                }
            );
    }

    setPage(page: number) {
        this.page = page;
        this.getAll();
    }

    selectCountry() {
        this.distributorCountry.toggleCountryModal(true);
        this.distributorService.countryModalVisible.next(true);
    }

    export() {
        this.distributorFinderApi
            .exportFile().pipe(
            takeUntil(this.unsubscribe))
            .subscribe(res => {
                let fileData = atob(res.text());
                var byteNumbers = Array(fileData.length);

                for (var i = 0; i < fileData.length; i++) {
                    byteNumbers[i] = fileData.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                let blob = new Blob([byteArray], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var objectUrl = URL.createObjectURL(blob);
                window.open(objectUrl);
            });
    }

    publish() {
        this.publishSpinner = true;
        this.distributorFinderApi
            .publish()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                res => {
                    this.showPublishMessage = true;
                    this.publishSpinner = false;
                },
                error => {
                    this.publishSpinner = false;
                }
            );
    }

    toggleConfirmModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
            this.showPublishMessage = false;
        }

        this.confirmModal = show;
    }
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
