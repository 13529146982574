
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { TpwService } from '../../Tpw.service';
import { ITpwChannel } from '../../../model/product/tena/tpw/ITpwChannel';
import { TpwChannelApi } from '../../../api/product/tena/tpw/TpwChannelApi';

@Component({
    selector: 'admin-tpw-channel-select',
    templateUrl: 'TpwChannelSelect.component.html',
    providers: [TpwChannelApi]
})
export class TpwChannelSelectComponent implements OnInit, OnDestroy {
    channelForm: FormGroup;
    channels: ITpwChannel[];

    @Input()
    selectedChannel: ITpwChannel;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public tpwService: TpwService,
        private tpwChannelApi: TpwChannelApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.tpwService.channels.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((channels: ITpwChannel[]) => {
                this.channels = channels;
            });

        this.channelForm = this.fb.group({
            channel: new FormControl(
                this.selectedChannel ? this.selectedChannel.channelId.toString() : '',
                Validators.required
            )
        });

        this.tpwChannelApi.getChannelList().subscribe((res: ITpwChannel[]) => {
            this.tpwService.channels.next(res);
        });

        this.tpwService.channelModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleChannelModal(visible);
            });
        this.toggleChannelModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectChannel(channel: ITpwChannel) {
        if (this.channelForm.valid) {
            channel = this.channels.find((item: ITpwChannel) => {
                if (item.channelId === parseInt(this.channelForm.value.channel)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('tpwSelectedChannel', JSON.stringify(channel));
            this.tpwService.channelChanged.next(channel);

            this.tpwService.channelModalVisible.next(false);
        }
    }

    toggleChannelModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
