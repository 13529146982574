import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IGuidDistributionHubCatalogSync } from '../../../model/product/distributionHub/IGuidDistributionHubCatalogSync';
import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';

@Injectable()
export class DistributionHubCatalogEnableDisableApi extends BaseAuthorizedGuidCrudApi<
IGuidDistributionHubCatalogSync
> {
    public syncEnable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/enablesync',null);
    }

    public syncDisable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/disablesync',null);
    }

    public eciEnable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/enableeci',null);
    }

    public eciDisable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/disableeci',null);
    }

    public salsifyEnable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/enablesalsify',null);
    }

    public salsifyDisable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/disablesalsify',null);
    }

    public powerReviewEnable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/enablepowerreview',null);
    }

    public powerReviewDisable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/disablepowerreview',null);
    }

    public buyItNowEnable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/enablebuyitnow',null);
    }

    public buyItNowDisable(id: string): Observable<any> {
        return this.putJson('/catalog/' + id + '/disablebuyitnow',null);
    }
   
    protected getBaseUrl() {
        return this.environment.api.distributionHub ;
    }
}
