import { Component, ElementRef, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { keyBy, sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TenaFullFilmentSchedulerApi } from '../../api/product/tena/tcw/TenaFullFilmentSchedulerApi';
import { ITenaFullFillmentScheduler } from '../../model/product/tena/tcw/ITenaFullFillmentScheduler';
import { Country } from '../../model/product/Country';
import { ICatalog } from '../../model/product/ICatalog';
import getCatalogName from '../getCatalogName';
import sortCatalogs from '../sortCatalogs';
import { TenaCatalogApi } from '../../api/product/tena/TenaCatalogApi';

@Component({
    templateUrl: 'TenaFullFilmentScheduler.component.html',
    providers: [TenaCatalogApi, TenaFullFilmentSchedulerApi]
})
export class TenaFullFilmentSchedulerComponent
    extends BaseLiveStageCrudList<
        ITenaFullFillmentScheduler,
        ICrudData<ITenaFullFillmentScheduler>,
        { liveStage: LiveStage }
    >
    implements OnInit {
        catalogs: ICatalog[];
        countryOptions = Object.keys(Country);
        catalogsById: { [id: number]: ICatalog };
        readonly getCatalogName = getCatalogName;

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tenaFullFilmentSchedulerApi: TenaFullFilmentSchedulerApi,
        private tenaCatalogApi: TenaCatalogApi,
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'FullFIlmentScheduler');
    }

    ngOnInit() {
        this.adminTitle.setTitle('FulFilment Scheduler - Tena');

        this.filter.observable.subscribe(filter => {
            forkJoin(
                this.tenaFullFilmentSchedulerApi.getAll(),
                this.tenaCatalogApi.getAll()
            ).subscribe(results => {
                let FullFilmentScheduler = results[0],
                    catalogs = results[1];

                this.init(sortBy(FullFilmentScheduler, 'physicalPath'));

                this.catalogs = sortCatalogs(catalogs);
                this.catalogsById = keyBy(catalogs, 'objectId');
            });
        });
    }

    /**
     * Converts an object's truthy keys into a comma-separated string.
     *
     * @param {Object} obj - The input object to extract keys from.
     * @returns {string} A comma-separated string containing the truthy keys from the input object.
     */
    objToText(obj) {
        const formattedStrings = [];

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                obj[key] && formattedStrings.push(key);
            }
        }

        return formattedStrings.join(', ');
    }

    protected createItem() {
        return {
            fileTypes: {
                csv: false,
                xml: false,
                json: false,
                xlsx: false
            },
            frequency: {
                Monday: false,
                Tuesday: false,
                Wednesday: false,
                Thursday: false,
                Friday: false,
                Saturday: false,
                Sunday: false
            }
        } as unknown as ITenaFullFillmentScheduler;
    }

    protected createCrud() {
        this.createItem();

        return new ObjectIdCrud(this.tenaFullFilmentSchedulerApi, this.authService);
    }
}