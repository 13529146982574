import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ITenaSegment } from '../../model/product/tena/ITenaSegment';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TenaSegmentApi } from '../../api/product/tena/TenaSegmentApi';

@Component({
    templateUrl: 'TenaSegment.component.html',
    providers: [TenaSegmentApi]
})
export class TenaSegmentComponent
    extends BaseLiveStageCrudList<
        ITenaSegment,
        ICrudData<ITenaSegment>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tenaSegmentsApi: TenaSegmentApi
    ) {
        super(elementRef, 'product', liveStageService, prettySubmitGuard, 'Segment');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Segments - Tena');

        this.filter.observable.subscribe(filter => {
            this.tenaSegmentsApi.getAll().subscribe(segments => {
                this.init(sortBy(segments, 'segment'));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tenaSegmentsApi, this.authService);
    }
}
