import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { PublishingStatisticsApi } from '../../../api/report/PublishingStatisticsApi';
import { groupBy } from 'lodash';
import { ChartColors } from '../../../model/chart/ChartColors';
import { BasePublishing } from '../BasePublishing';
import { IItemStatistic } from '../../../model/report/publishing/IItemStatistic';
import { LineChartOptions } from '../../../model/chart/BaseLineChartOptions';
import { AdminTitle } from '../../../layout/AdminTitle';

@Component({
    templateUrl: 'Item.component.html',
    providers: [PublishingStatisticsApi]
})
export class ItemComponent extends BasePublishing<IItemStatistic> implements OnInit {
    constructor(
        protected adminTitle: AdminTitle,
        protected publishingStatisticsApi: PublishingStatisticsApi
    ) {
        super(adminTitle, publishingStatisticsApi);
    }

    ngOnInit() {
        this.adminTitle.setTitle('Published items');
        this.getChartData();
    }

    getChartData() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.chartData = [];

        this.subscription = this.publishingStatisticsApi
            .getItemStatistics(this.startDate, this.endDate, this.interval, this.brand)
            .subscribe(itemStatistics => {
                let datasets = this.prepareItemStatisticsDataset(itemStatistics);
                this.setChartOptionInterval();

                if (this.chartData.datasets) {
                    this.chartData.datasets = datasets;

                    return this.chartComponent.chart.update();
                }

                this.chartData = {
                    datasets: datasets
                };
            });
    }

    private prepareItemStatisticsDataset(itemStatistics: IItemStatistic[]) {
        let transformedPriorityStatistics = groupBy(itemStatistics, 'ItemType');
        let datasets: ChartDataSets[] = [];

        Object.keys(transformedPriorityStatistics)
            .sort()
            .forEach((brand, index) => {
                let points: any = [];

                transformedPriorityStatistics[brand].forEach((point: IItemStatistic) => {
                    points.push({
                        x: new Date(point.Date),
                        y: point.Count
                    });
                });

                datasets.push({
                    ...LineChartOptions,
                    label: brand ? brand : 'All brands',
                    data: points,
                    borderColor: ChartColors[index],
                    pointBackgroundColor: ChartColors[index]
                });
            });

        return datasets;
    }
}
