import { Pipe, PipeTransform } from '@angular/core';
import { IActimoveTCWCatalog } from '../../../model/product/tena/actimove/IActimoveTCWCatalog';

@Pipe({
    name: 'platformActimoveCountryNamePipe'
})
export class ActimoveCountryNamePipe implements PipeTransform {
    transform(value: IActimoveTCWCatalog[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: IActimoveTCWCatalog) => {
                if (item.countryName.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
