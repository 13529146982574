import { Component, Input } from '@angular/core';
import { BaseListHeaderComponent } from '../list/BaseListHeader.component';

@Component({
    selector: 'admin-tpw-kitquota-list-header',
    templateUrl: 'TpwKitQuotaListHeader.component.html'
})
export class TpwKitQuotaListHeaderComponent<I> extends BaseListHeaderComponent<I> {
    @Input()
    useCountry = true;

    @Input()
    useChannel = true;
}
