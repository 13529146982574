import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';

import { Tool } from '../../model/auth/login/Tool';
import { ActimoveCatalogComponent } from '../../product/tena/actimove/ActimoveCatalog.component';
import { ActimoveCatalogIdentificationMappingComponent } from '../../product/tena/mapping/ActimoveCatalogIdentificationMapping.component';
import { ActimoveWebShopComponent } from '../../product/tena/actimove/ActimoveWebShop.component';
import { ActimoveSiteMapComponent } from '../../product/tena/actimove/ActimoveSiteMap.component';
import { ActimoveFulFillmentComponent } from '../../product/tena/actimove/ActimoveFulFillment.component';

export const ActimoveChildRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Catalogs',
        path: 'catalogs',
        component: ActimoveCatalogComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592398/4.2.1+Pim+Tena+Catalogs',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Catalog Segment ⬌ Identification',
        path: 'mapping/catalog-segment-identification',
        component: ActimoveCatalogIdentificationMappingComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Site Maps',
        path: 'site-maps',
        component: ActimoveSiteMapComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592738/4.2.5+Pim+Tena+Site+Maps',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Buy Now',
        path: 'webshop',
        component: ActimoveWebShopComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.PIM]
        }
    },
    {
        navbarName: 'Fulfillment Report',
        path: 'fulfillmentReport',
        component: ActimoveFulFillmentComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.TenaConsumerUserInformation]
        }
    }
];
