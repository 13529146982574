import { BaseList } from 'tridion.web.ui/src/app/angular/list/BaseList';

export abstract class BaseAdminList<I> extends BaseList<I> {
    constructor(
        public readonly itemType: string,
        public readonly itemTypeLong = itemType
    ) {
        super();
    }
}
