import { Http, Response } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CutimedJobType } from '../../../../model/product/tena/cutimed/CutimedJobType';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { CutimedService } from '../../../../product/tena/cutimed/Cutimed.service';
import { CutimedBaseTCWCatalogApi } from './CutimedBaseTCWCatalogApi';
import { ICutimedUserData } from '../../../../model/product/tena/cutimed/ICutimedUserData';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { CutimedTCWCatalogApi } from './CutimedTCWCatalogApi';
import { ICutimedTCWCatalog } from '../../../../model/product/tena/cutimed/ICutimedTCWCatalog';

@Injectable()
export class CutimedFulFillmentApi extends CutimedBaseTCWCatalogApi<ICutimedUserData> {

    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        cutimedService: CutimedService,
        readonly cutimedService1: CutimedService,
        liveStageService: LiveStageService,
        readonly cutimedCatalogApi: CutimedTCWCatalogApi
    ) {
        super(apiErrorHandler, authService, environment, http, cutimedService, liveStageService);
    }

    getItems(
        countryId: number,
        jobType: CutimedJobType,
        pageSize: number,
        startDate: string,
        endDate: string
    ): Observable<ICutimedUserData[]> {
        return this.getJson(`/${countryId}/${jobType}/${startDate}/${endDate}`, {
            params: {
                pageSize: pageSize,
                offset: 0
            }
        });
    }

    getMoreItems(
        countryId: number,
        jobType: CutimedJobType,
        pageSize: number,
        offset: number,
        startDate: string,
        endDate: string
    ): Observable<ICutimedUserData[]> {
        return this.getJson(`/${countryId}/${jobType}/${startDate}/${endDate}`, {
            params: {
                pageSize: pageSize,
                offset: offset
            }
        });
    }

    getCsv(
        countryId: number,
        jobType: CutimedJobType,
        startDate: string,
        endDate: string,
        showPersonalData: boolean
    ): Observable<Response> {
        if (showPersonalData) {
            return this.get(`/${countryId}/${jobType}/${startDate}/${endDate}/excel/includepersonaldata`);
        }
        else {
            return this.get(`/${countryId}/${jobType}/${startDate}/${endDate}/excel`);
        }
    }

    protected getBaseUrl() {
        this.cutimedCatalogApi.getAll().subscribe((res: ICutimedTCWCatalog[]) => {
            this.cutimedService1.countries.next(res);
        });

        return this.TCWBaseURl + 'countryuserdata';
    }
}
