import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidCrudApi } from '../BaseAuthorizedGuidCrudApi';
import { IAuthModule } from '../../model/auth/IAuthModule';

@Injectable()
export class AuthModuleApi extends BaseAuthorizedGuidCrudApi<IAuthModule> {
    protected getBaseUrl() {
        return this.environment.api.authentication + 'module';
    }
}
