import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import addTrailingSlash from 'tridion.web.ui/src/app/url/addTrailingSlash';
import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ITorkSiteMap } from '../../model/product/tork/ITorkSiteMap';
import joinUrl from 'tridion.web.ui/src/app/url/joinUrl';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import {
    SiteMapFrequency,
    SiteMapFrequencyDescription
} from '../../model/product/tork/SiteMapFrequency';
import { TorkSiteMapApi } from '../../api/product/tork/TorkSiteMapApi';

@Component({
    templateUrl: 'TorkSiteMap.component.html',
    providers: [TorkSiteMapApi]
})
export class TorkSiteMapComponent
    extends BaseLiveStageCrudList<
        ITorkSiteMap,
        ICrudData<ITorkSiteMap>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    readonly addTrailingSlash = addTrailingSlash;
    readonly joinUrl = joinUrl;
    readonly SiteMapFrequencyDescription = SiteMapFrequencyDescription;
    readonly siteMapFrequencyOptions = Object.keys(SiteMapFrequency);

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private torkSiteMapApi: TorkSiteMapApi
    ) {
        super(elementRef, 'product', liveStageService, prettySubmitGuard, 'Site Map');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Site Maps - Tork');

        this.filter.observable.subscribe(() => {
            this.torkSiteMapApi.getAll().subscribe(siteMaps => {
                this.init(sortBy(siteMaps, 'domainName'));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.torkSiteMapApi, this.authService);
    }
}
