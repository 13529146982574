import { Component, ElementRef, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { keyBy, sortBy } from 'lodash';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../../crud/list/BaseLiveStageCrudList';
import getCatalogName from '../../getCatalogName';
import { ICatalog } from '../../../model/product/ICatalog';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ITenaSegment } from '../../../model/product/tena/ITenaSegment';
import { LiveStage } from '../../../environment/LiveStage';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../../sortCatalogs';
import { CutimedCatalogApi } from '../../../api/product/tena/cutimed/CutimedCatalogApi';
import { CutimedCatalogIdentificationMappingApi } from '../../../api/product/tena/cutimed/CutimedCatalogIdentificationMappingApi';
import { TenaSegmentApi } from '../../../api/product/tena/TenaSegmentApi';
import { ICutimedCatalogIdentificationMapping } from 'src/app/model/product/tena/cutimed/ICutimedCatalogIdentificationMapping';

@Component({
    templateUrl: 'CutimedCatalogIdentificationMapping.component.html',
    providers: [CutimedCatalogApi, CutimedCatalogIdentificationMappingApi, TenaSegmentApi]
})
export class CutimedCatalogIdentificationMappingComponent
    extends BaseLiveStageCrudList<
        ICutimedCatalogIdentificationMapping,
        ICrudData<ICutimedCatalogIdentificationMapping>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    catalogs: ICatalog[];
    catalogsById: { [id: number]: ICatalog };
    segments: ITenaSegment[];
    segmentsById: { [id: number]: ITenaSegment };
    readonly getCatalogName = getCatalogName;

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private cutimedCatalogApi: CutimedCatalogApi,
        private cutimedCatalogIdentificationMappingApi: CutimedCatalogIdentificationMappingApi,
        private tenaSegmentApi: TenaSegmentApi
    ) {
        super(
            elementRef,
            'product',
            liveStageService,
            prettySubmitGuard,
            'Catalog Segment ⬌ Identification'
        );
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalog Segment ⬌ Identifications - Cutimed');

        this.filter.observable.subscribe(filter => {
            forkJoin(
                this.cutimedCatalogApi.getAll(),
                this.tenaSegmentApi.getAll(),
                this.cutimedCatalogIdentificationMappingApi.getAll()
            ).subscribe(results => {
                var catalogs = results[0],
                    segments = results[1],
                    mappings = results[2];

                this.catalogs = sortCatalogs(catalogs);
                this.catalogsById = keyBy(catalogs, 'objectId');

                this.segments = sortBy(segments, 'segment');
                this.segmentsById = keyBy(segments, 'objectId');

                this.init(
                    sortBy(mappings, mapping => {
                        var catalog = this.catalogsById[mapping.lkpCatelogObjectId],
                            segment = this.segmentsById[mapping.targetSegmentid];

                        return [
                            catalog && catalog.countryName,
                            catalog && catalog.languageName,
                            segment && segment.segment
                        ];
                    })
                );
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(
            this.cutimedCatalogIdentificationMappingApi,
            this.authService
        );
    }

    protected createItem() {
        return {
            brand: 'Cutimed'
        } as ICutimedCatalogIdentificationMapping;
    }
}
