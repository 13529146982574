import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { ILeukoplastCatalog } from './../../../../model/product/tena/leukoplast/ILeukoplastCatalog.d';

@Injectable()
export class LeukoplastCatalogApi extends BaseProductApi<ILeukoplastCatalog> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscatalog/leukoplast';
    }
}
