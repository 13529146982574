import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseCatalog } from '../../BaseCatalog';
import { IJobstCatalog } from '../../../model/product/tena/jobst/IJobstCatalog';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../../sortCatalogs';
import { JobstCatalogApi } from '../../../api/product/tena/jobst/JobstCatalogApi';

@Component({
    templateUrl: '../../BaseCatalog.component.html',
    providers: [JobstCatalogApi]
})
export class JobstCatalogComponent extends BaseCatalog<IJobstCatalog> implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private jobstCatalogApi: JobstCatalogApi
    ) {
        super(elementRef, liveStageService, prettySubmitGuard, 'Jobst');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalogs - Jobst');

        this.filter.observable.subscribe(filter => {
            this.jobstCatalogApi.getAll().subscribe(catalogs => {
                this.init(sortCatalogs(catalogs));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.jobstCatalogApi, this.authService);
    }
}
