
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { TpwService } from '../../Tpw.service';
import { ITpwCountry } from '../../../model/product/tena/tpw/ITpwCountry';
import { TpwCountryApi } from '../../../api/product/tena/tpw/TpwCountryApi';

@Component({
    selector: 'admin-tpw-country-select',
    templateUrl: 'TpwCountrySelect.component.html',
    providers: [TpwCountryApi]
})
export class TpwCountrySelectComponent implements OnInit, OnDestroy {
    countryForm: FormGroup;
    countries: ITpwCountry[];

    @Input()
    selectedCountry: ITpwCountry;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public tpwService: TpwService,
        private tpwCountryApi: TpwCountryApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.tpwService.countries.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((countries: ITpwCountry[]) => {
                this.countries = countries;
            });

        this.countryForm = this.fb.group({
            country: new FormControl(
                this.selectedCountry ? this.selectedCountry.countryId.toString() : '',
                Validators.required
            )
        });

        this.tpwCountryApi.getAll().subscribe((res: ITpwCountry[]) => {
            this.tpwService.countries.next(res);
        });

        this.tpwService.countryModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleCountryModal(visible);
            });
        this.toggleCountryModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectCountry(country: ITpwCountry) {
        if (this.countryForm.valid) {
            country = this.countries.find((item: ITpwCountry) => {
                if (item.countryId === parseInt(this.countryForm.value.country)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('tpwSelectedCountry', JSON.stringify(country));
            this.tpwService.countryChanged.next(country);

            this.tpwService.countryModalVisible.next(false);
        }
    }

    toggleCountryModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
