import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { IFemcareCatalog } from '../../../model/product/femcare/IFemcareCatalog';

@Injectable()
export class FemcareCatalogApi extends BaseProductApi<IFemcareCatalog> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'femcare/lkpcatalog';
    }
}
