
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../../environment/LiveStage';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { LeukoplastWebShopApi } from '../../../api/product/tena/leukoplast/LeukoplastWebShopApi';
import { ILeukoplastWebShop } from '../../../model/product/tena/leukoplast/ILeukoplastWebShop';
import { Subject } from 'rxjs';
import { LeukoplastService } from './Leukoplast.service';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { LeukoplastWebShopCatalogApi } from '../../../api/product/tena/leukoplast/LeukoplastWebShopCatalogApi';
import { ILeukoplastWebShopCatalog } from '../../../model/product/tena/leukoplast/ILeukoplastWebShopCatalog';
import { LeukoplastSegmentApi } from '../../../api/product/tena/leukoplast/LeukoplastSegmentApi';
import { ILeukoplastSegment } from './../../../model/product/tena/leukoplast/ILeukoplastSegment.d';

@Component({
    templateUrl: 'LeukoplastWebShop.component.html',
    providers: [LeukoplastWebShopApi, LeukoplastWebShopCatalogApi, LeukoplastSegmentApi]
})
export class LeukoplastWebShopComponent
    extends BaseLiveStageCrudList<
        ILeukoplastWebShop,
        ICrudData<ILeukoplastWebShop>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    countries: ILeukoplastWebShopCatalog[];
    segments: ILeukoplastSegment[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private leukoplastWebShopApi: LeukoplastWebShopApi,
        readonly leukoplastCatalogApi: LeukoplastWebShopCatalogApi,
        readonly leukoplastSegmentApi: LeukoplastSegmentApi,
        readonly leukoplastService: LeukoplastService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'WebShop');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Webshop - Leukoplast');

        this.leukoplastCatalogApi.getAll().subscribe((res: ILeukoplastWebShopCatalog[]) => {
          this.leukoplastService.countries.next(res);
        });

        this.leukoplastSegmentApi.getAll().subscribe((res: ILeukoplastSegment[]) => {
            const isLeukoplastSegment = res.filter(segment => segment.isLeukoplastSegment);
            this.leukoplastService.segments.next(isLeukoplastSegment);
        });

        this.leukoplastService.segmentChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedSegment => {
                if (selectedSegment) {
                    this.getWebShops();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    getWebShops() {
        this.filter.observable.subscribe(() => {
            this.leukoplastWebShopApi
                .getWebShops(
                    this.leukoplastService.getActiveCountry().objectId,
                    this.leukoplastService.getActiveSegment().objectId
                )
                .subscribe(data => {
                    this.clear();
                    this.init(data);
                });
        });
    }

    saveWebShop(webshopCrud: BaseCrud<ILeukoplastWebShop, ICrudData<ILeukoplastWebShop>>) {
        // Set catalog and segment ID for new webshops.
        if (webshopCrud.isNew()) {
            webshopCrud.data.item.catalogObjectId = this.leukoplastService.getActiveCountry().objectId;
            webshopCrud.data.item.segmentObjectId = this.leukoplastService.getActiveSegment().objectId;
        }

        webshopCrud.save();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.leukoplastWebShopApi, this.authService);
    }
}
