import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidCrudApi } from '../BaseAuthorizedGuidCrudApi';
import { ISite } from '../../model/site/ISite';

@Injectable()
export class SiteApi extends BaseAuthorizedGuidCrudApi<ISite> {
    getSitesByBrand(brand: string) {
        return this.getJson('/authorizationentity/platform|' + brand);
    }

    getSitesByBrandAccesslevel(brand: string) {
        return this.getJson('/accesslevel/platform|' + brand);
    }

    protected getBaseUrl() {
        return this.environment.api.authentication + 'site';
    }
}
