import { Component, Input } from '@angular/core';
import { BaseListHeaderComponent } from '../list/BaseListHeader.component';

@Component({
    selector: 'admin-tpw-sampleorder-list-header',
    templateUrl: 'TpwSampleOrderListHeader.component.html'
})
export class TpwSampleOrderListHeaderComponent<I> extends BaseListHeaderComponent<I> {
    @Input()
    useCountry = true;

    @Input()
    useChannel = true;
}
