import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';
import { AdminTitle } from '../../layout/AdminTitle';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { DistributionHubSolrcoreApi } from '../../api/product/distributionHub/DistributionHubSolrcoreApi';
import { IGuidSolrcore } from '../../model/product/IGuidSolrcore';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { ShowMoreAdminCrudList } from '../../crud/list/ShowMoreAdminCrudList';
import { DistributionHubSolrCoreConfigApi } from '../../api/product/distributionHub/DistributionHubSolrCoreConfigApi';
import { IGuidSolrCoreConfig } from '../../model/product/distributionHub/IGuidSolrCoreConfig';

@Component({
    templateUrl: 'DistributionHubSolr.component.html',
    providers: [DistributionHubSolrcoreApi,
        DistributionHubSolrCoreConfigApi]
})
export class DistributionHubSolrComponent
    extends ShowMoreAdminCrudList<IGuidSolrcore, ICrudData<IGuidSolrcore>>
    implements OnInit {
        solrCoreConfig : IGuidSolrCoreConfig[];
        
    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        
        private distributionHubSolrcoreApi: DistributionHubSolrcoreApi,
        private distributionHubSolrCoreConfigApi:DistributionHubSolrCoreConfigApi
    ) {
        super(elementRef, prettySubmitGuard, 'Solr Core');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Solr Cores - Distribution Hub');

        this.distributionHubSolrcoreApi.getAll().subscribe(cores => {
            this.init(sortBy(cores, 'core'));
        });

        this.distributionHubSolrCoreConfigApi.getAll().subscribe(coreConfigs => {
                this.solrCoreConfig=coreConfigs;    });
    }

    saveData(crud: GuidCrud<IGuidSolrcore, ICrudData<IGuidSolrcore>>){
        
        let configId :any = crud.data.item.solrCoreConfigId;
        
        this.distributionHubSolrCoreConfigApi.getById(crud.data.item.solrCoreConfigId).subscribe(coreConfig => {
        
        crud.data.item.solrCoreConfigId=configId;
        crud.data.item.solrCoreConfigName=coreConfig.domain + '-'+coreConfig.solrCoreType      ;
        crud.save();
        });
    }
       

    protected createCrud() {
        return new GuidCrud(this.distributionHubSolrcoreApi);
    }
}
