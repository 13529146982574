
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TenaWebShopApi } from '../../api/product/tena/tcw/TenaWebShopApi';
import { ITenaWebShop } from '../../model/product/tena/tcw/ITenaWebShop';
import { Subject } from 'rxjs';
import { TenaService } from './Tena.service';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { TenaTCWCatalogApi } from '../../api/product/tena/tcw/TenaTCWCatalogApi';
import { ITenaTCWCatalog } from '../../model/product/tena/tcw/ITenaTCWCatalog';
import { TenaSegmentApi } from '../../api/product/tena/TenaSegmentApi';
import { ITenaSegment } from './../../model/product/tena/ITenaSegment.d';

@Component({
    templateUrl: 'TenaWebShop.component.html',
    providers: [TenaWebShopApi, TenaTCWCatalogApi, TenaSegmentApi]
})
export class TenaWebShopComponent
    extends BaseLiveStageCrudList<
        ITenaWebShop,
        ICrudData<ITenaWebShop>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    countries: ITenaTCWCatalog[];
    segments: ITenaSegment[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tenaWebShopApi: TenaWebShopApi,
        readonly tenaCatalogApi: TenaTCWCatalogApi,
        readonly tenaSegmentsApi: TenaSegmentApi,
        readonly tenaService: TenaService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'WebShop');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Webshop - Tena');

        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
          this.tenaService.countries.next(res);
        });

        this.tenaSegmentsApi.getAll().subscribe((res: ITenaSegment[]) => {
            const consumerSegments = res.filter(segment => segment.isConsumerSegment);
            this.tenaService.segments.next(consumerSegments);
        });

        this.tenaService.segmentChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedSegment => {
                if (selectedSegment) {
                    this.getWebShops();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    getWebShops() {
        this.filter.observable.subscribe(() => {
            this.tenaWebShopApi
                .getWebShops(
                    this.tenaService.getActiveCountry().objectId,
                    this.tenaService.getActiveSegment().objectId
                )
                .subscribe(data => {
                    this.clear();
                    this.init(data);
                });
        });
    }

    saveWebShop(webshopCrud: BaseCrud<ITenaWebShop, ICrudData<ITenaWebShop>>) {
        // Set catalog and segment ID for new webshops.
        if (webshopCrud.isNew()) {
            webshopCrud.data.item.catalogObjectId = this.tenaService.getActiveCountry().objectId;
            webshopCrud.data.item.segmentObjectId = this.tenaService.getActiveSegment().objectId;
        }

        webshopCrud.save();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tenaWebShopApi, this.authService);
    }
}
