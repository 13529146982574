import { ContentChild, ElementRef, Input, TemplateRef } from '@angular/core';

import { BaseAdminCrudList } from '../crud/list/BaseAdminCrudList';
import { BaseAdminList } from './BaseAdminList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';

export class BaseListHeaderComponent<I> {
    @Input()
    list: BaseAdminList<I> | BaseAdminCrudList<I, ICrudData<I>>;
    @ContentChild('filters', { static: false })
    filters?: TemplateRef<ElementRef>;
    @Input()
    hideFilters?: boolean;
    @Input()
    showAddButton?= true;
    @Input()
    showItemsLoading?= true;
    @Input()
    showSearchFilter?= true;

    areFiltersVisible() {
        if (this.hideFilters) {
            return false;
        }

        return this.hasItems();
    }

    hasItems() {
        var list = this.list;

        if (this.isCrudList()) {
            let cruds = (list as BaseAdminCrudList<I, ICrudData<I>>).cruds;
            if (cruds) {
                for (let index = 0, length = cruds.length; index < length; index++) {
                    if (!cruds[index].isNew()) {
                        return true;
                    }
                }
            }

            return false;
        } else {
            let items = list.items;

            return !!(items && items.length);
        }
    }

    isCrudList() {
        return !!(this.list as BaseAdminCrudList<I, ICrudData<I>>).addItem;
    }

    isLoading() {
        var list = this.list;

        return !list.items || !!(list as any).loading;
    }
}
