import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { ILeukoplastSiteMap } from '../../../../model/product/tena/leukoplast/ILeukoplastSiteMap';

@Injectable()
export class LeukoplastSiteMapApi extends BaseProductApi<ILeukoplastSiteMap> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmsglobalsitemap/leukoplast';
    }
}
