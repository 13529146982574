import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Observable } from 'rxjs';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';

import { ITpwFullfillmentSetting } from '../../../../model/product/tena/tpw/ITpwFullfillmentSetting';
import { TpwService } from '../../../../gdpr/Tpw.service';
import { BaseTPWSampleApi } from './BaseTPWSampleApi';

@Injectable()
export class TpwFullfillmentSettingApi extends BaseTPWSampleApi<ITpwFullfillmentSetting> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        tpwService: TpwService
    ) {
        super(apiErrorHandler, authService, environment, http, tpwService);
    }

    getFullfillmentSetting(
        SampleType: string,
        channelId: number
    ): Observable<ITpwFullfillmentSetting[]> {
        return this.getJson(`?SampleType=${SampleType}&ChannelID=${channelId}`);
    }

    add(item: ITpwFullfillmentSetting): Observable<ITpwFullfillmentSetting> {
        if (item.fullFilmentCenter) {
            // Remove any white space
            item.fullFilmentCenter = (item.fullFilmentCenter as string).replace(
                /\s/g,
                ''
            );
        }

        return this.postJson(
            `?SettingId=${item.settingId}&SampleType=${item.sampleType}&CountryID=${
                item.countryId
            }&ChannelID=${item.channelId}&FullFilmentCenter=${
                item.fullFilmentCenter
            }&PeriodofEmailBatch=${item.periodofEmailBatch}&IsAvailableForExtendedUser=${
                item.isAvailableForExtendedUser
            }`,
            null
        );
    }

    protected getBaseUrl() {
        return this.TPWBaseURl + 'Fulfillment';
    }
}
