import { Component, Input } from '@angular/core';
import { BaseListHeaderComponent } from '../list/BaseListHeader.component';

@Component({
    selector: 'admin-tpw-getsampleorderreport-list-header',
    templateUrl: 'TpwGetSampleOrderReportListHeader.component.html'
})
export class TpwGetSampleOrderReportListHeaderComponent<
    I
> extends BaseListHeaderComponent<I> {
    @Input()
    useCountry = true;
}
