export enum RedirectType {
    // 301 Moved Permanently
    Permanent = 'Permanent',

    // 302 Found
    Found = 'Found',

    // 307 Temporary Redirect
    Temporary = 'Temporary'
}
