
import {map, shareReplay, tap} from 'rxjs/operators';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { sortBy } from 'lodash';

import { ISite } from '../../model/site/ISite';
import { SiteApi } from '../../api/auth/SiteApi';
import { SiteMappingApi } from '../../api/campaign/siteMapping/SiteMappingApi';
import { ISiteMapping } from '../../model/campaign/siteMapping/ISiteMapping';
import { ShowMoreAdminCrudList } from '../../crud/list/ShowMoreAdminCrudList';
import { AdminTitle } from '../../layout/AdminTitle';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';

@Component({
    templateUrl: 'SiteMapping.component.html',
    providers: [SiteApi, SiteMappingApi]
})
export class SiteMappingComponent
    extends ShowMoreAdminCrudList<ISiteMapping, ICrudData<ISiteMapping>>
    implements OnInit {
    sites: Observable<ISite[]>;
    siteMappings: ISiteMapping[];

    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        protected siteApi: SiteApi,
        protected siteMappingApi: SiteMappingApi
    ) {
        super(elementRef, prettySubmitGuard, 'Mapping');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Site Mappings');

       
       
        // @ts-ignore @todo
        this.sites = this.siteApi
            .getSitesByBrand('femcare').pipe(
            // @ts-ignore @todo
            map(sites => sortBy(sites, 'name')),
            shareReplay(),);

        this.siteMappingApi.getAll().subscribe(mappings => {
            this.init(mappings);
        });
    }

    getBaseUrl(siteId: string) {
        let domainUrl;
        this.sites.pipe(tap(sites => {
            sites.forEach((site, index) => {
                if (site.id === siteId) {
                    domainUrl = site.domainUrl;

                    return;
                }
            });
        }));

        return domainUrl;
    }

    protected createCrud() {
        return new GuidCrud(this.siteMappingApi);
    }
}
