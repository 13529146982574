import { Component, ElementRef, OnInit } from '@angular/core';
import { AdminTitle } from '../../../layout/AdminTitle';
import { IGuidSolrCoreConfig } from '../../../model/product/distributionHub/IGuidSolrCoreConfig';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { DistributionHubDomainApi } from '../../../api/product/distributionHub/DistributionHubDomainApi';
import { DistributionHubSolrCoreConfigApi } from '../../../api/product/distributionHub/DistributionHubSolrCoreConfigApi';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { DistributionHubCatalogApi } from '../../../api/product/distributionHub/DistributionHubCatalogApi';
import { BaseDistributionHubSolrCoreConfig } from './BaseDistributionHubSolrCoreConfig';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'DistributionHubSolrCoreConfig.component.html',
    providers: [DistributionHubDomainApi,
        DistributionHubSolrCoreConfigApi,    
        DistributionHubCatalogApi        
        ]
})
export class DistributionHubSolrCoreConfigComponent
    extends   BaseDistributionHubSolrCoreConfig<IGuidSolrCoreConfig>
    implements OnInit {
        subscription = new Subscription();
        history:string;
    constructor(
       
        private adminTitle: AdminTitle,
       
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        private distributionHubSolrCoreConfigApi: DistributionHubSolrCoreConfigApi,
        private distributionHubDomainApi: DistributionHubDomainApi,
        
    ) {
        super(elementRef, prettySubmitGuard, 'SolrCoreConfig');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Solr Core Config - Distribution Hub');

        this.initAll(
           this.distributionHubSolrCoreConfigApi.getAll(),
           this.distributionHubDomainApi.getAll()                 
       );
    }

    setHistory(hist: string,index: string,xmlConfig:string) {       
       this.history = hist;        
       ((document.getElementById('xmlConfiguration'+index)) as HTMLInputElement).value=xmlConfig;
              
       (document.getElementById('xmlConfiguration'+index) as HTMLInputElement).dispatchEvent(new Event('input', { bubbles: true }));
       
    }

    checkHistory(hist: string) {        
        if(hist === this.history)
        { 
            return true;
        }
    }


    protected createCrud() {
        return new GuidCrud(this.distributionHubSolrCoreConfigApi);
    }
}
