import { Injectable } from '@angular/core';
import { IFemcareSample } from 'src/app/model/campaign/sample/IFemcareSample';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SendSampleEmailApi {
    constructor(private http: HttpClient) {}

    sendEmail(url: string, crud: IFemcareSample) : Observable<any> {
        return this.http.post(url, JSON.stringify(crud));
    }
}