import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFulfillmentSite } from '../../model/fulfillment/IFulfillmentSite';
import { ActivatedRoute } from '@angular/router';
import { SiteMappingApi } from '../../api/campaign/siteMapping/SiteMappingApi';
import { QuizApi } from '../../api/campaign/quiz/QuizApi';
import { IQuiz } from '../../model/campaign/quiz/IQuiz';
import { ChartComponent } from 'angular2-chartjs';
import { IQuizStats } from '../../model/campaign/quiz/IQuizStats';
import { ChartDataSets, ChartOptions, ChartTooltipLabelColor, TimeDisplayFormat, TimeUnit } from 'chart.js';
import { FemcareChartColors } from '../../model/chart/FemcareChartColors';
import { IItemCount } from '../../model/campaign/quiz/IItemCount';
import { groupBy, sortBy } from 'lodash';
import toIsoDate from '../../date/toIsoDate';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'Quiz.component.html',
    providers: [QuizApi, SiteMappingApi]
})
export class QuizComponent implements OnInit, AfterViewInit {
    @ViewChildren('answerBarChart')
    answerBarCharts: QueryList<ChartComponent>;
    answerBarChartsData: any = [];

    @ViewChildren('answerTimeChart')
    answerTimeCharts: QueryList<ChartComponent>;
    answerTimeChartData: any = [];

    @ViewChild('resultBarChart', {static: false})
    resultBarChart: ChartComponent;
    resultBarChartData: any = [];

    @ViewChild('resultTimeChart', {static: false})
    resultTimeChart: ChartComponent;
    resultTimeChartData: any = [];

    title: 'Quizzes';
    dayMilliseconds = 86400000;
    sites$: BehaviorSubject<IFulfillmentSite[]> = new BehaviorSubject<IFulfillmentSite[]>(null);
    quizzes$: BehaviorSubject<IQuiz[]> = new BehaviorSubject<IQuiz[]>(null);
    selectedSite?: IFulfillmentSite;
    selectedQuiz?: IQuiz;
    stats?: IQuizStats;
    startDate: string;
    endDate: string;
    showResults: boolean;
    quizStats: Subscription;

    barChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            display: false
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        }
    };

    interval: TimeUnit = 'day';
    displayFormats: TimeDisplayFormat = {
        day: 'MMM D',
        month: 'MMMM YYYY',
        year: 'YYYY'
    };

    timeChartOptions: ChartOptions = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                boxWidth: 12
            }
        },
        tooltips: {
            mode: 'x',
            callbacks: {
                labelColor: (tooltipItem, chart) => {
                    return {
                        backgroundColor: FemcareChartColors[tooltipItem.datasetIndex]
                    } as ChartTooltipLabelColor;
                }
            }
        },
        hover: {
            mode: 'x'
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ],
            xAxes: [
                {
                    type: 'time',
                    time: {
                        isoWeekday: true,
                        unit: this.interval,
                        displayFormats: this.displayFormats,
                        tooltipFormat: this.displayFormats[this.interval]
                    }
                }
            ]
        }
    };

    constructor(
        protected route: ActivatedRoute,
        protected siteMappingApi: SiteMappingApi,
        protected quizApi: QuizApi
    ) {
        let endDate = new Date();
        let startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 30);

        this.startDate = toIsoDate(startDate);
        this.endDate = toIsoDate(endDate);
    }

    ngOnInit() {
        this.siteMappingApi.getAll().subscribe(sites => this.sites$.next(sites));
    }

    ngAfterViewInit() {
        this.answerBarCharts.changes.subscribe(() => {
            this.answerBarCharts.toArray().forEach((chart, index) => {
                chart.chart.update();
            });
        });
        this.answerTimeCharts.changes.subscribe(() => {
            this.answerTimeCharts.toArray().forEach((chart, index) => {
                chart.chart.update();
            });
        });
    }

    loadQuizzesForSite() {
        if (this.selectedSite && Object.entries(this.selectedSite).length !== 0) {
            this.selectedQuiz = null;
            this.quizApi.getQuizzesBySite(this.selectedSite.name).subscribe(quizzes => this.quizzes$.next(quizzes));
        } else {
            this.quizzes$.next(null);
            this.selectedQuiz = null;
            this.answerBarChartsData = [];
        }
    }

    loadQuizStats() {
        if (this.selectedQuiz) {
            if (this.quizStats) {
                this.quizStats.unsubscribe();
                this.stats = null;
                this.showResults = false;
            }

            this.quizStats = this.quizApi
                .getQuizStats(this.selectedQuiz.id, this.selectedQuiz.websiteId, this.startDate, this.endDate)
                .subscribe((stats: IQuizStats) => {
                    this.stats = stats;
                    this.stats.dailyStats = sortBy(this.stats.dailyStats, 'date');

                    this.setChartOptionInterval();
                    this.initResults();
                    this.initAnswers();
                });
        }
    }

    private setChartOptionInterval() {
        let startDate = Date.parse(this.startDate);
        let endDate = Date.parse(this.endDate);
        let diffDays = Math.round(Math.abs((endDate - startDate) / this.dayMilliseconds));
        this.interval = diffDays > 60 ? 'month' : 'day';

        this.timeChartOptions.scales.xAxes[0].time.unit = this.interval;
        this.timeChartOptions.scales.xAxes[0].time.tooltipFormat = this.displayFormats[this.interval];
    }

    private initResults() {
       this.showResults = this.stats.resultTotalStats.length > 0;        
        
        if (this.showResults) {
            this.resultBarChartData = this.getTotalCountChartData(this.stats.resultTotalStats);
            this.resultBarChart.chart.update();

            if (this.interval === 'day') {
                this.prepareResultDailyCountChart();
            } else {
                this.prepareResultMonthlyCountChart();
            }
        }
    }

    private initAnswers() {
        this.answerBarChartsData.splice(this.stats.questionTotalStats.length);
        for (let i = 0; i < this.stats.questionTotalStats.length; i++) {
            this.answerBarChartsData[i] = this.getTotalCountChartData(this.stats.questionTotalStats[i].answerStats);
        }

        if (this.interval === 'day') {
            this.prepareAnswerDailyCountCharts();
        } else {
            this.prepareAnswerMonthlyCountCharts();
        }
    }

    private getTotalCountChartData(items: IItemCount[]) {
        let totalCountChartData = {
            labels: [] as string[],
            datasets: [
                {
                    data: [] as number[],
                    backgroundColor: [] as string[]
                }
            ]
        };

        for (let i = 0; i < items.length; i++) {
            totalCountChartData.labels.push(items[i].name);
            totalCountChartData.datasets[0].backgroundColor.push(FemcareChartColors[i]);
            totalCountChartData.datasets[0].data.push(items[i].count);
        }

        return totalCountChartData;
    }

    private prepareResultDailyCountChart() {
        let dailyCountDatasets: ChartDataSets[] = [];
        for (let i = 0; i < this.stats.resultTotalStats.length; i++) {
            let dailyDataPoints: any = [];
            for (let j = 0; j < this.stats.dailyStats.length; j++) {
                let day = this.stats.dailyStats[j];
                if (day.resultStats[i].count > 0) {
                    dailyDataPoints.push({
                        x: new Date(day.date),
                        y: day.resultStats[i].count
                    });
                }
            }

            dailyCountDatasets.push({
                label: this.stats.resultTotalStats[i].name,
                data: dailyDataPoints,
                lineTension: 0,
                backgroundColor: [FemcareChartColors[i]],
                borderColor: [FemcareChartColors[i]],
                fill: false
            });
        }

        this.resultTimeChartData.datasets = dailyCountDatasets;
        this.resultTimeChart.chart.options.scales.xAxes[0].time.unit = this.interval;
        this.resultTimeChart.chart.options.scales.xAxes[0].time.tooltipFormat = this.displayFormats[this.interval];

        this.resultTimeChart.chart.update();
    }

    private prepareAnswerDailyCountCharts() {
        this.answerTimeChartData.splice(this.stats.questionTotalStats.length);
        for (let i = 0; i < this.stats.questionTotalStats.length; i++) {
            let dailyCountDatasets: ChartDataSets[] = [];
            for (let j = 0; j < this.stats.questionTotalStats[i].answerStats.length; j++) {
                let dailyDataPoints: any = [];
                for (let k = 0; k < this.stats.dailyStats.length; k++) {
                    let day = this.stats.dailyStats[k];
                    if (day.questionStats[i] !== undefined && day.questionStats[i].answerStats[j].count > 0) {
                        dailyDataPoints.push({
                            x: new Date(day.date),
                            y: day.questionStats[i].answerStats[j].count
                        });
                    }
                }

                dailyCountDatasets.push({
                    label: this.stats.questionTotalStats[i].answerStats[j].name,
                    data: dailyDataPoints,
                    lineTension: 0,
                    backgroundColor: [FemcareChartColors[j]],
                    borderColor: [FemcareChartColors[j]],
                    fill: false
                });
            }
            this.answerTimeChartData[i] = {
                datasets: dailyCountDatasets,
                question: this.stats.questionTotalStats[i].question
            };
        }
    }

    private prepareResultMonthlyCountChart() {
        // time is in ISO 8601 format so first 7 chars determine year-month
        let monthlyCountDatasets: ChartDataSets[] = [];
        let daysPerMonth = groupBy(this.stats.dailyStats, e => e.date.substr(0, 7));
        let months = Object.keys(daysPerMonth);

        for (let i = 0; i < this.stats.resultTotalStats.length; i++) {
            let monthlyDataPoints: any = [];
            for (let m = 0; m < months.length; m++) {
                let monthlyCount = 0;
                let month = new Date(daysPerMonth[months[m]][0].date);
                month.setDate(1);

                for (let d = 0; d < daysPerMonth[months[m]].length; d++) {
                    let day = daysPerMonth[months[m]][d];
                    monthlyCount += day.resultStats[i].count;
                }
                
                if (monthlyCount > 0) {
                    monthlyDataPoints.push({ x: month, y: monthlyCount });
                }
            }

            monthlyCountDatasets.push({
                label: this.stats.resultTotalStats[i].name,
                data: monthlyDataPoints,
                lineTension: 0,
                borderColor: [FemcareChartColors[i]],
                backgroundColor: [FemcareChartColors[i]],
                fill: false
            });
        }

        this.resultTimeChartData.datasets = monthlyCountDatasets;
        this.resultTimeChart.chart.options.scales.xAxes[0].time.unit = this.interval;
        this.resultTimeChart.chart.options.scales.xAxes[0].time.tooltipFormat = this.displayFormats[
            this.interval
        ];
        this.resultTimeChart.chart.update();
    }
    
    private prepareAnswerMonthlyCountCharts() {
        // time is in ISO 8601 format so first 7 chars determine year-month
        let daysPerMonth = groupBy(this.stats.dailyStats, e => e.date.substr(0, 7));
        let months = Object.keys(daysPerMonth);
        this.answerTimeChartData.splice(this.stats.questionTotalStats.length);

        for (let i = 0; i < this.stats.questionTotalStats.length; i++) {
            let monthlyCountDatasets: ChartDataSets[] = [];
            for (let j = 0; j < this.stats.questionTotalStats[i].answerStats.length; j++) {
                let monthlyDataPoints: any = [];
                for (let m = 0; m < months.length; m++) {
                    let monthlyCount = 0;
                    let month = new Date(daysPerMonth[months[m]][0].date);
                    month.setDate(1);
                    
                    for (let d = 0; d < daysPerMonth[months[m]].length; d++) {
                        let day = daysPerMonth[months[m]][d];
                        monthlyCount += day.questionStats[i] !== undefined ? day.questionStats[i].answerStats[j].count : 0;
                    }

                    if (monthlyCount > 0) {
                        monthlyDataPoints.push({ x: month, y: monthlyCount });
                    }
                }

                monthlyCountDatasets.push({
                    label: this.stats.questionTotalStats[i].answerStats[j].name,
                    data: monthlyDataPoints,
                    lineTension: 0,
                    backgroundColor: [FemcareChartColors[j]],
                    borderColor: [FemcareChartColors[j]],
                    fill: false
                });
            }

            this.answerTimeChartData[i] = {
                datasets: monthlyCountDatasets,
                question: this.stats.questionTotalStats[i].question
            };
        }
    }
}