import { Pipe, PipeTransform } from '@angular/core';
import { ICutimedTCWCatalog } from '../../../model/product/tena/cutimed/ICutimedTCWCatalog';

@Pipe({
    name: 'platformCutimedCountryNamePipe'
})
export class CutimedCountryNamePipe implements PipeTransform {
    transform(value: ICutimedTCWCatalog[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: ICutimedTCWCatalog) => {
                if (item.countryName.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
