import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IActimoveSegment } from '../../../model/product/tena/actimove/IActimoveSegment';
import { IActimoveTCWCatalog } from '../../../model/product/tena/actimove/IActimoveTCWCatalog';

@Injectable()
export class ActimoveService {
    countryChanged = new Subject<IActimoveTCWCatalog>();
    segmentChanged = new Subject<IActimoveSegment>();

    countries = new Subject<IActimoveTCWCatalog[]>();
    segments = new Subject<IActimoveSegment[]>();
    countryModalVisible = new Subject<boolean>();
    segmentModalVisible = new Subject<boolean>();

    getActiveCountry(): IActimoveTCWCatalog {
        let country = null;
        let sessionStorage = window.sessionStorage.getItem('actimoveSelectedCountry');
        if (sessionStorage !== '' && sessionStorage !== null) {
            country = JSON.parse(sessionStorage);
        }

        return country;
    }

    getActiveSegment(): IActimoveSegment {
        let segment = null;
        let sessionStorage = window.sessionStorage.getItem('actimoveSelectedSegment');
        if (sessionStorage !== '' && sessionStorage !== null) {
            segment = JSON.parse(sessionStorage);
        }

        return segment;
    }
}
