import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { IActimoveSiteMap } from '../../../../model/product/tena/actimove/IActimoveSiteMap';

@Injectable()
export class ActimoveSiteMapApi extends BaseProductApi<IActimoveSiteMap> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmsglobalsitemap/actimove';
    }
}
