import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ITorkWebServiceCredentials } from '../../model/product/tork/ITorkWebServiceCredentials';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TorkWebServiceCredentialsApi } from '../../api/product/tork/TorkWebServiceCredentialsApi';

@Component({
    templateUrl: 'TorkWebServiceCredentials.component.html',
    providers: [TorkWebServiceCredentialsApi]
})
export class TorkWebServiceCredentialsComponent
    extends BaseLiveStageCrudList<
        ITorkWebServiceCredentials,
        ICrudData<ITorkWebServiceCredentials>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private torkWebServiceCredentialsApi: TorkWebServiceCredentialsApi
    ) {
        super(
            elementRef,
            'product',
            liveStageService,
            prettySubmitGuard,
            'Web Service Credential'
        );
    }

    ngOnInit() {
        this.adminTitle.setTitle('Web Service Credentials - Tork');

        this.filter.observable.subscribe(() => {
            this.torkWebServiceCredentialsApi.getAll().subscribe(colors => {
                this.init(sortBy(colors, ['isProdEnv', 'userId']));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.torkWebServiceCredentialsApi, this.authService);
    }
}
