import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';

import { Tool } from '../../model/auth/login/Tool';
import { DistributionHubDomainsComponent } from '../../product/distributionHub/DistributionHubDomains.component';
import { DistributionHubCatalogComponent } from '../../product/distributionHub/DistributionHubCatalog.component';
import { DistributionHubSolrComponent } from '../../product/distributionHub/DistributionHubSolr.component';
import { DistributionHubCatalogSolrcoreMappingComponent } from '../../product/distributionHub/mapping/DistributionHubCatalogSolrcoreMapping.component';
import { StatsComponent } from '../../product/distributionHub/stats/stats.component';
import { DistributionHubSolrCoreConfigComponent } from '../../product/distributionHub/SolrCoreConfig/DistributionHubSolrCoreConfig.component';

export const DistributionHubChildRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Domains',
        path: 'domains',
        component: DistributionHubDomainsComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Catalogs',
        path: 'catalogs',
        component: DistributionHubCatalogComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Catalog ⬌ Solr',
        path: 'mapping/catalog-solr',
        component: DistributionHubCatalogSolrcoreMappingComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Solr',
        path: 'solr',
        component: DistributionHubSolrComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'SolrCoreConfig',
        path: 'SolrCoreConfig',
        component: DistributionHubSolrCoreConfigComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Stats',
        path: 'stats',
        component: StatsComponent,
        requiredAccess: {
            tool: [Tool.DistributorFinder]
        }
    }
];
