import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { IJobstCatalogIdentificationMapping } from '../../../../model/product/tena/jobst/IJobstCatalogIdentificationMapping';

@Injectable()
export class JobstCatalogIdentificationMappingApi extends BaseProductApi<
IJobstCatalogIdentificationMapping
> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscountrytoidentificationsourcemapping/jobst';
    }
}
