import { Http } from '@angular/http';
import { Inject, Injectable } from '@angular/core';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../environment/IEnvironment';
import { LiveStageService } from '../../../environment/LiveStage.service';

@Injectable()
export class ImportLogsApi extends BaseAuthorizedApi<IEnvironment> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        private liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    getItems(startDate: string, endDate: string) {
        return this.getJson('femcare/productimportlogs', {
            params: { StartDate: startDate, EndDate: endDate }
        });
    }
    protected getBaseUrl() {
        return this.environment.api.product[this.liveStageService.get('product')];
    }
}
