import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITorkBrand } from '../../../model/product/tork/ITorkBrand';

@Injectable()
export class TorkBrandApi extends BaseProductApi<ITorkBrand> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'tork/lkpbrand';
    }
}
