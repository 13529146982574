import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ITorkColorSorting } from '../../model/product/tork/ITorkColorSorting';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TorkColorSortingApi } from '../../api/product/tork/TorkColorSortingApi';

@Component({
    templateUrl: 'TorkColorSorting.component.html',
    providers: [TorkColorSortingApi]
})
export class TorkColorSortingComponent
    extends BaseLiveStageCrudList<
        ITorkColorSorting,
        ICrudData<ITorkColorSorting>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private torkColorSortingApi: TorkColorSortingApi
    ) {
        super(elementRef, 'product', liveStageService, prettySubmitGuard, 'Color Theme');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Color Themes - Tork');

        this.filter.observable.subscribe(() => {
            this.torkColorSortingApi.getAll().subscribe(colors => {
                this.init(sortBy(colors, ['sortOrder', 'colorTheme']));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.torkColorSortingApi, this.authService);
    }
}
