import { DoCheck } from '@angular/core';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';

import { BaseAdminCrudList } from './BaseAdminCrudList';

export abstract class ShowMoreAdminCrudList<I, D extends ICrudData<I>>
    extends BaseAdminCrudList<I, D>
    implements DoCheck {
    private static MaxItems = 100;
    hasMore: boolean;
    maxItems = ShowMoreAdminCrudList.MaxItems;

    ngDoCheck() {
        super.ngDoCheck();

        var searchResults = this.searchResults,
            searchResultCount = searchResults && searchResults.length;

        if (searchResultCount > this.maxItems) {
            this.hasMore = true;
            this.searchResults = searchResults.slice(0, this.maxItems);
        } else {
            this.hasMore = false;
        }
    }

    showMore() {
        this.maxItems += ShowMoreAdminCrudList.MaxItems;
    }
}
