import { Response, ResponseContentType } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { IFulfillmentSite } from '../../../../model/fulfillment/IFulfillmentSite';
import { IFulfillmentApi } from '../../../fulfillment/IFulfillmentApi';
import { IPromotionSubmission } from '../../../../model/campaign/promotion/IPromotionSubmission';
import { IPromotionSubmissionRange } from 'src/app/model/campaign/promotion/IPromotionSubmissionRange';

@Injectable()
export class PromotionSubmissionsApi extends BaseAuthorizedApi<IEnvironment>
    implements IFulfillmentApi<IPromotionSubmission> {
        
    getItems(
        site: IFulfillmentSite,
        targetId: string,
        pageSize: number,
        startDate: string,
        endDate?: string
    ): Observable<IPromotionSubmission[]> {
        var props: any = {
            siteId: site.name,
            startDate: startDate,
            endDate: endDate,
            pageSize: pageSize
        };
        
        if(targetId){
            props.promotionId = targetId;
        }

        return this.getJson('submission', {
            params: props
        });
    }

    getMoreItems(
        site: IFulfillmentSite,
        targetId: string,
        pageSize: number,
        offset: number,
        startDate: string,
        endDate?: string
    ): Observable<IPromotionSubmission[]> {
        var props: any = {
            siteId: site.name,
            startDate: startDate,
            endDate: endDate,
            pageSize: pageSize,
            itemCount: offset
        };
        
        if(targetId){
            props.promotionId = targetId;
        }

        return this.getJson('submission/loadmoreresults', {
            params: props
        });
    }

    getItemById(id: string): Observable<IPromotionSubmission> {
        return this.getJson('submission/' + id);
    }

    getCsv(
        site: IFulfillmentSite,
        targetId: string,
        startDate: string,
        endDate?: string
    ): Observable<Response> {
        var props: any = {
            siteId: site.name,
            startDate: startDate,
            endDate: endDate
        };

        if(targetId){
            props.promotionId = targetId;
        }

        return this.get('submission/csv/', {
            params: props
        });
    }

    removeItem(item: IPromotionSubmission): Observable<Response> {
        return this.delete('request/' + item.id);
    }

    downloadAttachment(id: string): Observable<Response> {
        return this.get('submission/' + id + '/file', {
            responseType: ResponseContentType.Blob
        });
    }

    getSubmissionRange(
        site: IFulfillmentSite,
        targetId: string,
        pageSize: number,
        startDate: string,
        endDate?: string
    ): Observable<IPromotionSubmissionRange> {
        var props: any = {
            siteId: site.name,
            startDate: startDate,
            endDate: endDate,
            pageSize: pageSize
        };

        if(targetId){
            props.promotionId = targetId;
        }

        return this.getJson('submission/getsubmissionrange', {
            params: props
        });
    }

    protected getBaseUrl() {
        return this.environment.api.promotions;
    }
}
