import { Component, Input } from '@angular/core';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { CrudActionsComponent } from './CrudActions.component';
import { get } from 'lodash';

@Component({
    selector: 'admin-crud-filter-actions',
    templateUrl: 'CrudActions.component.html'
})
export class FilterCrudActionsComponent<
    I,
    D extends ICrudData<I>
> extends CrudActionsComponent<I, D> {
    @Input()
    filterCriteria: { filter: string | number }[];

    constructor() {
        super();
    }

    cancel() {
        let retainItemInList = true;
        if (!this.item.isNew() && this.filterCriteria) {
            this.filterCriteria.forEach(criteria => {
                let filterName = Object.keys(criteria)[0];
                let filterValue = get(criteria, filterName);
                let itemValue = get(this.item.savedData.item, filterName);

                if (itemValue !== filterValue) {
                    retainItemInList = false;
                }
            });
        }

        if (retainItemInList) {
            super.cancel();
        } else {
            this.item.onDelete.emit();
        }
    }
}
