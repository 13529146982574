import { Inject, Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';

import { Observable } from 'rxjs';
import { IActimoveWebShop } from '../../../../model/product/tena/actimove/IActimoveWebShop';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ActimoveService } from '../../../../product/tena/actimove/Actimove.service';
import { ActimoveBaseTCWCatalogApi } from './ActimoveBaseTCWCatalogApi';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { ActimoveWebShopCatalogApi } from '../actimove/ActimoveWebShopCatalogApi';
import { IActimoveWebShopCatalog } from '../../../../model/product/tena/actimove/IActimoveWebShopCatalog';
import { ActimoveSegmentApi } from './ActimoveSegmentApi';
import { IActimoveSegment } from '../../../../model/product/tena/actimove/IActimoveSegment';

@Injectable()
export class ActimoveWebShopApi extends ActimoveBaseTCWCatalogApi<IActimoveWebShop> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        actimoveService: ActimoveService,
        readonly actimoveService1: ActimoveService,
        liveStageService: LiveStageService,
        readonly actimoveCatalogApi: ActimoveWebShopCatalogApi,
        readonly actimoveSegmentsApi: ActimoveSegmentApi
    ) {
        super(apiErrorHandler, authService, environment, http, actimoveService, liveStageService);
    }

    getWebShops(countryId: number, segmentId: number): Observable<IActimoveWebShop[]> {
        return this.getJson(`/${countryId}/${segmentId}`);
    }

    protected getBaseUrl() {
        this.actimoveCatalogApi.getAll().subscribe((res: IActimoveWebShopCatalog[]) => {
            this.actimoveService1.countries.next(res);
        });

        this.actimoveSegmentsApi.getAll().subscribe((res: IActimoveSegment[]) => {
            const ActimoveSegment = res.filter(segment => segment.isActimoveSegment);

            this.actimoveService1.segments.next(ActimoveSegment);
        });

        return this.TCWBaseURl + 'webshop';
    }
}
