import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidCrudApi } from '../BaseAuthorizedGuidCrudApi';
import { IRedirect } from '../../model/site/redirect/IRedirect';
import { Observable } from 'rxjs';

@Injectable()
export class RedirectApi extends BaseAuthorizedGuidCrudApi<IRedirect> {
    public getRedirectsBySite(siteId: string): Observable<IRedirect[]> {
        return this.getJson('/site/' + siteId);
    }

    inactivate(item: IRedirect): Observable<any> {        
        return this.delete('/site/'+ item.siteId +'/redirect/' +item.id );        
    }

    protected getBaseUrl() {
        return this.environment.api.redirect + 'seoredirect';
    }
}
