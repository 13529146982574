import { IDeleteUser } from './IDeleteUser';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';

export class DeleteUserCrud extends ObjectIdCrud<IDeleteUser, ICrudData<IDeleteUser>> {
    protected performSave(
        data: ICrudData<IDeleteUser>,
        savedData: ICrudData<IDeleteUser>
    ) {
        // Override default save method to update field "id" after save. This API uses "id" instead of "objectId" so custom solution is needed.
        let observable = super.performSave(data, savedData);
        observable.subscribe(saved => {
            data.item.id = saved.id;
            savedData.item.id = saved.id;
        });

        return observable;
    }
}
