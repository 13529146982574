import { Injectable } from '@angular/core';

import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { IEnvironment } from '../../environment/IEnvironment';
import { ISubmittedFormsOptions } from '../../model/submitted/ISubmittedFormsOptions';

@Injectable()
export class SubmittedFormsApi extends BaseAuthorizedApi<IEnvironment> {
    downloadOrEmailExcel(options: ISubmittedFormsOptions) {
        return this.get('Tracking/GetOrSendExcelData/', {
            params: {
                IsDownloadRequired: options.download,
                Email: options.download ? null : options.email,
                StartDate: options.startDate,
                EndDate: options.endDate,
                CountryUrl: options.countryUrl
            }
        });
    }

    protected getBaseUrl() {
        return this.environment.api.submitted;
    }
}
