import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ITpwCountry } from '../model/product/tena/tpw/ITpwCountry';
import { ITpwChannel } from '../model/product/tena/tpw/ITpwChannel';
import { ITpwSample } from '../model/product/tena/tpw/ITpwSample';
import { ITpwProductFamily } from '../model/product/tena/tpw/ITpwProductFamily';
import { ITpwSortCategory } from '../model/product/tena/tpw/ITpwSortCategory';
import { ITpwTier } from '../model/product/tena/tpw/ITpwTier';

@Injectable()
export class TpwService {
    sampleChanged = new Subject<ITpwSample>();
    countryChanged = new Subject<ITpwCountry>();
    channelChanged = new Subject<ITpwChannel>();
    tierChanged = new Subject<ITpwTier>();
    productFamilyChanged = new Subject<ITpwProductFamily>();
    sortCategoryChanged = new Subject<ITpwSortCategory>();

    samples = new Subject<ITpwSample[]>();
    countries = new Subject<ITpwCountry[]>();
    channels = new Subject<ITpwChannel[]>();
    tiers = new Subject<ITpwTier[]>();
    productFamilies = new Subject<ITpwProductFamily[]>();
    sortCategorys = new Subject<ITpwSortCategory[]>();

    sampleModalVisible = new Subject<boolean>();
    countryModalVisible = new Subject<boolean>();
    channelModalVisible = new Subject<boolean>();
    tierModalVisible = new Subject<boolean>();
    productFamilyModalVisible = new Subject<boolean>();
    sortCategoryModalVisible = new Subject<boolean>();

    getActiveSample(): ITpwSample {
        let sample = null;
        let sessionStorage = window.sessionStorage.getItem('tpwSelectedSample');
        if (sessionStorage !== '' && sessionStorage !== null) {
            sample = JSON.parse(sessionStorage);
        }

        return sample;
    }

    getActiveCountry(): ITpwCountry {
        let country = null;
        let sessionStorage = window.sessionStorage.getItem('tpwSelectedCountry');
        if (sessionStorage !== '' && sessionStorage !== null) {
            country = JSON.parse(sessionStorage);
        }

        return country;
    }

    getActiveChannel(): ITpwChannel {
        let channel = null;
        let sessionStorage = window.sessionStorage.getItem('tpwSelectedChannel');
        if (sessionStorage !== '' && sessionStorage !== null) {
            channel = JSON.parse(sessionStorage);
        }

        return channel;
    }

    getActiveTier(): ITpwTier {
        let tier = null;
        let sessionStorage = window.sessionStorage.getItem('tpwSelectedTier');
        if (sessionStorage !== '' && sessionStorage !== null) {
            tier = JSON.parse(sessionStorage);
        }

        return tier;
    }

    getActiveProductFamily(): ITpwProductFamily {
        let productFamily = null;
        let sessionStorage = window.sessionStorage.getItem('tpwSelectedProductFamily');
        if (sessionStorage !== '' && sessionStorage !== null) {
            productFamily = JSON.parse(sessionStorage);
        }

        return productFamily;
    }

    getActiveSortCategory(): ITpwSortCategory {
        let sortCategory = null;
        let sessionStorage = window.sessionStorage.getItem('tpwSelectedsortCategory');
        if (sessionStorage !== '' && sessionStorage !== null) {
            sortCategory = JSON.parse(sessionStorage);
        }

        return sortCategory;
    }
}
