import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';
import { ISiteMapping } from '../../../model/campaign/siteMapping/ISiteMapping';

@Injectable()
export class SiteMappingApi extends BaseAuthorizedGuidCrudApi<ISiteMapping> {
    protected getBaseUrl() {
        return this.environment.api.samples + 'site';
    }
}
