import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ICountry } from '../model/distributorFinder/ICountry';

@Injectable()
export class CountriesService {
    selectedCountry = new Subject<ICountry>();
    totalCount: number;
    countries = new Subject<ICountry[]>();
    country = new Subject<ICountry>();
    countryNewValues = new Subject<ICountry>();
    countryModalVisible = new Subject<boolean>();
}
