import Count from 'tridion.web.ui/src/app/angular/pipe/Count';

export default function Plural(
    count: number | any[] | any,
    word: string,
    pluralWord?: string
): string {
    count = Count(count);

    if (Number(count) !== 1) {
        if (pluralWord) {
            word = pluralWord;
        } else if (word.match(/(ch|s|x)$/)) {
            word += 'es';
        } else if (word.match(/y$/)) {
            word = word.substring(0, word.length - 1) + 'ies';
        } else if (word.match(/fe?$/)) {
            word = word.replace(/fe?$/, 'ves');
        } else {
            word += 's';
        }
    }

    return word;
}
