import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthModuleApi } from '../../api/auth/AuthModuleApi';
import { BaseAdminCrudList } from '../../crud/list/BaseAdminCrudList';
import getUniqueOptions from 'tridion.web.ui/src/app/angular/crud/getUniqueOptions';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { IAuthEntity } from '../../model/auth/IAuthEntity';
import { IAuthModule } from '../../model/auth/IAuthModule';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Tool } from '../../model/auth/login/Tool';

@Component({
    templateUrl: 'AuthModules.component.html',
    providers: [AuthModuleApi]
})
export class AuthModulesComponent
    extends BaseAdminCrudList<IAuthModule, ICrudData<IAuthModule>>
    implements OnInit {
    readonly toolOptions = Object.keys(Tool);

    constructor(
        private adminTitle: AdminTitle,
        private authModuleApi: AuthModuleApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard
    ) {
        super(elementRef, prettySubmitGuard, 'Tool');
    }

    ngOnInit() {
        this.adminTitle.setTitle(['Tools', 'Security']);

        this.authModuleApi.getAll().subscribe(modules => {
            this.init(sortBy(modules, 'name'));
        });
    }

    protected createCrud() {
        return new GuidCrud(this.authModuleApi);
    }
}
