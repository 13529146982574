import { Component, Input, OnInit } from '@angular/core';
import { ITest } from '../model/testResults/ITest';
import { SlugifyPipe } from '../pipe/slugify.pipe';
import { PrettyDatePipe } from 'tridion.web.ui/src/app/angular/pipe/PrettyDate.pipe';
import { IWebsite } from '../model/testResults/IWebsite';
import { IDomain } from '../model/testResults/IDomain';
import { ITestData } from '../model/testResults/ITestData';
import { ITestResultsContainer } from '../model/testResults/ITestResultsContainer';
import { IStep } from '../model/testResults/IStep';

@Component({
    selector: 'admin-test-result-list',
    templateUrl: 'testResultlist.component.html',
    providers: [SlugifyPipe, PrettyDatePipe]
})
export class TestResultListComponent implements OnInit {
    @Input()
    domain: IDomain;
    @Input()
    container: ITestResultsContainer;
    @Input()
    tests: ITest[];
    @Input()
    testData: ITestData[];
    page = 1;
    totalPages: number;
    limit = 10;
    arr: number[];    
    availableStatuses: string[];
    public status?: string;  
    filterBy='status';
    ngOnInit() {
        this.availableStatuses = ['all', 'pass', 'fail'];
        this.status = this.availableStatuses[0];
    }

    setTest(test: ITest) {
        this.container.test = test;
    }

    checkTest(test: ITest) {
        return this.container && this.container.test && this.container.test === test;
    }

    get website(): IWebsite {
        return this.container.website;
    }

    set website(value: IWebsite) {
        this.container.website = value;
    }

    get test(): ITest {      
        
        return this.container.test;
    }

    set test(value: ITest) {
        this.container.test = value;
    }

    testHasFailed(test: ITest): boolean {
        let failed = false;
        test.steps.forEach((step: IStep) => {
            if (step.status.toLowerCase() === 'fail') {
                failed = true;

                return false;
            }
        });

        return failed;
    }
    testHasPassed(test: ITest): boolean {
        let passed = true;
        test.steps.forEach((step: IStep) => {
            if (step.status.toLowerCase() === 'fail') {
                passed = false;

                return false;
            }
        });

        return passed;
    }    
    filterTest(test:ITest,status:string){
       let filter = false;       
       if( status === 'pass' && this.testHasPassed(test) ){
           
             return true;
       }
       if( status === 'fail' && this.testHasFailed(test) ){

        return true;
        }
        if( status === 'all'){

            return true;
            }
        
       return filter;
    }

    filterTestRows(test:ITest,filterBy:string,status:string,index:number){
        let filter = false;
         let currentPage = (this.page-1) * this.limit;
         let nextPage = this.page * this.limit;
        if(filterBy === 'status'){
            filter= this.filterTest(test,status);  
        }
        if(filterBy === 'pageSize'){
           
             
            if( this.filterTest(test,'all') &&  index >= currentPage && index < nextPage){
                filter = true;
            }
            else{
                filter = false;
            }            
        }

        return filter;      
        }

    setPage(page: number) {
        this.page = page;               
    }
    
    setArray(tests: ITest[]){
        this.totalPages = Math.ceil(
            tests.length / this.limit
        );
        this.arr = Array(this.totalPages);

        if (this.page > this.totalPages) {
            this.setPage(this.totalPages);
        }        
    }    
}
