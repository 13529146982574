
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

import 'date-input-polyfill';
import toIsoDate from '../../date/toIsoDate';
import { TenaJobType } from '../../model/product/tena/tcw/TenaJobType';
import { ITenaUserData } from '../../model/product/tena/tcw/ITenaUserData';
import { TenaFulFillmentApi } from '../../api/product/tena/tcw/TenaFulFillmentApi';
import { AdminTitle } from '../../layout/AdminTitle';
import { TenaService } from './Tena.service';
import saveBlob from '../../api/fileManager/saveBlob';
import { LiveStageService } from '../../environment/LiveStage.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../environment/LiveStage';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { TenaTCWCatalogApi } from '../../api/product/tena/tcw/TenaTCWCatalogApi';
import { ITenaTCWCatalog } from '../../model/product/tena/tcw/ITenaTCWCatalog';
@Component({
    templateUrl: 'TenaFulFillment.component.html',
    providers: [TenaFulFillmentApi,TenaTCWCatalogApi]
})
export class TenaFulFillmentComponent
    extends BaseLiveStageCrudList<
        ITenaUserData,
        ICrudData<ITenaUserData>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    keys: string[];
    jobTypes = TenaJobType;
    jobType = TenaJobType.Default;
    items$: BehaviorSubject<ITenaUserData[]> = new BehaviorSubject<ITenaUserData[]>(null);
    item: ITenaUserData;
    startDate: string;
    endDate: string;
    pageSize = 20;
    hasMoreItems = false;
    loadingItems = false;
    showQnA = false;
    showPersonalData = false;
    countries: ITenaTCWCatalog[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        readonly tenaFulFillmentApi: TenaFulFillmentApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        liveStageService: LiveStageService,
        readonly authService: AuthService,
        readonly tenaCatalogApi: TenaTCWCatalogApi,
        private tenaService: TenaService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'Fulfillment Report');
        let endDate = new Date();
        let startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 1);
        this.startDate = toIsoDate(startDate);
        this.endDate = toIsoDate(endDate);

        this.keys = Object.keys(this.jobTypes);
        this.jobType = TenaJobType.Default;
    }

    ngOnInit() {
        this.adminTitle.setTitle('Fulfillment Report');

        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
            this.tenaService.countries.next(res);
        });
        this.tenaService.countryChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedCountry => {
                if (selectedCountry) {
                    this.loadItems();
                }
            });
    }

    handleResponse(items: ITenaUserData[], append: boolean) {
        // Response can return pagesize + 1 number of items to indicate that next page exists.
        this.hasMoreItems = items.length > this.pageSize;
        if (this.hasMoreItems) {
            items.pop();
        }

        let newItems = append ? this.items$.value.concat(items) : items;
        this.items$.next(newItems);

        this.showQnA = newItems.some(x => x.userQuestionAnswers.length > 0);
    }

    loadItems() {
        this.filter.observable.subscribe(filter => {
            if (this.startDate) {
                this.tenaFulFillmentApi
                    .getItems(
                        this.tenaService.getActiveCountry().objectId,
                        this.jobType,
                        this.pageSize,
                        this.startDate,
                        this.endDate
                    )
                    .subscribe(items => {
                        this.handleResponse(items, false);
                    });
            }
        });
    }

    loadMoreItems() {
        if (this.startDate) {
            this.loadingItems = true;
            this.tenaFulFillmentApi
                .getMoreItems(
                    this.tenaService.getActiveCountry().objectId,
                    this.jobType,
                    this.pageSize,
                    this.items$.value.length,
                    this.startDate,
                    this.endDate
                )
                .subscribe(
                    items => {
                        this.handleResponse(items, true);
                    },
                    err => {
                        this.loadingItems = false;
                    },
                    () => {
                        this.loadingItems = false;
                    }
                );
        }
    }

    exportToCsv() {
        if (this.startDate) {
            this.tenaFulFillmentApi
                .getCsv(
                    this.tenaService.getActiveCountry().objectId,
                    this.jobType,
                    this.startDate,
                    this.endDate,
                    this.showPersonalData
                )
                .subscribe(response => {
                    let fileName =
                        this.jobType + '_' + this.startDate + '_' + this.endDate;

                    saveBlob(response.text(), fileName, 'xls');
                });
        }
    }

    toggleShowPersonalDataFlag() {
        this.showPersonalData = !this.showPersonalData;
    }

    getContactMessage(item: ITenaUserData): string {
        if (!item.contact) {
            return 'No';
        }

        let message = 'Yes,';
        if (item.contactByEmail) {
            message += ' by email,';
        }
        if (item.contactByPhone) {
            message += ' by phone,';
        }
        if (item.contactByPostal) {
            message += ' by postal,';
        }
        message = message.substring(0, message.length - 1);

        return message;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tenaFulFillmentApi, this.authService);
    }
}
