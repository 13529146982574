
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Subject } from 'rxjs';
import { TenaService } from './Tena.service';
import { ITenaSampleOrder } from '../../model/product/tena/tcw/ITenaSampleOrder';
import { TenaSampleOrderApi } from '../../api/product/tena/tcw/TenaSampleOrderApi';
import { TenaSampleOrderCrud } from '../../model/product/tena/tcw/TenaSampleOrderCrud';
import { TenaProductFamilyApi } from '../../api/product/tena/tcw/TenaProductFamilyApi';
import { ITenaProductFamily } from '../../model/product/tena/tcw/ITenaProductFamily';
import { TenaTCWCatalogApi } from './../../api/product/tena/tcw/TenaTCWCatalogApi';
import { TenaSegmentApi } from './../../api/product/tena/TenaSegmentApi';
import { ITenaSegment } from './../../model/product/tena/ITenaSegment.d';
import { ITenaTCWCatalog } from './../../model/product/tena/tcw/ITenaTCWCatalog.d';

@Component({
    templateUrl: 'TenaSampleOrder.component.html',
    providers: [TenaSampleOrderApi, TenaProductFamilyApi, TenaTCWCatalogApi, TenaSegmentApi]
})
export class TenaSampleOrderComponent
    extends BaseLiveStageCrudList<
        ITenaSampleOrder,
        ICrudData<ITenaSampleOrder>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    productFamilies: ITenaProductFamily[];
    sortingOrderOptions: number[];
    cruds: TenaSampleOrderCrud[];

    countries: ITenaTCWCatalog[];
    segments: ITenaSegment[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tenaSampleOrderApi: TenaSampleOrderApi,
        private tenaProductFamilyApi: TenaProductFamilyApi,
        readonly tenaCatalogApi: TenaTCWCatalogApi,
        readonly tenaSegmentsApi: TenaSegmentApi,
        readonly tenaService: TenaService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'Sample Order');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Sample Order - Tena');

        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
          this.tenaService.countries.next(res);
        });

        this.tenaSegmentsApi.getAll().subscribe((res: ITenaSegment[]) => {
            const consumerSegments = res.filter(segment => segment.isConsumerSegment);
            this.tenaService.segments.next(consumerSegments);
        });

        // Sorting order is 0-15.
        this.sortingOrderOptions = Array(51)
            .fill(0)
            .map((value, index) => index);

        /**
         * 1. Get selected country (user selection).
         * 2. Get selected segment (user selection).
         * 3. Fetch their product families. (api).
         * 4. Fetch sample orders (api).
         */

        this.tenaService.segmentChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedSegment => {
                if (selectedSegment) {
                    this.clear();
                    this.getProductFamilies();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    getProductFamilies() {
        this.filter.observable.subscribe(() => {
            this.tenaProductFamilyApi
                .getProductFamilies(
                    this.tenaService.getActiveCountry().objectId,
                    this.tenaService.getActiveSegment().objectId
                )
                .subscribe(productFamilies => {
                    this.setProductFamilies(productFamilies);
                });
        });
    }

    setProductFamilies(productFamilies: ITenaProductFamily[]) {
        this.productFamilies = productFamilies;

        if (productFamilies.length > 0) {
            this.getProductSorting(productFamilies[0].urlFriendlyName);
        } else {
            this.init([]);
        }
    }

    getProductSorting(urlFriendlyName: string) {
        this.tenaSampleOrderApi
            .getSampleOrders(
                this.tenaService.getActiveCountry().objectId,
                this.tenaService.getActiveSegment().objectId,
                urlFriendlyName
            )
            .subscribe(productSortings => {
                this.clear();
                this.init(productSortings);
            });
    }

    protected createCrud() {
        return new TenaSampleOrderCrud(this.tenaSampleOrderApi, this.authService);
    }
}
