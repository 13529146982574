import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ShowMoreAdminCrudList } from '../../crud/list/ShowMoreAdminCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { AdminTitle } from '../../layout/AdminTitle';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { IElearning } from '../../model/elearning/IElearning';
import { ElearningApi } from '../../api/elearning/ElearningApi';
import { CrudMode } from 'tridion.web.ui/src/app/angular/crud/CrudMode';

@Component({
    templateUrl: 'Elearning.component.html',
    providers: [ElearningApi]
})
export class ElearningComponent
    extends ShowMoreAdminCrudList<IElearning, ICrudData<IElearning>>
    implements OnInit {
    file: File;
    uploadInProgress = false;

    @ViewChild('fileInput', {static: false})
    fileInput: ElementRef;

    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        protected elearningApi: ElearningApi
    ) {
        super(elementRef, prettySubmitGuard, 'E-learning');
    }

    ngOnInit() {
        this.adminTitle.setTitle('E-learning');
        this.getAll();
    }

    getAll() {
        this.elearningApi.getAll().subscribe(data => {
            this.clear();
            this.init(data);
        });
    }

    changeFile() {
        if (this.fileInput.nativeElement.files.length > 0) {
            let file: File = this.fileInput.nativeElement.files[0];

            const zipMime = [
                'application/zip',
                'application/octet-stream',
                'application/x-zip-compressed',
                'multipart/x-zip'
            ];

            if (zipMime.includes(file.type)) {
                this.file = file;
            } else {
                this.file = null;
            }
        }
    }

    importFile(crud: GuidCrud<IElearning, ICrudData<IElearning>>) {
        this.uploadInProgress = true;
        let data = new FormData();

        // Replace white space with underscore
        data.append('file', this.file, this.file.name.replace(/\s/g, '_'));

        let name = crud.data.item.name;

        crud.saving = true;
        this.elearningApi.upload(data, name).subscribe(
            () => {
                crud.setMode(CrudMode.Read);

                this.getAll();
            },
            error => {
                this.uploadInProgress = false;
                this.file = null;
            },
            () => {
                this.uploadInProgress = false;
                this.file = null;
            }
        );
    }

    protected createCrud() {
        return new GuidCrud(this.elearningApi);
    }
}
