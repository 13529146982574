
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

import 'date-input-polyfill';
import { ICutimedUserData } from '../../../model/product/tena/cutimed/ICutimedUserData';
import { CutimedLegacyIdExportApi } from '../../../api/product/tena/cutimed/CutimedLegacyIdExportApi';
import { AdminTitle } from '../../../layout/AdminTitle';
import { CutimedService } from './Cutimed.service';
import saveBlob from '../../../api/fileManager/saveBlob';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { BaseLiveStageCrudList } from '../../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../../environment/LiveStage';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { CutimedTCWCatalogApi } from '../../../api/product/tena/cutimed/CutimedTCWCatalogApi';
import { ICutimedTCWCatalog } from '../../../model/product/tena/cutimed/ICutimedTCWCatalog';

@Component({
    templateUrl: 'CutimedLegacyIdExport.component.html',
    providers: [CutimedLegacyIdExportApi, CutimedTCWCatalogApi]
})
export class CutimedLegacyIdExportComponent
    extends BaseLiveStageCrudList<
        ICutimedUserData,
        ICrudData<ICutimedUserData>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    items$: BehaviorSubject<ICutimedUserData[]> = new BehaviorSubject<ICutimedUserData[]>(null);
    item: ICutimedUserData;
    pageSize = 20;
    hasMoreItems = false;
    showQnA = false;
    countries: ICutimedTCWCatalog[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        readonly cutimedLegacyIdExportApi: CutimedLegacyIdExportApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        liveStageService: LiveStageService,
        readonly authService: AuthService,
        readonly cutimedCatalogApi: CutimedTCWCatalogApi,
        private cutimedService: CutimedService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'Legacy ID export');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Legacy ID export');

        this.cutimedCatalogApi.getAll().subscribe((res: ICutimedTCWCatalog[]) => {
            this.cutimedService.countries.next(res);
        });
        this.cutimedService.countryChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedCountry => {
                if (selectedCountry) {
                    this.loadItems();
                }
            });
    }

    handleResponse(items: ICutimedUserData[], append: boolean) {
        // Response can return pagesize + 1 number of items to indicate that next page exists.
        this.hasMoreItems = items.length > this.pageSize;
        if (this.hasMoreItems) {
            items.pop();
        }

        let newItems = append ? this.items$.value.concat(items) : items;
        this.items$.next(newItems);

        this.showQnA = newItems.some(x => x.userQuestionAnswers.length > 0);
    }

    loadItems() {
        this.filter.observable.subscribe(filter => {
            this.cutimedLegacyIdExportApi
                .getItems(
                    this.cutimedService.getActiveCountry().objectId,
                )
                .subscribe(items => {
                    this.handleResponse(items, false);
                });
        });
    }

    exportToCsv() {
        this.cutimedLegacyIdExportApi
            .getCsv(
                this.cutimedService.getActiveCountry().objectId,
            )
            .subscribe(response => {
                let fileName = 'Cutimed - Legacy ID';

                saveBlob(response.text(), fileName, 'xls');
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.cutimedLegacyIdExportApi, this.authService);
    }
}
