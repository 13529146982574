import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Observable } from 'rxjs';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { CutimedService } from '../../../../product/tena/cutimed/Cutimed.service';
import { BaseCutimedCatalogApi } from './BaseCutimedCatalogApi';
import { ICutimedProductFamily } from '../../../../model/product/tena/cutimed/ICutimedProductFamily';
import { LiveStageService } from '../../../../environment/LiveStage.service';

@Injectable()
export class CutimedProductFamilyApi extends BaseCutimedCatalogApi<ICutimedProductFamily> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        cutimedService: CutimedService,
        liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http, cutimedService, liveStageService);
    }

    getProductFamilies(
        countryId: number,
        segmentId: number
    ): Observable<ICutimedProductFamily[]> {
        return this.getJson(`/${countryId}/${segmentId}`);
    }

    protected getBaseUrl() {
        return this.TCWBaseURl + 'productfamily';
    }
}
