import { Http } from '@angular/http';
import { Inject, Injectable } from '@angular/core';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseTCWApi } from '../tcw/BaseTCWApi';
import { ICutimedTCWCatalog } from '../../../../model/product/tena/cutimed/ICutimedTCWCatalog';
import { LiveStageService } from '../../../../environment/LiveStage.service';

@Injectable()
export class CutimedTCWCatalogApi extends BaseTCWApi<ICutimedTCWCatalog> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http, liveStageService);
    }

    protected getBaseUrl() {
        return this.TCWBaseURl + 'catalog/cutimed';
    }
}
