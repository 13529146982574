import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITorkCatalog } from '../../../model/product/tork/ITorkCatalog';

@Injectable()
export class TorkCatalogApi extends BaseProductApi<ITorkCatalog> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'tork/lkpcatalog';
    }
}
