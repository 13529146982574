import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ICutimedSegment } from '../../../model/product/tena/cutimed/ICutimedSegment';
import { ICutimedTCWCatalog } from '../../../model/product/tena/cutimed/ICutimedTCWCatalog';

@Injectable()
export class CutimedService {
    countryChanged = new Subject<ICutimedTCWCatalog>();
    segmentChanged = new Subject<ICutimedSegment>();

    countries = new Subject<ICutimedTCWCatalog[]>();
    segments = new Subject<ICutimedSegment[]>();
    countryModalVisible = new Subject<boolean>();
    segmentModalVisible = new Subject<boolean>();

    getActiveCountry(): ICutimedTCWCatalog {
        let country = null;
        let sessionStorage = window.sessionStorage.getItem('cutimedSelectedCountry');
        if (sessionStorage !== '' && sessionStorage !== null) {
            country = JSON.parse(sessionStorage);
        }

        return country;
    }

    getActiveSegment(): ICutimedSegment {
        let segment = null;
        let sessionStorage = window.sessionStorage.getItem('cutimedSelectedSegment');
        if (sessionStorage !== '' && sessionStorage !== null) {
            segment = JSON.parse(sessionStorage);
        }

        return segment;
    }
}
