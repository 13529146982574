import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAuthorizedObjectIdApi } from '../../BaseAuthorizedObjectIdApi';
import { IDeleteUser } from '../../../../model/delete/IDeleteUser';
@Injectable()
export class TpwGdprBlacklistApi extends BaseAuthorizedObjectIdApi<IDeleteUser> {
    getAll(): Observable<IDeleteUser[]> {
        return this.getJson('/GetAll');
    }

    add(item: IDeleteUser): Observable<IDeleteUser> {
        return this.postJson(`?emailId=${item.emailId}`, null);
    }

    inactivate(item: IDeleteUser): Observable<any> {
        return this.delete('/' + item.id);
    }

    protected getBaseUrl() {
        return this.environment.api.submitted + 'Blacklist';
    }
}
