import { Component, ElementRef, OnInit } from '@angular/core';
import { AdminTitle } from '../../../layout/AdminTitle';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { DistributionHubCatalogApi } from '../../../api/product/distributionHub/DistributionHubCatalogApi';
import { DistributionHubSolrcoreApi } from '../../../api/product/distributionHub/DistributionHubSolrcoreApi';
import { DistributionHubCatalogSolrcoreMappingApi } from '../../../api/product/distributionHub/DistributionHubCatalogSolrcoreMappingApi';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { GuidCatalogSolrcoreMapping } from '../../mapping/GuidCatalogSolrcoreMapping';
import { IGuidCatalogSolrcoreMapping } from '../../../model/product/IGuidCatalogSolrcoreMapping';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';


@Component({
    templateUrl: 'DistributionHubCatalogSolrcoreMapping.component.html',
    providers: [
        DistributionHubCatalogApi,
        DistributionHubCatalogSolrcoreMappingApi,
        DistributionHubSolrcoreApi
    ]
})
export class DistributionHubCatalogSolrcoreMappingComponent
    extends GuidCatalogSolrcoreMapping
    implements OnInit {   
    selectedCatalogId = '';
    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        
        private distributionHubCatalogApi: DistributionHubCatalogApi,
        private distributionHubCatalogSolrcoreMappingApi: DistributionHubCatalogSolrcoreMappingApi,
        private distributionHubSolrcoreApi: DistributionHubSolrcoreApi,
        prettySubmitGuard: PrettySubmitGuard
    ) {
        super(elementRef, prettySubmitGuard);
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalog ⬌ Solr Cores - Distribution Hub');

        this.initAll(
            this.distributionHubCatalogApi.getAll(),
            this.distributionHubSolrcoreApi.getAll(),
            this.distributionHubCatalogSolrcoreMappingApi.getAll()
        );
    }
        
    crudsave(currentCrudItem:  GuidCrud<IGuidCatalogSolrcoreMapping, ICrudData<IGuidCatalogSolrcoreMapping>>){
        
        let selectedCatalogKey = (document.getElementById('mappingCatalog0')  as HTMLInputElement).value;     
       
        let tempcatalogId=this.getCatalogId(selectedCatalogKey);
        currentCrudItem.data.item.catalogId=tempcatalogId;

        let selectedCoreKey = (document.getElementById('mappingCore0')  as HTMLInputElement).value;  
   
        let tempCoreId= this.getCoreId(selectedCoreKey);
           
        currentCrudItem.data.item.solrCoreId=tempCoreId;
        currentCrudItem.save();
    }

    getItemId( ){
        let selectedCatalogKey = (document.getElementById('mappingCatalog0')  as HTMLInputElement).value;    
        this.selectedCatalogId= this.getCatalogId(selectedCatalogKey);                  
    }

    getCatalogId(catalogKey:string):string{
       
        let matchedCatalogId='',i: number,j;
        
        let catalogObjects = Object.keys(this.catalogsById).map(itm => this.catalogsById[itm]);
               
        for (i=0;i<catalogObjects.length;++i) {         
             
                let catalogitemValue: any = catalogObjects[i];
                let catalogItems = Object.keys(catalogitemValue).map(item=>catalogitemValue[item] );
                
                for(j=0;j<catalogItems.length;++j){
                    
                    if(catalogItems[j]=== catalogKey){
                       matchedCatalogId=catalogItems[j-1];            
                }            
            }
        }

        return  matchedCatalogId;
    }

    getCoreId(coreName:string):string{
       
        let matchedCoreId='',i: number,j;
        
        let coreObjects = Object.keys(this.coresById).map(item => this.coresById[item]);
               
        for (i=0;i<coreObjects.length;++i) {         
             
                let coreitemValue: any = coreObjects[i];
                let coreItems = Object.keys(coreitemValue).map(item=>coreitemValue[item] );
                
                for(j=0;j<coreItems.length;++j){
                    
                    if(coreItems[j]=== coreName){
                        matchedCoreId=coreItems[j-1];            
                }            
            }
        }

        return  matchedCoreId;
    }

    protected createCrud() {
        return new GuidCrud(this.distributionHubCatalogSolrcoreMappingApi);
    }      
}
