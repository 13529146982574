import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { IActimoveSegment } from '../../../../model/product/tena/actimove/IActimoveSegment';

@Injectable()
export class ActimoveSegmentApi extends BaseProductApi<IActimoveSegment> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmssegment/actimove';
    }
}
