import { Inject, Injectable } from '@angular/core';
import { IFemcareProduct } from '../../model/product/femcare/IFemcareProduct';
import { BaseAuthorizedGuidCrudApi } from '../BaseAuthorizedGuidCrudApi';
import { Observable } from 'rxjs';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { Http } from '@angular/http';
import { IEnvironment } from '../../environment/IEnvironment';

@Injectable()
export class ProductImporterFormApi extends BaseAuthorizedGuidCrudApi<IFemcareProduct> {
    private _selectedSiteId: string;

    set selectedSiteId(selectedSiteId: string) {
        this._selectedSiteId = selectedSiteId;
    }

    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        private liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    inactivate(item: IFemcareProduct): Observable<any> {
        return this.delete('/site/' + this._selectedSiteId + '/ean/' + item.ean);
    }

    public getProductsBySite(siteId: string): Observable<IFemcareProduct[]> {
        return this.getJson('/site/' + siteId);
    }

    update(item: IFemcareProduct): Observable<IFemcareProduct> {
        return this.postJson('/site/' + this._selectedSiteId, item);
    }

    add(item: IFemcareProduct): Observable<IFemcareProduct> {
        return this.postJson('/site/' + this._selectedSiteId, item);
    }

    protected getBaseUrl() {
        return (
            this.environment.api.productImporter[
                this.liveStageService.get('productImporter')
            ] + 'productimporter'
        );
    }
}
