
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

import 'date-input-polyfill';
import toIsoDate from '../../../date/toIsoDate';
import { LeukoplastJobType } from '../../../model/product/tena/leukoplast/LeukoplastJobType';
import { ILeukoplastUserData } from '../../../model/product/tena/leukoplast/ILeukoplastUserData';
import { LeukoplastFulFillmentApi } from '../../../api/product/tena/leukoplast/LeukoplastFulFillmentApi';
import { AdminTitle } from '../../../layout/AdminTitle';
import { LeukoplastService } from './../leukoplast/Leukoplast.service';
import saveBlob from '../../../api/fileManager/saveBlob';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { BaseLiveStageCrudList } from '../../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../../environment/LiveStage';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { LeukoplastTCWCatalogApi } from '../../../api/product/tena/leukoplast/LeukoplastTCWCatalogApi';
import { ILeukoplastTCWCatalog } from '../../../model/product/tena/leukoplast/ILeukoplastTCWCatalog';
@Component({
    templateUrl: 'LeukoplastFulFillment.component.html',
    providers: [LeukoplastFulFillmentApi, LeukoplastTCWCatalogApi]
})
export class LeukoplastFulFillmentComponent
    extends BaseLiveStageCrudList<
        ILeukoplastUserData,
        ICrudData<ILeukoplastUserData>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    keys: string[];
    jobTypes = LeukoplastJobType;
    jobType = LeukoplastJobType.Default;
    items$: BehaviorSubject<ILeukoplastUserData[]> = new BehaviorSubject<ILeukoplastUserData[]>(null);
    item: ILeukoplastUserData;
    startDate: string;
    endDate: string;
    pageSize = 20;
    hasMoreItems = false;
    loadingItems = false;
    showQnA = false;
    showPersonalData = false;
    countries: ILeukoplastTCWCatalog[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        readonly leukoplastFulFillmentApi: LeukoplastFulFillmentApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        liveStageService: LiveStageService,
        readonly authService: AuthService,
        readonly leukoplastCatalogApi: LeukoplastTCWCatalogApi,
        private leukoplastService: LeukoplastService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'Fulfillment Report');
        let endDate = new Date();
        let startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 1);
        this.startDate = toIsoDate(startDate);
        this.endDate = toIsoDate(endDate);

        this.keys = Object.keys(this.jobTypes);
        this.jobType = LeukoplastJobType.Default;
    }

    ngOnInit() {
        this.adminTitle.setTitle('Fulfillment Report');

        this.leukoplastCatalogApi.getAll().subscribe((res: ILeukoplastTCWCatalog[]) => {
            this.leukoplastService.countries.next(res);
        });
        this.leukoplastService.countryChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedCountry => {
                if (selectedCountry) {
                    this.loadItems();
                }
            });
    }

    handleResponse(items: ILeukoplastUserData[], append: boolean) {
        // Response can return pagesize + 1 number of items to indicate that next page exists.
        this.hasMoreItems = items.length > this.pageSize;
        if (this.hasMoreItems) {
            items.pop();
        }

        let newItems = append ? this.items$.value.concat(items) : items;
        this.items$.next(newItems);

        this.showQnA = newItems.some(x => x.userQuestionAnswers.length > 0);
    }

    loadItems() {
        this.filter.observable.subscribe(filter => {
            if (this.startDate) {
                this.leukoplastFulFillmentApi
                    .getItems(
                        this.leukoplastService.getActiveCountry().objectId,
                        this.jobType,
                        this.pageSize,
                        this.startDate,
                        this.endDate
                    )
                    .subscribe(items => {
                        this.handleResponse(items, false);
                    });
            }
        });
    }

    loadMoreItems() {
        if (this.startDate) {
            this.loadingItems = true;
            this.leukoplastFulFillmentApi
                .getMoreItems(
                    this.leukoplastService.getActiveCountry().objectId,
                    this.jobType,
                    this.pageSize,
                    this.items$.value.length,
                    this.startDate,
                    this.endDate
                )
                .subscribe(
                    items => {
                        this.handleResponse(items, true);
                    },
                    err => {
                        this.loadingItems = false;
                    },
                    () => {
                        this.loadingItems = false;
                    }
                );
        }
    }

    exportToCsv() {
        if (this.startDate) {
            this.leukoplastFulFillmentApi
                .getCsv(
                    this.leukoplastService.getActiveCountry().objectId,
                    this.jobType,
                    this.startDate,
                    this.endDate,
                    this.showPersonalData
                )
                .subscribe(response => {
                    let fileName =
                        this.jobType + '_' + this.startDate + '_' + this.endDate;

                    saveBlob(response.text(), fileName, 'xls');
                });
        }
    }

    toggleShowPersonalDataFlag() {
        this.showPersonalData = !this.showPersonalData;
    }

    getContactMessage(item: ILeukoplastUserData): string {
        if (!item.contact) {
            return 'No';
        }

        let message = 'Yes,';
        if (item.contactByEmail) {
            message += ' by email,';
        }
        if (item.contactByPhone) {
            message += ' by phone,';
        }
        if (item.contactByPostal) {
            message += ' by postal,';
        }
        message = message.substring(0, message.length - 1);

        return message;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.leukoplastFulFillmentApi, this.authService);
    }
}
