import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseCatalog } from '../BaseCatalog';
import { IFemcareCatalog } from '../../model/product/femcare/IFemcareCatalog';
import { FemcareCatalogApi } from '../../api/product/femcare/FemcareCatalogApi';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../sortCatalogs';

@Component({
    templateUrl: '../BaseCatalog.component.html',
    providers: [FemcareCatalogApi]
})
export class FemcareCatalogComponent extends BaseCatalog<IFemcareCatalog>
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        private femcareCatalogApi: FemcareCatalogApi,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard
    ) {
        super(elementRef, liveStageService, prettySubmitGuard, 'FemCare');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalogs - FemCare');

        this.filter.observable.subscribe(filter => {
            this.femcareCatalogApi.getAll().subscribe(catalogs => {
                this.init(sortCatalogs(catalogs));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.femcareCatalogApi, this.authService);
    }
}
