import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IGuidDomainCatalogMapping } from '../../../model/product/IGuidDomainCatalogMapping';
import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';

@Injectable()
export class DistributionHubDomainCatalogMappingApi extends BaseAuthorizedGuidCrudApi<
    IGuidDomainCatalogMapping
> {
    public getDomainByCatalogId(id: string): Observable<any> {
        return this.getJson('/catalog/' + id);
    }
    protected getBaseUrl() {
        return this.environment.api.distributionHub + 'domaincatalogmapping';
    }
}
