import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidCrudApi } from '../BaseAuthorizedGuidCrudApi';
import { IActiveDirectoryGroup } from '../../model/auth/IActiveDirectoryGroup';

@Injectable()
export class ActiveDirectoryGroupApi extends BaseAuthorizedGuidCrudApi<
    IActiveDirectoryGroup
> {
    protected getBaseUrl() {
        return this.environment.api.authentication + 'adgroup';
    }
}
