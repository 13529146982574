import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { PublishingStatisticsApi } from '../../../api/report/PublishingStatisticsApi';
import { groupBy } from 'lodash';
import { IWaitingTransaction } from '../../../model/report/publishing/IWaitingTransaction';
import { ChartColors } from '../../../model/chart/ChartColors';
import { BasePublishing } from '../BasePublishing';
import { LineChartOptions } from '../../../model/chart/BaseLineChartOptions';
import { AdminTitle } from '../../../layout/AdminTitle';

@Component({
    templateUrl: 'WaitingTransactions.component.html',
    providers: [PublishingStatisticsApi]
})
export class WaitingTransactionsComponent extends BasePublishing<IWaitingTransaction>
    implements OnInit {
    constructor(
        protected adminTitle: AdminTitle,
        protected publishingStatisticsApi: PublishingStatisticsApi
    ) {
        super(adminTitle, publishingStatisticsApi);
    }

    ngOnInit() {
        this.adminTitle.setTitle('Waiting transactions');
        this.getChartData();
    }

    getChartData() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.chartData = [];

        this.subscription = this.publishingStatisticsApi
            .getWaitingTransactions(
                this.startDate,
                this.endDate,
                this.interval,
                this.brand
            )
            .subscribe(waitingTransactions => {
                let datasets = this.prepareWaitingTransactionsDataset(
                    waitingTransactions
                );
                this.setChartOptionInterval();

                if (this.chartData.datasets) {
                    this.chartData.datasets = datasets;

                    return this.chartComponent.chart.update();
                }

                this.chartData = {
                    datasets: datasets
                };
            });
    }

    // tslint:disable:max-func-body-length
    private prepareWaitingTransactionsDataset(
        waitingTransactions: IWaitingTransaction[]
    ) {
        let transformedWaitingTransactions = groupBy(waitingTransactions, 'Brand');
        let datasets: ChartDataSets[] = [];

        Object.keys(transformedWaitingTransactions)
            .sort()
            .forEach((brand, index) => {
                let waitingTransactionsCount: any = [];
                let averageTimes: any = [];

                transformedWaitingTransactions[brand].forEach(
                    (point: IWaitingTransaction) => {
                        waitingTransactionsCount.push({
                            x: new Date(point.Date),
                            y: point.WaitingTransactions
                        });

                        averageTimes.push({
                            x: new Date(point.Date),
                            y: point.AverageTime
                        });
                    }
                );

                datasets.push({
                    ...LineChartOptions,
                    label: 'Count',
                    data: waitingTransactionsCount,
                    borderColor: ChartColors[index],
                    pointBackgroundColor: ChartColors[index]
                });

                datasets.push({
                    ...LineChartOptions,
                    label: 'Average Time (mins)',
                    data: averageTimes,
                    borderColor: ChartColors[index + 1],
                    pointBackgroundColor: ChartColors[index + 1]
                });
            });

        return datasets;
    }
    // tslint:enable
}
