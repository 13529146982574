import { Pipe, PipeTransform } from '@angular/core';
import { ISite } from '../../model/site/ISite';

@Pipe({
    name: 'platformSiteNamePipe'
})
export class SiteNamePipe implements PipeTransform {
    transform(value: ISite[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: ISite) => {
                if (item.name.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
