import { Observable } from 'rxjs';

import { BaseAuthorizedGuidApi } from './BaseAuthorizedGuidApi';
import { ICrudApi } from 'tridion.web.ui/src/app/angular/api/ICrudApi';
import { IGuidModel } from 'tridion.web.ui/src/app/angular/model/IGuidModel';

export abstract class BaseAuthorizedGuidCrudApi<T extends IGuidModel>
    extends BaseAuthorizedGuidApi<T>
    implements ICrudApi<T, string> {
    add(item: T): Observable<T> {
        return this.postJson('', item);
    }

    update(item: T): Observable<T> {
        return this.putJson('/' + item.id, item);
    }

    activate(item: T): Observable<any> {
        return this.put('/' + item.id + '/activate', undefined);
    }

    inactivate(item: T): Observable<any> {
        return this.delete('/' + item.id);
    }
}
