import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITenaCatalogIdentificationMapping } from '../../../model/product/tena/ITenaCatalogIdentificationMapping';

@Injectable()
export class TenaCatalogIdentificationMappingApi extends BaseProductApi<
    ITenaCatalogIdentificationMapping
> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscountrytoidentificationsourcemapping/tena';
    }
}
