import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';
import { IFemcareSample } from '../../../model/campaign/sample/IFemcareSample';

@Injectable()
export class FemcareSampleApi extends BaseAuthorizedGuidCrudApi<IFemcareSample> {
    protected getBaseUrl() {
        return this.environment.api.samples + 'sample';
    }
}
