import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';
import { TpwGdprDeleteComponent } from '../../gdpr/TpwGdprDelete.component';
import { Tool } from '../../model/auth/login/Tool';
import { TpwGdprBlacklistComponent } from '../../gdpr/TpwGdprBlacklist.component';
import { TpwFullfillmentSettingComponent } from '../../gdpr/TpwFullfillmentSetting.component';
import { TpwKitQuotaComponent } from '../../gdpr/TpwKitQuota.component';
import { TpwSampleOrderComponent } from '../../gdpr/TpwSampleOrder.component';
import { TpwGetSampleOrderReportComponent } from '../../gdpr/TpwGetSampleOrderReport.component';

export const TpwChildRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Sample Order',
        path: 'sampleOrder',
        component: TpwSampleOrderComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.SampleorderTPW] // TODO get requiredAccess
        }
    },
    {
        navbarName: 'Kit Quota',
        path: 'kitQuota',
        component: TpwKitQuotaComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.SampleorderTPW] // TODO get requiredAccess
        }
    },
    {
        navbarName: 'Fullfillment Setting',
        path: 'fullfillmentSetting',
        component: TpwFullfillmentSettingComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.SampleorderTPW] // TODO get requiredAccess
        }
    },
    {
        navbarName: 'Sample Order Report',
        path: 'sampleOrderReport',
        component: TpwGetSampleOrderReportComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.SampleorderTPW] // TODO get requiredAccess
        }
    },
    {
        navbarName: 'GDPR',
        path: 'gdpr',
        component: TpwGdprDeleteComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.ActivityTracking] // TODO get requiredAccess
        }
    },
    {
        navbarName: 'Blacklist',
        path: 'gdprBlacklist',
        component: TpwGdprBlacklistComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.ActivityTracking] // TODO get requiredAccess
        }
    }
];
