import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IDistributor } from '../model/distributorFinder/IDistributor';
import { ICountry } from '../model/distributorFinder/ICountry';

@Injectable()
export class DistributorService {
    selectedCountry = new Subject<ICountry>();
    mapOpen = new Subject<boolean>();
    distributor = new Subject<IDistributor>();
    distributorNewValues = new Subject<IDistributor>();
    distributors = new Subject<IDistributor[]>();
    totalCount: number;
    countries = new Subject<ICountry[]>();
    countryModalVisible = new Subject<boolean>();

    getActiveCountry() {
        let country = null;
        let sessionStorage = window.sessionStorage.getItem('selectedCountry');
        if (sessionStorage !== '' && sessionStorage !== null) {
            country = JSON.parse(sessionStorage);
        }

        return country;
    }
}
