import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../layout/AdminTitle';
import { IGuidCatalog } from '../../model/product/IGuidCatalog';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { DistributionHubCatalogApi } from '../../api/product/distributionHub/DistributionHubCatalogApi';
import { BaseDistributionHubCatalog } from './BaseDistributionHubCatalog';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { DistributionHubDomainApi } from '../../api/product/distributionHub/DistributionHubDomainApi';
import { DistributionHubDomainCatalogMappingApi } from '../../api/product/distributionHub/DistributionHubDomainCatalogMappingApi';
import { CrudMode } from 'tridion.web.ui/src/app/angular/crud/CrudMode';
import { IGuidDomainCatalogMapping } from '../../model/product/IGuidDomainCatalogMapping';
import { IGuidDistributionHubCatalogSync} from '../../model/product/distributionHub/IGuidDistributionHubCatalogSync';
import { DistributionHubCatalogEnableDisableApi } from '../../api/product/distributionHub/DistributionHubCatalogEnableDisableApi';


@Component({
    templateUrl: 'DistributionHubCatalog.component.html',
    providers: [
        DistributionHubCatalogApi,
        DistributionHubDomainApi,
        DistributionHubDomainCatalogMappingApi,
        DistributionHubCatalogEnableDisableApi
    ]
})
export class DistributionHubCatalogComponent
    extends BaseDistributionHubCatalog<IGuidCatalog>
    implements OnInit {
        enableDisableStatus: IGuidDistributionHubCatalogSync;        
        catalogData: IGuidCatalog;        
    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        
        private distributionHubCatalogApi: DistributionHubCatalogApi,
        private distributionHubDomainApi: DistributionHubDomainApi,
        private distributionHubDomainCatalogMappingApi: DistributionHubDomainCatalogMappingApi,
        private distributionHubCatalogEnableDisableApi: DistributionHubCatalogEnableDisableApi
    ) {
        super(elementRef, prettySubmitGuard, 'Distribution Hub');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalogs - Distribution Hub');        
         this.initAll(
            this.distributionHubCatalogApi.getAll(),
            this.distributionHubDomainApi.getAll(),
            this.distributionHubDomainCatalogMappingApi.getAll()
            
        );
    }

    
    public EnableDisble(crud: any,catalog: IGuidCatalog, status: boolean, type: string ) {
        
        if(status  === true){
            if(confirm('Are you sure to disable the '+ type +' ?')) {
                this.Disable(crud,catalog.id,type);
            }                                         
        }
        else {
            if(confirm('Are you sure to enable the '+ type +' ?')) {
                this.Enable(crud,catalog.id,type);
            }                       
        }    
        this.distributionHubCatalogApi.getAll().subscribe();
       
    }   

    protected UpdateCrud(crud: any,catalogId: string,type: string,value: boolean){
        
        if(crud.data.item.id === catalogId){

            crud.setMode(CrudMode.Edit);
            if(type === 'sync'){
                crud.data.item.syncEnabled=value;
            }
            if(type === 'eci'){
                crud.data.item.eciEnabled=value;
            }
            if(type === 'salsify'){
                crud.data.item.salsifyEnabled=value;
            }
            if(type === 'powerreview'){
                crud.data.item.powerReviewEnabled=value;
            }
            if(type === 'buyitnow'){
                crud.data.item.buyItNowEnabled=value;
            }           
            crud.setMode(CrudMode.Read);    
        }
    }

    protected getClass(status:boolean): string {
        let classValue: string ;

        if(status === true){
            return 'badge badge-sm badge-success';
        }
        else{
            return 'badge badge-sm badge-danger';
        }

        return classValue;
    }

    protected getLabel(status:boolean): string {
        let Label: string ;

        if(status === true){
            Label= 'Yes';
        }
        else{
            Label= 'No';
        }

        return Label;
    }
    protected saveMap(crud: any, catalog: IGuidCatalog) {
        
        const mapping = {
            catalogId: catalog.id,
            domainId: catalog.domainId
        } as IGuidDomainCatalogMapping;
       
        
        if (catalog.catalogDomainMapping) {
            mapping.id = catalog.catalogDomainMapping.id;
        }

        const resultHandler: any = (result: IGuidDomainCatalogMapping) => {
            catalog.catalogDomainMapping = result;
            catalog.domainId = result.domainId;
            catalog.domain = this.domainsById[result.domainId]
                ? this.domainsById[result.domainId].name
                : result.domainId;
            crud.setMode(CrudMode.Read);
        };

        const errorHandler: any = () => {
            crud.setMode(CrudMode.Read);
        };

        if (mapping.id && mapping.id.length) {            
            this.distributionHubDomainCatalogMappingApi
                .update(mapping)                
                .subscribe(resultHandler, errorHandler);
        } else {
            this.distributionHubDomainCatalogMappingApi
                .add(mapping)
                .subscribe(resultHandler, errorHandler);
        }
    }

    protected createCrud() {
        return new GuidCrud(this.distributionHubCatalogApi);
    }

    private Enable(crud: any,catalogId: string,type:string) {  
        if(type === 'sync'){           
        this.distributionHubCatalogEnableDisableApi
            .syncEnable(catalogId)
            .subscribe(enableSync => {   enableSync.id = catalogId; this.enableDisableStatus = enableSync; });
        }       
        if(type === 'eci'){           
            this.distributionHubCatalogEnableDisableApi
            .eciEnable(catalogId)
            .subscribe(enableSync => {  enableSync.id = catalogId; this.enableDisableStatus = enableSync; });
        }
        if(type === 'salsify'){           
            this.distributionHubCatalogEnableDisableApi
            .salsifyEnable(catalogId)
            .subscribe(enableSync => {  enableSync.id = catalogId; this.enableDisableStatus = enableSync;  });
        }
        if(type === 'powerreview'){           
            this.distributionHubCatalogEnableDisableApi
            .powerReviewEnable(catalogId)
            .subscribe(enableSync => {  enableSync.id = catalogId; this.enableDisableStatus = enableSync;  });
        }
        if(type === 'buyitnow'){           
            this.distributionHubCatalogEnableDisableApi
            .buyItNowEnable(catalogId)
            .subscribe(enableSync => { enableSync.id = catalogId;  this.enableDisableStatus = enableSync;   });
        }
        this.distributionHubCatalogApi
        .getById(catalogId)
        .subscribe(catalogdata=>{ catalogdata.id=catalogId;  this.catalogData=catalogdata;   });        
        this.UpdateCrud(crud,catalogId,type,true);
    }

    private Disable(crud: any,catalogId: string,type: string) {        

        if(type === 'sync'){
            this.distributionHubCatalogEnableDisableApi
            .syncDisable(catalogId)
            .subscribe(disableStatus => {  disableStatus.id = catalogId; this.enableDisableStatus = disableStatus; });
        }
        if(type === 'eci'){
            this.distributionHubCatalogEnableDisableApi
            .eciDisable(catalogId)
            .subscribe(disableStatus => {  disableStatus.id = catalogId; this.enableDisableStatus = disableStatus;  });
        }
        if(type === 'salsify'){
            this.distributionHubCatalogEnableDisableApi
            .salsifyDisable(catalogId)
            .subscribe(disableStatus => {  disableStatus.id = catalogId; this.enableDisableStatus = disableStatus;   });
        }
        if(type === 'powerreview'){
            this.distributionHubCatalogEnableDisableApi
            .powerReviewDisable(catalogId)
            .subscribe(disableStatus => {  disableStatus.id = catalogId; this.enableDisableStatus = disableStatus;    });
        }
        if(type === 'buyitnow'){
            this.distributionHubCatalogEnableDisableApi
            .buyItNowDisable(catalogId)
            .subscribe(disableStatus => {  disableStatus.id = catalogId; this.enableDisableStatus = disableStatus;     });
        }
        this.distributionHubCatalogApi
        .getById(catalogId)
        .subscribe(catalogdata=>{  catalogdata.id=catalogId;  this.catalogData=catalogdata; });
        this.UpdateCrud(crud,catalogId,type,false);   
    }
}
