import { Component, Input } from '@angular/core';
import { BaseAdminCrudList } from './BaseAdminCrudList';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { CrudMode } from 'tridion.web.ui/src/app/angular/crud/CrudMode';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';

@Component({
    selector: 'admin-crud-list-actions',
    templateUrl: 'CrudListActions.component.html'
})
export class CrudListActionsComponent<I, D extends ICrudData<I>> {
    @Input()
    showEditBtn? = true;
    @Input()
    showDeleteBtn? = true;
    @Input()
    item: BaseCrud<I, D>;
    @Input()
    list: BaseAdminCrudList<I, D>;
    readonly CrudMode = CrudMode;

    getDeleteTitle() {
        let title = 'Remove';

        let type = this.list.itemType;
        if (type) {
            title += ' ' + type;
        }

        return title;
    }

    getEditTitle() {
        let title = 'Edit';

        let type = this.list.itemType;
        if (type) {
            title += ' ' + type;
        }

        return title;
    }
}
