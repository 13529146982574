import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseCatalog } from '../../BaseCatalog';
import { ICutimedCatalog } from '../../../model/product/tena/cutimed/ICutimedCatalog';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../../sortCatalogs';
import { CutimedCatalogApi } from '../../../api/product/tena/cutimed/CutimedCatalogApi';

@Component({
    templateUrl: '../../BaseCatalog.component.html',
    providers: [CutimedCatalogApi]
})
export class CutimedCatalogComponent extends BaseCatalog<ICutimedCatalog> implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private cutimedCatalogApi: CutimedCatalogApi
    ) {
        super(elementRef, liveStageService, prettySubmitGuard, 'Cutimed');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalogs - Cutimed');

        this.filter.observable.subscribe(filter => {
            this.cutimedCatalogApi.getAll().subscribe(catalogs => {
                this.init(sortCatalogs(catalogs));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.cutimedCatalogApi, this.authService);
    }
}
