import { Component, OnInit } from '@angular/core';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';

@Component({
    templateUrl: 'Logout.component.html'
})
export class LogoutComponent implements OnInit {
    constructor(public authService: AuthService, private adminTitle: AdminTitle) {}

    ngOnInit() {
        this.adminTitle.setTitle('Logout');
    }
}
