import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { AskTheExpertApi } from '../../../api/expert/question/AskTheExpertApi';
import { IQuestion } from '../../../model/expert/question/IQuestion';
import { BaseAdminCrudList } from '../../../crud/list/BaseAdminCrudList';
import { ExpertAnswerCategory } from '../../../model/expert/question/ExpertAnswerCategory';
import { ExpertAnswerStatus } from '../../../model/expert/question/ExpertAnswerStatus';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { SendEmailApi } from '../../../api/expert/email/SendEmailApi';
import { Observable } from 'rxjs';
import { sortBy } from 'lodash';
import { IFulfillmentSite } from '../../../model/fulfillment/IFulfillmentSite';
import { SiteMappingApi } from '../../../api/campaign/siteMapping/SiteMappingApi';
import { map, shareReplay } from 'rxjs/operators';

@Component({
    templateUrl: './AskTheExpert.component.html',
    providers: [SiteMappingApi, AskTheExpertApi, SendEmailApi]
})
export class AskTheExpertComponent
    extends BaseAdminCrudList<IQuestion, ICrudData<IQuestion>>
    implements OnDestroy, OnInit {
    sites$: Observable<IFulfillmentSite[]>;
    selectedSite?: IFulfillmentSite;
    selectedStatus?: string;
    selectedCategory?: string;
    subscription: ISubscription;
    sendEmailUrl: string;
    hasMoreItems = false;
    pageSize = 20;
    offset = 0;
    answerCategoryType = ExpertAnswerCategory;
    answerCategoryTypeKeys = Object.keys(ExpertAnswerCategory);
    answerStatusType = ExpertAnswerStatus;
    answerStatusTypeKeys = Object.keys(ExpertAnswerStatus);
    loadingItems = false;

    constructor(
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        private questionsApi: AskTheExpertApi,
        private authService: AuthService,
        private sendEmailApi: SendEmailApi,
        protected siteMappingApi: SiteMappingApi
    ) {
        super(elementRef, prettySubmitGuard, 'Questions');
    }

    loadItems() {
        if (this.selectedSite) {
            this.hasMoreItems = false;
            this.clear();
            this.loadingItems = true;

            this.subscription = this.questionsApi
                .getItems(this.selectedSite, this.selectedStatus, this.selectedCategory, 0, this.pageSize + 1)
                .subscribe(items => {
                    // Response can return pagesize + 1 number of items to indicate that next page exists.
                    this.hasMoreItems = items.length > this.pageSize;
                    this.offset = items.length;

                    if (this.hasMoreItems) {
                        items.pop();
                        this.offset--;
                    }
                    this.init(items);
                },
                    err => { this.loadingItems = false; },
                    () => { this.loadingItems = false; });
        }
    }

    showMore() {
        this.loadingItems = true;

        this.subscription = this.questionsApi
            .getItems(this.selectedSite, this.selectedStatus, this.selectedCategory, this.offset, this.pageSize + 1)
            .subscribe(items => {
                // Response can return pagesize + 1 number of items to indicate that next page exists.
                this.hasMoreItems = items.length > this.pageSize;
                this.offset += items.length;

                if (this.hasMoreItems) {
                    items.pop();
                    this.offset--;
                }

                this.init(items);
            },
                err => { this.loadingItems = false; },
                () => { this.loadingItems = false; });
    }

    ngOnInit() {
        this.sites$ = this.siteMappingApi.getAll().pipe(
            map(sites => sortBy(sites, 'name')),
            shareReplay()
        );
    }

    isStatusEditible(crud: any) {
        return ExpertAnswerStatus[crud.data.item.status] === ExpertAnswerStatus.Unanswered;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getName() {
        return this.authService.getUserFirstName();
    }

    onSubmit(crud: any) {
        let subscription = crud.onSave.subscribe(() => {
            if (
                (!crud.data.item.answeredEmailStatus ||
                    !crud.savedData.item.answeredEmailStatus) &&
                crud.isSaved() && crud.savedData.item.status === ExpertAnswerStatus.Published
            ) {
                this.generateUrl(crud.savedData.item.publishUrl);
                this.sendEmail(this.sendEmailUrl, crud);
            }

            subscription.unsubscribe();
        });
    }

    generateUrl(publishUrl: string) {
        var index = publishUrl.indexOf('/', 10);
        this.sendEmailUrl =
            publishUrl.substring(0, index) + '/asktheexpertpublishedquestionemail/';
    }

    sendEmail(url: string, crud: any) {
        this.sendEmailApi.sendEmail(url, crud.data.item).subscribe(res => {
            if (res.json().success) {
                crud.data.item.answeredEmailStatus = true;
                crud.save();
            }
        });
    }

    protected createCrud() {
        return new GuidCrud(this.questionsApi);
    }
}
