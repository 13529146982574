import { groupBy, map, mapValues } from 'lodash';
import * as moment from 'moment';

import { ISiteByDate } from '../model/monitor/ISiteByDate';
import { ISiteOnDate } from '../model/monitor/ISiteOnDate';
import toIsoDate from '../date/toIsoDate';

export default function groupBySiteAndDate<D extends ISiteOnDate>(
    onDates: D[],
    startDate: string,
    endDate: string
) {
    var bySite: ISiteByDate<D>[];

    if (onDates) {
        onDates.forEach(onDate => {
            onDate.date = toIsoDate(onDate.date);
        });

        onDates = onDates.filter(onDate => {
            var date = moment(onDate.date);

            return date.isSameOrAfter(startDate) && date.isSameOrBefore(endDate);
        });

        bySite = map(groupBy(onDates, 'site'), site => {
            return {
                site: site[0].site,
                byDate: mapValues(groupBy(site, 'date'), onDate => onDate[0])
            };
        });
    }

    return bySite;
}
