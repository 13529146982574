
import {takeUntil} from 'rxjs/operators';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DistributorCountriesApi } from '../../api/distributorFinder/DistributorCountriesApi';
import { CountriesService } from '../../distributorFinder/Countries.service';
import { ICountry } from '../../model/distributorFinder/ICountry';
import { AppConstants } from '../../constants/constants';

@Component({
    selector: 'admin-country-modal-edit',
    templateUrl: 'CountryModalEdit.component.html'
})
export class CountryModalEditComponent implements OnInit, OnDestroy {
    @Input()
    page: number;
    @Input()
    limit: number;
    response: string;
    closeResult: string;
    showModal = false;
    mapVisible: boolean;
    country: ICountry;
    countryNewValues: ICountry;
    countryForm: FormGroup;
    wholeCountry = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private distributorCountriesApi: DistributorCountriesApi,
        private countriesService: CountriesService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.countriesService.country.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((country: ICountry) => {
                this.country = country;
            });

        this.countriesService.countryNewValues.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((country: any) => {
                this.countryNewValues = country;
                if (country) {
                    if (!this.countryForm) {
                        this.buildForm();
                    } else {
                        for (let item in this.countryForm.value) {
                            if (
                                country[item] !== this.countryForm.value[item] &&
                                country[item]
                            ) {
                                this.countryForm.controls[item].setValue(country[item]);
                            }
                        }
                    }
                    this.toggleEditModal(true);
                }
            });
    }

    buildForm() {
        let webSiteRegexPattern = new RegExp(AppConstants.webSiteUrlPattern);
        this.countryForm = this.fb.group({
            name: new FormControl(this.countryNewValues.name, Validators.required),
            websiteName: new FormControl(
                this.countryNewValues.websiteName,
                Validators.pattern(webSiteRegexPattern)
            ),
            additionalCountries: new FormControl(
                this.countryNewValues.additionalCountries
            ),
            fusionTableName: new FormControl(this.countryNewValues.fusionTableName),
            code: new FormControl(this.countryNewValues.code, Validators.required),
            authorizationPath: new FormControl(
                this.countryNewValues.authorizationPath,
                Validators.required
            ),
            rowVersion: new FormControl(this.countryNewValues.rowVersion),
            isInactive: new FormControl(this.countryNewValues.isInactive)
        });
    }

    saveCountry() {
        if (this.countryForm.invalid) {
            return;
        }

        Object.assign(this.countryNewValues, this.countryForm.value);
        this.distributorCountriesApi
            .update(this.countryNewValues).pipe(
            takeUntil(this.unsubscribe))
            .subscribe(
                (res: ICountry) => {
                    this.countriesService.countryNewValues.next(null);
                    this.countriesService.country.next(undefined);
                    this.distributorCountriesApi
                        .getCountries(this.limit, this.page).pipe(
                        takeUntil(this.unsubscribe))
                        .subscribe(response => {
                            this.countriesService.totalCount = response.totalCount;
                            this.countriesService.countries.next(response.countries);
                        });
                    this.response = 'Country changed!';

                    setTimeout(() => {
                        this.toggleEditModal(false);
                    }, 2500);
                },
                error => {
                    this.response = error.statusText;
                }
            );
    }

    addCountry() {
        if (this.countryForm.invalid) {
            return;
        }

        Object.assign(this.countryNewValues, this.countryForm.value);

        this.distributorCountriesApi
            .add(this.countryNewValues)
            .subscribe((res: ICountry) => {
                this.distributorCountriesApi
                    .getCountries(this.limit, this.page).pipe(
                    takeUntil(this.unsubscribe))
                    .subscribe(response => {
                        this.countriesService.totalCount = response.totalCount;
                        this.countriesService.countries.next(response.countries);
                    });
                this.countriesService.countryNewValues.next(null);
                this.response = 'Country added!';

                setTimeout(() => {
                    this.toggleEditModal(false);
                }, 2500);
            });
    }

    resetForm() {
        this.countryForm.reset(this.country);
    }

    toggleEditModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            this.response = '';
            this.countriesService.country.next(undefined);
            this.countriesService.countryNewValues.next(null);

            this.countryForm.reset();

            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }

        this.showModal = show;
    }

    @HostListener('click', ['$event.target.className'])
    onClick(elementClass: string) {
        if (elementClass === 'modal') {
            this.toggleEditModal(false);
        }
    }
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
