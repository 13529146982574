import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';
import { IGuidCatalogSolrcoreMapping } from '../../../model/product/IGuidCatalogSolrcoreMapping';

@Injectable()
export class DistributionHubCatalogSolrcoreMappingApi extends BaseAuthorizedGuidCrudApi<
    IGuidCatalogSolrcoreMapping
> {
    protected getBaseUrl() {
        return this.environment.api.distributionHub + 'catalogsolrcoremapping';
    }
}
