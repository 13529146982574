import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseCatalog } from '../../BaseCatalog';
import { ILeukoplastCatalog } from '../../../model/product/tena/leukoplast/ILeukoplastCatalog';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../../sortCatalogs';
import { LeukoplastCatalogApi } from '../../../api/product/tena/leukoplast/LeukoplastCatalogApi';

@Component({
    templateUrl: '../../BaseCatalog.component.html',
    providers: [LeukoplastCatalogApi]
})
export class LeukoplastCatalogComponent extends BaseCatalog<ILeukoplastCatalog> implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private leukoplastCatalogApi: LeukoplastCatalogApi
    ) {
        super(elementRef, liveStageService, prettySubmitGuard, 'Leukoplast');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalogs - Leukoplast');

        this.filter.observable.subscribe(filter => {
            this.leukoplastCatalogApi.getAll().subscribe(catalogs => {
                this.init(sortCatalogs(catalogs));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.leukoplastCatalogApi, this.authService);
    }
}
