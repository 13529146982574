
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { JobstService } from './Jobst.service';
import { IJobstSegment } from '../../../model/product/tena/jobst/IJobstSegment';
import { IJobstWebShopCatalogApi } from '../../../model/product/tena/jobst/IJobstWebShopCatalogApi';

@Component({
    selector: 'admin-jobst-country-segment-menu',
    templateUrl: 'JobstCountrySegmentMenu.component.html'
})
export class JobstCountrySegmentMenuComponent implements OnInit, OnDestroy {
    countryModalVisible: boolean;
    segmentModalVisible: boolean;
    country: IJobstWebShopCatalogApi;
    segment: IJobstSegment;

    @Input()
    useSegment = true;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private jobstService: JobstService) {}

    ngOnInit() {
        // First show country popup and then segment popup.
        this.initializeCountryModal();
    }

    initializeCountryModal(): void {
        this.jobstService.countryModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.countryModalVisible = visible;
            });

        this.jobstService.countryChanged.pipe(takeUntil(this.unsubscribe)).subscribe(country => {
            if (country) {
                this.country = country;

                if (this.useSegment) {
                    // Now show segment popup
                    this.initializeSegmentModal();
                }
            }
        });

        let sessionStorageCountry = this.jobstService.getActiveCountry();
        if (sessionStorageCountry) {
            this.jobstService.countryChanged.next(sessionStorageCountry);
            this.jobstService.countryModalVisible.next(false);
        } else {
            this.jobstService.countryModalVisible.next(true);
        }
    }

    initializeSegmentModal(): void {
        this.jobstService.segmentModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.segmentModalVisible = visible;
            });

        this.jobstService.segmentChanged.pipe(takeUntil(this.unsubscribe)).subscribe(segment => {
            if (segment) {
                this.segment = segment;
            }
        });

        let sessionStorageSegment = this.jobstService.getActiveSegment();
        if (sessionStorageSegment) {
            this.jobstService.segmentChanged.next(sessionStorageSegment);
            this.jobstService.segmentModalVisible.next(false);
        } else {
            this.jobstService.segmentModalVisible.next(true);
        }
    }

    selectSegment() {
        this.jobstService.segmentModalVisible.next(true);
    }

    selectCountry() {
        this.jobstService.countryModalVisible.next(true);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
