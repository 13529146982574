import { Component, ElementRef, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { keyBy, sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import getCatalogName from '../getCatalogName';
import { ICatalog } from '../../model/product/ICatalog';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ITenaSiteMap } from '../../model/product/tena/ITenaSiteMap';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../sortCatalogs';
import { TenaCatalogApi } from '../../api/product/tena/TenaCatalogApi';
import { TenaSiteMapApi } from '../../api/product/tena/TenaSiteMapApi';

@Component({
    templateUrl: 'TenaSiteMap.component.html',
    providers: [TenaCatalogApi, TenaSiteMapApi]
})
export class TenaSiteMapComponent
    extends BaseLiveStageCrudList<
        ITenaSiteMap,
        ICrudData<ITenaSiteMap>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    catalogs: ICatalog[];
    catalogsById: { [id: number]: ICatalog };
    readonly getCatalogName = getCatalogName;

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        private femcareCatalogApi: TenaCatalogApi,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tenaSiteMapApi: TenaSiteMapApi
    ) {
        super(elementRef, 'product', liveStageService, prettySubmitGuard, 'Site Map');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Site Maps - Tena');

        this.filter.observable.subscribe(filter => {
            forkJoin(
                this.tenaSiteMapApi.getAll(),
                this.femcareCatalogApi.getAll()
            ).subscribe(results => {
                var siteMaps = results[0],
                    catalogs = results[1];

                this.init(sortBy(siteMaps, 'physicalPath'));

                this.catalogs = sortCatalogs(catalogs);
                this.catalogsById = keyBy(catalogs, 'objectId');
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tenaSiteMapApi, this.authService);
    }
}
