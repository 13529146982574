
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TpwService } from '../Tpw.service';
import { ITpwCountry } from '../../model/product/tena/tpw/ITpwCountry';
import { ITpwChannel } from '../../model/product/tena/tpw/ITpwChannel';

@Component({
    selector: 'admin-tpw-kitquota-menu',
    templateUrl: 'TpwKitQuotaMenu.component.html'
})
export class TpwKitQuotaMenuComponent implements OnDestroy {
    countryModalVisible: boolean;
    channelModalVisible: boolean;

    country: ITpwCountry;
    channel: ITpwChannel;

    @Input()
    useCountry = true;

    @Input()
    useChannel = true;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private tpwService: TpwService) {}

    initializeCountryModal(): void {
        this.tpwService.countryModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.countryModalVisible = visible;
            });

        this.tpwService.countryChanged.pipe(takeUntil(this.unsubscribe)).subscribe(country => {
            if (country) {
                this.country = country;
                if (this.useChannel) {
                    // Now show channel popup
                    this.initializeChannelModal();
                }
            }
        });

        let sessionStorageCountry = this.tpwService.getActiveCountry();
        if (sessionStorageCountry) {
            this.tpwService.countryChanged.next(sessionStorageCountry);
            this.tpwService.countryModalVisible.next(false);
        } else {
            this.tpwService.countryModalVisible.next(true);
        }
    }

    initializeChannelModal(): void {
        this.tpwService.channelModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.channelModalVisible = visible;
            });

        this.tpwService.channelChanged.pipe(takeUntil(this.unsubscribe)).subscribe(channel => {
            if (channel) {
                this.channel = channel;
            }
        });

        let sessionStorageChannel = this.tpwService.getActiveChannel();
        if (sessionStorageChannel) {
            this.tpwService.channelChanged.next(sessionStorageChannel);
            this.tpwService.channelModalVisible.next(false);
        } else {
            this.tpwService.channelModalVisible.next(true);
        }
    }

    selectCountry() {
        this.tpwService.countryModalVisible.next(true);
    }

    selectChannel() {
        this.tpwService.channelModalVisible.next(true);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
