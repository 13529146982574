import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { ITpwGDPR } from '../../../../model/product/tena/tpw/ITpwGDPR';
import { BaseAuthorizedObjectIdApi } from '../../BaseAuthorizedObjectIdApi';

@Injectable()
export class TpwGDPRApi extends BaseAuthorizedObjectIdApi<ITpwGDPR> {
    getUserData(emails: string[]): Observable<ITpwGDPR[]> {
        return this.getJson(`/GetGdprDataByEmail/email/${emails.join(';')}`);
    }

    deleteEmails(emails: string[]): Observable<Response> {
        return this.delete(`/Delete/email/${emails.join(';')}/`);
    }

    getCsv(emails: string[]): Observable<Response> {
        return this.get(`/GetOrSendExcelData?email=${emails.join(';')}`);
    }

    protected getBaseUrl() {
        return this.environment.api.submitted + 'Tracking/Gdpr';
    }
}
