import { Http, Response } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { TenaService } from '../../../../product/tena/Tena.service';
import { BaseTCWCatalogApi } from './BaseTCWCatalogApi';
import { ITenaUserData } from '../../../../model/product/tena/tcw/ITenaUserData';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { TenaTCWCatalogApi } from './TenaTCWCatalogApi';
import { ITenaTCWCatalog } from '../../../../model/product/tena/tcw/ITenaTCWCatalog';

@Injectable()
export class TenaLegacyIdExportApi extends BaseTCWCatalogApi<ITenaUserData> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        tenaService: TenaService,
        readonly tenaService1: TenaService,
        liveStageService: LiveStageService,
        readonly tenaCatalogApi: TenaTCWCatalogApi
    ) {
        super(apiErrorHandler, authService, environment, http, tenaService, liveStageService);
    }

    getItems(
        countryId: number,
    ): Observable<ITenaUserData[]> {
        return this.getJson(`exportlegacymappings/${countryId}`);
    }

    getCsv(
        countryId: number,
    ): Observable<Response> {
        return this.get(`exportlegacymappings/${countryId}?excel=true`);
    }

    protected getBaseUrl() {
        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
            this.tenaService1.countries.next(res);
        });

        return this.TCWBaseURl;
    }
}
