import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { ICutimedCatalogIdentificationMapping } from '../../../../model/product/tena/cutimed/ICutimedCatalogIdentificationMapping';

@Injectable()
export class CutimedCatalogIdentificationMappingApi extends BaseProductApi<
ICutimedCatalogIdentificationMapping
> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscountrytoidentificationsourcemapping/cutimed';
    }
}
