import { ElementRef } from '@angular/core';

import { BaseLiveStageCrudList } from '../crud/list/BaseLiveStageCrudList';
import { Country } from '../model/product/Country';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ICatalog } from '../model/product/ICatalog';
import { LiveStage } from '../environment/LiveStage';
import { LiveStageService } from '../environment/LiveStage.service';
import { Locale, LocaleDescription } from '../model/product/Locale';
import { MetricSystem, MetricSystemDescription } from '../model/product/MetricSystem';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';

export abstract class BaseCatalog<I extends ICatalog> extends BaseLiveStageCrudList<
    I,
    ICrudData<I>,
    { liveStage: LiveStage }
> {
    readonly countryOptions = Object.keys(Country);
    readonly LocaleDescription = LocaleDescription;
    readonly localeOptions = Object.keys(Locale);
    readonly MetricSystemDescription = MetricSystemDescription;
    readonly metricSystemOptions = Object.keys(MetricSystem);

    isHMS = ['Tena', 'Leukoplast', 'Cutimed', 'Actimove', 'Jobst'].includes(this.brand);

    constructor(
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        public readonly brand: string
    ) {
        super(elementRef, 'product', liveStageService, prettySubmitGuard, 'Catalog');
    }

    protected createItem() {
        return {
            brand: this.brand
        } as I;
    }
}
