import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITenaSegment } from '../../../model/product/tena/ITenaSegment';

@Injectable()
export class TenaSegmentApi extends BaseProductApi<ITenaSegment> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmssegment/tena';
    }
}
