import { Component, ElementRef, OnInit } from '@angular/core';
import { keyBy, sortBy } from 'lodash';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import addTrailingSlash from 'tridion.web.ui/src/app/url/addTrailingSlash';
import { AdminTitle } from '../../layout/AdminTitle';
import CrudListFilter from '../../crud/list/CrudListFilter';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { IRedirect } from '../../model/site/redirect/IRedirect';
import { ISite } from '../../model/site/ISite';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { RedirectApi } from '../../api/redirect/RedirectApi';
import { RedirectType } from '../../model/site/redirect/RedirectType';
import { ShowMoreAdminCrudList } from '../../crud/list/ShowMoreAdminCrudList';
import { SiteApi } from '../../api/auth/SiteApi';
import splitUrlForOverflow from '../../url/splitUrlForOverflow';
import * as moment from 'moment';

@Component({
    templateUrl: 'Redirects.component.html',
    providers: [RedirectApi, SiteApi]
})
export class RedirectsComponent
    extends ShowMoreAdminCrudList<IRedirect, ICrudData<IRedirect>>
    implements OnInit {
    private static MaxExpirationMonths = 12;
    sites: Observable<ISite[]>;
    selectedSiteId: string;
    pendingRequests: any;
    readonly maxExpiration = RedirectsComponent.getMaxExpiration(new Date());
    readonly redirectTypeOptions = Object.keys(RedirectType);
    readonly splitUrlForOverflow = splitUrlForOverflow;  
    private readonly filter = new CrudListFilter<{ siteId: string }>(this);
    private sitesById: Observable<{ [id: number]: ISite }>;

    static getMaxExpiration(today: Date) {      

        return ExpiryDate();
    }

    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        private redirectApi: RedirectApi,
        private siteApi: SiteApi
    ) {
        super(elementRef, prettySubmitGuard, 'Redirect');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Redirects');

        this.sites = this.siteApi
            .getAll().pipe(
            map(sites => sortBy(sites, 'name')),
            shareReplay(),);
        this.sitesById = this.sites.pipe(map(sites => keyBy(sites, 'id')),shareReplay(),);

        this.filter.observable.subscribe(filter => {
            if (this.pendingRequests) {
                this.pendingRequests.unsubscribe();
            }

            let siteId = filter.siteId;
            
            if (siteId) {
                this.pendingRequests = this.redirectApi
                    .getRedirectsBySite(siteId)
                    .subscribe(redirects => {
                        redirects.forEach(redirect => {
                            var expirationDate = redirect.expirationDate;                                                 
                            redirect.siteId = siteId;
                        });

                        this.init(sortBy(redirects, 'expirationDate'));
                    });
            }
        });
    }

    getBaseUrl(siteId: number) {
        return this.sitesById.pipe(map(sitesById => {
            var site = sitesById && sitesById[siteId],
                domainUrl = site && site.domainUrl;

            return addTrailingSlash(domainUrl);
        }));
    }

    updateFilter() {
        var filter = this.filter;

        if (!filter.setFilter({ siteId: this.selectedSiteId })) {
            setTimeout(() => {
                this.selectedSiteId = filter.getFilter().siteId;
            }, 0);
        }
    }

    protected createCrud() {
        return new GuidCrud(this.redirectApi);
    }

    protected createItem() {
        return {
            type: RedirectType.Permanent,
            expirationDate:  ExpiryDate(),
            siteId: this.selectedSiteId
        } as IRedirect;
    }    
}

function ExpiryDate() {
    var expiryDate: string;
        var date=new Date();
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        var newDate = new Date(year + 5, month, day);
        expiryDate = moment(newDate).format('YYYY-MM-DD');
    
        return expiryDate;
}

