
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BaseLiveStageCrudList } from '../crud/list/BaseLiveStageCrudList';
import { Subject } from 'rxjs';
import { LiveStage } from '../environment/LiveStage';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { AdminTitle } from '../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { LiveStageService } from '../environment/LiveStage.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ITpwChannel } from '../model/product/tena/tpw/ITpwChannel';
import { ObjectIdCrud } from '../crud/ObjectIdCrud';
import { TpwService } from './Tpw.service';
import { ITpwTier } from '../model/product/tena/tpw/ITpwTier';
import { TpwProductFamilyApi } from '../api/product/tena/tpw/TpwProductFamilyApi';
import { ITpwProductFamily } from '../model/product/tena/tpw/ITpwProductFamily';
import { TpwSampleOrderApi } from '../api/product/tena/tpw/TpwSampleOrderApi';
import { ITpwSampleOrder } from '../model/product/tena/tpw/ITpwSampleOrder';
import { TpwTierApi } from '../api/product/tena/tpw/TpwTierApi';
import { CrudMode } from 'tridion.web.ui/src/app/angular/crud/CrudMode';

@Component({
    templateUrl: 'TpwSampleOrder.component.html',
    providers: [TpwSampleOrderApi, TpwProductFamilyApi, TpwTierApi]
})
export class TpwSampleOrderComponent
    extends BaseLiveStageCrudList<
        ITpwSampleOrder,
        ICrudData<ITpwSampleOrder>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    channels: ITpwChannel[];
    tiers: ITpwTier[];
    sortingOrderOptions: number[];
    productFamilies: ITpwProductFamily[];
    disabled = false;
    Isdeleteflag = false;
    isIconvisible = false;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tpwSampleOrderApi: TpwSampleOrderApi,
        private tpwProductFamilyApi: TpwProductFamilyApi,
        private tpwTierApi: TpwTierApi,
        private tpwService: TpwService
    ) {
        super(
            elementRef,
            'sampleorder',
            liveStageService,
            prettySubmitGuard,
            'Sample Order'
        );
    }

    ngOnInit() {
        this.adminTitle.setTitle('Sample Order - Tpw');

        this.tpwTierApi.getAll().subscribe((res: ITpwTier[]) => {
            this.tpwService.tiers.next(res);
            this.tiers = res;
        });

        this.tpwService.countryChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedCountry => {
                if (selectedCountry) {
                    this.clear();
                    this.getProductFamily();
                }
            });

        this.tpwService.channelChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedChannel => {
                if (selectedChannel) {
                    this.clear();
                    this.getProductFamily();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    getProductFamily() {
        this.filter.observable.subscribe(() => {
            this.tpwProductFamilyApi
                .getProductFamily(
                    this.tpwService.getActiveCountry().catalogCode,
                    this.tpwService.getActiveCountry().locale
                )
                .subscribe(productFamilies => {
                    this.setProductFamilies(productFamilies);
                });
        });
    }

    setProductFamilies(productFamilies: ITpwProductFamily[]) {
        this.productFamilies = productFamilies;

        if (productFamilies.length > 0) {
            this.getSampleOrder(productFamilies[0].categoryCode);
        } else {
            this.init([]);
        }
    }

    getSampleOrder(categoryCode: string) {
        let country = this.tpwService.getActiveCountry();
        let channels = this.tpwService.getActiveChannel();
        if (categoryCode !== null && country !== null && channels !== null) {
            this.tpwSampleOrderApi
                .getsampleOrder(
                    categoryCode,
                    country.catalogCode,
                    country.locale,
                    country.countryId,
                    channels.channelId,
                    0
                )
                .subscribe(sampleOrder => {
                    this.clear();
                    this.init(sampleOrder);
                });
        }
    }

    get isActionCodeNeeded() {
        let sample = this.tpwService.getActiveSample();

        return sample;
    }

    public EnableDisble(crud: any, type: string) {
        this.isIconvisible = true;
        if (type === 'Yes') {
            if (confirm('Are you sure you want to make this product unsampleable?')) {
                this.Disable(crud, type);
                this.getProductFamily();
            }
        } else {
            alert(
                'To make this product sampleable add ("Package Code"," Order Quantity","Quota Monthly","Quota Yearly")'
            );
            this.isIconvisible = false;
        }
    }

    public onButtonClick(crud: any) {
        this.isIconvisible = true;
        if (crud !== null) {
            this.onSave(crud);
        }
        this.isIconvisible = false;
        this.getProductFamily();
    }

    public onCancel(crud: any) {
        crud.setMode(CrudMode.Read);
    }

    getEditClass(status: string) {
        let classValue: string;
        if (status === 'Yes') {
            classValue = 'btn btn-success';
        } else {
            classValue = 'btn btn-primary';
        }

        return classValue;
    }

    getEdittext(status: string) {
        let buttonText: string;
        if (status === 'Yes') {
            buttonText = 'Saved';
        } else {
            buttonText = 'Save';
        }

        return buttonText;
    }

    protected getClass(status: string): string {
        let classValue: string;

        if (status === 'Yes') {
            return 'badge badge-sm badge-success';
        } else {
            return 'badge badge-sm badge-danger';
        }

        return classValue;
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tpwSampleOrderApi, this.authService);
    }

    // Product is Sampleable
    private Disable(crud: any, type: string) {
        if (type === 'Yes') {
            let item = crud.data.item;

            this.tpwSampleOrderApi.inactivate(item).subscribe(
                res => {
                    crud = res;
                },
                err => {
                    alert(
                        'The product you are trying to unsample is a part of Kit ' +
                            err._body +
                            ', please delete the Kit before unsampling this product.'
                    );
                }
            );
        }
        this.isIconvisible = false;
        crud.setMode(CrudMode.Edit);
        crud.setMode(CrudMode.Read);
    }

    // Edit Sample order
    private onSave(crud: any) {
        let item = crud.data.item;

        this.tpwSampleOrderApi.add(item).subscribe(res => {
            crud = res;
        });
        crud.setMode(CrudMode.Edit);
        this.getEditClass('Yes');
        this.getEdittext('Yes');
        crud.setMode(CrudMode.Read);
    }
}
