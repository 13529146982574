import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { BaseFulfillmentComponent } from '../../../fulfillment/BaseFulfillment.component';
import { ISampleRequest } from '../../../model/campaign/sample/ISampleRequest';
import { SamplesApi } from '../../../api/campaign/sample/fulfillment/SamplesApi';
import { SiteMappingApi } from '../../../api/campaign/siteMapping/SiteMappingApi';
import { FulfillmentAuthApi } from '../../../api/fulfillment/FulfillmentAuthApi';

@Component({
    templateUrl: '../../../fulfillment/BaseFulfillment.component.html',
    providers: [SamplesApi, SiteMappingApi, FulfillmentAuthApi]
})
export class SamplesComponent extends BaseFulfillmentComponent<ISampleRequest> {
    static readonly componentTitle: string = 'Fulfillment';
    static readonly itemTitle: string = 'sample request';
    static readonly pageSize: number = 20;

    constructor(
        protected samplesApi: SamplesApi,
        protected route: ActivatedRoute,
        protected adminTitle: AdminTitle,
        protected fulfillmentAuthApi: FulfillmentAuthApi,
        protected siteMappingApi: SiteMappingApi
    ) {
        super(
            samplesApi,
            route,
            adminTitle,
            fulfillmentAuthApi,
            siteMappingApi,
            {
                title: SamplesComponent.componentTitle,
                itemTitle: SamplesComponent.itemTitle
            },
            SamplesComponent.pageSize
        );
    }
}
