import * as moment from 'moment';
import { Moment } from 'moment';
import { isNumber, isString } from 'lodash';

import { PrettyDatePipe } from 'tridion.web.ui/src/app/angular/pipe/PrettyDate.pipe';

/**
 * Get a `date` in `YYYY-MM-DD` ([ISO 8601](https://en.wikipedia.org/wiki/ISO_8601))
 * format, using the user’s time zone.
 *
 * @param date
 */
export default function toIsoDate(date: Date | Moment | number | string) {
    var isoDate: string;

    if (date) {
        if (isNumber(date)) {
            date = new Date(date);
        }

        if (isString(date) && date.length === PrettyDatePipe.IsoDateLength) {
            isoDate = date;
        } else {
            isoDate = moment(date).format('YYYY-MM-DD');
        }
    }

    return isoDate;
}
