import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ICatalogSiteMapMapping } from '../../../model/product/tork/ICatalogSiteMapMapping';

@Injectable()
export class TorkCatalogSiteMapMappingApi extends BaseProductApi<ICatalogSiteMapMapping> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'tork/catalogsitemapmapping';
    }
}
