
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { TenaService } from '../../Tena.service';
import { TenaTCWCatalogApi } from '../../../../api/product/tena/tcw/TenaTCWCatalogApi';
import { ITenaTCWCatalog } from '../../../../model/product/tena/tcw/ITenaTCWCatalog';

@Component({
    selector: 'admin-tena-country-select',
    templateUrl: 'TenaCountrySelect.component.html',
    providers: [TenaTCWCatalogApi]
})
export class TenaCountrySelectComponent implements OnInit, OnDestroy {
    countryForm: FormGroup;
    countries: ITenaTCWCatalog[];

    @Input()
    selectedCountry: ITenaTCWCatalog;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public tenaService: TenaService,
        private tenaCatalogApi: TenaTCWCatalogApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.tenaService.countries.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((countries: ITenaTCWCatalog[]) => {
                this.countries = countries;
            });

        this.countryForm = this.fb.group({
            country: new FormControl(
                this.selectedCountry ? this.selectedCountry.objectId.toString() : '',
                Validators.required
            )
        });

        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
            this.tenaService.countries.next(res);
        });

        this.tenaService.countryModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleCountryModal(visible);
            });

        this.toggleCountryModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectCountry(country: ITenaTCWCatalog) {
        if (this.countryForm.valid) {
            country = this.countries.find((item: ITenaTCWCatalog) => {
                if (item.objectId === parseInt(this.countryForm.value.country)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('tenaSelectedCountry', JSON.stringify(country));
            this.tenaService.countryChanged.next(country);

            this.tenaService.countryModalVisible.next(false);
        }
    }

    toggleCountryModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
