
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

import 'date-input-polyfill';
import { ITenaUserData } from '../../model/product/tena/tcw/ITenaUserData';
import { TenaLegacyIdExportApi } from '../../api/product/tena/tcw/TenaLegacyIdExportApi';
import { AdminTitle } from '../../layout/AdminTitle';
import { TenaService } from './Tena.service';
import saveBlob from '../../api/fileManager/saveBlob';
import { LiveStageService } from '../../environment/LiveStage.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../environment/LiveStage';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { TenaTCWCatalogApi } from '../../api/product/tena/tcw/TenaTCWCatalogApi';
import { ITenaTCWCatalog } from '../../model/product/tena/tcw/ITenaTCWCatalog';

@Component({
    templateUrl: 'TenaLegacyIDexport.component.html',
    providers: [TenaLegacyIdExportApi,TenaTCWCatalogApi]
})
export class TenaLegacyIdExportComponent
    extends BaseLiveStageCrudList<
        ITenaUserData,
        ICrudData<ITenaUserData>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    items$: BehaviorSubject<ITenaUserData[]> = new BehaviorSubject<ITenaUserData[]>(null);
    item: ITenaUserData;
    pageSize = 20;
    hasMoreItems = false;
    showQnA = false;
    countries: ITenaTCWCatalog[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        readonly tenaLegacyIdExportApi: TenaLegacyIdExportApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        liveStageService: LiveStageService,
        readonly authService: AuthService,
        readonly tenaCatalogApi: TenaTCWCatalogApi,
        private tenaService: TenaService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'Legacy ID export');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Legacy ID export');

        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
            this.tenaService.countries.next(res);
        });
        this.tenaService.countryChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedCountry => {
                if (selectedCountry) {
                    this.loadItems();
                }
            });
    }

    handleResponse(items: ITenaUserData[], append: boolean) {
        // Response can return pagesize + 1 number of items to indicate that next page exists.
        this.hasMoreItems = items.length > this.pageSize;
        if (this.hasMoreItems) {
            items.pop();
        }

        let newItems = append ? this.items$.value.concat(items) : items;
        this.items$.next(newItems);

        this.showQnA = newItems.some(x => x.userQuestionAnswers.length > 0);
    }

    loadItems() {
        this.filter.observable.subscribe(filter => {
            this.tenaLegacyIdExportApi
                .getItems(
                    this.tenaService.getActiveCountry().objectId
                )
                .subscribe(items => {
                    this.handleResponse(items, false);
                });
        });
    }

    exportToCsv() {
        this.tenaLegacyIdExportApi
            .getCsv(
                this.tenaService.getActiveCountry().objectId,
            )
            .subscribe(response => {
                let fileName = 'TCW - Legacy ID';

                saveBlob(response.text(), fileName, 'xls');
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tenaLegacyIdExportApi, this.authService);
    }
}
