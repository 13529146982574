import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ICutimedSegment } from '../../../model/product/tena/cutimed/ICutimedSegment';
import { CutimedService } from './Cutimed.service';
import { CutimedUserSegmentApi } from '../../../api/product/tena/cutimed/CutimedUserSegmentApi';

@Component({
    selector: 'admin-cutimed-segment-select',
    templateUrl: 'CutimedSegmentSelect.component.html',
    providers: [CutimedUserSegmentApi]
})
export class CutimedSegmentSelectComponent implements OnInit, OnDestroy {
    segmentForm: FormGroup;
    segments: ICutimedSegment[];

    @Input()
    selectedSegment: ICutimedSegment;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public cutimedService: CutimedService,
        private cutimedSegmentApi: CutimedUserSegmentApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.cutimedService.segments.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((segments: ICutimedSegment[]) => {
                this.segments = segments;
            });

        this.segmentForm = this.fb.group({
            segment: new FormControl(
                this.selectedSegment ? this.selectedSegment.objectId.toString() : '',
                Validators.required
            )
        });

        this.cutimedSegmentApi.getAll().subscribe((res: ICutimedSegment[]) => {
            let CutimedSegment = res.filter(segment => segment.isCutimedSegment);
            this.cutimedService.segments.next(CutimedSegment);
        });

        this.cutimedService.segmentModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleSegmentModal(visible);
            });

        this.toggleSegmentModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectSegment(segment: ICutimedSegment) {
        if (this.segmentForm.valid) {
            segment = this.segments.find((item: ICutimedSegment) => {
                if (item.objectId === parseInt(this.segmentForm.value.segment)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('cutimedSelectedSegment', JSON.stringify(segment));
            this.cutimedService.segmentChanged.next(segment);

            this.cutimedService.segmentModalVisible.next(false);
        }
    }

    toggleSegmentModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
