import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidCrudApi } from '../BaseAuthorizedGuidCrudApi';
import { IAuthEntity } from '../../model/auth/IAuthEntity';

@Injectable()
export class AuthEntityApi extends BaseAuthorizedGuidCrudApi<IAuthEntity> {
    protected getBaseUrl() {
        return this.environment.api.authentication + 'authorizationentity';
    }
}
