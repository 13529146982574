import { ElementRef } from '@angular/core';

import { BaseCrudList } from 'tridion.web.ui/src/app/angular/crud/list/BaseCrudList';
import { CrudMode } from 'tridion.web.ui/src/app/angular/crud/CrudMode';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';

export abstract class BaseAdminCrudList<I, D extends ICrudData<I>> extends BaseCrudList<
    I,
    D
> {
    readonly CrudMode = CrudMode;

    constructor(
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        public readonly itemType: string,
        public readonly itemTypeLong = itemType
    ) {
        super(elementRef, prettySubmitGuard);
    }
}
