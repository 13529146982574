import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidApi } from '../../BaseAuthorizedGuidApi';
import { IDashboard } from '../../../model/monitor/capacity/IDashboard';

@Injectable()
export class DashboardApi extends BaseAuthorizedGuidApi<IDashboard> {
    protected getBaseUrl() {
        return this.environment.api.monitor.capacity + 'Dashboard';
    }
}
