import { Response } from '@angular/http';
import saveBlob from './saveBlob';

export default function saveResponseFile(
    response: Response,
    fileName: string,
    fileType: 'csv' | 'xlsx' = 'csv'
): void {
    let fileBinaryString = atob(response.text()),
        fileCharCodes = fileBinaryString.split('').map(character => {
            return character.charCodeAt(0);
        }),
        fileByteArray = new Uint8Array(fileCharCodes);

    saveBlob(fileByteArray, fileName, fileType);
}
