import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITorkWebService } from '../../../model/product/tork/ITorkWebService';

@Injectable()
export class TorkWebServiceApi extends BaseProductApi<ITorkWebService> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'tork/lkpwebservicedetails';
    }
}
