import { DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { sortBy } from 'lodash';

import { BasePerformanceUptimeController } from '../BasePerformanceUptime.controller';
import { IResponseDaily } from '../../model/monitor/performance/IResponseDaily';
import { ISiteByDate } from '../../model/monitor/ISiteByDate';
import { ResponseDailyApi } from '../../api/monitor/performance/ResponseDailyApi';
import splitUrlForOverflow from '../../url/splitUrlForOverflow';

@Component({
    selector: 'admin-performance',
    templateUrl: 'Performance.component.html',
    providers: [ResponseDailyApi]
})
export class PerformanceComponent extends BasePerformanceUptimeController<
    IResponseDaily
> {
    readonly splitUrlForOverflow = splitUrlForOverflow;

    constructor(
        private decimalPipe: DecimalPipe,
        private responseDailyApi: ResponseDailyApi
    ) {
        super(14, 'Average Page Load');
    }

    getDuration(item: ISiteByDate<IResponseDaily>, date: string) {
        var duration: string | number,
            byDate = this.getByDate(item, date);

        if (byDate) {
            duration = this.decimalPipe.transform(byDate.avgDur, '0.1-1');
        }
        if (duration && duration !== 0) {
            duration += 's';
        }

        return duration;
    }

    protected getAll(startDate: string, endDate: string) {
        return this.responseDailyApi.getByDateRange(startDate, endDate);
    }

    protected transformItems(items: ISiteByDate<IResponseDaily>[]) {
        return sortBy(items, 'site');
    }
}
