import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFinancialFile } from '../../../model/report/financial/IFinancialFile';
import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';

@Injectable()
export class FinancialFileApi extends BaseAuthorizedGuidCrudApi<IFinancialFile> {
    private _reportId: string;

    set reportId(reportId: string) {
        this._reportId = reportId;
    }

    add(file: IFinancialFile): Observable<IFinancialFile> {
        let data = new FormData();
        data.append('file', file.file, file.fileName);
        if (file.title) {
            data.append('title', file.title);
        }

        return this.postJson(this._reportId + '/file/upload', data);
    }

    inactivate(reportFile: IFinancialFile): Observable<any> {
        return this.delete('file/' + reportFile.id);
    }

    reorderFiles(list: string[]): Observable<any> {
        return this.put(this._reportId + '/file/order', list);
    }

    update(file: IFinancialFile): Observable<IFinancialFile> {
        return this.putJson('file/' + file.id, file, {
            params: { fileTitle: file.title }
        });
    }

    protected getBaseUrl(): string {
        return this.environment.api.financialReport + 'reports/';
    }
}
