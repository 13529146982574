import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseCatalog } from '../BaseCatalog';
import { ITenaCatalog } from '../../model/product/tena/ITenaCatalog';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../sortCatalogs';
import { TenaCatalogApi } from '../../api/product/tena/TenaCatalogApi';

@Component({
    templateUrl: '../BaseCatalog.component.html',
    providers: [TenaCatalogApi]
})
export class TenaCatalogComponent extends BaseCatalog<ITenaCatalog> implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tenaCatalogApi: TenaCatalogApi
    ) {
        super(elementRef, liveStageService, prettySubmitGuard, 'Tena');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalogs - Tena');

        this.filter.observable.subscribe(filter => {
            this.tenaCatalogApi.getAll().subscribe(catalogs => {
                this.init(sortCatalogs(catalogs));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tenaCatalogApi, this.authService);
    }
}
