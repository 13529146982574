import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { StatsApi } from  '../../../api/product/distributionHub/stats/StatsApi';
import { groupBy } from 'lodash';
import { IStatsItems } from  '../../../model/product/stats/IStatsItems';
import { ChartColors } from '../../../model/chart/ChartColors';
import { BaseStats } from '../stats/BaseStats';
import { LineChartOptions } from '../../../model/chart/BaseLineChartOptions';
import { AdminTitle } from '../../../layout/AdminTitle';

@Component({
    templateUrl: 'stats.component.html',
    providers: [StatsApi]
})
export class StatsComponent extends BaseStats<IStatsItems>
    implements OnInit {
    constructor(
        protected adminTitle: AdminTitle,
        protected statsApi: StatsApi
    ) {
        super(adminTitle, statsApi);
    }

    ngOnInit() {
        this.adminTitle.setTitle('Stats');
        this.getChartData();
    }

    getChartData() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.chartData = [];

        this.subscription = this.statsApi
            .getWaitingTransactions(
                this.startDate,
                this.endDate,
                this.interval,
                this.brand
            )
            .subscribe(stats => {
                let datasets = this.prepareStatTransactionsDataset(
                    stats
                );
                this.setChartOptionInterval();

                if (this.chartData.datasets) {
                    this.chartData.datasets = datasets;

                    return this.chartComponent.chart.update();
                }

                this.chartData = {
                    datasets: datasets
                };
            });
    }

    // tslint:disable:max-func-body-length
    private prepareStatTransactionsDataset(
        statItems: IStatsItems[]
    ) {
        
        let statTransactions = groupBy(statItems, 'endpoint');
        let datasets: ChartDataSets[] = [];
        let isPushable: boolean;

        Object.keys(statTransactions)
            .sort()
            .forEach((endpoint, index) => {
                let stattimeinMinutes: any = [];              

                statTransactions[endpoint].forEach(
                    (point: IStatsItems) => {
                       
                        if(point.type.toLocaleLowerCase() === 'download'){                            
                            stattimeinMinutes.push({
                                x: new Date(point.started),
                                y: point.totalTimeInMinutes
                            });
                            isPushable= true;
                        }
                    }
                );
                
                if( isPushable){                   
                    datasets.push({
                        ...LineChartOptions,
                        label: endpoint,
                        data: stattimeinMinutes,
                        borderColor: ChartColors[index],
                        pointBackgroundColor: ChartColors[index]
                    });  
                    isPushable= false;           
                }   
            });

        return datasets;
    }    
}
