import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFinancial } from '../../../model/report/financial/IFinancial';
import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';
import { Headers } from '@angular/http';

@Injectable()
export class FinancialApi extends BaseAuthorizedGuidCrudApi<IFinancial> {
    getAllReports(siteName: string): Observable<IFinancial[]> {
        return this.getJson<IFinancial[]>('/site/' + siteName, {
            headers: new Headers({
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            })
        });
    }

    update(item: IFinancial): Observable<IFinancial> {
        return this.putJson('/id:guid?id=' + item.id, item);
    }

    inactivate(item: IFinancial): Observable<any> {
        return this.delete('/id:guid?', { params: { id: item.id } });
    }

    protected getBaseUrl(): string {
        return this.environment.api.financialReport + 'reports';
    }
}
