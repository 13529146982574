import { Http } from '@angular/http';
import { Inject, Injectable } from '@angular/core';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';

import { BaseTPWApi } from './BaseTPWApi';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ITpwGetSampleReport } from 'src/app/model/product/tena/tpw/ITpwGetSampleOrderReport';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';  
@Injectable()
export class TpwGetSampleReportApi extends BaseTPWApi<ITpwGetSampleReport> {
  
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http
    ) {
        super(apiErrorHandler, authService, environment, http);
    }
    getSampleReport(
        fromDate: string,
        toDate: string
    ): Observable<ITpwGetSampleReport[]> {
        return this.getJson(`?fromDate=${fromDate}&toDate=${toDate}`);
    }

    protected getBaseUrl() {
        return this.TPWBaseURl + 'Fulfillment/GetSampleReport';
    }
}
