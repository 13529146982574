
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { AdminTitle } from '../../layout/AdminTitle';
import { TenaService } from './Tena.service';
import { TenaExternalSampleApi } from '../../api/product/tena/tcw/TenaExternalSampleApi';
import { ITenaExternalSampleRegistration } from '../../model/product/tena/tcw/ITenaExternalSampleRegistration';
import { TenaSampleType } from '../../model/product/tena/tcw/TenaSampleType';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../environment/LiveStage';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { LiveStageService } from '../../environment/LiveStage.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ITenaUserData } from '../../model/product/tena/tcw/ITenaUserData';
import { ITenaTCWCatalog } from './../../model/product/tena/tcw/ITenaTCWCatalog.d';
import { ITenaSegment } from './../../model/product/tena/ITenaSegment.d';
import { TenaSegmentApi } from './../../api/product/tena/TenaSegmentApi';
import { TenaTCWCatalogApi } from './../../api/product/tena/tcw/TenaTCWCatalogApi';

@Component({
    templateUrl: 'TenaExternalSample.component.html',
    providers: [TenaExternalSampleApi, TenaTCWCatalogApi, TenaSegmentApi]
})
export class TenaExternalSampleComponent
    extends BaseLiveStageCrudList<
        ITenaUserData,
        ICrudData<ITenaUserData>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    externalSampleRegistration: ITenaExternalSampleRegistration;

    keys: string[];
    sampleTypes = TenaSampleType;

    @ViewChild('sampleForm', {static: false})
    sampleForm: NgForm;

    countries: ITenaTCWCatalog[];
    segments: ITenaSegment[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private tenaExternalSampleApi: TenaExternalSampleApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        liveStageService: LiveStageService,
        readonly authService: AuthService,
        readonly tenaCatalogApi: TenaTCWCatalogApi,
        readonly tenaSegmentsApi: TenaSegmentApi,
        private tenaService: TenaService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'External Sample Registration');
        this.keys = Object.keys(this.sampleTypes).filter(x => !isNaN(Number(x)));
    }

    ngOnInit() {
        this.adminTitle.setTitle('External Sample Registration - Tena');

        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
          this.tenaService.countries.next(res);
        });

        this.tenaSegmentsApi.getAll().subscribe((res: ITenaSegment[]) => {
            const consumerSegments = res.filter(segment => segment.isConsumerSegment);
            this.tenaService.segments.next(consumerSegments);
        });

        this.tenaService.segmentChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedSegment => {
                if (selectedSegment) {
                    this.getExternalSampleRegistration();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    getExternalSampleRegistration() {
        this.filter.observable.subscribe(filter => {
            this.tenaExternalSampleApi
                .getExternalSampleRegistration(
                    this.tenaService.getActiveCountry().objectId,
                    this.tenaService.getActiveSegment().objectId
                )
                .subscribe(
                    data => {
                        this.handleRegistration(data);
                    },
                    error => {
                        this.handleRegistration();
                    }
                );
          });
    }

    handleRegistration(externalSampleRegistration?: ITenaExternalSampleRegistration) {
        this.externalSampleRegistration = externalSampleRegistration;

        if (!this.externalSampleRegistration) {
            this.externalSampleRegistration = {
                externalSampleType: 0
            } as ITenaExternalSampleRegistration;
        }

        if (this.sampleForm) {
            this.sampleForm.reset(this.externalSampleRegistration);
        }
    }

    isRegistrationObject(object: any): object is ITenaExternalSampleRegistration {
        return (
            (object as ITenaExternalSampleRegistration).externalSampleType !== undefined
        );
    }

    save() {
        let observable: Observable<any>;

        if (
            // tslint:disable-next-line: triple-equals
            this.externalSampleRegistration.externalSampleType ==
            this.sampleTypes.TridionHosted
        ) {
            observable = this.tenaExternalSampleApi.deleteExternalSampleRegistration(
                this.externalSampleRegistration.objectId
            );
        } else if (this.externalSampleRegistration.objectId) {
            observable = this.tenaExternalSampleApi.updateExternalSampleRegistration(
                this.externalSampleRegistration
            );
        } else {
            this.externalSampleRegistration.catalogId = this.tenaService.getActiveCountry().objectId;
            this.externalSampleRegistration.segmentId = this.tenaService.getActiveSegment().objectId;

            observable = this.tenaExternalSampleApi.addExternalSampleRegistration(
                this.externalSampleRegistration
            );
        }

        observable.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
            this.handleRegistration(this.isRegistrationObject(x) ? x : null);
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tenaExternalSampleApi, this.authService);
    }
}
