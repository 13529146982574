export enum AuthEntityType {
    Platform = 'Platform',
    Brand = 'Brand',
    Application = 'Application',
    Segment = 'Segment',
    Country = 'Country',
    Language = 'Language'
}

export function getParentTypes(type: AuthEntityType) {
    switch (type) {
        case AuthEntityType.Brand:
            return [AuthEntityType.Platform];
        case AuthEntityType.Application:
            return [AuthEntityType.Platform, AuthEntityType.Brand];
        case AuthEntityType.Segment:
            return [AuthEntityType.Application];
        case AuthEntityType.Country:
            return [AuthEntityType.Segment];
        case AuthEntityType.Language:
            return [AuthEntityType.Country];
    }
}
