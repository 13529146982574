import { Component, Input } from '@angular/core';
import { IAutomationFilters } from '../model/testResults/IAutomationFilters';
import * as moment from 'moment';

@Component({
    selector: 'admin-automation-filters',
    templateUrl: 'TestFilters.component.html'
})
export class AdminTestFiltersComponent {
    @Input()
    filters: IAutomationFilters;
    @Input()
    domains: string[];
    @Input()
    types: string[];
    maxDate: string;

    constructor() {
        this.maxDate = moment().format('YYYY-MM-DD');
    }
}
