import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { PublishingStatisticsApi } from '../../../api/report/PublishingStatisticsApi';
import { groupBy } from 'lodash';
import { ChartColors } from '../../../model/chart/ChartColors';
import { BasePublishing } from '../BasePublishing';
import { IPriorityStatistic } from '../../../model/report/publishing/IPriorityStatistic';
import { LineChartOptions } from '../../../model/chart/BaseLineChartOptions';
import { AdminTitle } from '../../../layout/AdminTitle';

@Component({
    templateUrl: 'Priority.component.html',
    providers: [PublishingStatisticsApi]
})
export class PriorityComponent extends BasePublishing<IPriorityStatistic>
    implements OnInit {
    constructor(
        protected adminTitle: AdminTitle,
        protected publishingStatisticsApi: PublishingStatisticsApi
    ) {
        super(adminTitle, publishingStatisticsApi);
    }

    ngOnInit() {
        this.adminTitle.setTitle('Priority statistics');
        this.getChartData();
    }

    getChartData() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.chartData = [];

        this.subscription = this.publishingStatisticsApi
            .getPriorityStatistics(
                this.startDate,
                this.endDate,
                this.interval,
                this.brand
            )
            .subscribe(priorityStatistics => {
                let datasets = this.preparePriorityStatisticsDataset(priorityStatistics);
                this.setChartOptionInterval();

                if (this.chartData.datasets) {
                    this.chartData.datasets = datasets;

                    return this.chartComponent.chart.update();
                }

                this.chartData = {
                    datasets: datasets
                };
            });
    }

    private preparePriorityStatisticsDataset(priorityStatistics: IPriorityStatistic[]) {
        let transformedPriorityStatistics = groupBy(priorityStatistics, 'Priority');
        let datasets: ChartDataSets[] = [];

        Object.keys(transformedPriorityStatistics)
            .sort()
            .forEach((brand, index) => {
                let points: any = [];

                transformedPriorityStatistics[brand].forEach(
                    (point: IPriorityStatistic) => {
                        points.push({
                            x: new Date(point.Date),
                            y: point.Count
                        });
                    }
                );

                datasets.push({
                    ...LineChartOptions,
                    label: brand ? brand : 'All brands',
                    data: points,
                    borderColor: ChartColors[index],
                    pointBackgroundColor: ChartColors[index]
                });
            });

        return datasets;
    }
}
