
import {map} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Headers, Http, RequestOptions } from '@angular/http';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { ICrudApi } from 'tridion.web.ui/src/app/angular/api/ICrudApi';
import { ICountry } from '../../model/distributorFinder/ICountry';
import { IEnvironment } from '../../environment/IEnvironment';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';

@Injectable()
export class DistributorCountriesApi extends BaseAuthorizedApi<IEnvironment>
    implements ICrudApi<ICountry, string> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    getAll(): Observable<ICountry[]> {
        return this.get('state').pipe(map(response => {
            const sortedCountries = response.json().sort((a: ICountry, b: ICountry) => {
                const countryA = a.name.toUpperCase();
                const countryB = b.name.toUpperCase();
                if (countryA < countryB) {

                  return -1;
                }
                if (countryA > countryB) {

                  return 1;
                }

                return 0;
              });
              
            return sortedCountries as ICountry[];
        }));
    }
    getCountries(limit: number, page: number) {
        return this.get('state', {
            params: {
                page: page,
                limit: limit
            }
        }).pipe(map(response => {
            return {
                totalCount: Number(response.headers.get('x-total-count')),
                countries: response.json() as ICountry[]
            };
        }));
    }
    getById(id: string): Observable<ICountry> {
        return this.getJson('state/' + id);
    }

    add(item: ICountry): Observable<any> {
        let options = this.getOptions(item.authorizationPath);

        return this.post('state/', item, options);
    }

    update(item: ICountry): Observable<any> {
        let options = this.getOptions(item.authorizationPath);

        return this.put('state/' + item.id, item, options);
    }

    inactivate(item: ICountry): Observable<any> {
        let options = this.getOptions(item.authorizationPath);

        return this.delete('state/' + item.id, options);
    }

    protected getBaseUrl() {
        return this.environment.api.distributor;
    }
    private getOptions(authorizationPath: string) {
        let headers = new Headers();
        headers.append('x-state-path', authorizationPath);

        return new RequestOptions({ headers: headers });
    }
}
