import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ITest } from '../model/testResults/ITest';
import { IWebsite } from '../model/testResults/IWebsite';
import { IDomain } from '../model/testResults/IDomain';
import { ITestData } from '../model/testResults/ITestData';
import { ITestResultsContainer } from '../model/testResults/ITestResultsContainer';

import { orderBy } from 'lodash';

@Component({
    selector: 'admin-test-results',
    templateUrl: 'TestResults.component.html'
})
export class TestResultsComponent implements OnChanges {
    @Input()
    testData: ITestData = null;
    domain: IDomain;
    websites: IWebsite[];
    container: ITestResultsContainer;
    // test: ITest;

    constructor() {
        if (!this.container) {
            this.container = {
                website: null,
                test: null
            };
        }
    }

    ngOnChanges() {
        if (this.testData) {
            this.domain = this.testData.domains[0];
            this.websites = this.domain.websites;
            this.container.website = null;
            this.container.test = null;
        }
    }

    get website(): IWebsite {
        return this.container.website;
    }

    set website(value: IWebsite) {
        this.container.website = value;
        this.container.test = null;
    }

    get test(): ITest {
        return this.container.test;
    }

    set test(value: ITest) {
        this.container.test = value;
    }

    get tests(): ITest[] {
        return this.website && this.website.tests
            ? orderBy(this.website.tests, ['timestamp', 'name'], ['desc', 'asc'])
            : null;
    }
}
