import { Inject, Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';

import { Observable } from 'rxjs';
import { ICutimedWebShop } from '../../../../model/product/tena/cutimed/ICutimedWebShop';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { CutimedService } from '../../../../product/tena/cutimed/Cutimed.service';
import { CutimedBaseTCWCatalogApi } from './CutimedBaseTCWCatalogApi';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { CutimedWebShopCatalogApi } from '../cutimed/CutimedWebShopCatalogApi';
import { ICutimedWebShopCatalog } from '../../../../model/product/tena/cutimed/ICutimedWebShopCatalog';
import { CutimedSegmentApi } from './CutimedSegmentApi';
import { ICutimedSegment } from '../../../../model/product/tena/cutimed/ICutimedSegment';

@Injectable()
export class CutimedWebShopApi extends CutimedBaseTCWCatalogApi<ICutimedWebShop> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        cutimedService: CutimedService,
        readonly cutimedService1: CutimedService,
        liveStageService: LiveStageService,
        readonly cutimedCatalogApi: CutimedWebShopCatalogApi,
        readonly cutimedSegmentsApi: CutimedSegmentApi
    ) {
        super(apiErrorHandler, authService, environment, http, cutimedService, liveStageService);
    }

    getWebShops(countryId: number, segmentId: number): Observable<ICutimedWebShop[]> {
        return this.getJson(`/${countryId}/${segmentId}`);
    }

    protected getBaseUrl() {
        this.cutimedCatalogApi.getAll().subscribe((res: ICutimedWebShopCatalog[]) => {
            this.cutimedService1.countries.next(res);
        });

        this.cutimedSegmentsApi.getAll().subscribe((res: ICutimedSegment[]) => {
            const CutimedSegment = res.filter(segment => segment.isCutimedSegment);

            this.cutimedService1.segments.next(CutimedSegment);
        });

        return this.TCWBaseURl + 'webshop';
    }
}
