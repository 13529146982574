import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ITorkBrand } from '../../model/product/tork/ITorkBrand';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TorkBrandApi } from '../../api/product/tork/TorkBrandApi';

@Component({
    templateUrl: 'TorkBrands.component.html',
    providers: [TorkBrandApi]
})
export class TorkBrandsComponent
    extends BaseLiveStageCrudList<
        ITorkBrand,
        ICrudData<ITorkBrand>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private torkBrandApi: TorkBrandApi
    ) {
        super(elementRef, 'product', liveStageService, prettySubmitGuard, 'Brand');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Brands - Tork');

        this.filter.observable.subscribe(() => {
            this.torkBrandApi.getAll().subscribe(brands => {
                this.init(sortBy(brands, 'brand'));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.torkBrandApi, this.authService);
    }
}
