import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFinancialFileLink } from '../../../model/report/financial/IFinanciaFileLink';
import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';

@Injectable()
export class FinancialFileLinkApi extends BaseAuthorizedGuidCrudApi<IFinancialFileLink> {
    private _reportId: string;

    set reportId(reportId: string) {
        this._reportId = reportId;
    }

    add(link: IFinancialFileLink): Observable<IFinancialFileLink> {
        return this.postJson(this._reportId + '/link', link);
    }

    update(link: IFinancialFileLink): Observable<IFinancialFileLink> {
        return this.putJson('link/' + link.id, null, {
            params: { Title: link.title, Url: link.url }
        });
    }

    inactivate(link: IFinancialFileLink): Observable<any> {
        return this.delete('link/' + link.id);
    }

    reorderLinks(links: any[]): Observable<any> {
        return this.put(this._reportId + '/link/order', links);
    }

    protected getBaseUrl(): string {
        return this.environment.api.financialReport + 'reports/';
    }
}
