import { Pipe, PipeTransform } from '@angular/core';
import { ITpwCountry } from '../../../model/product/tena/tpw/ITpwCountry';

@Pipe({
    name: 'platformTpwCountryNamePipe'
})
export class TpwCountryNamePipe implements PipeTransform {
    transform(value: ITpwCountry[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: ITpwCountry) => {
                if (item.countryName.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
