import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Observable } from 'rxjs';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { CutimedService } from '../../../../product/tena/cutimed/Cutimed.service';
import { BaseCutimedCatalogApi } from './BaseCutimedCatalogApi';
import { ICutimedSampleOrder } from '../../../../model/product/tena/cutimed/ICutimedSampleOrder';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { CutimedSegmentApi } from './CutimedSegmentApi';
import { CutimedTCWCatalogApi } from './CutimedTCWCatalogApi';
import { ICutimedTCWCatalog } from '../../../../model/product/tena/cutimed/ICutimedTCWCatalog';
import { ICutimedSegment } from '../../../../model/product/tena/cutimed/ICutimedSegment';

@Injectable()
export class CutimedSampleOrderApi extends BaseCutimedCatalogApi<ICutimedSampleOrder> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        cutimedService: CutimedService,
        readonly cutimedService1: CutimedService,
        liveStageService: LiveStageService,
        readonly cutimedCatalogApi: CutimedTCWCatalogApi,
        readonly cutimedSegmentsApi: CutimedSegmentApi
    ) {
        super(apiErrorHandler, authService, environment, http, cutimedService, liveStageService);
    }

    getSampleOrders(
        countryId: number,
        segmentId: number,
        urlFriendlyName: string
    ): Observable<ICutimedSampleOrder[]> {
        return this.getJson(`/${countryId}/${segmentId}/${urlFriendlyName}`);
    }

    protected getBaseUrl() {
        this.cutimedCatalogApi.getAll().subscribe((res: ICutimedTCWCatalog[]) => {
            this.cutimedService1.countries.next(res);
        });

        this.cutimedSegmentsApi.getAll().subscribe((res: ICutimedSegment[]) => {
            const consumerSegments = res.filter(segment => segment.isConsumerSegment);
            this.cutimedService1.segments.next(consumerSegments);
        });

        return this.TCWBaseURl + 'ProductSampling';
    }
}
