
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { TenaService } from '../Tena.service';
import { ITenaSegment } from '../../../model/product/tena/ITenaSegment';
import { ITenaTCWCatalog } from '../../../model/product/tena/tcw/ITenaTCWCatalog';

@Component({
    selector: 'admin-tena-country-segment-menu',
    templateUrl: 'TenaCountrySegmentMenu.component.html'
})
export class TenaCountrySegmentMenuComponent implements OnInit, OnDestroy {
    countryModalVisible: boolean;
    segmentModalVisible: boolean;
    country: ITenaTCWCatalog;
    segment: ITenaSegment;

    @Input()
    useSegment = true;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private tenaService: TenaService) {}

    ngOnInit() {
        // First show country popup and then segment popup.
        this.initializeCountryModal();
    }

    initializeCountryModal(): void {
        this.tenaService.countryModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.countryModalVisible = visible;
            });

        this.tenaService.countryChanged.pipe(takeUntil(this.unsubscribe)).subscribe(country => {
            if (country) {
                this.country = country;

                if (this.useSegment) {
                    // Now show segment popup
                    this.initializeSegmentModal();
                }
            }
        });

        let sessionStorageCountry = this.tenaService.getActiveCountry();
        if (sessionStorageCountry) {
            this.tenaService.countryChanged.next(sessionStorageCountry);
            this.tenaService.countryModalVisible.next(false);
        } else {
            this.tenaService.countryModalVisible.next(true);
        }
    }

    initializeSegmentModal(): void {
        this.tenaService.segmentModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.segmentModalVisible = visible;
            });

        this.tenaService.segmentChanged.pipe(takeUntil(this.unsubscribe)).subscribe(segment => {
            if (segment) {
                this.segment = segment;
            }
        });

        let sessionStorageSegment = this.tenaService.getActiveSegment();
        if (sessionStorageSegment) {
            this.tenaService.segmentChanged.next(sessionStorageSegment);
            this.tenaService.segmentModalVisible.next(false);
        } else {
            this.tenaService.segmentModalVisible.next(true);
        }
    }

    selectSegment() {
        this.tenaService.segmentModalVisible.next(true);
    }

    selectCountry() {
        this.tenaService.countryModalVisible.next(true);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
