import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Observable } from 'rxjs';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { TenaService } from '../../../../product/tena/Tena.service';
import { BaseTCWCatalogApi } from './BaseTCWCatalogApi';
import { ITenaProductFamily } from '../../../../model/product/tena/tcw/ITenaProductFamily';
import { LiveStageService } from '../../../../environment/LiveStage.service';

@Injectable()
export class TenaProductFamilyApi extends BaseTCWCatalogApi<ITenaProductFamily> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        tenaService: TenaService,
        liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http, tenaService, liveStageService);
    }

    getProductFamilies(
        countryId: number,
        segmentId: number
    ): Observable<ITenaProductFamily[]> {
        return this.getJson(`/${countryId}/${segmentId}`);
    }

    protected getBaseUrl() {
        return this.TCWBaseURl + 'productfamily';
    }
}
