import { Component, ElementRef, OnInit } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { sortBy } from 'lodash';

import { AdminTitle } from '../layout/AdminTitle';
import { AuthEntityApi } from '../api/auth/AuthEntityApi';
import { BaseAdminCrudList } from '../crud/list/BaseAdminCrudList';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { IAuthEntity } from '../model/auth/IAuthEntity';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ISite } from '../model/site/ISite';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { SiteApi } from '../api/auth/SiteApi';

@Component({
    templateUrl: 'Sites.component.html',
    providers: [AuthEntityApi, SiteApi]
})
export class SitesComponent extends BaseAdminCrudList<ISite, ICrudData<ISite>>
    implements OnInit {
    allEntities: Observable<IAuthEntity[]>;

    constructor(
        private adminTitle: AdminTitle,
        private authEntityApi: AuthEntityApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        private siteApi: SiteApi
    ) {
        super(elementRef, prettySubmitGuard, 'Site');
    }

    ngOnInit() {
        this.adminTitle.setTitle(['Sites']);

        this.siteApi.getAll().subscribe(sites => {
            this.init(sortBy(sites, 'name'));
        });
        this.allEntities = this.authEntityApi
            .getAll().pipe(
            map(allEntities => {
                return sortBy(allEntities, 'namePath');
            }),
            shareReplay(),);
    }

    getAuthEntityName(entity: IAuthEntity) {
        return entity.namePath.split('/').join(' > ');
    }

    protected createCrud() {
        return new GuidCrud(this.siteApi);
    }
}
