import {Component, OnInit} from '@angular/core';
import {find, last, upperFirst} from 'lodash';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {AuthService} from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import getNavbar from './navbar/getNavbar';
import {IAccess} from '../model/auth/login/IAccess';
import {INavbarItem} from './navbar/INavbarItem';
import {INavbarLevel} from './navbar/INavbarLevel';
import {LiveStageService} from '../environment/LiveStage.service';
import {Routes} from '../Routes';
import {translationsEn} from '../i18n/translationsEn';
import {UserAccessService} from '../auth/login/UserAccess.service';

@Component({
    selector: 'admin-app',
    templateUrl: 'Layout.component.html'
})
export class LayoutComponent implements OnInit {
    private static readonly LevelClasses: { [level: string]: string } = {
        default: 'navbar-light',
        1: 'navbar-dark bg-primary',
        2: 'navbar-light',
        3: 'navbar-dark bg-dark',
        4: 'navbar-dark bg-magenta',
        5: 'navbar-dark bg-dark-blue'
    };

    accessLoaded = false;
    fullWidth = false;
    helpUrl?: string;
    item: INavbarItem;
    levelOpen: boolean[] = [];
    levels: INavbarLevel[];
    liveStage: boolean;
    liveStageDescription: string;

    constructor(
        public authService: AuthService,
        public liveStageService: LiveStageService,
        private router: Router,
        translate: TranslateService,
        private userAccessService: UserAccessService
    ) {
        translate.use('en');
        translate.setTranslation('en', translationsEn);
    }

    ngOnInit() {
        var router = this.router,
            liveStageService = this.liveStageService;

        var updateLevelsAndHelpUrl = (userAccess: IAccess) => {
            var levels = getNavbar(Routes, router, userAccess),
                item = find(last(levels).items, {active: true});

            this.helpUrl = item && item.helpUrl;
            this.fullWidth = item && item.fullWidth;
            this.item = item;
            this.levels = levels;
        };

        this.userAccessService.getAccess().subscribe(userAccess => {
            this.accessLoaded = true;

            router.events.subscribe(() => {
                updateLevelsAndHelpUrl(userAccess);
            });
            updateLevelsAndHelpUrl(userAccess);
        });

        liveStageService.liveStage.subscribe(liveStage => {
            this.liveStageDescription = upperFirst(liveStage);
        });

        liveStageService.listeners.subscribe(listeners => {
            setTimeout(() => {
                this.liveStage = listeners.length > 0;
            }, 400);
        });
    }

    getLevelClass(levelIndex: number) {
        var levelClass =
            LayoutComponent.LevelClasses[this.levels.length - levelIndex] ||
            LayoutComponent.LevelClasses.default;

        return 'navbar navbar-expand-md ' + levelClass;
    }

    getStyle(env:string){
        if(env === 'Stage'){
            return 'nav-item nav-link float-right u-white';

        }
        else{
            return 'nav-item nav-link float-right u-yellow';
        }
    }

    onNav(isFirstLevel:boolean) {
      if (isFirstLevel) {
        window.sessionStorage.removeItem('tenaSelectedCountry');
        window.sessionStorage.removeItem('tenaSelectedSegment');

        window.sessionStorage.removeItem('leukoplastSelectedCountry');
        window.sessionStorage.removeItem('leukoplastSelectedSegment');

        window.sessionStorage.removeItem('cutimedSelectedCountry');
        window.sessionStorage.removeItem('cutimedSelectedSegment');

        window.sessionStorage.removeItem('actimoveSelectedCountry');
        window.sessionStorage.removeItem('actimoveSelectedSegment');

        window.sessionStorage.removeItem('jobstSelectedCountry');
        window.sessionStorage.removeItem('jobstSelectedSegment');
      }
    }
}
