import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidCrudApi } from '../BaseAuthorizedGuidCrudApi';
import { ISite } from '../../model/site/ISite';

@Injectable()
export class FulfillmentAuthApi extends BaseAuthorizedGuidCrudApi<ISite> {
    getUserByRole() {
        return this.getJson(
            'authorizationentity/module/Fulfillment/accessLevel/Platform|Femcare/right/edit'
        );
    }

    protected getBaseUrl() {
        return this.environment.api.authentication;
    }
}
