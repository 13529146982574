export const ChartColors = [
    '#044d4c',
    '#f50082',
    '#ffff00',
    '#afee42',
    '#9a9a9a',
    '#000',
    '#e50000',
    '#e2431e',
    '#e7711b',
    '#f1ca3a',
    '#6f9654',
    '#865864',
    '#80829b',
    '#48159d',
    '#DB1DDE',
    '#00779d'
    
];
