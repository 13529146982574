import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { CatalogSolrcoreMapping } from '../../mapping/CatalogSolrcoreMapping';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TorkCatalogApi } from '../../../api/product/tork/TorkCatalogApi';
import { TorkCatalogSolrcoreMappingApi } from '../../../api/product/tork/TorkCatalogSolrcoreMappingApi';
import { TorkSolrcoreApi } from '../../../api/product/tork/TorkSolrcoreApi';

@Component({
    templateUrl: '../../mapping/CatalogSolrcoreMapping.component.html',
    providers: [TorkCatalogApi, TorkCatalogSolrcoreMappingApi, TorkSolrcoreApi]
})
export class TorkCatalogSolrcoreMappingComponent extends CatalogSolrcoreMapping
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private torkCatalogApi: TorkCatalogApi,
        private torkCatalogSolrcoreMappingApi: TorkCatalogSolrcoreMappingApi,
        private torkSolrcoreApi: TorkSolrcoreApi
    ) {
        super(elementRef, liveStageService, prettySubmitGuard);
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalog ⬌ Solr Cores - Tork');

        this.filter.observable.subscribe(() => {
            this.initAll(
                this.torkCatalogApi.getAll(),
                this.torkSolrcoreApi.getAll(),
                this.torkCatalogSolrcoreMappingApi.getAll()
            );
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.torkCatalogSolrcoreMappingApi, this.authService);
    }
}
