import { Component, Input } from '@angular/core';

import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { CrudMode } from 'tridion.web.ui/src/app/angular/crud/CrudMode';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';

@Component({
    selector: 'admin-crud-delete-warning',
    templateUrl: 'CrudDeleteWarning.component.html'
})
export class CrudDeleteWarningComponent<I, D extends ICrudData<I>> {
    @Input() details?: string;
    @Input() item: BaseCrud<I, D>;
    @Input() itemName?: string;
    @Input() itemType?: string;

    cancel() {
        this.item.setMode(CrudMode.Read);
    }

    getButtonText() {
        var text: string;

        if (this.item.deleting) {
            text = 'Deleting';
        } else {
            text = 'Delete';
        }

        var name = this.itemName;
        if (name) {
            text += ' “' + name + '”';
        }

        var type = this.itemType;
        if (type) {
            text += ' ' + type;
        }

        return text;
    }

    getErrorText() {
        var text = 'There was an error deleting the ';

        var type = this.itemType;
        if (type) {
            text += type.toLowerCase();
        } else {
            text += 'item';
        }

        text += '.';

        return text;
    }

    getMessage() {
        var message = 'Are you sure you want to delete ';

        var name = this.itemName;
        if (name) {
            message += 'the “' + name + '”';
        } else {
            message += 'this';
        }

        var type = this.itemType;
        if (type) {
            message += ' ' + type.toLowerCase();
        }

        message += '?';

        return message;
    }
}
