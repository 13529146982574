import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../layout/AdminTitle';
import { IDeleteUser } from '../model/delete/IDeleteUser';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { BaseLiveStageCrudList } from '../crud/list/BaseLiveStageCrudList';
import { LiveStage } from '../environment/LiveStage';
import { LiveStageService } from '../environment/LiveStage.service';
import { DeleteUserCrud } from '../model/delete/DeleteUserCrud';
import { TpwGdprBlacklistApi } from '../api/product/tena/tpw/TpwGdprBlacklistApi';

@Component({
    templateUrl: 'TpwGdprBlacklist.component.html',
    providers: [TpwGdprBlacklistApi]
})
export class TpwGdprBlacklistComponent
    extends BaseLiveStageCrudList<
        IDeleteUser,
        ICrudData<IDeleteUser>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    isButtonvisible = true;
    errorMessage: string;
    isIconvisible: boolean;

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tpwGdprBlacklistApi: TpwGdprBlacklistApi
    ) {
        super(elementRef, 'submitted', liveStageService, prettySubmitGuard, 'Blacklist');
    }

    ngOnInit() {
        this.adminTitle.setTitle('TPW GDPR Blacklist');
        this.getBlacklistUser();
    }

    getBlacklistUser() {
        return this.filter.observable.subscribe(filter => {
            this.tpwGdprBlacklistApi.getAll().subscribe(segments => {
                this.init(segments);
            });
        });
    }

    protected createCrud() {
        return new DeleteUserCrud(this.tpwGdprBlacklistApi, this.authService);
    }
}
