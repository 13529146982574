
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../../environment/LiveStage';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { CutimedWebShopApi } from '../../../api/product/tena/cutimed/CutimedWebShopApi';
import { ICutimedWebShop } from '../../../model/product/tena/cutimed/ICutimedWebShop';
import { Subject } from 'rxjs';
import { CutimedService } from './Cutimed.service';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { CutimedWebShopCatalogApi } from '../../../api/product/tena/cutimed/CutimedWebShopCatalogApi';
import { ICutimedWebShopCatalog } from '../../../model/product/tena/cutimed/ICutimedWebShopCatalog';
import { CutimedSegmentApi } from '../../../api/product/tena/cutimed/CutimedSegmentApi';
import { ICutimedSegment } from '../../../model/product/tena/cutimed/ICutimedSegment';

@Component({
    templateUrl: 'CutimedWebShop.component.html',
    providers: [CutimedWebShopApi, CutimedWebShopCatalogApi, CutimedSegmentApi]
})
export class CutimedWebShopComponent
    extends BaseLiveStageCrudList<
        ICutimedWebShop,
        ICrudData<ICutimedWebShop>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    countries: ICutimedWebShopCatalog[];
    segments: ICutimedSegment[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private cutimedWebShopApi: CutimedWebShopApi,
        readonly cutimedCatalogApi: CutimedWebShopCatalogApi,
        readonly cutimedSegmentApi: CutimedSegmentApi,
        readonly cutimedService: CutimedService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'WebShop');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Webshop - Cutimed');

        this.cutimedCatalogApi.getAll().subscribe((res: ICutimedWebShopCatalog[]) => {
          this.cutimedService.countries.next(res);
        });

        this.cutimedSegmentApi.getAll().subscribe((res: ICutimedSegment[]) => {
            const isCutimedSegment = res.filter(segment => segment.isCutimedSegment);
            this.cutimedService.segments.next(isCutimedSegment);
        });

        this.cutimedService.segmentChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedSegment => {
                if (selectedSegment) {
                    this.getWebShops();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    getWebShops() {
        this.filter.observable.subscribe(() => {
            this.cutimedWebShopApi
                .getWebShops(
                    this.cutimedService.getActiveCountry().objectId,
                    this.cutimedService.getActiveSegment().objectId
                )
                .subscribe(data => {
                    this.clear();
                    this.init(data);
                });
        });
    }

    saveWebShop(webshopCrud: BaseCrud<ICutimedWebShop, ICrudData<ICutimedWebShop>>) {
        // Set catalog and segment ID for new webshops.
        if (webshopCrud.isNew()) {
            webshopCrud.data.item.catalogObjectId = this.cutimedService.getActiveCountry().objectId;
            webshopCrud.data.item.segmentObjectId = this.cutimedService.getActiveSegment().objectId;
        }

        webshopCrud.save();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.cutimedWebShopApi, this.authService);
    }
}
