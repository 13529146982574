import { of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IFemcareProduct } from '../model/product/femcare/IFemcareProduct';
import { sortBy } from 'lodash';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { ProductImporterFormApi } from '../api/productImporter/ProductImporterFormApi';
import { ShowMoreAdminCrudList } from '../crud/list/ShowMoreAdminCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AdminTitle } from '../layout/AdminTitle';
import { SiteApi } from '../api/auth/SiteApi';
import { Observable, Subscription } from 'rxjs';
import { ISite } from '../model/site/ISite';
import CrudListFilter from '../crud/list/CrudListFilter';
import { LiveStageService } from '../environment/LiveStage.service';
import { Variant } from '../model/celum/femcare/Variant';
import { CelumApi } from '../api/celum/CelumApi';
import { IVariantCDNUrl } from '../model/celum/IVariantCDNUrl';
import { IFemcareMediaAssetRenditionUrl } from '../model/product/femcare/IFemcareMediaAssetRenditionUrl';
import { IFemcareLongDescriptionImage } from '../model/product/femcare/IFemcareLongDescriptionImage';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { Rendition } from '../model/product/femcare/Rendition';
import { IFemcareMediaAsset } from '../model/product/femcare/IFemcareMediaAsset';
import { UnderwearType } from '../model/product/femcare/UnderwearType';
import { UsageOccasion } from '../model/product/femcare/UsageOccasion';
import { ProductFeatures } from '../model/product/femcare/ProductFeatures';
import { FemcareSiteApi } from '../api/product/femcare/FemcareSiteApi';
import { IFemcareSite } from '../model/product/femcare/IFemcareSite';

type ProductCrud = BaseCrud<IFemcareProduct, ICrudData<IFemcareProduct>>;

@Component({
    templateUrl: 'ProductImporterForm.component.html',
    providers: [ProductImporterFormApi, SiteApi, FemcareSiteApi]
})
export class ProductImporterFormComponent
    extends ShowMoreAdminCrudList<IFemcareProduct, ICrudData<IFemcareProduct>>
    implements OnInit, OnDestroy {
    sites: Observable<ISite[]>;
    femcareSites: IFemcareSite[];
    url: string;
    ckeConfig: any;
    tempImageCelumId: string;
    underwearTypes$: any[];
    usageOccasions$: any[];
    productFeatures$: any[];
    tempImageUrlArray: any = [];

    @Input()
    selectedSiteId: string;
    private readonly filter = new CrudListFilter<{ siteId: string }>(this);
    private liveStageSubscription: Subscription;

    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        protected productImporterFormApi: ProductImporterFormApi,
        private liveStageService: LiveStageService,
        private siteApi: SiteApi,
        private celumApi: CelumApi,
        private femcareSiteApi: FemcareSiteApi
    ) {
        super(elementRef, prettySubmitGuard, 'Product');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Product Importer Form');
        this.liveStageService.addListener(this, 'productImporter');
        this.selectedSiteId = undefined;
        this.productImporterFormApi.selectedSiteId = this.selectedSiteId;

        this.underwearTypes$ = Object.values(UnderwearType);
        this.usageOccasions$ = Object.values(UsageOccasion);
        this.productFeatures$ = Object.values(ProductFeatures);

        this.liveStageSubscription = this.liveStageService.liveStage.subscribe(() => {
            this.femcareSiteApi.getAll().subscribe(sites => {
                this.femcareSites = sites;
            });
            // @ts-ignore @todo
            this.sites = this.siteApi
                .getSitesByBrand('femcare').pipe(
                    // @ts-ignore @todo
                    map(sites => sortBy(sites, 'name')),
                    shareReplay(),);
            this.sites.subscribe(sites => {
                const filteredSites = sites.filter(site =>
                    this.femcareSites.some(femcareSite => femcareSite.siteUrl === site.domainUrl)
                );
                this.sites = of(filteredSites);
            });
            this.selectedSiteId = undefined;
        });

        this.initProducts();

        this.ckeConfig = {
            allowedContent: false,
            forcePasteAsPlainText: true,
            toolbarGroups: [
                {
                    name: 'productImporterForm',
                    groups: ['basicstyles', 'list', 'undo', 'clipboard', 'links']
                }
            ]
        };
    }

    initProducts() {
        this.filter.observable.subscribe(filter => {
            let siteId = filter.siteId;
            if (siteId) {
                this.productImporterFormApi
                    .getProductsBySite(siteId)
                    .subscribe(products => {
                        products.forEach((p: IFemcareProduct) => {
                            this.initLongDescription(p);
                            this.initVideos(p);
                            if (
                                p.secondaryImages === undefined ||
                                p.secondaryImages.length === 0
                            ) {
                                p.secondaryImages = [
                                    {
                                        celumMediaAssetId: ''
                                    } as IFemcareMediaAsset
                                ];
                            }
                        });
                        this.init(sortBy(products, 'title'));
                    });

                this.selectedSiteId = siteId;
                this.productImporterFormApi.selectedSiteId = this.selectedSiteId;
            }
        });
    }

    initVideos(product: IFemcareProduct) {
        if (product.videos === undefined || product.videos.length === 0) {
            product.videos = [
                {
                    video: {
                        celumMediaAssetId: ''
                    } as IFemcareMediaAsset,
                    videoPoster: {
                        celumMediaAssetId: ''
                    } as IFemcareMediaAsset
                }
            ];
        }
        product.videos.forEach(v => {
            if (v.video === undefined) {
                v.video = {
                    celumMediaAssetId: ''
                } as IFemcareMediaAsset;
            }
            if (v.videoPoster === undefined) {
                v.videoPoster = {
                    celumMediaAssetId: ''
                } as IFemcareMediaAsset;
            }
        });
    }

    initLongDescription(product: IFemcareProduct) {
        if (
            product.longDescriptions === undefined ||
            product.longDescriptions.length === 0
        ) {
            product.longDescriptions = [
                {
                    longDescription: '',
                    image: {} as IFemcareMediaAsset
                }
            ] as IFemcareLongDescriptionImage[];
        }
    }

    updateFilter() {
        var filter = this.filter;

        if (!filter.setFilter({ siteId: this.selectedSiteId })) {
            setTimeout(() => {
                this.selectedSiteId = filter.getFilter().siteId;
            }, 0);
        }
    }

    onBlurImage(
        mainImageCelumId: string,
        productEan: string,
        elementType: string,
        crudIndex: number,
        subIndex: number
    ) {
        if (this.tempImageCelumId !== mainImageCelumId && mainImageCelumId !== '') {
            this.tempImageCelumId = mainImageCelumId;

            this.setRenditionUrlsPerType(
                mainImageCelumId,
                elementType,
                crudIndex,
                subIndex
            );

            let productNewImage = this.celumApi
                .getVariantUrls(mainImageCelumId)
                .pipe(
                    // @ts-ignore @todo
                    map((itemUrls: IVariantCDNUrl[]) => {
                        return {
                            cdnUrl:
                                (
                                    itemUrls.find((i: any) =>
                                        i.Variant.includes(Variant.FemC_01_305x305)
                                    ) || ({} as IVariantCDNUrl)
                                ).CDNUrl || ''
                        };
                    }),
                    shareReplay(),
                );

            productNewImage.subscribe((productNewImageItem: any) => {
                let product = this.cruds.find(p => p.data.item.ean === productEan);
                elementType += crudIndex + '' + subIndex + '-' + product.data.item.ean;

                var wrappedResult = document.getElementsByClassName(elementType);
                if (
                    wrappedResult !== undefined &&
                    wrappedResult.length >= 0 &&
                    wrappedResult[0] !== undefined
                ) {
                    wrappedResult[0].setAttribute('src', productNewImageItem.cdnUrl);
                    wrappedResult[0].removeAttribute('hidden');
                }
            });
        }
    }

    setRenditionUrlsPerType(
        mainImageCelumId: string,
        elementType: string,
        crudIndex: number,
        subIndex: number
    ) {
        this.celumApi
            .getVariantUrls(mainImageCelumId)
            .subscribe((itemUrls: IVariantCDNUrl[]) => {
                let renditionUrls = itemUrls.map((variantUrlItem: IVariantCDNUrl) => {
                    if (this.getRenditionForVariant(variantUrlItem) !== '') {
                        let result: IFemcareMediaAssetRenditionUrl = {
                            rendition: this.getRenditionForVariant(variantUrlItem),
                            cdnUrl: variantUrlItem.CDNUrl
                        };

                        return result;
                    }
                });

                if (elementType === 'heroImage') {
                    this.cruds[crudIndex].data.item.heroImage.renditionUrls = renditionUrls;
                } else if (elementType === 'secondaryImages') {
                    this.cruds[crudIndex].data.item.secondaryImages[subIndex].renditionUrls = renditionUrls;
                } else if (elementType === 'videoPoster') {
                    this.cruds[crudIndex].data.item.videos[subIndex].videoPoster.renditionUrls = renditionUrls;
                } else if (elementType === 'video') {
                    this.cruds[crudIndex].data.item.videos[subIndex].video.renditionUrls = renditionUrls;
                } else if (elementType === 'longDescriptions') {
                    this.cruds[crudIndex].data.item.longDescriptions[subIndex].image.renditionUrls = renditionUrls;
                }
            });
    }

    getRenditionForVariant(variantItem: IVariantCDNUrl) {
        let renditionTemp: string;

        if (variantItem.Variant.includes(Variant.FemC_01_305x305)) {
            renditionTemp = Rendition.Femcare305x305;
        } else if (variantItem.Variant.includes(Variant.FemC_03_615x615)) {
            renditionTemp = Rendition.Femcare615x615;
        } else if (variantItem.Variant.includes(Variant.FemC_03_615x615)) {
            renditionTemp = Rendition.Femcare1500x750;
        } else if (variantItem.Variant.includes(Variant.H264_4500kbps_AAC_und_ch2_128kbps)) {
            renditionTemp = Rendition.H264_4500kbps_AAC_und_ch2_128kbps;
        } else if (variantItem.Variant.includes(Variant.H264_Broadband_720p)) {
            renditionTemp = Rendition.H264_Broadband_720p;
        }

        return renditionTemp;
    }

    ngOnDestroy(): void {
        this.liveStageService.removeListener(this);
        this.liveStageSubscription.unsubscribe();
    }

    addLongDescription(crud: ProductCrud) {
        crud.data.item.longDescriptions.push({
            longDescription: '',
            image: { celumMediaAssetId: '' } as IFemcareMediaAsset
        });
    }

    addVideoSection(crud: ProductCrud) {
        crud.data.item.videos.push({
            video: { celumMediaAssetId: '' } as IFemcareMediaAsset,
            videoPoster: { celumMediaAssetId: '' } as IFemcareMediaAsset
        });
    }

    addAdditionalImageSection(crud: ProductCrud) {
        crud.data.item.secondaryImages.push({
            celumMediaAssetId: ''
        } as IFemcareMediaAsset);
    }

    removeLongDescription(crud: ProductCrud, index: number) {
        crud.data.item.longDescriptions.splice(index, 1);
    }

    removeVideoSection(crud: ProductCrud, index: number) {
        crud.data.item.videos.splice(index, 1);
    }

    removeAdditionalImageSection(crud: ProductCrud, index: number) {
        crud.data.item.secondaryImages.splice(index, 1);
    }

    protected createCrud() {
        return new GuidCrud(this.productImporterFormApi);
    }

    protected createItem() {
        return {
            heroImage: {} as IFemcareMediaAsset,
            longDescriptions: [
                { longDescription: '', image: {} as IFemcareMediaAsset }
            ] as IFemcareLongDescriptionImage[]
        } as IFemcareProduct;
    }
}
