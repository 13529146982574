import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ILeukoplastSegment } from '../../../model/product/tena/leukoplast/ILeukoplastSegment';
import { ILeukoplastTCWCatalog } from '../../../model/product/tena/leukoplast/ILeukoplastTCWCatalog';

@Injectable()
export class LeukoplastService {
    countryChanged = new Subject<ILeukoplastTCWCatalog>();
    segmentChanged = new Subject<ILeukoplastSegment>();

    countries = new Subject<ILeukoplastTCWCatalog[]>();
    segments = new Subject<ILeukoplastSegment[]>();
    countryModalVisible = new Subject<boolean>();
    segmentModalVisible = new Subject<boolean>();

    getActiveCountry(): ILeukoplastTCWCatalog {
        let country = null;
        let sessionStorage = window.sessionStorage.getItem('leukoplastSelectedCountry');
        if (sessionStorage !== '' && sessionStorage !== null) {
            country = JSON.parse(sessionStorage);
        }

        return country;
    }

    getActiveSegment(): ILeukoplastSegment {
        let segment = null;
        let sessionStorage = window.sessionStorage.getItem('leukoplastSelectedSegment');
        if (sessionStorage !== '' && sessionStorage !== null) {
            segment = JSON.parse(sessionStorage);
        }

        return segment;
    }
}
