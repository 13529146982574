import * as moment from 'moment';

import { ChartData, ChartDataSets, ChartOptions, ChartPoint } from 'chart.js';
import { Component, OnInit } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { forEach, pull, sortBy } from 'lodash';

import { BaseAdminList } from '../../list/BaseAdminList';
import { DashboardApi } from '../../api/monitor/capacity/DashboardApi';
import { EnvironmentName } from '../../model/monitor/capacity/EnvironmentName';
import { IDashboard } from '../../model/monitor/capacity/IDashboard';
import { ServerUsageInfoApi } from '../../api/monitor/capacity/ServerUsageInfoApi';
import { ChartColors } from '../../model/chart/ChartColors';

@Component({
    selector: 'admin-capacity',
    templateUrl: 'Capacity.component.html',
    providers: [DashboardApi, ServerUsageInfoApi]
})
export class CapacityComponent extends BaseAdminList<IDashboard> implements OnInit {
    readonly chartOptions: ChartOptions = {
        scales: {
            xAxes: [
                {
                    type: 'time'
                }
            ],
            yAxes: [
                {
                    ticks: {
                        suggestedMin: 0,
                        suggestedMax: 100
                    } as any
                }
            ]
        }
    };
    showDriveLetters = false;
    widgetTall = false;
    private chartDatas: { [serverName: string]: Observable<ChartData> } = {};
    private readonly chartStartDate: string;
    private readonly chartEndDate: string;
    private showChart: string[] = [];

    constructor(
        private dashboardApi: DashboardApi,
        private serverUsageInfoApi: ServerUsageInfoApi
    ) {
        super('Server');

        var chartEndDate = moment(),
            chartStartDate = chartEndDate.clone().subtract(2, 'weeks');

        this.chartStartDate = chartStartDate.toISOString();
        this.chartEndDate = chartEndDate.toISOString();
    }

    ngOnInit() {
        this.dashboardApi.getAll().subscribe(dashboards => {
            this.items = sortBy(dashboards, [
                (dashboard: IDashboard) => {
                    return Object.keys(EnvironmentName).indexOf(dashboard.environment);
                },
                (dashboard: IDashboard) => {
                    return -dashboard.currentCPUUsage;
                },
                (dashboard: IDashboard) => {
                    return -dashboard.currentRAMUsage;
                },
                'serverName'
            ]);
        });
    }

    // tslint:disable:max-func-body-length

    getChartData(item: IDashboard) {
        var serverName = item.serverName,
            chartDatas = this.chartDatas,
            chartData = chartDatas[serverName];

        if (!chartData) {
            chartData = this.serverUsageInfoApi
                .getByServerDateRange(item.id, this.chartStartDate, this.chartEndDate).pipe(
                map(usages => {
                    var cpuPoints: ChartPoint[] = [],
                        ramPoints: ChartPoint[] = [],
                        driveLetterPoints: { [driveLetter: string]: ChartPoint[] } = {};

                    usages.forEach(usage => {
                        let capturedAt = new Date(usage.capturedAt);

                        cpuPoints.push({
                            t: capturedAt,
                            y: usage.cpuPercentageUsed
                        });
                        ramPoints.push({
                            t: capturedAt,
                            y: usage.ramPercentageUsed
                        });

                        usage.serverDiskUsageInfo.forEach(driveUsage => {
                            var driveLtter = driveUsage.diskName.replace(/\\/g, '');

                            var points = driveLetterPoints[driveLtter];
                            if (!points) {
                                points = [];
                                driveLetterPoints[driveLtter] = points;
                            }

                            points.push({
                                t: capturedAt,
                                y: driveUsage.diskUsagePercentage
                            });
                        });
                    });

                    var datasets: ChartDataSets[] = [
                        {
                            label: 'CPU',
                            data: cpuPoints,
                            backgroundColor: ChartColors[0],
                            borderColor: ChartColors[0],
                            pointRadius: 0,
                            pointHitRadius: 10
                        },
                        {
                            label: 'RAM',
                            data: ramPoints,
                            backgroundColor: ChartColors[1],
                            borderColor: ChartColors[1],
                            pointRadius: 0,
                            pointHitRadius: 10
                        }
                    ];

                    Object.keys(driveLetterPoints)
                        .sort()
                        .forEach((driveLetter, index) => {
                            datasets.push({
                                label: driveLetter,
                                data: driveLetterPoints[driveLetter],
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                borderColor: ChartColors[index + 2],
                                pointRadius: 0,
                                pointHitRadius: 10,
                                hidden: true
                            });
                        });

                    return {
                        datasets: datasets
                    };
                }),
                shareReplay(),);

            chartDatas[serverName] = chartData;
        }

        return chartData;
    }

    // tslint:enable

    getColor(percent: number, dangerThreshold = 90, warningThreshold = 75) {
        var color: string;

        if (percent >= dangerThreshold) {
            color = 'table-danger';
        } else if (percent >= warningThreshold) {
            color = 'table-warning';
        } else if (percent >= 0) {
            color = 'table-success';
        }

        return color;
    }

    getColorRam(percent: number, item: IDashboard) {
        var color: string;

        var serverPurpose = item.serverPurpose;
        if (serverPurpose && serverPurpose.indexOf('SQL') >= 0) {
            color = this.getColor(percent, 98, 96);
        } else {
            color = this.getColor(percent);
        }

        return color;
    }

    getDriveLetters() {
        var driveLetters: { [driveLetter: string]: boolean } = {};

        var dashboards = this.items;
        if (dashboards) {
            dashboards.forEach(dashboard => {
                forEach(dashboard, (usagePercent, driveLetter) => {
                    if (usagePercent > 0 && driveLetter.length === 1) {
                        driveLetters[driveLetter] = true;
                    }
                });
            });
        }

        return Object.keys(driveLetters).sort();
    }

    isChartOpen(item: IDashboard) {
        return this.showChart.includes(item.serverName);
    }

    toggleChart(item: IDashboard) {
        var serverName = item.serverName,
            showChart = this.showChart;

        if (showChart.includes(serverName)) {
            pull(showChart, serverName);
        } else {
            showChart.push(serverName);
            this.widgetTall = true;
        }
    }
}
