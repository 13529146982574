export default function saveBlob(
    blobData: string | Uint8Array,
    fileName: string,
    fileType: 'csv' | 'xls' | 'xlsx' = 'csv'
): void {
    let typeByExtension = {
        csv: 'text/csv',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    let blob = new Blob([blobData], {
        type: typeByExtension[fileType]
    });

    let completeFileName = fileName + '.' + fileType;

    // IE & Edge fix for downloading blob files, gives option to save or open the file when the link is opened.
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, completeFileName);
    } else {
        let blobUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');

        a.href = blobUrl;
        a.download = completeFileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
    }
}
