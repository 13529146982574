import { Component } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute } from '@angular/router';
import { SiteMappingApi } from '../../../api/campaign/siteMapping/SiteMappingApi';
import { FulfillmentAuthApi } from '../../../api/fulfillment/FulfillmentAuthApi';
import { PromotionSubmissionsApi } from '../../../api/campaign/promotion/fulfillment/PromotionSubmissionsApi';
import { BaseFulfillmentComponent } from '../../../fulfillment/BaseFulfillment.component';
import { AdminTitle } from '../../../layout/AdminTitle';
import { IPromotionSubmission } from '../../../model/campaign/promotion/IPromotionSubmission';
import { BehaviorSubject } from 'rxjs';

@Component({
    templateUrl: 'PromotionSubmissions.component.html',
    providers: [PromotionSubmissionsApi, SiteMappingApi, FulfillmentAuthApi]
})
export class PromotionSubmissionsComponent extends BaseFulfillmentComponent<
    IPromotionSubmission
> {
    static readonly componentTitle: string = 'Fulfillment';
    static readonly itemTitle: string = 'promotion submission';
    static readonly pageSize: number = 20;
    promotions$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
    selectedPromotion?: string;
    idFilter: string;

    constructor(
        protected promotionSubmissionsApi: PromotionSubmissionsApi,
        protected route: ActivatedRoute,
        protected adminTitle: AdminTitle,
        protected fulfillmentAuthApi: FulfillmentAuthApi,
        protected siteMappingApi: SiteMappingApi
    ) {
        super(
            promotionSubmissionsApi,
            route,
            adminTitle,
            fulfillmentAuthApi,
            siteMappingApi,
            {
                title: PromotionSubmissionsComponent.componentTitle,
                itemTitle: PromotionSubmissionsComponent.itemTitle
            },
            PromotionSubmissionsComponent.pageSize
        );
    }

    downloadAttachment(id: string, event: any) {
        event.preventDefault();
        let anchor = event.currentTarget;
        if (anchor.dataset.downloading) {
            return;
        }

        anchor.dataset.downloading = 'true';
        this.promotionSubmissionsApi.downloadAttachment(id).subscribe(
            (response: Response) => {
                anchor.dataset.blobUrl = URL.createObjectURL(response.blob());
            },
            () => {
                anchor.dataset.downloading = '';
            },
            () => {
                anchor.dataset.downloading = '';
            }
        );
    }

    openAttachment(event: any) {
        event.preventDefault();
        let anchor = event.currentTarget;
        if (anchor.dataset.downloading) {
            return;
        }

        let blobUrl = anchor.dataset.blobUrl;
        window.open(blobUrl);
        anchor.dataset.blobUrl = '';
        URL.revokeObjectURL(blobUrl);
    }

    getItemById() {
        if (this.idFilter) {
            this.promotionSubmissionsApi.getItemById(this.idFilter).subscribe(item => {
                this.items$.next([item]);
            });
        }
    }

    onChangeSite() {
        this.selectedPromotion = null;
        this.getSubmissionRange();
    }

    getSubmissionRange() {
        if (this.site && this.startDate) {
            this.loadingItems = true;

            this.promotionSubmissionsApi
                .getSubmissionRange(this.site, this.selectedPromotion, this.pageSize, this.startDate, this.endDate)
                .subscribe(range => {
                    // Response can return pagesize + 1 number of items to indicate that next page exists.
                    this.hasMoreItems = range.submissions.length > this.pageSize;
                    if (this.hasMoreItems) {
                        range.submissions.pop();
                    }
                    this.items$.next(range.submissions);
                    this.promotions$.next(range.promotions);

                    this.loadingItems = false;
                });

            this.showDeleteButton();
        }
    }

    getTargetId() {
        return this.selectedPromotion;
    }

    getUniqueKeys(items: IPromotionSubmission[]): string[] {
        const uniqueKeys = new Set<string>();
        items.forEach(item => {
            if (item.formData) {
                Object.keys(item.formData).forEach(key => {
                    if (!this.isExcludedKey(key)) {
                        uniqueKeys.add(key);
                    }
                });
            }
        });

        return Array.from(uniqueKeys);
    }

    isExcludedKey(key: string): boolean {
        const lowerCaseKey = key.toLowerCase();

        return lowerCaseKey === 'formid' || lowerCaseKey === 'promotionid' || lowerCaseKey.startsWith('accept');
    }

    isDate(value: any): boolean {
        if (!value) {

            return false; // Check for null or undefined
        }

        if (!value.includes('-')) {

            return false;
        }

        return !isNaN(Date.parse(value));
    }

    shouldShowFileColumn(items: IPromotionSubmission[]): boolean {

        return items.some(item => item && item.attachmentName !== null);
    }
}
