import { Component } from '@angular/core';
import { BaseFulfillmentComponent } from '../../../fulfillment/BaseFulfillment.component';
import { IQuizSubmission } from '../../../model/campaign/quiz/IQuizSubmission';
import { QuizSubmissionApi } from '../../../api/campaign/quiz/QuizSubmissionApi';
import { ActivatedRoute } from '@angular/router';
import { AdminTitle } from '../../../layout/AdminTitle';
import { FulfillmentAuthApi } from '../../../api/fulfillment/FulfillmentAuthApi';
import { SiteMappingApi } from '../../../api/campaign/siteMapping/SiteMappingApi';
import { QuizApi } from '../../../api/campaign/quiz/QuizApi';
import { IQuiz } from '../../../model/campaign/quiz/IQuiz';
import { BehaviorSubject } from 'rxjs';

@Component({
    templateUrl: 'QuizSubmissions.component.html',
    providers: [QuizApi, QuizSubmissionApi, SiteMappingApi, FulfillmentAuthApi]
})
export class QuizSubmissionsComponent extends BaseFulfillmentComponent<IQuizSubmission>{
    static readonly componentTitle: string = 'Submissions';
    static readonly itemTitle: string = 'Submission';
    static readonly pageSize: number = 20;
    quizzes$: BehaviorSubject<IQuiz[]> = new BehaviorSubject<IQuiz[]>(null);
    selectedQuiz?: IQuiz;

    constructor(
        protected quizSubmissionApi : QuizSubmissionApi,
        protected quizApi: QuizApi,
        protected route: ActivatedRoute,
        protected adminTitle: AdminTitle,
        protected fulfillmentAuthApi: FulfillmentAuthApi,
        protected siteMappingApi: SiteMappingApi
    ){
        super(
            quizSubmissionApi,
            route,
            adminTitle,
            fulfillmentAuthApi,
            siteMappingApi,
            {
                title: QuizSubmissionsComponent.componentTitle,
                itemTitle: QuizSubmissionsComponent.itemTitle
            },
            QuizSubmissionsComponent.pageSize
        );
    }

    loadQuizzesForSite() {
        if (this.site) {
            this.selectedQuiz = null;
            this.quizApi.getQuizzesBySite(this.site.name).subscribe(quizzes => this.quizzes$.next(quizzes));
        }
    }
}