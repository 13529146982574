import { Inject, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { TenaService } from '../../../../product/tena/Tena.service';
import { BaseTCWCatalogApi } from './BaseTCWCatalogApi';
import { Observable } from 'rxjs';
import { ITenaUserData } from '../../../../model/product/tena/tcw/ITenaUserData';
import { LiveStageService } from '../../../../environment/LiveStage.service';

@Injectable()
export class TenaGDPRApi extends BaseTCWCatalogApi<ITenaUserData> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        tenaService: TenaService,
        liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http, tenaService, liveStageService);
    }

    getUserData(emails: string[]): Observable<ITenaUserData[]> {
        return this.getJson(`/${emails.join(',')}/`);
    }

    deleteEmails(emails: string[]): Observable<Response> {
        return this.delete(`/${emails.join(',')}/`);
    }

    getCsv(emails: string[]): Observable<Response> {
        return this.get(`/${emails.join(',')}/excel`);
    }

    protected getBaseUrl() {
        return this.TCWBaseURl + 'userdata';
    }
}
