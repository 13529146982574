
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { TpwSampleApi } from '../../../api/product/tena/tpw/TpwSampleApi';
import { ITpwSample } from '../../../model/product/tena/tpw/ITpwSample';
import { TpwService } from '../../Tpw.service';

@Component({
    selector: 'admin-tpw-sample-select',
    templateUrl: 'TpwSampleSelect.component.html',
    providers: [TpwSampleApi]
})
export class TpwSampleSelectComponent implements OnInit, OnDestroy {
    sampleForm: FormGroup;
    samples: ITpwSample[];

    @Input()
    selectedSample: ITpwSample;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public tpwService: TpwService,
        private tpwSampleApi: TpwSampleApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.tpwService.samples.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((samples: ITpwSample[]) => {
                this.samples = samples;
            });

        this.sampleForm = this.fb.group({
            sample: new FormControl(
                this.selectedSample ? this.selectedSample.categoryName : '',
                Validators.required
            )
        });

        this.tpwSampleApi.getSampleList().subscribe((res: ITpwSample[]) => {
            this.tpwService.samples.next(res);
        });

        this.tpwService.sampleModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleSampleModal(visible);
            });
        this.toggleSampleModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectSample(sample: ITpwSample) {
        if (this.sampleForm.valid) {
            sample = this.samples.find((item: ITpwSample) => {
                if (item.categoryName === this.sampleForm.value.sample) {
                    return true;
                }
            });
            window.sessionStorage.setItem('tpwSelectedSample', JSON.stringify(sample));
            this.tpwService.sampleChanged.next(sample);

            this.tpwService.sampleModalVisible.next(false);
        }
    }

    toggleSampleModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
