import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { IEnvironment } from '../../environment/IEnvironment';

import { IWaitingTransaction } from '../../model/report/publishing/IWaitingTransaction';
import { IExecutionTime } from '../../model/report/publishing/IExecutionTime';
import { IItemStatistic } from '../../model/report/publishing/IItemStatistic';
import { IPriorityStatistic } from '../../model/report/publishing/IPriorityStatistic';

@Injectable()
export class PublishingStatisticsApi extends BaseAuthorizedApi<IEnvironment> {
    getWaitingTransactions(
        startDate: string,
        endDate: string,
        interval?: string,
        brand?: string
    ): Observable<IWaitingTransaction[]> {
        return this.getJson('/PublishingReport/waitingtransactions', {
            params: {
                Start: startDate,
                End: endDate,
                Interval: interval,
                Brand: brand
            }
        });
    }

    getExecutionTimes(
        startDate: string,
        endDate: string,
        interval?: string,
        brand?: string
    ): Observable<IExecutionTime[]> {
        return this.getJson('/PublishingReport/executiontimes', {
            params: {
                Start: startDate,
                End: endDate,
                Interval: interval,
                Brand: brand
            }
        });
    }

    getItemStatistics(
        startDate: string,
        endDate: string,
        interval?: string,
        brand?: string
    ): Observable<IItemStatistic[]> {
        return this.getJson('/PublishingReport/itemsstatistics', {
            params: {
                Start: startDate,
                End: endDate,
                Interval: interval,
                Brand: brand
            }
        });
    }

    getPriorityStatistics(
        startDate: string,
        endDate: string,
        interval?: string,
        brand?: string
    ): Observable<IPriorityStatistic[]> {
        return this.getJson('/PublishingReport/prioritystatistics', {
            params: {
                Start: startDate,
                End: endDate,
                Interval: interval,
                Brand: brand
            }
        });
    }

    protected getBaseUrl() {
        return this.environment.api.publishingStatistics;
    }
}
