import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { forEach, intersection, uniq } from 'lodash';
import { map, shareReplay } from 'rxjs/operators';

import { AuthEntityApi } from '../../api/auth/AuthEntityApi';
import { AuthModuleApi } from '../../api/auth/AuthModuleApi';
import { Brand } from '../../model/auth/login/Brand';
import { IAccess } from '../../model/auth/login/IAccess';

@Injectable()
export class UserAccessService {
    private access: Observable<IAccess>;

    static hasAccess(userAccess: IAccess, requiredAccess?: IAccess): boolean {
        var hasAccess = false,
            hasRequirement = false;

        forEach(requiredAccess, (requiredOptions, requiredType: keyof IAccess) => {
            if (requiredOptions && requiredOptions.length) {
                hasRequirement = true;

                let userValues = userAccess && userAccess[requiredType];
                if (userValues && intersection(requiredOptions, userValues).length) {
                    hasAccess = true;
                }
            }
        });

        return hasAccess || !hasRequirement;
    }

    constructor(
        private authEntityApi: AuthEntityApi,
        private authModuleApi: AuthModuleApi
    ) {}
    // FIXME: Maybe the back end should return this in a single request?
    getAccess(): Observable<IAccess> {
        var access = this.access;
        if (!access) {
            access = forkJoin(
                this.authEntityApi.getAll(),
                this.authModuleApi.getAll()
            ).pipe(
                map(results => {
                    var entities = results[0],
                        brands: Brand[] = [],
                        applications: string[] = [];
                    entities.forEach(entity => {
                        var namePath = entity.namePath;
                        if (namePath) {
                            let names = namePath.split('/');

                            let brand = names[1];
                            if (brand) {
                                brands.push(brand as Brand);
                            }
                            let application = names[2];
                            if (application) {
                                applications.push(application);
                            }
                        }
                    });

                    return {
                        brand: uniq(brands).sort(),
                        application: uniq(applications).sort(),
                        tool: results[1].map(tool => tool.name).sort()
                    } as IAccess;
                }),
                shareReplay(),);
            this.access = access;
        }

        return access;
    }
}
