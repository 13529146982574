import { Http, Response } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TenaJobType } from '../../../../model/product/tena/tcw/TenaJobType';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { TenaService } from '../../../../product/tena/Tena.service';
import { BaseTCWCatalogApi } from './BaseTCWCatalogApi';
import { ITenaUserData } from '../../../../model/product/tena/tcw/ITenaUserData';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { TenaTCWCatalogApi } from './TenaTCWCatalogApi';
import { ITenaTCWCatalog } from '../../../../model/product/tena/tcw/ITenaTCWCatalog';

@Injectable()
export class TenaFulFillmentApi extends BaseTCWCatalogApi<ITenaUserData> {

    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        tenaService: TenaService,
        readonly tenaService1: TenaService,
        liveStageService: LiveStageService,
        readonly tenaCatalogApi: TenaTCWCatalogApi
    ) {
        super(apiErrorHandler, authService, environment, http, tenaService, liveStageService);
    }

    getItems(
        countryId: number,
        jobType: TenaJobType,
        pageSize: number,
        startDate: string,
        endDate: string
    ): Observable<ITenaUserData[]> {
        return this.getJson(`/${countryId}/${jobType}/${startDate}/${endDate}`, {
            params: {
                pageSize: pageSize,
                offset: 0
            }
        });
    }

    getMoreItems(
        countryId: number,
        jobType: TenaJobType,
        pageSize: number,
        offset: number,
        startDate: string,
        endDate: string
    ): Observable<ITenaUserData[]> {
        return this.getJson(`/${countryId}/${jobType}/${startDate}/${endDate}`, {
            params: {
                pageSize: pageSize,
                offset: offset
            }
        });
    }

    getCsv(
        countryId: number,
        jobType: TenaJobType,
        startDate: string,
        endDate: string,
        showPersonalData: boolean
    ): Observable<Response> {
        if (showPersonalData) {
            return this.get(`/${countryId}/${jobType}/${startDate}/${endDate}/excel/includepersonaldata`);
        }
        else {
            return this.get(`/${countryId}/${jobType}/${startDate}/${endDate}/excel`);
        }
    }

    protected getBaseUrl() {
        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
            this.tenaService1.countries.next(res);
        });

        return this.TCWBaseURl + 'countryuserdata';
    }
}
