import { Component, Input } from '@angular/core';
import { ITestData } from '../model/testResults/ITestData';
import { ChartOptions } from 'chart.js';

@Component({
    selector: 'admin-test-results-dashboard',
    templateUrl: 'TestResultDashboard.component.html'
})
export class TestResultDashboardComponent {
    @Input('testData')
    set testData(testData: ITestData) {
        this._testData = testData;
        this.calculateTestData();
    }

    get testData(): ITestData {
        return this._testData;
    }

    totalTests: number;
    passPercentage: number;
    chartData: any = [];
    chartOptions: ChartOptions = {
        responsive: true
    };

    protected _testData: ITestData;

    protected calculateTestData() {
        this.totalTests =
            this.testData.summary.total.pass +
            this.testData.summary.total.fail +
            this.testData.summary.total.skip;
        this.passPercentage = Math.ceil(
            (this.testData.summary.total.pass / this.totalTests) * 100
        );
        this.setChartData();
    }

    protected setChartData() {
        this.chartData = {
            datasets: [
                {
                    data: [
                        // 33, 2, 1
                        this.testData.summary.total.pass,
                        this.testData.summary.total.fail,
                        this.testData.summary.total.skip
                    ],
                    backgroundColor: ['#28a745', '#dc3545', '#80ccff']
                }
            ],
            labels: ['Pass', 'Fail', 'Skipped']
        };
    }
}
