/**
 * Based on https://gist.github.com/ndbroadbent/588fefab8e0f1b459fcec8181b41b39c
 */

/* tslint:disable:max-file-line-count */

export enum Locale {
    af = 'af',
    'af-NA' = 'af-NA',
    'af-ZA' = 'af-ZA',
    agq = 'agq',
    'agq-CM' = 'agq-CM',
    ak = 'ak',
    'ak-GH' = 'ak-GH',
    am = 'am',
    'am-ET' = 'am-ET',
    ar = 'ar',
    'ar-001' = 'ar-001',
    'ar-AE' = 'ar-AE',
    'ar-BH' = 'ar-BH',
    'ar-DJ' = 'ar-DJ',
    'ar-DZ' = 'ar-DZ',
    'ar-EG' = 'ar-EG',
    'ar-EH' = 'ar-EH',
    'ar-ER' = 'ar-ER',
    'ar-IL' = 'ar-IL',
    'ar-IQ' = 'ar-IQ',
    'ar-JO' = 'ar-JO',
    'ar-KM' = 'ar-KM',
    'ar-KW' = 'ar-KW',
    'ar-LB' = 'ar-LB',
    'ar-LY' = 'ar-LY',
    'ar-MA' = 'ar-MA',
    'ar-MR' = 'ar-MR',
    'ar-OM' = 'ar-OM',
    'ar-PS' = 'ar-PS',
    'ar-QA' = 'ar-QA',
    'ar-SA' = 'ar-SA',
    'ar-SD' = 'ar-SD',
    'ar-SO' = 'ar-SO',
    'ar-SS' = 'ar-SS',
    'ar-SY' = 'ar-SY',
    'ar-TD' = 'ar-TD',
    'ar-TN' = 'ar-TN',
    'ar-YE' = 'ar-YE',
    as = 'as',
    'as-IN' = 'as-IN',
    asa = 'asa',
    'asa-TZ' = 'asa-TZ',
    az = 'az',
    'az-Cyrl' = 'az-Cyrl',
    'az-Cyrl-AZ' = 'az-Cyrl-AZ',
    'az-Latn' = 'az-Latn',
    'az-Latn-AZ' = 'az-Latn-AZ',
    bas = 'bas',
    'bas-CM' = 'bas-CM',
    be = 'be',
    'be-BY' = 'be-BY',
    bem = 'bem',
    'bem-ZM' = 'bem-ZM',
    bez = 'bez',
    'bez-TZ' = 'bez-TZ',
    bg = 'bg',
    'bg-BG' = 'bg-BG',
    bm = 'bm',
    'bm-Latn' = 'bm-Latn',
    'bm-Latn-ML' = 'bm-Latn-ML',
    bn = 'bn',
    'bn-BD' = 'bn-BD',
    'bn-IN' = 'bn-IN',
    bo = 'bo',
    'bo-CN' = 'bo-CN',
    'bo-IN' = 'bo-IN',
    br = 'br',
    'br-FR' = 'br-FR',
    brx = 'brx',
    'brx-IN' = 'brx-IN',
    bs = 'bs',
    'bs-Cyrl' = 'bs-Cyrl',
    'bs-Cyrl-BA' = 'bs-Cyrl-BA',
    'bs-Latn' = 'bs-Latn',
    'bs-Latn-BA' = 'bs-Latn-BA',
    ca = 'ca',
    'ca-AD' = 'ca-AD',
    'ca-ES' = 'ca-ES',
    'ca-FR' = 'ca-FR',
    'ca-IT' = 'ca-IT',
    cgg = 'cgg',
    'cgg-UG' = 'cgg-UG',
    chr = 'chr',
    'chr-US' = 'chr-US',
    ckb = 'ckb',
    'ckb-IQ' = 'ckb-IQ',
    'ckb-IR' = 'ckb-IR',
    cs = 'cs',
    'cs-CZ' = 'cs-CZ',
    cy = 'cy',
    'cy-GB' = 'cy-GB',
    da = 'da',
    'da-DK' = 'da-DK',
    'da-GL' = 'da-GL',
    dav = 'dav',
    'dav-KE' = 'dav-KE',
    de = 'de',
    'de-AT' = 'de-AT',
    'de-BE' = 'de-BE',
    'de-CH' = 'de-CH',
    'de-DE' = 'de-DE',
    'de-LI' = 'de-LI',
    'de-LU' = 'de-LU',
    dje = 'dje',
    'dje-NE' = 'dje-NE',
    dsb = 'dsb',
    'dsb-DE' = 'dsb-DE',
    dua = 'dua',
    'dua-CM' = 'dua-CM',
    dyo = 'dyo',
    'dyo-SN' = 'dyo-SN',
    dz = 'dz',
    'dz-BT' = 'dz-BT',
    ebu = 'ebu',
    'ebu-KE' = 'ebu-KE',
    ee = 'ee',
    'ee-GH' = 'ee-GH',
    'ee-TG' = 'ee-TG',
    el = 'el',
    'el-CY' = 'el-CY',
    'el-GR' = 'el-GR',
    en = 'en',
    'en-001' = 'en-001',
    'en-150' = 'en-150',
    'en-AD' = 'en-AD',
    'en-AG' = 'en-AG',
    'en-AI' = 'en-AI',
    'en-AL' = 'en-AL',
    'en-AS' = 'en-AS',
    'en-AT' = 'en-AT',
    'en-AU' = 'en-AU',
    'en-BA' = 'en-BA',
    'en-BB' = 'en-BB',
    'en-BE' = 'en-BE',
    'en-BM' = 'en-BM',
    'en-BS' = 'en-BS',
    'en-BW' = 'en-BW',
    'en-BZ' = 'en-BZ',
    'en-CA' = 'en-CA',
    'en-CC' = 'en-CC',
    'en-CH' = 'en-CH',
    'en-CK' = 'en-CK',
    'en-CM' = 'en-CM',
    'en-CX' = 'en-CX',
    'en-CY' = 'en-CY',
    'en-CZ' = 'en-CZ',
    'en-DE' = 'en-DE',
    'en-DG' = 'en-DG',
    'en-DK' = 'en-DK',
    'en-DM' = 'en-DM',
    'en-EE' = 'en-EE',
    'en-ER' = 'en-ER',
    'en-ES' = 'en-ES',
    'en-FI' = 'en-FI',
    'en-FJ' = 'en-FJ',
    'en-FK' = 'en-FK',
    'en-FM' = 'en-FM',
    'en-FR' = 'en-FR',
    'en-GB' = 'en-GB',
    'en-GD' = 'en-GD',
    'en-GG' = 'en-GG',
    'en-GH' = 'en-GH',
    'en-GI' = 'en-GI',
    'en-GM' = 'en-GM',
    'en-GR' = 'en-GR',
    'en-GU' = 'en-GU',
    'en-GY' = 'en-GY',
    'en-HK' = 'en-HK',
    'en-HR' = 'en-HR',
    'en-HU' = 'en-HU',
    'en-IE' = 'en-IE',
    'en-IL' = 'en-IL',
    'en-IM' = 'en-IM',
    'en-IN' = 'en-IN',
    'en-IO' = 'en-IO',
    'en-IS' = 'en-IS',
    'en-IT' = 'en-IT',
    'en-JE' = 'en-JE',
    'en-JM' = 'en-JM',
    'en-KE' = 'en-KE',
    'en-KI' = 'en-KI',
    'en-KN' = 'en-KN',
    'en-KY' = 'en-KY',
    'en-LC' = 'en-LC',
    'en-LR' = 'en-LR',
    'en-LS' = 'en-LS',
    'en-LT' = 'en-LT',
    'en-LU' = 'en-LU',
    'en-LV' = 'en-LV',
    'en-ME' = 'en-ME',
    'en-MG' = 'en-MG',
    'en-MH' = 'en-MH',
    'en-MO' = 'en-MO',
    'en-MP' = 'en-MP',
    'en-MS' = 'en-MS',
    'en-MT' = 'en-MT',
    'en-MU' = 'en-MU',
    'en-MW' = 'en-MW',
    'en-MY' = 'en-MY',
    'en-NA' = 'en-NA',
    'en-NF' = 'en-NF',
    'en-NG' = 'en-NG',
    'en-NL' = 'en-NL',
    'en-NO' = 'en-NO',
    'en-NR' = 'en-NR',
    'en-NU' = 'en-NU',
    'en-NZ' = 'en-NZ',
    'en-PG' = 'en-PG',
    'en-PH' = 'en-PH',
    'en-PK' = 'en-PK',
    'en-PL' = 'en-PL',
    'en-PN' = 'en-PN',
    'en-PR' = 'en-PR',
    'en-PT' = 'en-PT',
    'en-PW' = 'en-PW',
    'en-RO' = 'en-RO',
    'en-RU' = 'en-RU',
    'en-RW' = 'en-RW',
    'en-SB' = 'en-SB',
    'en-SC' = 'en-SC',
    'en-SD' = 'en-SD',
    'en-SE' = 'en-SE',
    'en-SG' = 'en-SG',
    'en-SH' = 'en-SH',
    'en-SI' = 'en-SI',
    'en-SK' = 'en-SK',
    'en-SL' = 'en-SL',
    'en-SS' = 'en-SS',
    'en-SX' = 'en-SX',
    'en-SZ' = 'en-SZ',
    'en-TC' = 'en-TC',
    'en-TK' = 'en-TK',
    'en-TO' = 'en-TO',
    'en-TR' = 'en-TR',
    'en-TT' = 'en-TT',
    'en-TV' = 'en-TV',
    'en-TZ' = 'en-TZ',
    'en-UG' = 'en-UG',
    'en-UM' = 'en-UM',
    'en-US' = 'en-US',
    'en-US-POSIX' = 'en-US-POSIX',
    'en-VC' = 'en-VC',
    'en-VG' = 'en-VG',
    'en-VI' = 'en-VI',
    'en-VU' = 'en-VU',
    'en-WS' = 'en-WS',
    'en-ZA' = 'en-ZA',
    'en-ZM' = 'en-ZM',
    'en-ZW' = 'en-ZW',
    eo = 'eo',
    es = 'es',
    'es-419' = 'es-419',
    'es-AR' = 'es-AR',
    'es-BO' = 'es-BO',
    'es-CL' = 'es-CL',
    'es-CO' = 'es-CO',
    'es-CR' = 'es-CR',
    'es-CU' = 'es-CU',
    'es-DO' = 'es-DO',
    'es-EA' = 'es-EA',
    'es-EC' = 'es-EC',
    'es-ES' = 'es-ES',
    'es-GQ' = 'es-GQ',
    'es-GT' = 'es-GT',
    'es-HN' = 'es-HN',
    'es-IC' = 'es-IC',
    'es-MX' = 'es-MX',
    'es-NI' = 'es-NI',
    'es-PA' = 'es-PA',
    'es-PE' = 'es-PE',
    'es-PH' = 'es-PH',
    'es-PR' = 'es-PR',
    'es-PY' = 'es-PY',
    'es-SV' = 'es-SV',
    'es-US' = 'es-US',
    'es-UY' = 'es-UY',
    'es-VE' = 'es-VE',
    et = 'et',
    'et-EE' = 'et-EE',
    eu = 'eu',
    'eu-ES' = 'eu-ES',
    ewo = 'ewo',
    'ewo-CM' = 'ewo-CM',
    fa = 'fa',
    'fa-AF' = 'fa-AF',
    'fa-IR' = 'fa-IR',
    ff = 'ff',
    'ff-CM' = 'ff-CM',
    'ff-GN' = 'ff-GN',
    'ff-MR' = 'ff-MR',
    'ff-SN' = 'ff-SN',
    fi = 'fi',
    'fi-FI' = 'fi-FI',
    fil = 'fil',
    'fil-PH' = 'fil-PH',
    fo = 'fo',
    'fo-FO' = 'fo-FO',
    fr = 'fr',
    'fr-BE' = 'fr-BE',
    'fr-BF' = 'fr-BF',
    'fr-BI' = 'fr-BI',
    'fr-BJ' = 'fr-BJ',
    'fr-BL' = 'fr-BL',
    'fr-CA' = 'fr-CA',
    'fr-CD' = 'fr-CD',
    'fr-CF' = 'fr-CF',
    'fr-CG' = 'fr-CG',
    'fr-CH' = 'fr-CH',
    'fr-CI' = 'fr-CI',
    'fr-CM' = 'fr-CM',
    'fr-DJ' = 'fr-DJ',
    'fr-DZ' = 'fr-DZ',
    'fr-FR' = 'fr-FR',
    'fr-GA' = 'fr-GA',
    'fr-GF' = 'fr-GF',
    'fr-GN' = 'fr-GN',
    'fr-GP' = 'fr-GP',
    'fr-GQ' = 'fr-GQ',
    'fr-HT' = 'fr-HT',
    'fr-KM' = 'fr-KM',
    'fr-LU' = 'fr-LU',
    'fr-MA' = 'fr-MA',
    'fr-MC' = 'fr-MC',
    'fr-MF' = 'fr-MF',
    'fr-MG' = 'fr-MG',
    'fr-ML' = 'fr-ML',
    'fr-MQ' = 'fr-MQ',
    'fr-MR' = 'fr-MR',
    'fr-MU' = 'fr-MU',
    'fr-NC' = 'fr-NC',
    'fr-NE' = 'fr-NE',
    'fr-PF' = 'fr-PF',
    'fr-PM' = 'fr-PM',
    'fr-RE' = 'fr-RE',
    'fr-RW' = 'fr-RW',
    'fr-SC' = 'fr-SC',
    'fr-SN' = 'fr-SN',
    'fr-SY' = 'fr-SY',
    'fr-TD' = 'fr-TD',
    'fr-TG' = 'fr-TG',
    'fr-TN' = 'fr-TN',
    'fr-VU' = 'fr-VU',
    'fr-WF' = 'fr-WF',
    'fr-YT' = 'fr-YT',
    fur = 'fur',
    'fur-IT' = 'fur-IT',
    fy = 'fy',
    'fy-NL' = 'fy-NL',
    ga = 'ga',
    'ga-IE' = 'ga-IE',
    gd = 'gd',
    'gd-GB' = 'gd-GB',
    gl = 'gl',
    'gl-ES' = 'gl-ES',
    gsw = 'gsw',
    'gsw-CH' = 'gsw-CH',
    'gsw-FR' = 'gsw-FR',
    'gsw-LI' = 'gsw-LI',
    gu = 'gu',
    'gu-IN' = 'gu-IN',
    guz = 'guz',
    'guz-KE' = 'guz-KE',
    gv = 'gv',
    'gv-IM' = 'gv-IM',
    ha = 'ha',
    'ha-Latn' = 'ha-Latn',
    'ha-Latn-GH' = 'ha-Latn-GH',
    'ha-Latn-NE' = 'ha-Latn-NE',
    'ha-Latn-NG' = 'ha-Latn-NG',
    haw = 'haw',
    'haw-US' = 'haw-US',
    he = 'he',
    'he-IL' = 'he-IL',
    hi = 'hi',
    'hi-IN' = 'hi-IN',
    hr = 'hr',
    'hr-BA' = 'hr-BA',
    'hr-HR' = 'hr-HR',
    hsb = 'hsb',
    'hsb-DE' = 'hsb-DE',
    hu = 'hu',
    'hu-HU' = 'hu-HU',
    hy = 'hy',
    'hy-AM' = 'hy-AM',
    id = 'id',
    'id-ID' = 'id-ID',
    ig = 'ig',
    'ig-NG' = 'ig-NG',
    ii = 'ii',
    'ii-CN' = 'ii-CN',
    is = 'is',
    'is-IS' = 'is-IS',
    it = 'it',
    'it-CH' = 'it-CH',
    'it-IT' = 'it-IT',
    'it-SM' = 'it-SM',
    iu = 'iu',
    'iu-Cans' = 'iu-Cans',
    'iu-Cans-CA' = 'iu-Cans-CA',
    ja = 'ja',
    'ja-JP' = 'ja-JP',
    jgo = 'jgo',
    'jgo-CM' = 'jgo-CM',
    jmc = 'jmc',
    'jmc-TZ' = 'jmc-TZ',
    ka = 'ka',
    'ka-GE' = 'ka-GE',
    kab = 'kab',
    'kab-DZ' = 'kab-DZ',
    kam = 'kam',
    'kam-KE' = 'kam-KE',
    kde = 'kde',
    'kde-TZ' = 'kde-TZ',
    kea = 'kea',
    'kea-CV' = 'kea-CV',
    khq = 'khq',
    'khq-ML' = 'khq-ML',
    ki = 'ki',
    'ki-KE' = 'ki-KE',
    kk = 'kk',
    'kk-Cyrl' = 'kk-Cyrl',
    'kk-Cyrl-KZ' = 'kk-Cyrl-KZ',
    kkj = 'kkj',
    'kkj-CM' = 'kkj-CM',
    kl = 'kl',
    'kl-GL' = 'kl-GL',
    kln = 'kln',
    'kln-KE' = 'kln-KE',
    km = 'km',
    'km-KH' = 'km-KH',
    kn = 'kn',
    'kn-IN' = 'kn-IN',
    ko = 'ko',
    'ko-KP' = 'ko-KP',
    'ko-KR' = 'ko-KR',
    kok = 'kok',
    'kok-IN' = 'kok-IN',
    ks = 'ks',
    'ks-Arab' = 'ks-Arab',
    'ks-Arab-IN' = 'ks-Arab-IN',
    ksb = 'ksb',
    'ksb-TZ' = 'ksb-TZ',
    ksf = 'ksf',
    'ksf-CM' = 'ksf-CM',
    ksh = 'ksh',
    'ksh-DE' = 'ksh-DE',
    kw = 'kw',
    'kw-GB' = 'kw-GB',
    ky = 'ky',
    'ky-Cyrl' = 'ky-Cyrl',
    'ky-Cyrl-KG' = 'ky-Cyrl-KG',
    lag = 'lag',
    'lag-TZ' = 'lag-TZ',
    lb = 'lb',
    'lb-LU' = 'lb-LU',
    lg = 'lg',
    'lg-UG' = 'lg-UG',
    lkt = 'lkt',
    'lkt-US' = 'lkt-US',
    ln = 'ln',
    'ln-AO' = 'ln-AO',
    'ln-CD' = 'ln-CD',
    'ln-CF' = 'ln-CF',
    'ln-CG' = 'ln-CG',
    lo = 'lo',
    'lo-LA' = 'lo-LA',
    localeIdentifier = 'localeIdentifier',
    lt = 'lt',
    'lt-LT' = 'lt-LT',
    lu = 'lu',
    'lu-CD' = 'lu-CD',
    luo = 'luo',
    'luo-KE' = 'luo-KE',
    luy = 'luy',
    'luy-KE' = 'luy-KE',
    lv = 'lv',
    'lv-LV' = 'lv-LV',
    mas = 'mas',
    'mas-KE' = 'mas-KE',
    'mas-TZ' = 'mas-TZ',
    mer = 'mer',
    'mer-KE' = 'mer-KE',
    mfe = 'mfe',
    'mfe-MU' = 'mfe-MU',
    mg = 'mg',
    'mg-MG' = 'mg-MG',
    mgh = 'mgh',
    'mgh-MZ' = 'mgh-MZ',
    mgo = 'mgo',
    'mgo-CM' = 'mgo-CM',
    mk = 'mk',
    'mk-MK' = 'mk-MK',
    ml = 'ml',
    'ml-IN' = 'ml-IN',
    mn = 'mn',
    'mn-Cyrl' = 'mn-Cyrl',
    'mn-Cyrl-MN' = 'mn-Cyrl-MN',
    mr = 'mr',
    'mr-IN' = 'mr-IN',
    ms = 'ms',
    'ms-Arab' = 'ms-Arab',
    'ms-Arab-BN' = 'ms-Arab-BN',
    'ms-Arab-MY' = 'ms-Arab-MY',
    'ms-Latn' = 'ms-Latn',
    'ms-Latn-BN' = 'ms-Latn-BN',
    'ms-Latn-MY' = 'ms-Latn-MY',
    'ms-Latn-SG' = 'ms-Latn-SG',
    mt = 'mt',
    'mt-MT' = 'mt-MT',
    mua = 'mua',
    'mua-CM' = 'mua-CM',
    my = 'my',
    'my-MM' = 'my-MM',
    naq = 'naq',
    'naq-NA' = 'naq-NA',
    nb = 'nb',
    'nb-NO' = 'nb-NO',
    'nb-SJ' = 'nb-SJ',
    nd = 'nd',
    'nd-ZW' = 'nd-ZW',
    ne = 'ne',
    'ne-IN' = 'ne-IN',
    'ne-NP' = 'ne-NP',
    nl = 'nl',
    'nl-AW' = 'nl-AW',
    'nl-BE' = 'nl-BE',
    'nl-BQ' = 'nl-BQ',
    'nl-CW' = 'nl-CW',
    'nl-NL' = 'nl-NL',
    'nl-SR' = 'nl-SR',
    'nl-SX' = 'nl-SX',
    nmg = 'nmg',
    'nmg-CM' = 'nmg-CM',
    nn = 'nn',
    'nn-NO' = 'nn-NO',
    nnh = 'nnh',
    'nnh-CM' = 'nnh-CM',
    nus = 'nus',
    'nus-SD' = 'nus-SD',
    nyn = 'nyn',
    'nyn-UG' = 'nyn-UG',
    om = 'om',
    'om-ET' = 'om-ET',
    'om-KE' = 'om-KE',
    or = 'or',
    'or-IN' = 'or-IN',
    os = 'os',
    'os-GE' = 'os-GE',
    'os-RU' = 'os-RU',
    pa = 'pa',
    'pa-Arab' = 'pa-Arab',
    'pa-Arab-PK' = 'pa-Arab-PK',
    'pa-Guru' = 'pa-Guru',
    'pa-Guru-IN' = 'pa-Guru-IN',
    pl = 'pl',
    'pl-PL' = 'pl-PL',
    ps = 'ps',
    'ps-AF' = 'ps-AF',
    pt = 'pt',
    'pt-AO' = 'pt-AO',
    'pt-BR' = 'pt-BR',
    'pt-CV' = 'pt-CV',
    'pt-GW' = 'pt-GW',
    'pt-MO' = 'pt-MO',
    'pt-MZ' = 'pt-MZ',
    'pt-PT' = 'pt-PT',
    'pt-ST' = 'pt-ST',
    'pt-TL' = 'pt-TL',
    qu = 'qu',
    'qu-BO' = 'qu-BO',
    'qu-EC' = 'qu-EC',
    'qu-PE' = 'qu-PE',
    rm = 'rm',
    'rm-CH' = 'rm-CH',
    rn = 'rn',
    'rn-BI' = 'rn-BI',
    ro = 'ro',
    'ro-MD' = 'ro-MD',
    'ro-RO' = 'ro-RO',
    rof = 'rof',
    'rof-TZ' = 'rof-TZ',
    ru = 'ru',
    'ru-BY' = 'ru-BY',
    'ru-KG' = 'ru-KG',
    'ru-KZ' = 'ru-KZ',
    'ru-MD' = 'ru-MD',
    'ru-RU' = 'ru-RU',
    'ru-UA' = 'ru-UA',
    rw = 'rw',
    'rw-RW' = 'rw-RW',
    rwk = 'rwk',
    'rwk-TZ' = 'rwk-TZ',
    sah = 'sah',
    'sah-RU' = 'sah-RU',
    saq = 'saq',
    'saq-KE' = 'saq-KE',
    sbp = 'sbp',
    'sbp-TZ' = 'sbp-TZ',
    se = 'se',
    'se-FI' = 'se-FI',
    'se-NO' = 'se-NO',
    'se-SE' = 'se-SE',
    seh = 'seh',
    'seh-MZ' = 'seh-MZ',
    ses = 'ses',
    'ses-ML' = 'ses-ML',
    sg = 'sg',
    'sg-CF' = 'sg-CF',
    shi = 'shi',
    'shi-Latn' = 'shi-Latn',
    'shi-Latn-MA' = 'shi-Latn-MA',
    'shi-Tfng' = 'shi-Tfng',
    'shi-Tfng-MA' = 'shi-Tfng-MA',
    si = 'si',
    'si-LK' = 'si-LK',
    sk = 'sk',
    'sk-SK' = 'sk-SK',
    sl = 'sl',
    'sl-SI' = 'sl-SI',
    smn = 'smn',
    'smn-FI' = 'smn-FI',
    sn = 'sn',
    'sn-ZW' = 'sn-ZW',
    so = 'so',
    'so-DJ' = 'so-DJ',
    'so-ET' = 'so-ET',
    'so-KE' = 'so-KE',
    'so-SO' = 'so-SO',
    sq = 'sq',
    'sq-AL' = 'sq-AL',
    'sq-MK' = 'sq-MK',
    'sq-XK' = 'sq-XK',
    sr = 'sr',
    'sr-Cyrl' = 'sr-Cyrl',
    'sr-Cyrl-BA' = 'sr-Cyrl-BA',
    'sr-Cyrl-ME' = 'sr-Cyrl-ME',
    'sr-Cyrl-RS' = 'sr-Cyrl-RS',
    'sr-Cyrl-XK' = 'sr-Cyrl-XK',
    'sr-Latn' = 'sr-Latn',
    'sr-Latn-BA' = 'sr-Latn-BA',
    'sr-Latn-ME' = 'sr-Latn-ME',
    'sr-Latn-RS' = 'sr-Latn-RS',
    'sr-Latn-XK' = 'sr-Latn-XK',
    sv = 'sv',
    'sv-AX' = 'sv-AX',
    'sv-FI' = 'sv-FI',
    'sv-SE' = 'sv-SE',
    sw = 'sw',
    'sw-CD' = 'sw-CD',
    'sw-KE' = 'sw-KE',
    'sw-TZ' = 'sw-TZ',
    'sw-UG' = 'sw-UG',
    ta = 'ta',
    'ta-IN' = 'ta-IN',
    'ta-LK' = 'ta-LK',
    'ta-MY' = 'ta-MY',
    'ta-SG' = 'ta-SG',
    te = 'te',
    'te-IN' = 'te-IN',
    teo = 'teo',
    'teo-KE' = 'teo-KE',
    'teo-UG' = 'teo-UG',
    tg = 'tg',
    'tg-Cyrl' = 'tg-Cyrl',
    'tg-Cyrl-TJ' = 'tg-Cyrl-TJ',
    th = 'th',
    'th-TH' = 'th-TH',
    ti = 'ti',
    'ti-ER' = 'ti-ER',
    'ti-ET' = 'ti-ET',
    tk = 'tk',
    'tk-Latn' = 'tk-Latn',
    'tk-Latn-TM' = 'tk-Latn-TM',
    to = 'to',
    'to-TO' = 'to-TO',
    tr = 'tr',
    'tr-CY' = 'tr-CY',
    'tr-TR' = 'tr-TR',
    twq = 'twq',
    'twq-NE' = 'twq-NE',
    tzm = 'tzm',
    'tzm-Latn' = 'tzm-Latn',
    'tzm-Latn-MA' = 'tzm-Latn-MA',
    ug = 'ug',
    'ug-Arab' = 'ug-Arab',
    'ug-Arab-CN' = 'ug-Arab-CN',
    uk = 'uk',
    'uk-UA' = 'uk-UA',
    ur = 'ur',
    'ur-IN' = 'ur-IN',
    'ur-PK' = 'ur-PK',
    uz = 'uz',
    'uz-Arab' = 'uz-Arab',
    'uz-Arab-AF' = 'uz-Arab-AF',
    'uz-Cyrl' = 'uz-Cyrl',
    'uz-Cyrl-UZ' = 'uz-Cyrl-UZ',
    'uz-Latn' = 'uz-Latn',
    'uz-Latn-UZ' = 'uz-Latn-UZ',
    vai = 'vai',
    'vai-Latn' = 'vai-Latn',
    'vai-Latn-LR' = 'vai-Latn-LR',
    'vai-Vaii' = 'vai-Vaii',
    'vai-Vaii-LR' = 'vai-Vaii-LR',
    vi = 'vi',
    'vi-VN' = 'vi-VN',
    vun = 'vun',
    'vun-TZ' = 'vun-TZ',
    wae = 'wae',
    'wae-CH' = 'wae-CH',
    xog = 'xog',
    'xog-UG' = 'xog-UG',
    yav = 'yav',
    'yav-CM' = 'yav-CM',
    yi = 'yi',
    'yi-001' = 'yi-001',
    yo = 'yo',
    'yo-BJ' = 'yo-BJ',
    'yo-NG' = 'yo-NG',
    zgh = 'zgh',
    'zgh-MA' = 'zgh-MA',
    zh = 'zh',
    'zh-Hans' = 'zh-Hans',
    'zh-Hans-CN' = 'zh-Hans-CN',
    'zh-Hans-HK' = 'zh-Hans-HK',
    'zh-Hans-MO' = 'zh-Hans-MO',
    'zh-Hans-SG' = 'zh-Hans-SG',
    'zh-Hant' = 'zh-Hant',
    'zh-Hant-HK' = 'zh-Hant-HK',
    'zh-Hant-MO' = 'zh-Hant-MO',
    'zh-Hant-TW' = 'zh-Hant-TW',
    zu = 'zu',
    'zu-ZA' = 'zu-ZA'
}

export const LocaleDescription = {
    af: 'Afrikaans',
    'af-NA': 'Afrikaans (Namibia)',
    'af-ZA': 'Afrikaans (South Africa)',
    agq: 'Aghem',
    'agq-CM': 'Aghem (Cameroon)',
    ak: 'Akan',
    'ak-GH': 'Akan (Ghana)',
    am: 'Amharic',
    'am-ET': 'Amharic (Ethiopia)',
    ar: 'Arabic',
    'ar-001': 'Arabic (World)',
    'ar-AE': 'Arabic (United Arab Emirates)',
    'ar-BH': 'Arabic (Bahrain)',
    'ar-DJ': 'Arabic (Djibouti)',
    'ar-DZ': 'Arabic (Algeria)',
    'ar-EG': 'Arabic (Egypt)',
    'ar-EH': 'Arabic (Western Sahara)',
    'ar-ER': 'Arabic (Eritrea)',
    'ar-IL': 'Arabic (Israel)',
    'ar-IQ': 'Arabic (Iraq)',
    'ar-JO': 'Arabic (Jordan)',
    'ar-KM': 'Arabic (Comoros)',
    'ar-KW': 'Arabic (Kuwait)',
    'ar-LB': 'Arabic (Lebanon)',
    'ar-LY': 'Arabic (Libya)',
    'ar-MA': 'Arabic (Morocco)',
    'ar-MR': 'Arabic (Mauritania)',
    'ar-OM': 'Arabic (Oman)',
    'ar-PS': 'Arabic (Palestinian Territories)',
    'ar-QA': 'Arabic (Qatar)',
    'ar-SA': 'Arabic (Saudi Arabia)',
    'ar-SD': 'Arabic (Sudan)',
    'ar-SO': 'Arabic (Somalia)',
    'ar-SS': 'Arabic (South Sudan)',
    'ar-SY': 'Arabic (Syria)',
    'ar-TD': 'Arabic (Chad)',
    'ar-TN': 'Arabic (Tunisia)',
    'ar-YE': 'Arabic (Yemen)',
    as: 'Assamese',
    'as-IN': 'Assamese (India)',
    asa: 'Asu',
    'asa-TZ': 'Asu (Tanzania)',
    az: 'Azerbaijani',
    'az-Cyrl': 'Azerbaijani (Cyrillic)',
    'az-Cyrl-AZ': 'Azerbaijani (Cyrillic, Azerbaijan)',
    'az-Latn': 'Azerbaijani (Latin)',
    'az-Latn-AZ': 'Azerbaijani (Latin, Azerbaijan)',
    bas: 'Basaa',
    'bas-CM': 'Basaa (Cameroon)',
    be: 'Belarusian',
    'be-BY': 'Belarusian (Belarus)',
    bem: 'Bemba',
    'bem-ZM': 'Bemba (Zambia)',
    bez: 'Bena',
    'bez-TZ': 'Bena (Tanzania)',
    bg: 'Bulgarian',
    'bg-BG': 'Bulgarian (Bulgaria)',
    bm: 'Bambara',
    'bm-Latn': 'Bambara (Latin)',
    'bm-Latn-ML': 'Bambara (Latin, Mali)',
    bn: 'Bengali',
    'bn-BD': 'Bengali (Bangladesh)',
    'bn-IN': 'Bengali (India)',
    bo: 'Tibetan',
    'bo-CN': 'Tibetan (China)',
    'bo-IN': 'Tibetan (India)',
    br: 'Breton',
    'br-FR': 'Breton (France)',
    brx: 'Bodo',
    'brx-IN': 'Bodo (India)',
    bs: 'Bosnian',
    'bs-Cyrl': 'Bosnian (Cyrillic)',
    'bs-Cyrl-BA': 'Bosnian (Cyrillic, Bosnia & Herzegovina)',
    'bs-Latn': 'Bosnian (Latin)',
    'bs-Latn-BA': 'Bosnian (Latin, Bosnia & Herzegovina)',
    ca: 'Catalan',
    'ca-AD': 'Catalan (Andorra)',
    'ca-ES': 'Catalan (Spain)',
    'ca-FR': 'Catalan (France)',
    'ca-IT': 'Catalan (Italy)',
    cgg: 'Chiga',
    'cgg-UG': 'Chiga (Uganda)',
    chr: 'Cherokee',
    'chr-US': 'Cherokee (United States)',
    ckb: 'Central Kurdish',
    'ckb-IQ': 'Central Kurdish (Iraq)',
    'ckb-IR': 'Central Kurdish (Iran)',
    cs: 'Czech',
    'cs-CZ': 'Czech (Czech Republic)',
    cy: 'Welsh',
    'cy-GB': 'Welsh (United Kingdom)',
    da: 'Danish',
    'da-DK': 'Danish (Denmark)',
    'da-GL': 'Danish (Greenland)',
    dav: 'Taita',
    'dav-KE': 'Taita (Kenya)',
    de: 'German',
    'de-AT': 'German (Austria)',
    'de-BE': 'German (Belgium)',
    'de-CH': 'German (Switzerland)',
    'de-DE': 'German (Germany)',
    'de-LI': 'German (Liechtenstein)',
    'de-LU': 'German (Luxembourg)',
    dje: 'Zarma',
    'dje-NE': 'Zarma (Niger)',
    dsb: 'Lower Sorbian',
    'dsb-DE': 'Lower Sorbian (Germany)',
    dua: 'Duala',
    'dua-CM': 'Duala (Cameroon)',
    dyo: 'Jola-Fonyi',
    'dyo-SN': 'Jola-Fonyi (Senegal)',
    dz: 'Dzongkha',
    'dz-BT': 'Dzongkha (Bhutan)',
    ebu: 'Embu',
    'ebu-KE': 'Embu (Kenya)',
    ee: 'Ewe',
    'ee-GH': 'Ewe (Ghana)',
    'ee-TG': 'Ewe (Togo)',
    el: 'Greek',
    'el-CY': 'Greek (Cyprus)',
    'el-GR': 'Greek (Greece)',
    en: 'English',
    'en-001': 'English (World)',
    'en-150': 'English (Europe)',
    'en-AD': 'English (Andorra)',
    'en-AG': 'English (Antigua & Barbuda)',
    'en-AI': 'English (Anguilla)',
    'en-AL': 'English (Albania)',
    'en-AS': 'English (American Samoa)',
    'en-AT': 'English (Austria)',
    'en-AU': 'English (Australia)',
    'en-BA': 'English (Bosnia & Herzegovina)',
    'en-BB': 'English (Barbados)',
    'en-BE': 'English (Belgium)',
    'en-BM': 'English (Bermuda)',
    'en-BS': 'English (Bahamas)',
    'en-BW': 'English (Botswana)',
    'en-BZ': 'English (Belize)',
    'en-CA': 'English (Canada)',
    'en-CC': 'English (Cocos [Keeling] Islands)',
    'en-CH': 'English (Switzerland)',
    'en-CK': 'English (Cook Islands)',
    'en-CM': 'English (Cameroon)',
    'en-CX': 'English (Christmas Island)',
    'en-CY': 'English (Cyprus)',
    'en-CZ': 'English (Czech Republic)',
    'en-DE': 'English (Germany)',
    'en-DG': 'English (Diego Garcia)',
    'en-DK': 'English (Denmark)',
    'en-DM': 'English (Dominica)',
    'en-EE': 'English (Estonia)',
    'en-ER': 'English (Eritrea)',
    'en-ES': 'English (Spain)',
    'en-FI': 'English (Finland)',
    'en-FJ': 'English (Fiji)',
    'en-FK': 'English (Falkland Islands)',
    'en-FM': 'English (Micronesia)',
    'en-FR': 'English (France)',
    'en-GB': 'English (United Kingdom)',
    'en-GD': 'English (Grenada)',
    'en-GG': 'English (Guernsey)',
    'en-GH': 'English (Ghana)',
    'en-GI': 'English (Gibraltar)',
    'en-GM': 'English (Gambia)',
    'en-GR': 'English (Greece)',
    'en-GU': 'English (Guam)',
    'en-GY': 'English (Guyana)',
    'en-HK': 'English (Hong Kong SAR China)',
    'en-HR': 'English (Croatia)',
    'en-HU': 'English (Hungary)',
    'en-IE': 'English (Ireland)',
    'en-IL': 'English (Israel)',
    'en-IM': 'English (Isle of Man)',
    'en-IN': 'English (India)',
    'en-IO': 'English (British Indian Ocean Territory)',
    'en-IS': 'English (Iceland)',
    'en-IT': 'English (Italy)',
    'en-JE': 'English (Jersey)',
    'en-JM': 'English (Jamaica)',
    'en-KE': 'English (Kenya)',
    'en-KI': 'English (Kiribati)',
    'en-KN': 'English (St. Kitts & Nevis)',
    'en-KY': 'English (Cayman Islands)',
    'en-LC': 'English (St. Lucia)',
    'en-LR': 'English (Liberia)',
    'en-LS': 'English (Lesotho)',
    'en-LT': 'English (Lithuania)',
    'en-LU': 'English (Luxembourg)',
    'en-LV': 'English (Latvia)',
    'en-ME': 'English (Montenegro)',
    'en-MG': 'English (Madagascar)',
    'en-MH': 'English (Marshall Islands)',
    'en-MO': 'English (Macau SAR China)',
    'en-MP': 'English (Northern Mariana Islands)',
    'en-MS': 'English (Montserrat)',
    'en-MT': 'English (Malta)',
    'en-MU': 'English (Mauritius)',
    'en-MW': 'English (Malawi)',
    'en-MY': 'English (Malaysia)',
    'en-NA': 'English (Namibia)',
    'en-NF': 'English (Norfolk Island)',
    'en-NG': 'English (Nigeria)',
    'en-NL': 'English (Netherlands)',
    'en-NO': 'English (Norway)',
    'en-NR': 'English (Nauru)',
    'en-NU': 'English (Niue)',
    'en-NZ': 'English (New Zealand)',
    'en-PG': 'English (Papua New Guinea)',
    'en-PH': 'English (Philippines)',
    'en-PK': 'English (Pakistan)',
    'en-PL': 'English (Poland)',
    'en-PN': 'English (Pitcairn Islands)',
    'en-PR': 'English (Puerto Rico)',
    'en-PT': 'English (Portugal)',
    'en-PW': 'English (Palau)',
    'en-RO': 'English (Romania)',
    'en-RU': 'English (Russia)',
    'en-RW': 'English (Rwanda)',
    'en-SB': 'English (Solomon Islands)',
    'en-SC': 'English (Seychelles)',
    'en-SD': 'English (Sudan)',
    'en-SE': 'English (Sweden)',
    'en-SG': 'English (Singapore)',
    'en-SH': 'English (St. Helena)',
    'en-SI': 'English (Slovenia)',
    'en-SK': 'English (Slovakia)',
    'en-SL': 'English (Sierra Leone)',
    'en-SS': 'English (South Sudan)',
    'en-SX': 'English (Sint Maarten)',
    'en-SZ': 'English (Swaziland)',
    'en-TC': 'English (Turks & Caicos Islands)',
    'en-TK': 'English (Tokelau)',
    'en-TO': 'English (Tonga)',
    'en-TR': 'English (Turkey)',
    'en-TT': 'English (Trinidad & Tobago)',
    'en-TV': 'English (Tuvalu)',
    'en-TZ': 'English (Tanzania)',
    'en-UG': 'English (Uganda)',
    'en-UM': 'English (U.S. Outlying Islands)',
    'en-US': 'English (United States)',
    'en-US-POSIX': 'English (United States, Computer)',
    'en-VC': 'English (St. Vincent & Grenadines)',
    'en-VG': 'English (British Virgin Islands)',
    'en-VI': 'English (U.S. Virgin Islands)',
    'en-VU': 'English (Vanuatu)',
    'en-WS': 'English (Samoa)',
    'en-ZA': 'English (South Africa)',
    'en-ZM': 'English (Zambia)',
    'en-ZW': 'English (Zimbabwe)',
    eo: 'Esperanto',
    es: 'Spanish',
    'es-419': 'Spanish (Latin America)',
    'es-AR': 'Spanish (Argentina)',
    'es-BO': 'Spanish (Bolivia)',
    'es-CL': 'Spanish (Chile)',
    'es-CO': 'Spanish (Colombia)',
    'es-CR': 'Spanish (Costa Rica)',
    'es-CU': 'Spanish (Cuba)',
    'es-DO': 'Spanish (Dominican Republic)',
    'es-EA': 'Spanish (Ceuta & Melilla)',
    'es-EC': 'Spanish (Ecuador)',
    'es-ES': 'Spanish (Spain)',
    'es-GQ': 'Spanish (Equatorial Guinea)',
    'es-GT': 'Spanish (Guatemala)',
    'es-HN': 'Spanish (Honduras)',
    'es-IC': 'Spanish (Canary Islands)',
    'es-MX': 'Spanish (Mexico)',
    'es-NI': 'Spanish (Nicaragua)',
    'es-PA': 'Spanish (Panama)',
    'es-PE': 'Spanish (Peru)',
    'es-PH': 'Spanish (Philippines)',
    'es-PR': 'Spanish (Puerto Rico)',
    'es-PY': 'Spanish (Paraguay)',
    'es-SV': 'Spanish (El Salvador)',
    'es-US': 'Spanish (United States)',
    'es-UY': 'Spanish (Uruguay)',
    'es-VE': 'Spanish (Venezuela)',
    et: 'Estonian',
    'et-EE': 'Estonian (Estonia)',
    eu: 'Basque',
    'eu-ES': 'Basque (Spain)',
    ewo: 'Ewondo',
    'ewo-CM': 'Ewondo (Cameroon)',
    fa: 'Persian',
    'fa-AF': 'Persian (Afghanistan)',
    'fa-IR': 'Persian (Iran)',
    ff: 'Fulah',
    'ff-CM': 'Fulah (Cameroon)',
    'ff-GN': 'Fulah (Guinea)',
    'ff-MR': 'Fulah (Mauritania)',
    'ff-SN': 'Fulah (Senegal)',
    fi: 'Finnish',
    'fi-FI': 'Finnish (Finland)',
    fil: 'Filipino',
    'fil-PH': 'Filipino (Philippines)',
    fo: 'Faroese',
    'fo-FO': 'Faroese (Faroe Islands)',
    fr: 'French',
    'fr-BE': 'French (Belgium)',
    'fr-BF': 'French (Burkina Faso)',
    'fr-BI': 'French (Burundi)',
    'fr-BJ': 'French (Benin)',
    'fr-BL': 'French (St. Barthélemy)',
    'fr-CA': 'French (Canada)',
    'fr-CD': 'French (Congo - Kinshasa)',
    'fr-CF': 'French (Central African Republic)',
    'fr-CG': 'French (Congo - Brazzaville)',
    'fr-CH': 'French (Switzerland)',
    'fr-CI': 'French (Côte d’Ivoire)',
    'fr-CM': 'French (Cameroon)',
    'fr-DJ': 'French (Djibouti)',
    'fr-DZ': 'French (Algeria)',
    'fr-FR': 'French (France)',
    'fr-GA': 'French (Gabon)',
    'fr-GF': 'French (French Guiana)',
    'fr-GN': 'French (Guinea)',
    'fr-GP': 'French (Guadeloupe)',
    'fr-GQ': 'French (Equatorial Guinea)',
    'fr-HT': 'French (Haiti)',
    'fr-KM': 'French (Comoros)',
    'fr-LU': 'French (Luxembourg)',
    'fr-MA': 'French (Morocco)',
    'fr-MC': 'French (Monaco)',
    'fr-MF': 'French (St. Martin)',
    'fr-MG': 'French (Madagascar)',
    'fr-ML': 'French (Mali)',
    'fr-MQ': 'French (Martinique)',
    'fr-MR': 'French (Mauritania)',
    'fr-MU': 'French (Mauritius)',
    'fr-NC': 'French (New Caledonia)',
    'fr-NE': 'French (Niger)',
    'fr-PF': 'French (French Polynesia)',
    'fr-PM': 'French (St. Pierre & Miquelon)',
    'fr-RE': 'French (Réunion)',
    'fr-RW': 'French (Rwanda)',
    'fr-SC': 'French (Seychelles)',
    'fr-SN': 'French (Senegal)',
    'fr-SY': 'French (Syria)',
    'fr-TD': 'French (Chad)',
    'fr-TG': 'French (Togo)',
    'fr-TN': 'French (Tunisia)',
    'fr-VU': 'French (Vanuatu)',
    'fr-WF': 'French (Wallis & Futuna)',
    'fr-YT': 'French (Mayotte)',
    fur: 'Friulian',
    'fur-IT': 'Friulian (Italy)',
    fy: 'Western Frisian',
    'fy-NL': 'Western Frisian (Netherlands)',
    ga: 'Irish',
    'ga-IE': 'Irish (Ireland)',
    gd: 'Scottish Gaelic',
    'gd-GB': 'Scottish Gaelic (United Kingdom)',
    gl: 'Galician',
    'gl-ES': 'Galician (Spain)',
    gsw: 'Swiss German',
    'gsw-CH': 'Swiss German (Switzerland)',
    'gsw-FR': 'Swiss German (France)',
    'gsw-LI': 'Swiss German (Liechtenstein)',
    gu: 'Gujarati',
    'gu-IN': 'Gujarati (India)',
    guz: 'Gusii',
    'guz-KE': 'Gusii (Kenya)',
    gv: 'Manx',
    'gv-IM': 'Manx (Isle of Man)',
    ha: 'Hausa',
    'ha-Latn': 'Hausa (Latin)',
    'ha-Latn-GH': 'Hausa (Latin, Ghana)',
    'ha-Latn-NE': 'Hausa (Latin, Niger)',
    'ha-Latn-NG': 'Hausa (Latin, Nigeria)',
    haw: 'Hawaiian',
    'haw-US': 'Hawaiian (United States)',
    he: 'Hebrew',
    'he-IL': 'Hebrew (Israel)',
    hi: 'Hindi',
    'hi-IN': 'Hindi (India)',
    hr: 'Croatian',
    'hr-BA': 'Croatian (Bosnia & Herzegovina)',
    'hr-HR': 'Croatian (Croatia)',
    hsb: 'Upper Sorbian',
    'hsb-DE': 'Upper Sorbian (Germany)',
    hu: 'Hungarian',
    'hu-HU': 'Hungarian (Hungary)',
    hy: 'Armenian',
    'hy-AM': 'Armenian (Armenia)',
    id: 'Indonesian',
    'id-ID': 'Indonesian (Indonesia)',
    ig: 'Igbo',
    'ig-NG': 'Igbo (Nigeria)',
    ii: 'Sichuan Yi',
    'ii-CN': 'Sichuan Yi (China)',
    is: 'Icelandic',
    'is-IS': 'Icelandic (Iceland)',
    it: 'Italian',
    'it-CH': 'Italian (Switzerland)',
    'it-IT': 'Italian (Italy)',
    'it-SM': 'Italian (San Marino)',
    iu: 'Inuktitut',
    'iu-Cans': 'Inuktitut (Unified Canadian Aboriginal Syllabics)',
    'iu-Cans-CA': 'Inuktitut (Unified Canadian Aboriginal Syllabics, Canada)',
    ja: 'Japanese',
    'ja-JP': 'Japanese (Japan)',
    jgo: 'Ngomba',
    'jgo-CM': 'Ngomba (Cameroon)',
    jmc: 'Machame',
    'jmc-TZ': 'Machame (Tanzania)',
    ka: 'Georgian',
    'ka-GE': 'Georgian (Georgia)',
    kab: 'Kabyle',
    'kab-DZ': 'Kabyle (Algeria)',
    kam: 'Kamba',
    'kam-KE': 'Kamba (Kenya)',
    kde: 'Makonde',
    'kde-TZ': 'Makonde (Tanzania)',
    kea: 'Kabuverdianu',
    'kea-CV': 'Kabuverdianu (Cape Verde)',
    khq: 'Koyra Chiini',
    'khq-ML': 'Koyra Chiini (Mali)',
    ki: 'Kikuyu',
    'ki-KE': 'Kikuyu (Kenya)',
    kk: 'Kazakh',
    'kk-Cyrl': 'Kazakh (Cyrillic)',
    'kk-Cyrl-KZ': 'Kazakh (Cyrillic, Kazakhstan)',
    kkj: 'Kako',
    'kkj-CM': 'Kako (Cameroon)',
    kl: 'Kalaallisut',
    'kl-GL': 'Kalaallisut (Greenland)',
    kln: 'Kalenjin',
    'kln-KE': 'Kalenjin (Kenya)',
    km: 'Khmer',
    'km-KH': 'Khmer (Cambodia)',
    kn: 'Kannada',
    'kn-IN': 'Kannada (India)',
    ko: 'Korean',
    'ko-KP': 'Korean (North Korea)',
    'ko-KR': 'Korean (South Korea)',
    kok: 'Konkani',
    'kok-IN': 'Konkani (India)',
    ks: 'Kashmiri',
    'ks-Arab': 'Kashmiri (Arabic)',
    'ks-Arab-IN': 'Kashmiri (Arabic, India)',
    ksb: 'Shambala',
    'ksb-TZ': 'Shambala (Tanzania)',
    ksf: 'Bafia',
    'ksf-CM': 'Bafia (Cameroon)',
    ksh: 'Colognian',
    'ksh-DE': 'Colognian (Germany)',
    kw: 'Cornish',
    'kw-GB': 'Cornish (United Kingdom)',
    ky: 'Kyrgyz',
    'ky-Cyrl': 'Kyrgyz (Cyrillic)',
    'ky-Cyrl-KG': 'Kyrgyz (Cyrillic, Kyrgyzstan)',
    lag: 'Langi',
    'lag-TZ': 'Langi (Tanzania)',
    lb: 'Luxembourgish',
    'lb-LU': 'Luxembourgish (Luxembourg)',
    lg: 'Ganda',
    'lg-UG': 'Ganda (Uganda)',
    lkt: 'Lakota',
    'lkt-US': 'Lakota (United States)',
    ln: 'Lingala',
    'ln-AO': 'Lingala (Angola)',
    'ln-CD': 'Lingala (Congo - Kinshasa)',
    'ln-CF': 'Lingala (Central African Republic)',
    'ln-CG': 'Lingala (Congo - Brazzaville)',
    lo: 'Lao',
    'lo-LA': 'Lao (Laos)',
    localeIdentifier: 'Description',
    lt: 'Lithuanian',
    'lt-LT': 'Lithuanian (Lithuania)',
    lu: 'Luba-Katanga',
    'lu-CD': 'Luba-Katanga (Congo - Kinshasa)',
    luo: 'Luo',
    'luo-KE': 'Luo (Kenya)',
    luy: 'Luyia',
    'luy-KE': 'Luyia (Kenya)',
    lv: 'Latvian',
    'lv-LV': 'Latvian (Latvia)',
    mas: 'Masai',
    'mas-KE': 'Masai (Kenya)',
    'mas-TZ': 'Masai (Tanzania)',
    mer: 'Meru',
    'mer-KE': 'Meru (Kenya)',
    mfe: 'Morisyen',
    'mfe-MU': 'Morisyen (Mauritius)',
    mg: 'Malagasy',
    'mg-MG': 'Malagasy (Madagascar)',
    mgh: 'Makhuwa-Meetto',
    'mgh-MZ': 'Makhuwa-Meetto (Mozambique)',
    mgo: 'Metaʼ',
    'mgo-CM': 'Metaʼ (Cameroon)',
    mk: 'Macedonian',
    'mk-MK': 'Macedonian (Macedonia)',
    ml: 'Malayalam',
    'ml-IN': 'Malayalam (India)',
    mn: 'Mongolian',
    'mn-Cyrl': 'Mongolian (Cyrillic)',
    'mn-Cyrl-MN': 'Mongolian (Cyrillic, Mongolia)',
    mr: 'Marathi',
    'mr-IN': 'Marathi (India)',
    ms: 'Malay',
    'ms-Arab': 'Malay (Arabic)',
    'ms-Arab-BN': 'Malay (Arabic, Brunei)',
    'ms-Arab-MY': 'Malay (Arabic, Malaysia)',
    'ms-Latn': 'Malay (Latin)',
    'ms-Latn-BN': 'Malay (Latin, Brunei)',
    'ms-Latn-MY': 'Malay (Latin, Malaysia)',
    'ms-Latn-SG': 'Malay (Latin, Singapore)',
    mt: 'Maltese',
    'mt-MT': 'Maltese (Malta)',
    mua: 'Mundang',
    'mua-CM': 'Mundang (Cameroon)',
    my: 'Burmese',
    'my-MM': 'Burmese (Myanmar [Burma])',
    naq: 'Nama',
    'naq-NA': 'Nama (Namibia)',
    nb: 'Norwegian Bokmål',
    'nb-NO': 'Norwegian Bokmål (Norway)',
    'nb-SJ': 'Norwegian Bokmål (Svalbard & Jan Mayen)',
    nd: 'North Ndebele',
    'nd-ZW': 'North Ndebele (Zimbabwe)',
    ne: 'Nepali',
    'ne-IN': 'Nepali (India)',
    'ne-NP': 'Nepali (Nepal)',
    nl: 'Dutch',
    'nl-AW': 'Dutch (Aruba)',
    'nl-BE': 'Dutch (Belgium)',
    'nl-BQ': 'Dutch (Caribbean Netherlands)',
    'nl-CW': 'Dutch (Curaçao)',
    'nl-NL': 'Dutch (Netherlands)',
    'nl-SR': 'Dutch (Suriname)',
    'nl-SX': 'Dutch (Sint Maarten)',
    nmg: 'Kwasio',
    'nmg-CM': 'Kwasio (Cameroon)',
    nn: 'Norwegian Nynorsk',
    'nn-NO': 'Norwegian Nynorsk (Norway)',
    nnh: 'Ngiemboon',
    'nnh-CM': 'Ngiemboon (Cameroon)',
    nus: 'Nuer',
    'nus-SD': 'Nuer (Sudan)',
    nyn: 'Nyankole',
    'nyn-UG': 'Nyankole (Uganda)',
    om: 'Oromo',
    'om-ET': 'Oromo (Ethiopia)',
    'om-KE': 'Oromo (Kenya)',
    or: 'Oriya',
    'or-IN': 'Oriya (India)',
    os: 'Ossetic',
    'os-GE': 'Ossetic (Georgia)',
    'os-RU': 'Ossetic (Russia)',
    pa: 'Punjabi',
    'pa-Arab': 'Punjabi (Arabic)',
    'pa-Arab-PK': 'Punjabi (Arabic, Pakistan)',
    'pa-Guru': 'Punjabi (Gurmukhi)',
    'pa-Guru-IN': 'Punjabi (Gurmukhi, India)',
    pl: 'Polish',
    'pl-PL': 'Polish (Poland)',
    ps: 'Pashto',
    'ps-AF': 'Pashto (Afghanistan)',
    pt: 'Portuguese',
    'pt-AO': 'Portuguese (Angola)',
    'pt-BR': 'Portuguese (Brazil)',
    'pt-CV': 'Portuguese (Cape Verde)',
    'pt-GW': 'Portuguese (Guinea-Bissau)',
    'pt-MO': 'Portuguese (Macau SAR China)',
    'pt-MZ': 'Portuguese (Mozambique)',
    'pt-PT': 'Portuguese (Portugal)',
    'pt-ST': 'Portuguese (São Tomé & Príncipe)',
    'pt-TL': 'Portuguese (Timor-Leste)',
    qu: 'Quechua',
    'qu-BO': 'Quechua (Bolivia)',
    'qu-EC': 'Quechua (Ecuador)',
    'qu-PE': 'Quechua (Peru)',
    rm: 'Romansh',
    'rm-CH': 'Romansh (Switzerland)',
    rn: 'Rundi',
    'rn-BI': 'Rundi (Burundi)',
    ro: 'Romanian',
    'ro-MD': 'Romanian (Moldova)',
    'ro-RO': 'Romanian (Romania)',
    rof: 'Rombo',
    'rof-TZ': 'Rombo (Tanzania)',
    ru: 'Russian',
    'ru-BY': 'Russian (Belarus)',
    'ru-KG': 'Russian (Kyrgyzstan)',
    'ru-KZ': 'Russian (Kazakhstan)',
    'ru-MD': 'Russian (Moldova)',
    'ru-RU': 'Russian (Russia)',
    'ru-UA': 'Russian (Ukraine)',
    rw: 'Kinyarwanda',
    'rw-RW': 'Kinyarwanda (Rwanda)',
    rwk: 'Rwa',
    'rwk-TZ': 'Rwa (Tanzania)',
    sah: 'Sakha',
    'sah-RU': 'Sakha (Russia)',
    saq: 'Samburu',
    'saq-KE': 'Samburu (Kenya)',
    sbp: 'Sangu',
    'sbp-TZ': 'Sangu (Tanzania)',
    se: 'Northern Sami',
    'se-FI': 'Northern Sami (Finland)',
    'se-NO': 'Northern Sami (Norway)',
    'se-SE': 'Northern Sami (Sweden)',
    seh: 'Sena',
    'seh-MZ': 'Sena (Mozambique)',
    ses: 'Koyraboro Senni',
    'ses-ML': 'Koyraboro Senni (Mali)',
    sg: 'Sango',
    'sg-CF': 'Sango (Central African Republic)',
    shi: 'Tachelhit',
    'shi-Latn': 'Tachelhit (Latin)',
    'shi-Latn-MA': 'Tachelhit (Latin, Morocco)',
    'shi-Tfng': 'Tachelhit (Tifinagh)',
    'shi-Tfng-MA': 'Tachelhit (Tifinagh, Morocco)',
    si: 'Sinhala',
    'si-LK': 'Sinhala (Sri Lanka)',
    sk: 'Slovak',
    'sk-SK': 'Slovak (Slovakia)',
    sl: 'Slovenian',
    'sl-SI': 'Slovenian (Slovenia)',
    smn: 'Inari Sami',
    'smn-FI': 'Inari Sami (Finland)',
    sn: 'Shona',
    'sn-ZW': 'Shona (Zimbabwe)',
    so: 'Somali',
    'so-DJ': 'Somali (Djibouti)',
    'so-ET': 'Somali (Ethiopia)',
    'so-KE': 'Somali (Kenya)',
    'so-SO': 'Somali (Somalia)',
    sq: 'Albanian',
    'sq-AL': 'Albanian (Albania)',
    'sq-MK': 'Albanian (Macedonia)',
    'sq-XK': 'Albanian (Kosovo)',
    sr: 'Serbian',
    'sr-Cyrl': 'Serbian (Cyrillic)',
    'sr-Cyrl-BA': 'Serbian (Cyrillic, Bosnia & Herzegovina)',
    'sr-Cyrl-ME': 'Serbian (Cyrillic, Montenegro)',
    'sr-Cyrl-RS': 'Serbian (Cyrillic, Serbia)',
    'sr-Cyrl-XK': 'Serbian (Cyrillic, Kosovo)',
    'sr-Latn': 'Serbian (Latin)',
    'sr-Latn-BA': 'Serbian (Latin, Bosnia & Herzegovina)',
    'sr-Latn-ME': 'Serbian (Latin, Montenegro)',
    'sr-Latn-RS': 'Serbian (Latin, Serbia)',
    'sr-Latn-XK': 'Serbian (Latin, Kosovo)',
    sv: 'Swedish',
    'sv-AX': 'Swedish (Åland Islands)',
    'sv-FI': 'Swedish (Finland)',
    'sv-SE': 'Swedish (Sweden)',
    sw: 'Swahili',
    'sw-CD': 'Swahili (Congo - Kinshasa)',
    'sw-KE': 'Swahili (Kenya)',
    'sw-TZ': 'Swahili (Tanzania)',
    'sw-UG': 'Swahili (Uganda)',
    ta: 'Tamil',
    'ta-IN': 'Tamil (India)',
    'ta-LK': 'Tamil (Sri Lanka)',
    'ta-MY': 'Tamil (Malaysia)',
    'ta-SG': 'Tamil (Singapore)',
    te: 'Telugu',
    'te-IN': 'Telugu (India)',
    teo: 'Teso',
    'teo-KE': 'Teso (Kenya)',
    'teo-UG': 'Teso (Uganda)',
    tg: 'Tajik',
    'tg-Cyrl': 'Tajik (Cyrillic)',
    'tg-Cyrl-TJ': 'Tajik (Cyrillic, Tajikistan)',
    th: 'Thai',
    'th-TH': 'Thai (Thailand)',
    ti: 'Tigrinya',
    'ti-ER': 'Tigrinya (Eritrea)',
    'ti-ET': 'Tigrinya (Ethiopia)',
    tk: 'Turkmen',
    'tk-Latn': 'Turkmen (Latin)',
    'tk-Latn-TM': 'Turkmen (Latin, Turkmenistan)',
    to: 'Tongan',
    'to-TO': 'Tongan (Tonga)',
    tr: 'Turkish',
    'tr-CY': 'Turkish (Cyprus)',
    'tr-TR': 'Turkish (Turkey)',
    twq: 'Tasawaq',
    'twq-NE': 'Tasawaq (Niger)',
    tzm: 'Central Atlas Tamazight',
    'tzm-Latn': 'Central Atlas Tamazight (Latin)',
    'tzm-Latn-MA': 'Central Atlas Tamazight (Latin, Morocco)',
    ug: 'Uyghur',
    'ug-Arab': 'Uyghur (Arabic)',
    'ug-Arab-CN': 'Uyghur (Arabic, China)',
    uk: 'Ukrainian',
    'uk-UA': 'Ukrainian (Ukraine)',
    ur: 'Urdu',
    'ur-IN': 'Urdu (India)',
    'ur-PK': 'Urdu (Pakistan)',
    uz: 'Uzbek',
    'uz-Arab': 'Uzbek (Arabic)',
    'uz-Arab-AF': 'Uzbek (Arabic, Afghanistan)',
    'uz-Cyrl': 'Uzbek (Cyrillic)',
    'uz-Cyrl-UZ': 'Uzbek (Cyrillic, Uzbekistan)',
    'uz-Latn': 'Uzbek (Latin)',
    'uz-Latn-UZ': 'Uzbek (Latin, Uzbekistan)',
    vai: 'Vai',
    'vai-Latn': 'Vai (Latin)',
    'vai-Latn-LR': 'Vai (Latin, Liberia)',
    'vai-Vaii': 'Vai (Vai)',
    'vai-Vaii-LR': 'Vai (Vai, Liberia)',
    vi: 'Vietnamese',
    'vi-VN': 'Vietnamese (Vietnam)',
    vun: 'Vunjo',
    'vun-TZ': 'Vunjo (Tanzania)',
    wae: 'Walser',
    'wae-CH': 'Walser (Switzerland)',
    xog: 'Soga',
    'xog-UG': 'Soga (Uganda)',
    yav: 'Yangben',
    'yav-CM': 'Yangben (Cameroon)',
    yi: 'Yiddish',
    'yi-001': 'Yiddish (World)',
    yo: 'Yoruba',
    'yo-BJ': 'Yoruba (Benin)',
    'yo-NG': 'Yoruba (Nigeria)',
    zgh: 'Standard Moroccan Tamazight',
    'zgh-MA': 'Standard Moroccan Tamazight (Morocco)',
    zh: 'Chinese',
    'zh-Hans': 'Chinese (Simplified)',
    'zh-Hans-CN': 'Chinese (Simplified, China)',
    'zh-Hans-HK': 'Chinese (Simplified, Hong Kong SAR China)',
    'zh-Hans-MO': 'Chinese (Simplified, Macau SAR China)',
    'zh-Hans-SG': 'Chinese (Simplified, Singapore)',
    'zh-Hant': 'Chinese (Traditional)',
    'zh-Hant-HK': 'Chinese (Traditional, Hong Kong SAR China)',
    'zh-Hant-MO': 'Chinese (Traditional, Macau SAR China)',
    'zh-Hant-TW': 'Chinese (Traditional, Taiwan)',
    zu: 'Zulu',
    'zu-ZA': 'Zulu (South Africa)'
};
