export enum Brand {
    Baby = 'Baby',
    Essity = 'Essity',
    FemCare = 'FemCare',
    Tena = 'Tena',
    Tork = 'Tork',
    DistributionHub = 'DistributionHub',
    Leukoplast = 'Leukoplast',
    Cutimed = 'Cutimed',
    Actimove = 'Actimove',
    JOBST = 'JOBST'
}
