
import {takeUntil} from 'rxjs/operators';
import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { AdminTitle } from '../../../../layout/AdminTitle';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DistributorFinderApi } from '../../../../api/dealerLocator/DealerLocatorApi';
import { DealerLocatorService } from '../../../tena/jobst/dealerLocator/DistributorFinder.service';
import { IDistributor } from '../../../../model/dealerLocator/IDistributor';
import { DealerCountrySelectComponent } from './distributorCountrySelect/DistributorCountrySelect.component';
import { Subject } from 'rxjs';
import { AlertService } from 'tridion.web.ui/src/app/angular/layout/alert/Alert.service';
import { IAlert } from 'tridion.web.ui/src/app/angular/layout/alert/IAlert';
import { AlertType } from 'tridion.web.ui/src/app/angular/layout/alert/AlertType';
import { IJobstSegment } from 'src/app/model/product/tena/jobst/IJobstSegment';
import { JobstTCWCatalogApi } from '../../../../api/product/tena/jobst/JobstTCWCatalogApi';
import { IJobstTCWCatalog } from '../../../../model/product/tena/jobst/IJobstTCWCatalog.d';
import { JobstService } from '../Jobst.service';
import { JobstSegmentApi } from '../../../../api/product/tena/jobst/JobstSegmentApi';
import { LiveStageService } from '../../../../environment/LiveStage.service';

@Component({
    templateUrl: 'DistributorFinder.component.html',
    providers: [DealerCountrySelectComponent, DistributorFinderApi]
})
export class DealerLocatorComponent implements OnInit, OnDestroy {
    @ViewChild('browse', {static: false})
    fileUpload: ElementRef;
    distributors: IDistributor[];
    searchForm: FormGroup;
    distributor: IDistributor;
    closeResult: string;
    file: any;
    fileType: string;
    totalPages: number;
    page = 1;
    limit = 20;
    arr: number[];
    countries: IJobstTCWCatalog[];
    segments: IJobstSegment[];
    country: IJobstTCWCatalog;
    segment: IJobstSegment;
    modalVisible: boolean;
    importSuccess: string;
    spinner: boolean;
    confirmModal = false;
    publishSpinner = false;
    showPublishMessage = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private distributorFinderApi: DistributorFinderApi,
        private fb: FormBuilder,
        private dealerLocatorService: DealerLocatorService,
        private alertService: AlertService,
        readonly jobstCatalogApi: JobstTCWCatalogApi,
        readonly jobstService: JobstService,
        readonly jobstSegmentsApi: JobstSegmentApi,
        private liveStageService: LiveStageService,
    ) {}

    public getCountryAndSegment(): { country: string, segment: string } {
        const countryObj = JSON.parse(window.sessionStorage.getItem('jobstSelectedCountry') || window.localStorage.getItem('jobstSelectedCountry'));
        const segmentObj = JSON.parse(window.sessionStorage.getItem('jobstSelectedSegment') || window.localStorage.getItem('jobstSelectedSegment'));

        const country = countryObj && countryObj.objectId ? countryObj.objectId.toString() : '';
        const segment = segmentObj && segmentObj.objectId ? segmentObj.objectId.toString() : '';

        return { country, segment };
    }

    public edit(distributor: IDistributor) {
        this.dealerLocatorService.distributor.next(distributor);
        this.dealerLocatorService.distributorNewValues.next(Object.assign({}, distributor));
    }

    public delete(distributor: IDistributor) {
        const { country, segment } = this.getCountryAndSegment();

        this.distributorFinderApi
            .inactivate(distributor, country, segment)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res: IDistributor) => {
                this.getAll();
            });
    }

    ngOnInit(): void {
        this.adminTitle.setTitle('Dealer Locator');
        this.liveStageService.addListener(this, 'DealerLocator');

        this.liveStageService.liveStage.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => {
            this.getAll();
        });

        this.jobstCatalogApi.getAll().subscribe((res: IJobstTCWCatalog[]) => {
            this.jobstService.countries.next(res);
        });

        this.jobstSegmentsApi.getAll().subscribe((res: IJobstSegment[]) => {
            const consumerSegments = res.filter(segment => segment.isConsumerSegment);
            this.jobstService.segments.next(consumerSegments);
        });

        let previousCountry = window.sessionStorage.getItem('jobstSelectedCountry') || window.localStorage.getItem('jobstSelectedCountry');
        let previousSegment = window.sessionStorage.getItem('jobstSelectedSegment') || window.localStorage.getItem('jobstSelectedSegment');

        this.searchForm = this.fb.group({
            searchFilter: new FormControl('name'),
            searchField: new FormControl('')
        });

        this.jobstService.segmentChanged.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => {
            const currentCountry = window.sessionStorage.getItem('jobstSelectedCountry') || window.localStorage.getItem('jobstSelectedCountry');
            const currentSegment = window.sessionStorage.getItem('jobstSelectedSegment') || window.localStorage.getItem('jobstSelectedSegment');

            if (currentCountry !== previousCountry) {
                previousCountry = currentCountry;
                previousSegment = currentSegment;
                this.getAll();
            }
        });

        this.getAll();
        this.initializeDistributors();
    }

    initializeDistributors(): void {
        this.dealerLocatorService.distributor.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(distributor => {
                this.distributor = distributor;
            });

        this.dealerLocatorService.distributors.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(distributors => {
                this.distributors = distributors;
                this.totalPages = Math.ceil(
                    this.dealerLocatorService.totalCount / this.limit
                );
                this.arr = Array(this.totalPages);

                if (this.page > this.totalPages) {
                    this.setPage(this.totalPages);
                }
            });
        this.formChanges();
    }

    formChanges(): void {
        this.searchForm.valueChanges.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((val: FormGroup) => {
                this.getAll();
            });
    }

    getAll() {
        this.spinner = true;

        const { country, segment } = this.getCountryAndSegment();

        this.distributorFinderApi
            .getDistributors(this.limit, this.page === 0 ? 1 : this.page, this.searchForm, country, segment)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => {
                this.dealerLocatorService.totalCount = response.totalCount;
                this.dealerLocatorService.distributors.next(response.distributors);
                this.spinner = false;
            });
    }

    selectCountry(country: IJobstTCWCatalog) {
        this.country = country;
        window.sessionStorage.setItem('jobstSelectedCountry', JSON.stringify(country));
        window.localStorage.setItem('jobstSelectedCountry', JSON.stringify(country));
        this.getAll();
    }

    selectSegment(segment: IJobstSegment) {
        this.segment = segment;
        window.sessionStorage.setItem('jobstSelectedSegment', JSON.stringify(segment));
        window.localStorage.setItem('jobstSelectedSegment', JSON.stringify(segment));
        this.getAll();
    }

    addNewDistributor() {
        this.dealerLocatorService.distributorNewValues.next(Object.assign({}));
    }

    browseFile() {
        let event = new MouseEvent('click');
        this.fileUpload.nativeElement.dispatchEvent(event);
    }

    browseFileInputChange(event: Event) {
        if (this.fileUpload.nativeElement.files.length > 0) {
            this.file = this.fileUpload.nativeElement.files[0];
            if (
                this.file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                this.fileType = this.file.type;
            }
        }
    }

    importFile() {
        let data = new FormData();
        data.append('file', this.file, this.file.name);

        const { country, segment } = this.getCountryAndSegment();

        this.distributorFinderApi
            .importData(data, country, segment)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (res: any) => {
                    this.importSuccess = res;
                    this.getAll();

                    setTimeout(() => {
                        this.importSuccess = '';
                    }, 4000);
                },
                error => {
                    if (error._body) {
                        let alert: IAlert = {
                            message: '',
                            type: AlertType.error,
                            close: true
                        };

                        alert.message = error._body;
                        this.alertService.setAlert(alert);
                    }
                    this.file = null;
                    this.fileType = '';
                    this.fileUpload.nativeElement.value = null;
                },
                () => {
                    this.file = null;
                    this.fileType = '';
                    this.fileUpload.nativeElement.value = null;
                }
            );
    }

    setPage(page: number) {
        this.page = page;
        this.getAll();
    }

    export() {
        const { country, segment } = this.getCountryAndSegment();

        this.distributorFinderApi
            .exportFile(country, segment)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                let fileData = atob(res.text());
                var byteNumbers = Array(fileData.length);

                for (var i = 0; i < fileData.length; i++) {
                    byteNumbers[i] = fileData.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                let blob = new Blob([byteArray], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var objectUrl = URL.createObjectURL(blob);
                window.open(objectUrl);
            });
    }

    publish() {
        this.publishSpinner = true;

        const { country, segment } = this.getCountryAndSegment();

        this.distributorFinderApi
            .publish(country, segment)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                res => {
                    this.showPublishMessage = true;
                    this.publishSpinner = false;
                },
                error => {
                    this.publishSpinner = false;
                }
            );
    }

    toggleConfirmModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
            this.showPublishMessage = false;
        }

        this.confirmModal = show;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
