import { Pipe, PipeTransform } from '@angular/core';
import { ILeukoplastTCWCatalog } from '../../../model/product/tena/leukoplast/ILeukoplastTCWCatalog';

@Pipe({
    name: 'platformCountryNamePipe'
})
export class LeukoplastCountryNamePipe implements PipeTransform {
    transform(value: ILeukoplastTCWCatalog[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: ILeukoplastTCWCatalog) => {
                if (item.name.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
