
import {takeUntil} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';

import { AdminTitle } from '../../layout/AdminTitle';
import { ISubmittedFormsOptions } from '../../model/submitted/ISubmittedFormsOptions';
import saveResponseFile from '../../api/fileManager/fileManagerApi';
import { SubmittedFormsApi } from '../../api/submitted/SubmittedFormsApi';
import toIsoDate from '../../date/toIsoDate';
import { SiteApi } from '../../api/auth/SiteApi';
import { ISite } from '../../model/site/ISite';
import { SubmittedFormsService } from './SubmittedForms.service';
import { Subject } from 'rxjs';

@Component({
    templateUrl: 'SubmittedForms.component.html',
    providers: [SubmittedFormsApi, SiteApi]
})
export class SubmittedFormsComponent implements OnInit, OnDestroy {
    options: ISubmittedFormsOptions;
    submitting = false;
    emailed: string;
    sites: ISite[];
    selectedSite: ISite;
    siteModalVisible: boolean;
    loadingSite = true;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private submittedFormsApi: SubmittedFormsApi,
        private siteApi: SiteApi,
        private submittedFormsService: SubmittedFormsService
    ) {
        let endDate = moment(),
            startDate = endDate.clone().subtract(1, 'month');

        this.options = {
            download: true,
            startDate: toIsoDate(startDate),
            endDate: toIsoDate(endDate),
            countryUrl: null
        };
    }

    ngOnInit() {
        this.adminTitle.setTitle('Submitted Forms');
        this.setupSites();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setupSites() {
        this.initializeListeners();
        this.setupSavedSite();

        this.siteApi.getAll().subscribe(sites => {
            this.sites = sites;
            // User has multiple sites/countries -> show popup and let user choose
            if (this.sites.length > 1) {
                this.submittedFormsService.sites.next(this.sites);
                if (!this.selectedSite) {
                    this.submittedFormsService.siteModalVisible.next(true);
                }
            } else {
                this.submittedFormsService.siteChanged.next(this.sites[0]);
            }
        });
    }

    setupSavedSite() {
        let sessionStorageSite = this.submittedFormsService.getActiveSite();
        if (sessionStorageSite) {
            this.submittedFormsService.siteChanged.next(sessionStorageSite);
            this.submittedFormsService.siteModalVisible.next(false);
        }
    }

    initializeListeners(): void {
        this.submittedFormsService.siteModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.siteModalVisible = visible;
            });

        this.submittedFormsService.siteChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(site => {
                if (site) {
                    this.selectedSite = site;
                    this.submittedFormsService.setActiveSite(this.selectedSite);
                    this.loadingSite = false;
                    this.options.countryUrl = site.domainUrl;
                }
            });
    }

    selectCountry() {
        this.submittedFormsService.siteModalVisible.next(true);
    }

    downloadOrEmail() {
        let options = this.options,
            download = options.download,
            email = options.email,
            startDate = options.startDate,
            endDate = options.endDate;

        this.submitting = true;
        this.emailed = undefined;

        this.submittedFormsApi.downloadOrEmailExcel(options).subscribe(
            response => {
                if (download) {
                    let fileName = 'Submitted from ' + startDate + ' to ' + endDate;

                    saveResponseFile(response, fileName, 'xlsx');
                } else {
                    this.emailed = email;
                }

                this.submitting = false;
            },
            () => {
                this.submitting = false;
            }
        );
    }
}
