
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../../environment/LiveStage';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Subject } from 'rxjs';
import { CutimedService } from './Cutimed.service';
import { ICutimedSampleOrder } from '../../../model/product/tena/cutimed/ICutimedSampleOrder';
import { CutimedSampleOrderApi } from '../../../api/product/tena/cutimed/CutimedSampleOrderApi';
import { CutimedSampleOrderCrud } from '../../../model/product/tena/cutimed/CutimedSampleOrderCrud';
import { CutimedProductFamilyApi } from '../../../api/product/tena/cutimed/CutimedProductFamilyApi';
import { ICutimedProductFamily } from '../../../model/product/tena/cutimed/ICutimedProductFamily';
import { CutimedTCWCatalogApi } from '../../../api/product/tena/cutimed/CutimedTCWCatalogApi';
import { CutimedSegmentApi } from '../../../api/product/tena/cutimed/CutimedSegmentApi';
import { ICutimedSegment } from '../../../model/product/tena/cutimed/ICutimedSegment.d';
import { ICutimedTCWCatalog } from '../../../model/product/tena/cutimed/ICutimedTCWCatalog.d';

@Component({
    templateUrl: 'CutimedSampleOrder.component.html',
    providers: [CutimedSampleOrderApi, CutimedProductFamilyApi, CutimedTCWCatalogApi, CutimedSegmentApi]
})
export class CutimedSampleOrderComponent
    extends BaseLiveStageCrudList<
        ICutimedSampleOrder,
        ICrudData<ICutimedSampleOrder>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    productFamilies: ICutimedProductFamily[];
    sortingOrderOptions: number[];
    cruds: CutimedSampleOrderCrud[];

    countries: ICutimedTCWCatalog[];
    segments: ICutimedSegment[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private cutimedSampleOrderApi: CutimedSampleOrderApi,
        private cutimedProductFamilyApi: CutimedProductFamilyApi,
        readonly cutimedCatalogApi: CutimedTCWCatalogApi,
        readonly cutimedSegmentsApi: CutimedSegmentApi,
        readonly cutimedService: CutimedService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'Sample Order');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Sample Order - Cutimed');

        this.cutimedCatalogApi.getAll().subscribe((res: ICutimedTCWCatalog[]) => {
          this.cutimedService.countries.next(res);
        });

        this.cutimedSegmentsApi.getAll().subscribe((res: ICutimedSegment[]) => {
            const consumerSegments = res.filter(segment => segment.isConsumerSegment);
            this.cutimedService.segments.next(consumerSegments);
        });

        // Sorting order is 0-15.
        this.sortingOrderOptions = Array(51)
            .fill(0)
            .map((value, index) => index);

        /**
         * 1. Get selected country (user selection).
         * 2. Get selected segment (user selection).
         * 3. Fetch their product families. (api).
         * 4. Fetch sample orders (api).
         */

        this.cutimedService.segmentChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedSegment => {
                if (selectedSegment) {
                    this.clear();
                    this.getProductFamilies();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    getProductFamilies() {
        this.filter.observable.subscribe(() => {
            this.cutimedProductFamilyApi
                .getProductFamilies(
                    this.cutimedService.getActiveCountry().objectId,
                    this.cutimedService.getActiveSegment().objectId
                )
                .subscribe(productFamilies => {
                    this.setProductFamilies(productFamilies);
                });
        });
    }

    setProductFamilies(productFamilies: ICutimedProductFamily[]) {
        this.productFamilies = productFamilies;

        if (productFamilies.length > 0) {
            this.getProductSorting(productFamilies[0].urlFriendlyName);
        } else {
            this.init([]);
        }
    }

    getProductSorting(urlFriendlyName: string) {
        this.cutimedSampleOrderApi
            .getSampleOrders(
                this.cutimedService.getActiveCountry().objectId,
                this.cutimedService.getActiveSegment().objectId,
                urlFriendlyName
            )
            .subscribe(productSortings => {
                this.clear();
                this.init(productSortings);
            });
    }

    protected createCrud() {
        return new CutimedSampleOrderCrud(this.cutimedSampleOrderApi, this.authService);
    }
}
