import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';
import { IGuidSolrcore } from '../../../model/product/IGuidSolrcore';

@Injectable()
export class DistributionHubSolrcoreApi extends BaseAuthorizedGuidCrudApi<IGuidSolrcore> {
    protected getBaseUrl() {
        return this.environment.api.distributionHub + 'solrcore';
    }
}
