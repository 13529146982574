
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

import 'date-input-polyfill';
import { ILeukoplastUserData } from '../../../model/product/tena/leukoplast/ILeukoplastUserData';
import { LeukoplastLegacyIdExportApi } from '../../../api/product/tena/leukoplast/LeukoplastLegacyIdExportApi';
import { AdminTitle } from '../../../layout/AdminTitle';
import { LeukoplastService } from './Leukoplast.service';
import saveBlob from '../../../api/fileManager/saveBlob';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { BaseLiveStageCrudList } from '../../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../../environment/LiveStage';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { LeukoplastTCWCatalogApi } from '../../../api/product/tena/leukoplast/LeukoplastTCWCatalogApi';
import { ILeukoplastTCWCatalog } from '../../../model/product/tena/leukoplast/ILeukoplastTCWCatalog';

@Component({
    templateUrl: 'LeukoplastLegacyIdExport.component.html',
    providers: [LeukoplastLegacyIdExportApi, LeukoplastTCWCatalogApi]
})
export class LeukoplastLegacyIdExportComponent
    extends BaseLiveStageCrudList<
        ILeukoplastUserData,
        ICrudData<ILeukoplastUserData>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    items$: BehaviorSubject<ILeukoplastUserData[]> = new BehaviorSubject<ILeukoplastUserData[]>(null);
    item: ILeukoplastUserData;
    pageSize = 20;
    hasMoreItems = false;
    showQnA = false;
    countries: ILeukoplastTCWCatalog[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        readonly leukoplastLegacyIdExportApi: LeukoplastLegacyIdExportApi,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        liveStageService: LiveStageService,
        readonly authService: AuthService,
        readonly leukoplastCatalogApi: LeukoplastTCWCatalogApi,
        private leukoplastService: LeukoplastService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'Legacy ID export');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Legacy ID export');

        this.leukoplastCatalogApi.getAll().subscribe((res: ILeukoplastTCWCatalog[]) => {
            this.leukoplastService.countries.next(res);
        });
        this.leukoplastService.countryChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedCountry => {
                if (selectedCountry) {
                    this.loadItems();
                }
            });
    }

    handleResponse(items: ILeukoplastUserData[], append: boolean) {
        // Response can return pagesize + 1 number of items to indicate that next page exists.
        this.hasMoreItems = items.length > this.pageSize;
        if (this.hasMoreItems) {
            items.pop();
        }

        let newItems = append ? this.items$.value.concat(items) : items;
        this.items$.next(newItems);

        this.showQnA = newItems.some(x => x.userQuestionAnswers.length > 0);
    }

    loadItems() {
        this.filter.observable.subscribe(filter => {
            this.leukoplastLegacyIdExportApi
                .getItems(
                    this.leukoplastService.getActiveCountry().objectId,
                )
                .subscribe(items => {
                    this.handleResponse(items, false);
                });
        });
    }

    exportToCsv() {
        this.leukoplastLegacyIdExportApi
            .getCsv(
                this.leukoplastService.getActiveCountry().objectId,
            )
            .subscribe(response => {
                let fileName = 'Leukoplast - Legacy ID';

                saveBlob(response.text(), fileName, 'xls');
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.leukoplastLegacyIdExportApi, this.authService);
    }
}
