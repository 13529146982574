import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITorkSiteMap } from '../../../model/product/tork/ITorkSiteMap';

@Injectable()
export class TorkSiteMapApi extends BaseProductApi<ITorkSiteMap> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'tork/lkpsitemapdetail';
    }
}
