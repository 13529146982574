

import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { LeukoplastService } from './Leukoplast.service';
import { LeukoplastWebShopCatalogApi } from '../../../api/product/tena/leukoplast/LeukoplastWebShopCatalogApi';
import { ILeukoplastWebShopCatalog } from '../../../model/product/tena/leukoplast/ILeukoplastWebShopCatalog';

@Component({
    selector: 'admin-leukoplast-country-select',
    templateUrl: 'LeukoplastCountrySelect.component.html',
    providers: [LeukoplastWebShopCatalogApi]
})
export class LeukoplastCountrySelectComponent implements OnInit, OnDestroy {
    countryForm: FormGroup;
    countries: ILeukoplastWebShopCatalog[];

    @Input()
    selectedCountry: ILeukoplastWebShopCatalog;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public leukoplastService: LeukoplastService,
        private leukoplastCatalogApi: LeukoplastWebShopCatalogApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.leukoplastService.countries.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((countries: ILeukoplastWebShopCatalog[]) => {
                this.countries = countries;
            });

        this.countryForm = this.fb.group({
            country: new FormControl(
                this.selectedCountry ? this.selectedCountry.objectId.toString() : '',
                Validators.required
            )
        });

        this.leukoplastCatalogApi.getAll().subscribe((res: ILeukoplastWebShopCatalog[]) => {
            this.leukoplastService.countries.next(res);
        });

        this.leukoplastService.countryModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleCountryModal(visible);
            });

        this.toggleCountryModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectCountry(country: ILeukoplastWebShopCatalog) {
        if (this.countryForm.valid) {
            country = this.countries.find((item: ILeukoplastWebShopCatalog) => {
                if (item.objectId === parseInt(this.countryForm.value.country)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('leukoplastSelectedCountry', JSON.stringify(country));
            this.leukoplastService.countryChanged.next(country);

            this.leukoplastService.countryModalVisible.next(false);
        }
    }

    toggleCountryModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
