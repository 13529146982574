import { Route, RouterModule } from '@angular/router';
import { INavbarRoute } from '../layout/navbar/INavbarRoute';
import { NgModule } from '@angular/core';
import { AdminAuthGuard } from '../auth/login/AdminAuthGuard';
import { Tool } from '../model/auth/login/Tool';
import { Brand } from '../model/auth/login/Brand';
import { FemcareSamplesComponent } from './sample/FemcareSamples.component';
import { SamplesComponent } from './sample/fulfillment/Samples.component';
import { SiteMappingComponent } from './siteMapping/SiteMapping.component';
import { PromotionsComponent } from './promotion/Promotions.component';
import { PromotionSubmissionsComponent } from './promotion/fulfillment/PromotionSubmissions.component';
import { QuizComponent } from './quiz/Quiz.component';
import { QuizSubmissionsComponent } from './quiz/submissions/QuizSubmissions.component';

export const CampaignRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Samples',
        path: 'samples',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        requiredAccess: {
            brand: [Brand.FemCare],
            tool: [Tool.Fulfillment, Tool.SamplesConfiguration]
        },
        children: [
            {
                navbarName: 'Samples',
                path: 'samples',
                component: FemcareSamplesComponent,
                helpUrl:
                    'https://scadev.atlassian.net/wiki/spaces/TP/pages/206209228/7.5+Sample+Order+How+To+s',
                requiredAccess: {
                    tool: [Tool.SamplesConfiguration]
                }
            },
            {
                navbarName: 'Fulfilment',
                path: 'fulfilment',
                component: SamplesComponent,
                data: { brand: Brand.FemCare },
                helpUrl:
                    'https://scadev.atlassian.net/wiki/spaces/TP/pages/206209228/7.5+Sample+Order+How+To+s',
                requiredAccess: {
                    tool: [Tool.Fulfillment]
                }
            }
        ]
    },
    {
        navbarName: 'Site Mapping',
        path: 'site-mapping',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        requiredAccess: {
            brand: [Brand.FemCare],
            tool: [Tool.SamplesConfiguration]
        },
        children: [
            {
                navbarName: 'Site Mapping',
                path: 'mappings',
                component: SiteMappingComponent,
                data: { brand: Brand.FemCare },
                helpUrl:
                    'https://scadev.atlassian.net/wiki/spaces/TP/pages/206209228/7.5+Sample+Order+How+To+s',
                requiredAccess: {
                    tool: [Tool.SamplesConfiguration]
                }
            }
        ]
    },
    {
        navbarName: 'Promotions',
        path: 'promotions',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        requiredAccess: {
            brand: [Brand.FemCare],
            tool: [Tool.Fulfillment]
        },
        children: [
            {
                navbarName: 'Promotion',
                path: 'promotions',
                component: PromotionsComponent,
                helpUrl: '#',
                requiredAccess: {
                    brand: [Brand.FemCare]
                }
            },
            {
                navbarName: 'Fulfilment',
                path: 'fulfillment',
                component: PromotionSubmissionsComponent,
                data: { brand: Brand.FemCare },
                helpUrl: '#',
                requiredAccess: {
                    tool: [Tool.Fulfillment]
                }
            }
        ]
    },
    {
        navbarName: 'Quiz',
        path: 'quiz',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        requiredAccess: {
            brand: [Brand.FemCare],
        },
        children: [
            {
                navbarName: 'Quiz',
                path: 'quiz',
                component: QuizComponent,
                data: { brand: Brand.FemCare },
                helpUrl: '#',
                requiredAccess: {
                    brand: [Brand.FemCare]
                }
            },
            {
                navbarName: 'Submissions',
                path: 'submissions',
                component: QuizSubmissionsComponent,
                data: { brand: Brand.FemCare },
                helpUrl: '#',
                requiredAccess: {
                    tool: [Tool.Fulfillment]
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(CampaignRoutes)],
    exports: [RouterModule]
})
export class CampaignRoutingModule { }