import { Http } from '@angular/http';
import { Inject, Injectable } from '@angular/core';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';

import { BaseTPWApi } from './BaseTPWApi';
import { ITpwCountry } from '../../../../model/product/tena/tpw/ITpwCountry';
import { IEnvironment } from '../../../../environment/IEnvironment';

@Injectable()
export class TpwCountryApi extends BaseTPWApi<ITpwCountry> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    protected getBaseUrl() {
        return this.TPWBaseURl + 'SampleOrder/GetCountries';
    }
}
