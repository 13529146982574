import { Pipe, PipeTransform } from '@angular/core';
import { ITpwChannel } from '../../../model/product/tena/tpw/ITpwChannel';

@Pipe({
    name: 'platformTpwChannelNamePipe'
})
export class TpwChannelNamePipe implements PipeTransform {
    transform(value: ITpwChannel[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: ITpwChannel) => {
                if (item.channelName.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
