import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IObjectIdModel } from '../../../../model/product/IObjectIdModel';
import { BaseAuthorizedObjectIdApi } from '../../BaseAuthorizedObjectIdApi';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';

@Injectable()
export abstract class BaseTPWApi<
    T extends IObjectIdModel
> extends BaseAuthorizedObjectIdApi<T> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    protected get TPWBaseURl() {
        return this.environment.api.sampleorder;
    }

    protected getBaseUrl() {
        return this.TPWBaseURl;
    }
}
