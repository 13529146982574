import { Component, Input } from '@angular/core';
import { pull } from 'lodash';

@Component({
    selector: 'admin-small-remove-action',
    templateUrl: 'SmallRemoveAction.component.html'
})
export class SmallRemoveActionComponent<I> {
    @Input() item: I;
    @Input() items: I[];
    @Input() itemType: string;

    delete() {
        pull(this.items, this.item);
    }
}
