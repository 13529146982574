import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { IDistributor } from '../../../../../model/dealerLocator/IDistributor';
import { ICountry } from '../../../../../model/dealerLocator/ICountry';
import { DealerLocatorService } from '../DistributorFinder.service';

declare var google: any;

@Component({
    selector: 'admin-dealer-map',
    templateUrl: 'DistributorMapp.component.html'
})
export class DealerMapComponent implements OnInit, OnDestroy {
    distributor: IDistributor;
    distributorNewValues: IDistributor;
    @Input()
    selectedCountry: ICountry;
    countryError = false;

    mapVisible: boolean;
    street: string;
    city: string;
    zip: string;

    // initial map position
    lat: number;
    lng: number;

    // latLang is used in Geocoder for address
    latLang = {
        lat: 0,
        lng: 0
    };
    label: string;

    constructor(
        private dealerLocatorService: DealerLocatorService
    ) {}

    ngOnInit() {
        this.dealerLocatorService.distributor.subscribe((distributor: IDistributor) => {
            this.distributor = distributor;
        });

        this.dealerLocatorService.distributorNewValues.subscribe(
            (distributor: IDistributor) => {
                this.distributorNewValues = distributor;
            }
        );

        this.dealerLocatorService.mapOpen.subscribe(visible => {
            if (visible) {
                if (this.distributorNewValues.geoCode) {
                    let code = this.distributorNewValues.geoCode.split(',');
                    this.lat = Number(code[0]);
                    this.lng = Number(code[1]);
                } else {
                    this.lat = 0;
                    this.lng = 0;
                }
            }

            this.mapVisible = visible;
        });
    }

    @HostListener('click', ['$event.target.className'])
    onClick(elementClass: string) {
        if (elementClass.includes('distributorMap')) {
            this.closeModal();
        }
    }

    mapReady($event: any) {
        this.latLang.lat = this.lat;
        this.latLang.lng = this.lng;

        this.label = '';

        this.getAddress(this.latLang).then(res => {
            let response: any = res;

            this.label = response.formatted_address;
        });
    }

    mapClicked($event: any) {
        this.getAddress($event.coords).then(res => {
            let response: any = res;
            let countryData = response.address_components.find((item: any) => {
                if (item.types[0] === 'country') {
                    return item;
                }
            });
            if (this.selectedCountry.code === countryData.short_name) {
                response.address_components.filter((item: any) => {
                    switch (item.types[0]) {
                        case 'street_number':
                            this.street = item.short_name;
                            break;
                        case 'route':
                            this.street += ' ' + item.short_name;
                            break;
                        case 'locality':
                            this.city = item.short_name;
                            break;
                        case 'postal_code':
                            this.zip = item.short_name;
                    }
                });

                this.label = response.formatted_address;
                this.countryError = false;
            } else {
                this.label = 'Unreachable country.';
                this.countryError = true;
            }
        });

        this.latLang.lat = $event.coords.lat;
        this.latLang.lng = $event.coords.lng;
    }

    getAddressData(latLang: any) {
        let address: string[] = [];
        let data: any;

        if (latLang.lat === 0 && latLang.lng === 0) {
            if (this.distributorNewValues.address || this.distributorNewValues.city) {
                if (this.distributorNewValues.address) {
                    address.push(this.distributorNewValues.address);
                }

                if (this.distributorNewValues.city) {
                    address.push(this.distributorNewValues.city);
                }
            }

            address.push(this.selectedCountry.name);
            data = {
                address: address.join(',')
            };
        } else {
            data = {
                latLng: latLang
            };
        }

        return data;
    }

    getAddress(latLang: any): Promise<string> {
        let geocoder = new google.maps.Geocoder();
        let data: any;

        data = this.getAddressData(latLang);

        return new Promise((res, rej) => {
            geocoder.geocode(data, (results: any, status: any) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        if (latLang.lat === 0 && latLang.lng === 0) {
                            this.latLang.lat = results[0].geometry.location.lat();
                            this.latLang.lng = results[0].geometry.location.lng();
                        }

                        res(results[0]);
                    }
                } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                    results.formatted_address = 'Invalid address';

                    res(results);
                }
            });
        });
    }

    saveMapCords() {
        this.distributorNewValues.geoCode =
            '' + this.latLang.lat + ',' + this.latLang.lng + '';
        this.distributorNewValues.city = this.city;
        this.distributorNewValues.address = this.street;
        this.distributorNewValues.zip = this.zip;

        this.dealerLocatorService.distributorNewValues.next(this.distributorNewValues);
        this.dealerLocatorService.mapOpen.next(false);
    }

    closeModal() {
        this.dealerLocatorService.mapOpen.next(false);
        this.countryError = false;
    }

    ngOnDestroy() {
        return
    }
}
