import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ITenaSegment } from '../../model/product/tena/ITenaSegment';
import { ITenaTCWCatalog } from '../../model/product/tena/tcw/ITenaTCWCatalog';

@Injectable()
export class TenaService {
    countryChanged = new Subject<ITenaTCWCatalog>();
    segmentChanged = new Subject<ITenaSegment>();

    countries = new Subject<ITenaTCWCatalog[]>();
    segments = new Subject<ITenaSegment[]>();
    countryModalVisible = new Subject<boolean>();
    segmentModalVisible = new Subject<boolean>();

    getActiveCountry(): ITenaTCWCatalog {
        let country = null;
        let sessionStorage = window.sessionStorage.getItem('tenaSelectedCountry');
        if (sessionStorage !== '' && sessionStorage !== null) {
            country = JSON.parse(sessionStorage);
        }

        return country;
    }

    getActiveSegment(): ITenaSegment {
        let segment = null;
        let sessionStorage = window.sessionStorage.getItem('tenaSelectedSegment');
        if (sessionStorage !== '' && sessionStorage !== null) {
            segment = JSON.parse(sessionStorage);
        }

        return segment;
    }
}
