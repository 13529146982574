import { ICatalog } from '../model/product/ICatalog';

export default function getCatalogName(catalog: ICatalog) {
    if (catalog) {
        const isHMS = ['Tena', 'Leukoplast', 'Cutimed'].includes(catalog.brand);
        
        return (
            catalog.countryName +
            ': ' +
            catalog.languageName +
            ' (' +
            isHMS ? catalog.gpimCatalogCode : catalog.catalogCode +
            ')'
        );
    }
}
