import { Pipe, PipeTransform } from '@angular/core';
import { ITenaSegment } from '../../../../model/product/tena/ITenaSegment';

@Pipe({
    name: 'platformTenaSegmentNamePipe'
})
export class TenaSegmentNamePipe implements PipeTransform {
    transform(value: ITenaSegment[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: ITenaSegment) => {
                if (item.segment.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
