import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ISite } from '../../model/site/ISite';

@Injectable()
export class SubmittedFormsService {
    siteChanged = new Subject<ISite>();
    sites = new Subject<ISite[]>();
    siteModalVisible = new Subject<boolean>();

    getActiveSite() {
        let site = null;
        let sessionStorageSite = window.sessionStorage.getItem(
            'submittedFormsSelectedSite'
        );
        if (sessionStorageSite !== '' && sessionStorageSite !== null) {
            site = JSON.parse(sessionStorageSite);
        }

        return site;
    }

    setActiveSite(site: ISite) {
        window.sessionStorage.setItem('submittedFormsSelectedSite', JSON.stringify(site));
    }
}
