import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { AdminTitle } from '../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { BaseLiveStageCrudList } from '../crud/list/BaseLiveStageCrudList';
import { LiveStage } from '../environment/LiveStage';
import { LiveStageService } from '../environment/LiveStage.service';
import { TpwGDPRApi } from '../api/product/tena/tpw/TpwGDPRApi';
import { AlertService } from 'tridion.web.ui/src/app/angular/layout/alert/Alert.service';
import { Subject } from 'rxjs';
import saveResponseFile from '../api/fileManager/fileManagerApi';
import { ITpwGDPR } from '../model/product/tena/tpw/ITpwGDPR';
import { ObjectIdCrud } from '../crud/ObjectIdCrud';

@Component({
    templateUrl: 'TpwGdprDelete.component.html',
    providers: [TpwGDPRApi]
})
export class TpwGdprDeleteComponent
    extends BaseLiveStageCrudList<ITpwGDPR, ICrudData<ITpwGDPR>, { liveStage: LiveStage }>
    implements OnInit, OnDestroy {
    emailControl: FormControl;
    valForm: FormGroup;
    validEmails: string[] = [];
    showDelete = false;
    isSpinnerVisible = false;
    isExportSpinnerVisible = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tpwGDPRApi: TpwGDPRApi,
        private alertService: AlertService
    ) {
        super(elementRef, 'submitted', liveStageService, prettySubmitGuard, 'GDPR');

        this.valForm = new FormGroup(
            {
                emailControl: new FormControl(
                    null,
                    Validators.compose([this.checkForm()])
                )
            },
            { updateOn: 'blur' }
        );

        this.emailControl = this.valForm.controls.emailControl as FormControl;
        this.emailControl.valueChanges.subscribe(value => {
            if (this.emailControl && this.emailControl.valid) {
                this.valForm.patchValue({ emailControl: null }, { emitEvent: false });
            }
        });
    }

    ngOnInit() {
        this.adminTitle.setTitle('TPW GDPR');

        this.filter.observable.subscribe(() => {
            this.loadUserData();
        });
    }

    loadUserData() {
        if (this.emailControl.valid) {
            this.isSpinnerVisible = true;
            this.tpwGDPRApi.getUserData(this.validEmails).subscribe(
                data => {
                    this.clear();

                    let res: any[] = Array.of(data);
                    this.init(res);

                    this.isSpinnerVisible = false;
                    // Remove previous errors (server returns 404 for empty results, so we want to clear that if we do get results)
                    this.alertService.removeAll();
                },
                () => {
                    this.isSpinnerVisible = false;
                    this.clear(); // Server returns 404 for empty result so clear results here.
                }
            );
        }
    }

    checkForm(): ValidatorFn {
        return (control: FormControl) => {
            if (!control.value && this.validEmails.length === 0) {
                return { required: true };
            }

            let blacklist = [];
            let invalidEmail = false;

            for (let email of this.getEmails(this.emailControl)) {
                let isValid = true;
                if (this.validEmails.indexOf(email) !== -1) {
                    blacklist.push(email);
                    isValid = false;
                }

                if (!this.isValidEmail(email)) {
                    invalidEmail = true;
                    isValid = false;
                }

                // Got this far -> valid email
                if (isValid && this.validEmails.indexOf(email) === -1) {
                    this.validEmails.push(email);
                }
            }
            let validation = {
                ...(blacklist.length > 0 && { blacklist: blacklist }),
                ...(invalidEmail && { invalidEmail: true })
            };

            return Object.keys(validation).length > 0 ? validation : null;
        };
    }

    isValidEmail(email: string): boolean {
        let validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return validEmailRegex.test(email);
    }

    getEmails(control: FormControl): string[] {
        let emails: string[] = [];
        if (control.value) {
            // Remove any white space and split on commas and semicolons
            emails = (control.value as string)
                .replace(/,/g, ';')
                .replace(/\s/g, '')
                .split(';');
        }

        return emails;
    }

    validateForm(): boolean {
        this.emailControl.markAsTouched();

        return this.valForm.valid;
    }

    forceSumbitForm() {
        this.emailControl.markAsDirty();
        this.emailControl.updateValueAndValidity();
        this.validateForm();
    }

    onKeydown(event: KeyboardEvent, value: string) {
        if (event.key === ',' || event.key === ';' || event.key === 'Enter') {
            this.emailControl.setValue(value);
            this.forceSumbitForm();

            // Prevent writing ",",";"
            if (event.key !== 'Enter') {
                event.preventDefault();
            }
        } else if (event.key === 'Backspace' && value === '') {
            this.validEmails.pop();
            this.forceSumbitForm();
        }
    }

    deleteNewRecepient(email: string) {
        this.validEmails.splice(this.validEmails.indexOf(email), 1);
        this.forceSumbitForm();
    }

    submitForm($ev: Event) {
        $ev.preventDefault();

        this.loadUserData();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    exportToCsv() {
        this.isExportSpinnerVisible = true;
        if (this.validEmails && this.validEmails.length > 0) {
            this.tpwGDPRApi.getCsv(this.validEmails).subscribe(response => {
                let fileName = 'Tpw GDPR ' + this.validEmails.join(', ');
                saveResponseFile(response, fileName, 'xlsx');
                this.isExportSpinnerVisible = false;
            });
        }
    }

    deleteAll() {
        if (this.validEmails && this.validEmails.length > 0) {
            this.tpwGDPRApi.deleteEmails(this.validEmails).subscribe(data => {
                this.clear();
                this.showDelete = false;
            });
        }
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tpwGDPRApi, this.authService);
    }
}
