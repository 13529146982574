import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { IJobstSiteMap } from '../../../../model/product/tena/jobst/IJobstSiteMap';

@Injectable()
export class JobstSiteMapApi extends BaseProductApi<IJobstSiteMap> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmsglobalsitemap/jobst';
    }
}
