import { ElementRef } from '@angular/core';
import { forkJoin ,  Observable } from 'rxjs';
import { keyBy, sortBy } from 'lodash';

import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import getCatalogName from '../getCatalogName';
import { ICatalog } from '../../model/product/ICatalog';
import { ICatalogSolrcoreMapping } from '../../model/product/ICatalogSolrcoreMapping';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ISolrcore } from '../../model/product/ISolrcore';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../sortCatalogs';

export abstract class CatalogSolrcoreMapping extends BaseLiveStageCrudList<
    ICatalogSolrcoreMapping,
    ICrudData<ICatalogSolrcoreMapping>,
    { liveStage: LiveStage }
> {
    catalogs: ICatalog[];
    catalogsById: { [id: number]: ICatalog };
    cores: ISolrcore[];
    coresById: { [id: number]: ISolrcore };
    readonly getCatalogName = getCatalogName;

    constructor(
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard
    ) {
        super(
            elementRef,
            'product',
            liveStageService,
            prettySubmitGuard,
            'Catalog ⬌ Solr Core'
        );
    }

    protected initAll(
        catalogs: Observable<ICatalog[]>,
        cores: Observable<ISolrcore[]>,
        mappings: Observable<ICatalogSolrcoreMapping[]>
    ) {
        forkJoin(catalogs, cores, mappings).subscribe(results => {
            var catalogResults = results[0],
                coreResults = results[1],
                mappingResults = results[2];

            this.catalogs = sortCatalogs(catalogResults);
            this.catalogsById = keyBy(catalogResults, 'objectId');

            this.cores = sortBy(coreResults, 'core');
            this.coresById = keyBy(coreResults, 'objectId');

            this.init(
                sortBy(mappingResults, mapping => {
                    var catalog = this.catalogsById[mapping.catalogObjectId],
                        core = this.coresById[mapping.coreObjectId];

                    return [
                        catalog && catalog.countryName,
                        catalog && catalog.languageName,
                        core && core.core
                    ];
                })
            );
        });
    }
}
