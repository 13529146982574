import { Http } from '@angular/http';
import { Inject, Injectable } from '@angular/core';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseAuthorizedObjectIdApi } from './BaseAuthorizedObjectIdApi';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../environment/IEnvironment';
import { IObjectIdModel } from '../../model/product/IObjectIdModel';
import { LiveStageService } from '../../environment/LiveStage.service';

@Injectable()
export abstract class BaseProductApi<
    T extends IObjectIdModel
> extends BaseAuthorizedObjectIdApi<T> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        private liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    protected getBaseUrl() {
        return this.environment.api.product[this.liveStageService.get('product')];
    }
}
