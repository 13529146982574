import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ICatalogSolrcoreMapping } from '../../../model/product/ICatalogSolrcoreMapping';

@Injectable()
export class TorkCatalogSolrcoreMappingApi extends BaseProductApi<
    ICatalogSolrcoreMapping
> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'tork/catalogcoremapping';
    }
}
