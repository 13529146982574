import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { IFemcareSite } from '../../../model/product/femcare/IFemcareSite';

@Injectable()
export class FemcareSiteApi extends BaseProductApi<IFemcareSite> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'femcare/sites';
    }
}
