import { Http, Response } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { CutimedService } from '../../../../product/tena/cutimed/Cutimed.service';
import { BaseTCWCatalogApi } from '../tcw/BaseTCWCatalogApi';
import { ICutimedUserData } from '../../../../model/product/tena/cutimed/ICutimedUserData';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { CutimedTCWCatalogApi } from './CutimedTCWCatalogApi';
import { ICutimedTCWCatalog } from '../../../../model/product/tena/cutimed/ICutimedTCWCatalog';

@Injectable()
export class CutimedLegacyIdExportApi extends BaseTCWCatalogApi<ICutimedUserData> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        cutimedService: CutimedService,
        readonly cutimedService1: CutimedService,
        liveStageService: LiveStageService,
        readonly cutimedCatalogApi: CutimedTCWCatalogApi
    ) {
        super(apiErrorHandler, authService, environment, http, cutimedService, liveStageService);
    }

    getItems(
        countryId: number,
    ): Observable<ICutimedUserData[]> {
        return this.getJson(`exportlegacymappings/${countryId}`);
    }

    getCsv(
        countryId: number,
    ): Observable<Response> {
        return this.get(`exportlegacymappings/${countryId}?excel=true`);
    }

    protected getBaseUrl() {
        this.cutimedCatalogApi.getAll().subscribe((res: ICutimedTCWCatalog[]) => {
            this.cutimedService1.countries.next(res);
        });

        return this.TCWBaseURl;
    }
}
