import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ILeukoplastSegment } from '../../../model/product/tena/leukoplast/ILeukoplastSegment';
import { LeukoplastService } from './Leukoplast.service';
import { LeukoplastUserSegmentApi } from '../../../api/product/tena/leukoplast/LeukoplastUserSegmentApi';

@Component({
    selector: 'admin-leukoplast-segment-select',
    templateUrl: 'LeukoplastSegmentSelect.component.html',
    providers: [LeukoplastUserSegmentApi]
})
export class LeukoplastSegmentSelectComponent implements OnInit, OnDestroy {
    segmentForm: FormGroup;
    segments: ILeukoplastSegment[];

    @Input()
    selectedSegment: ILeukoplastSegment;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public leukoplastService: LeukoplastService,
        private leukoplastSegmentApi: LeukoplastUserSegmentApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.leukoplastService.segments.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((segments: ILeukoplastSegment[]) => {
                this.segments = segments;
            });

        this.segmentForm = this.fb.group({
            segment: new FormControl(
                this.selectedSegment ? this.selectedSegment.objectId.toString() : '',
                Validators.required
            )
        });

        this.leukoplastSegmentApi.getAll().subscribe((res: ILeukoplastSegment[]) => {
            let LeukoplastSegment = res.filter(segment => segment.isLeukoplastSegment);
            this.leukoplastService.segments.next(LeukoplastSegment);
        });

        this.leukoplastService.segmentModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleSegmentModal(visible);
            });

        this.toggleSegmentModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectSegment(segment: ILeukoplastSegment) {
        if (this.segmentForm.valid) {
            segment = this.segments.find((item: ILeukoplastSegment) => {
                if (item.objectId === parseInt(this.segmentForm.value.segment)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('leukoplastSelectedSegment', JSON.stringify(segment));
            this.leukoplastService.segmentChanged.next(segment);

            this.leukoplastService.segmentModalVisible.next(false);
        }
    }

    toggleSegmentModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
