import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { escapeRegExp } from 'lodash';

@Component({
    selector: 'admin-url-stub',
    templateUrl: 'UrlStub.component.html'
})
export class UrlStubComponent implements OnChanges {
    @Input() inputId: string;
    @Input() itemType?: string;
    @Input() maxlength?: number | string;
    @Input() required: boolean;
    @Input() urlBase: string;
    @Input() urlFull: string;
    @Input() urlStub: string;
    @Output() urlFullChange = new EventEmitter<string>();
    @Output() urlStubChange = new EventEmitter<string>();
    private oldUrlBase: string;
    private oldUrlBaseNonBlank: string;
    private oldUrlFull: string;
    private oldUrlStub: string;

    getUrlPattern() {
        var pattern: string;

        var base = this.urlBase;
        if (base) {
            pattern = '^' + escapeRegExp(base);
        }

        return pattern;
    }

    isValid() {
        var base = this.urlBase,
            full = this.urlFull;

        return !base || !full || full.startsWith(base);
    }

    // tslint:disable:cyclomatic-complexity
    // tslint:disable:max-func-body-length

    ngOnChanges() {
        var base = this.urlBase || '',
            full = this.urlFull || '',
            stub = this.urlStub || '',
            oldBase = this.oldUrlBase || '',
            oldBaseNonBlank = this.oldUrlBaseNonBlank || '',
            oldFull = this.oldUrlFull || '',
            oldStub = this.oldUrlStub || '';

        // Only calculate when the base is set.
        if (base) {
            // Handle case where base is removed, then another base is added.
            if (!oldBase && oldBaseNonBlank && full.startsWith(oldBaseNonBlank)) {
                stub = full.substring(oldBaseNonBlank.length);
                full = base + stub;
            }

            // Calculate stub.
            if (
                stub === oldStub &&
                full.startsWith(base) &&
                (!stub || full !== oldFull)
            ) {
                stub = full.substring(base.length);
            }

            // Calculate full.
            if (
                full === oldFull &&
                (!full ||
                    full.startsWith(base) ||
                    (oldBase && oldFull.startsWith(oldBase)))
            ) {
                full = base + stub;
            }
        }

        // Update data.
        this.urlFull = full || undefined;
        this.urlStub = stub;

        if (base !== oldBase) {
            this.oldUrlBase = base;
        }
        if (base && base !== oldBaseNonBlank) {
            this.oldUrlBaseNonBlank = base;
        }
        if (full !== oldFull) {
            this.urlFullChange.emit(full || undefined);
            this.oldUrlFull = full;
        }
        if (stub !== oldStub) {
            this.urlStubChange.emit(stub);
            this.oldUrlStub = stub;
        }
    }

    // tslint:enable
}
