import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TpwService } from '../Tpw.service';
import { ITpwSample } from '../../model/product/tena/tpw/ITpwSample';
import { ITpwChannel } from '../../model/product/tena/tpw/ITpwChannel';

@Component({
    selector: 'admin-tpw-fullfillmentsetting-menu',
    templateUrl: 'TpwFullfillmentSettingMenu.component.html'
})
export class TpwFullfillmentSettingMenuComponent implements OnInit, OnDestroy {
    sampleModalVisible: boolean;
    channelModalVisible: boolean;

    sample: ITpwSample;
    channel: ITpwChannel;

    @Input()
    useChannel = true;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private tpwService: TpwService) { }

    ngOnInit() {
        // First show Sample popup then Channel popup.
        this.initializeSampleModal();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    initializeSampleModal(): void {
        this.tpwService.sampleModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.sampleModalVisible = visible;
            });

        this.tpwService.sampleChanged.pipe(takeUntil(this.unsubscribe)).subscribe(sample => {
            if (sample) {
                this.sample = sample;

                if (this.useChannel) {
                    // Now show channel popup
                    this.initializeChannelModal();
                }
            }
        });

        let sessionStorageSample = this.tpwService.getActiveSample();
        if (sessionStorageSample) {
            this.tpwService.sampleChanged.next(sessionStorageSample);
            this.tpwService.sampleModalVisible.next(false);
        } else {
            this.tpwService.sampleModalVisible.next(true);
        }
    }

    initializeChannelModal(): void {
        this.tpwService.channelModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.channelModalVisible = visible;
            });

        this.tpwService.channelChanged.pipe(takeUntil(this.unsubscribe)).subscribe(channel => {
            if (channel) {
                this.channel = channel;
            }
        });

        let sessionStorageChannel = this.tpwService.getActiveChannel();
        if (sessionStorageChannel) {
            this.tpwService.channelChanged.next(sessionStorageChannel);
            this.tpwService.channelModalVisible.next(false);
        } else {
            this.tpwService.channelModalVisible.next(true);
        }
    }

    selectSample() {
        this.tpwService.sampleModalVisible.next(true);
    }

    selectChannel() {
        this.tpwService.channelModalVisible.next(true);
    }
}
