import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDomain } from '../model/testResults/IDomain';
import { ITestResultsContainer } from '../model/testResults/ITestResultsContainer';
import { IWebsite } from '../model/testResults/IWebsite';

@Component({
    selector: 'admin-test-navigation',
    templateUrl: 'TestNavigation.component.html'
})
export class AdminTestNavigationComponent {
    @Input()
    domain: IDomain;
    @Input()
    container: ITestResultsContainer;
    @Output()
    containerChange: EventEmitter<ITestResultsContainer> = new EventEmitter<
        ITestResultsContainer
    >();

    setWebsite(website: IWebsite) {
        if (this.container.website !== website) {
            this.container.website = website;
            this.container.test = null;
        }
    }

    checkWebsite(website: IWebsite) {
        return (
            this.container && this.container.website && this.container.website === website
        );
    }
}
