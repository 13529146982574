import { Component } from '@angular/core';
import { sortBy, sum } from 'lodash';

import { BasePerformanceUptimeController } from '../BasePerformanceUptime.controller';
import { ISiteByDate } from '../../model/monitor/ISiteByDate';
import { IUptimeDaily } from '../../model/monitor/uptime/IUptimeDaily';
import splitUrlForOverflow from '../../url/splitUrlForOverflow';
import { UptimeDailyApi } from '../../api/monitor/uptime/UptimeDailyApi';

@Component({
    selector: 'admin-uptime',
    templateUrl: 'Uptime.component.html',
    providers: [UptimeDailyApi]
})
export class UptimeComponent extends BasePerformanceUptimeController<IUptimeDaily> {
    readonly splitUrlForOverflow = splitUrlForOverflow;

    constructor(private uptimeDailyApi: UptimeDailyApi) {
        super(14, 'Website Uptime');
    }

    getColor(item: ISiteByDate<IUptimeDaily>, date: string) {
        var color: string,
            uptime = this.getUptime(item, date);

        if (uptime < 0.999) {
            color = 'table-danger';
        } else if (uptime < 0.99999) {
            color = 'table-warning';
        } else if (uptime === 1) {
            color = 'table-success';
        }

        return color;
    }

    getUptime(item: ISiteByDate<IUptimeDaily>, date: string) {
        var uptime: number,
            byDate = this.getByDate(item, date);

        if (byDate) {
            let result = byDate.result;
            if (result || result === 0) {
                uptime = Number(byDate.result) / 100;
            }
        }

        return uptime;
    }

    protected getAll(startDate: string, endDate: string) {
        return this.uptimeDailyApi.getByDateRange(startDate, endDate);
    }

    protected transformItems(items: ISiteByDate<IUptimeDaily>[]) {
        return sortBy(items, [
            (site: ISiteByDate<IUptimeDaily>) => {
                return sum(
                    Object.values(site.byDate).map(onDate => {
                        return Number(onDate && onDate.result) || 0;
                    })
                );
            },
            'site'
        ]);
    }
}
