import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { ILeukoplastSegment } from '../../../../model/product/tena/leukoplast/ILeukoplastSegment';

@Injectable()
export class LeukoplastSegmentApi extends BaseProductApi<ILeukoplastSegment> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmssegment/leukoplast';
    }
}
