import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';

import { Tool } from '../../model/auth/login/Tool';
import { TenaCatalogComponent } from '../../product/tena/TenaCatalog.component';
import { TenaCatalogSegmentMappingComponent } from '../../product/tena/mapping/TenaCatalogSegmentMapping.component';
import { TenaCatalogIdentificationMappingComponent } from '../../product/tena/mapping/TenaCatalogIdentificationMapping.component';
import { TenaColorCodeComponent } from '../../product/tena/TenaColorCode.component';
import { TenaBootstrapComponent } from '../../product/tena/TenaBootstrap.component';
import { TenaSegmentComponent } from '../../product/tena/TenaSegment.component';
import { TenaSiteMapComponent } from '../../product/tena/TenaSiteMap.component';
import { TenaWebShopComponent } from '../../product/tena/TenaWebShop.component';
import { TenaBlacklistComponent } from '../../product/tena/TenaBlacklist.component';
import { TenaEpiRulesComponent } from '../../product/tena/TenaEpiRules.component';
import { TenaGDPRComponent } from '../../product/tena/TenaGDPR.component';
import { TenaFulFillmentComponent } from '../../product/tena/TenaFulFillment.component';
import { TenaExternalSampleComponent } from '../../product/tena/TenaExternalSample.component';
import { TenaSampleOrderComponent } from '../../product/tena/TenaSampleOrder.component';
import { TenaLegacyIdExportComponent } from '../../product/tena/TenaLegacyIdExport.component';
import { TenaFullFilmentSchedulerComponent } from '../../product/tena/TenaFullFilmentScheduler.component';

export const TenaChildRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Catalogs',
        path: 'catalogs',
        component: TenaCatalogComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592398/4.2.1+Pim+Tena+Catalogs',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    // {
    //     navbarName: 'Catalog ⬌ Segment',
    //     path: 'mapping/catalog-segment',
    //     component: TenaCatalogSegmentMappingComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     helpUrl:
    //         'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
    //     requiredAccess: {
    //         tool: [Tool.ProductConfiguration]
    //     }
    // },
    {
        navbarName: 'Catalog Segment ⬌ Identification',
        path: 'mapping/catalog-segment-identification',
        component: TenaCatalogIdentificationMappingComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    // {
    //     navbarName: 'Colors',
    //     path: 'colors',
    //     component: TenaColorCodeComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     helpUrl:
    //         'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592521/4.2.2+Pim+Tena+Colors',
    //     requiredAccess: {
    //         tool: [Tool.ProductConfiguration]
    //     }
    // },
    // {
    //     navbarName: 'Data',
    //     path: 'data',
    //     component: TenaBootstrapComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     helpUrl:
    //         'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592580/4.2.3+Pim+Tena+Data+Records',
    //     requiredAccess: {
    //         tool: [Tool.ProductConfiguration]
    //     }
    // },
    {
        navbarName: 'Segments',
        path: 'segments',
        component: TenaSegmentComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592633/4.2.4+Pim+Tena+Segments',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Site Maps',
        path: 'site-maps',
        component: TenaSiteMapComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592738/4.2.5+Pim+Tena+Site+Maps',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Buy Now',
        path: 'webshop',
        component: TenaWebShopComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.PIM]
        }
    },
    {
        navbarName: 'Fulfillment Report',
        path: 'fulfillmentReport',
        component: TenaFulFillmentComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.TenaConsumerUserInformation]
        }
    },
    {
        navbarName: 'GDPR',
        path: 'gdpr',
        component: TenaGDPRComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.TenaConsumerUserInformation]
        }
    },
    {
        navbarName: 'Blacklist',
        path: 'blacklist',
        component: TenaBlacklistComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.TenaConsumerUserInformation]
        }
    },
    {
        navbarName: 'EPI Rules',
        path: 'epirules',
        component: TenaEpiRulesComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.PIM]
        }
    },
    {
        navbarName: 'Sample order',
        path: 'sampleOrder',
        component: TenaSampleOrderComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.PIM]
        }
    },
    {
        navbarName: 'External Sample Registration',
        path: 'externalSampleRegistration',
        component: TenaExternalSampleComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.PIM]
        }
    },
    {
        navbarName: 'Legacy ID export',
        path: 'legacyIdExport',
        component: TenaLegacyIdExportComponent,
        canDeactivate: [PrettySubmitGuard],
    },
    {
        navbarName: 'FullFillment Scheduler',
        path: 'fullFillmentScheduler',
        component: TenaFullFilmentSchedulerComponent,
        canDeactivate: [PrettySubmitGuard],
    },
];
