
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BaseLiveStageCrudList } from '../crud/list/BaseLiveStageCrudList';
import { Subject } from 'rxjs';
import { LiveStage } from '../environment/LiveStage';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { AdminTitle } from '../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { LiveStageService } from '../environment/LiveStage.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ObjectIdCrud } from '../crud/ObjectIdCrud';
import { ITpwGetSampleReport } from '../model/product/tena/tpw/ITpwGetSampleOrderReport';
import { TpwGetSampleReportApi } from '../api/product/tena/tpw/TpwGetSampleOrderReportApi';
import toIsoDate from '../date/toIsoDate';

@Component({
    templateUrl: 'TpwGetSampleOrderReport.component.html',
    providers: [TpwGetSampleReportApi]
})
export class TpwGetSampleOrderReportComponent
    extends BaseLiveStageCrudList<
       ITpwGetSampleReport,
        ICrudData<ITpwGetSampleReport>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
        getSampleReports: ITpwGetSampleReport[];
        fromDate?: string;
        toDate?: string;
        loading: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tpwGetSampleReportApi: TpwGetSampleReportApi
    ) {
        super(elementRef, 'sampleorder', liveStageService, prettySubmitGuard, 'Sample Order Report');
    }

    ngOnInit() {
        this.loading = true;
        var today = new Date();
        var toDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var fromDate = (today.getFullYear()-1)+'-'+(today.getMonth()+1)+'-'+today.getDate();
        this.fromDate = toIsoDate(fromDate);
        this.toDate = toIsoDate(toDate);
        this.tpwGetSampleReportApi.getSampleReport(this.fromDate, this.toDate).
        subscribe(SampleReport => {           
            if (SampleReport) {
                this.loading = false;
            }
                this.getSampleReports = SampleReport;         
        });
    }
        // onchange date range sample repord data change
        loadSampleReport() {    
            if (this.fromDate) {
                this.loading = true; 
                this.tpwGetSampleReportApi
                    .getSampleReport(
                        this.fromDate,
                        this.toDate
                    ).subscribe(items => {
                        if(items){
                            this.loading = false; 
                        }
                       this.getSampleReports = items;                      
                    });
            }    
    }
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }
    protected createCrud() {
        return new ObjectIdCrud(this.tpwGetSampleReportApi, this.authService);
    }
}
