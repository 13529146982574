import { Component, ElementRef, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { SiteMappingApi } from '../../api/campaign/siteMapping/SiteMappingApi';
import { ISiteMapping } from '../../model/campaign/siteMapping/ISiteMapping';
import { PromotionsApi } from '../../api/campaign/promotion/PromotionsApi';
import { IPromotion } from '../../model/campaign/promotion/IPromotion';
import { ShowMoreAdminCrudList } from '../../crud/list/ShowMoreAdminCrudList';
import { AdminTitle } from '../../layout/AdminTitle';
import { sortBy } from 'lodash';
import toIsoDate from '../../date/toIsoDate';

@Component({
    templateUrl: 'Promotions.component.html',
    providers: [SiteMappingApi, PromotionsApi]
})
export class PromotionsComponent
    extends ShowMoreAdminCrudList<IPromotion, ICrudData<IPromotion>>
    implements OnInit {
    sites$: BehaviorSubject<ISiteMapping[]> = new BehaviorSubject<ISiteMapping[]>(null);

    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        protected siteMappingsApi: SiteMappingApi,
        protected promotionsApi: PromotionsApi
    ) {
        super(elementRef, prettySubmitGuard, 'Promotion');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Promotions');
        this.siteMappingsApi.getAll().subscribe(sites => this.sites$.next(sites));

        this.promotionsApi.getAll().subscribe(promotions => {
            promotions = sortBy(promotions, 'sortOrder');

            promotions.forEach(promotion => {
                promotion.startDate = toIsoDate(promotion.startDate);
                promotion.endDate = toIsoDate(promotion.endDate);
            });

            this.init(promotions);
        });
    }

    protected createCrud() {
        return new GuidCrud(this.promotionsApi);
    }

    protected createItem() {
        return {
            startDate: toIsoDate(new Date())
        } as IPromotion;
    }
}
