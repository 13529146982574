import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ICatalogSolrcoreMapping } from '../../../model/product/ICatalogSolrcoreMapping';

@Injectable()
export class FemcareCatalogSolrcoreMappingApi extends BaseProductApi<
    ICatalogSolrcoreMapping
> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'femcare/catalogcoremapping';
    }
}
