
import { takeUntil } from 'rxjs/operators';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DistributorFinderApi } from '../../../../../api/dealerLocator/DealerLocatorApi';
import { DealerLocatorService } from '../../dealerLocator/DistributorFinder.service';
import { IDistributor } from '../../../../../model/dealerLocator/IDistributor';
import { Subject } from 'rxjs';
import { AppConstants } from '../../../../../constants/constants';
import { IAlert } from 'tridion.web.ui/src/app/angular/layout/alert/IAlert';
import { AlertType } from 'tridion.web.ui/src/app/angular/layout/alert/AlertType';
import { AlertService } from 'tridion.web.ui/src/app/angular/layout/alert/Alert.service';
import { MapsAPILoader } from '@agm/core';

@Component({
    selector: 'admin-dealer-modal-edit',
    templateUrl: 'DistributorModalEdit.component.html'
})
export class DealerLocatorModalEditComponent implements OnInit, OnDestroy {
    readonly geolocationResult: string;
    @Input()
    page: number;
    @Input()
    limit: number;
    response: string;
    closeResult: string;
    showModal = false;
    mapVisible: boolean;
    distributor: IDistributor;
    distributorNewValues: IDistributor;
    distributorForm: FormGroup;
    wholeCountry = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private distributorFinderApi: DistributorFinderApi,
        private dealerLocatorService: DealerLocatorService,
        private fb: FormBuilder,
        private alertService: AlertService,
        private mapsAPILoader: MapsAPILoader,
    ) { }

    ngOnInit() {
        this.dealerLocatorService.mapOpen.pipe(takeUntil(this.unsubscribe)).subscribe(visible => {
            this.mapVisible = visible;
        });

        this.dealerLocatorService.distributor.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((distributor: IDistributor) => {
                this.distributor = distributor;
            });

        this.dealerLocatorService.distributorNewValues.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((distributor: any) => {
                this.distributorNewValues = distributor;
                if (distributor) {
                    if (!this.distributorForm) {
                        this.buildForm();
                    } else {
                        for (let item in this.distributorForm.value) {
                            if (
                                distributor[item] !== this.distributorForm.value[item] &&
                                distributor[item]
                            ) {
                                this.distributorForm.controls[item].setValue(
                                    distributor[item]
                                );
                            }
                        }
                        this.distributorForm.controls.geoCode.setValue(
                            distributor.geoCode
                        );
                    }
                    this.toggleEditModal(true);
                }
            });
    }

    buildForm() {
        let regex = new RegExp(AppConstants.webSiteUrlPattern);
        let validateEmail = new RegExp(
            '[a-zA-Z0-9._-]+[@]+[a-zA-Z0-9-]+[.]+[a-zA-Z]{2,6}'
        );
        let phonePattern = new RegExp('^[0-9-+/() ]*$');

        this.distributorForm = this.fb.group({
            name: new FormControl(this.distributorNewValues.name, Validators.required),
            address: new FormControl(
                this.distributorNewValues.address,
                Validators.required
            ),
            city: new FormControl(this.distributorNewValues.city, Validators.required),
            region: new FormControl(this.distributorNewValues.region),
            zip: new FormControl(this.distributorNewValues.zip, Validators.required),
            geoCode: new FormControl(
                this.distributorNewValues.geoCode,
                Validators.required
            ),
            telephone: new FormControl(
                this.distributorNewValues.telephone,
                Validators.pattern(phonePattern)
            ),
            fax: new FormControl(this.distributorNewValues.fax),
            email: new FormControl(
                this.distributorNewValues.email,
                Validators.pattern(validateEmail)
            ),
            website: new FormControl(
                this.distributorNewValues.website,
                Validators.pattern(regex)
            ),
            description: new FormControl(this.distributorNewValues.description),
            geoSalesReach: new FormControl(this.distributorNewValues.geoSalesReach),
            isOnlineSale: new FormControl(this.distributorNewValues.isOnlineSale),
            isStoreSale: new FormControl(this.distributorNewValues.isStoreSale),
            wholeCountry: new FormControl(this.wholeCountry),
            logoUrl: new FormControl(this.distributorNewValues.logoUrl)
        });
    }

    saveDistributor() {
        if (this.distributorForm.invalid) {
            return;
        }

        Object.assign(this.distributorNewValues, this.distributorForm.value);

        this.distributorFinderApi
            .update(this.distributorNewValues).pipe(
                takeUntil(this.unsubscribe))
            .subscribe(
                (res: IDistributor) => {
                    this.dealerLocatorService.distributorNewValues.next(undefined);
                    this.dealerLocatorService.distributor.next(null);
                    this.distributorFinderApi
                        .getDistributors(this.limit, this.page === 0 ? 1 : this.page).pipe(
                            takeUntil(this.unsubscribe))
                        .subscribe(response => {
                            this.dealerLocatorService.totalCount = response.totalCount;
                            this.dealerLocatorService.distributors.next(
                                response.distributors
                            );
                        });
                    this.response = 'Distributor changed!';

                    setTimeout(() => {
                        this.toggleEditModal(false);
                    }, 2500);
                },
                error => {
                    if (error._body) {
                        let alert: IAlert = {
                            message: '',
                            type: AlertType.error,
                            close: true
                        };

                        alert.message = error._body;

                        this.alertService.setAlert(alert);
                    }

                    this.response = error.statusText;
                }
            );
    }

    addDistributor() {
        if (this.distributorForm.invalid) {
            return;
        }

        Object.assign(this.distributorNewValues, this.distributorForm.value);

        this.distributorFinderApi
            .add(this.distributorNewValues).pipe(
                takeUntil(this.unsubscribe))
            .subscribe((res: IDistributor) => {
                this.distributorFinderApi
                    .getDistributors(this.limit, this.page).pipe(
                        takeUntil(this.unsubscribe))
                    .subscribe(response => {
                        this.dealerLocatorService.totalCount = response.totalCount;
                        this.dealerLocatorService.distributors.next(response.distributors);
                    });
                this.dealerLocatorService.distributorNewValues.next(null);
                this.response = 'Distributor added!';

                setTimeout(() => {
                    this.toggleEditModal(false);
                }, 2500);
            });
    }

    resetForm() {
        if (this.distributor === null) {
            this.distributor = undefined;
        }
        this.distributorForm.reset(this.distributor);
    }

    toggleEditModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            this.response = '';
            this.dealerLocatorService.distributor.next(undefined);
            this.dealerLocatorService.distributorNewValues.next(null);

            this.distributorForm.reset();
            this.distributorForm.patchValue({ isStoreSale: false })
            this.distributorForm.patchValue({ isOnlineSale: false })

            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }

        this.showModal = show;
    }

    openMap() {
        Object.assign(this.distributorNewValues, this.distributorForm.value);
        const {
            city,
            address,
            zip
        } = this.distributorNewValues;
        this.setGeolocation(city, address, zip);
    }

    @HostListener('click', ['$event.target.className'])
    onClick(elementClass: string) {
        if (elementClass === 'modal') {
            this.toggleEditModal(false);
        }
    }
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setGeolocation(city: string, street: string, zipCode: string) {
        // Combine street, city, and zip code into a formatted address
        const address = `${street}, ${city}, ${zipCode}`;

        // Use the Google Maps Geocoding API to get geolocation
        this.mapsAPILoader.load().then(() => {
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK' && results.length > 0) {
                    const location = results[0].geometry.location;
                    const geoLoacation = `${location.lat()}, ${location.lng()}`;
                    Object.assign(this.distributorNewValues, {
                        geoCode: geoLoacation,
                    });
                    this.distributorForm.get('geoCode').setValue(geoLoacation);
                    this.dealerLocatorService.distributorNewValues.next(this.distributorNewValues);
                    this.dealerLocatorService.mapOpen.next(true);
                }
            });
        });
    }
}
