import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { FinancialFileApi } from '../../api/report/financial/FinancialFileApi';
import { ShowMoreAdminCrudList } from '../../crud/list/ShowMoreAdminCrudList';
import { IFinancialFile } from '../../model/report/financial/IFinancialFile';

type FileCrud = BaseCrud<IFinancialFile, ICrudData<IFinancialFile>>;

@Component({
    selector: 'admin-financial-report-file',
    templateUrl: 'FinancialFile.component.html',
    providers: [FinancialFileApi]
})
export class FinancialFileComponent
    extends ShowMoreAdminCrudList<IFinancialFile, ICrudData<IFinancialFile>>
    implements OnInit, OnDestroy {
    @Input()
    crudIndex: string;
    @Input()
    reportId: string;
    @Input()
    reportFiles: IFinancialFile[];
    @Output()
    reorder = new EventEmitter<string[]>();
    @Output()
    cancel = new EventEmitter<boolean>();
    @ViewChild('fileInput', {static: false})
    fileInput: ElementRef;
    orderChanged: boolean;
    dragulaGroup = 'Files';
    orderedFileIds: string[] = [];
    subscription = new Subscription();
    reorderInProgress: boolean;
    handleClass = 'FinancialFile--item__handle';

    constructor(
        prettySubmitGuard: PrettySubmitGuard,
        private financialReportFileApi: FinancialFileApi,
        private dragulaService: DragulaService
    ) {
        super(null, prettySubmitGuard, 'Financial Report File');
    }

    ngOnInit(): void {
        this.init(this.reportFiles);
        this.financialReportFileApi.reportId = this.reportId;
        this.dragulaGroup = this.dragulaGroup + this.crudIndex;
        this.dragulaService.createGroup(this.dragulaGroup, {
            moves: (el, container, handle) => {
                return (
                    handle.classList.contains(this.handleClass) &&
                    !this.reorderInProgress &&
                    !this.hasNewItem()
                );
            }
        });

        this.subscription.add(
            this.dragulaService
                .dropModel(this.dragulaGroup)
                .subscribe(({ targetModel }) => {
                    this.orderChanged = true;
                    this.orderedFileIds = targetModel.map(
                        (crud: FileCrud, index: number) => {
                            this.items[index] = crud.savedData.item;

                            return crud.data.item.id;
                        }
                    );
                })
        );
    }

    ngOnDestroy() {
        this.dragulaService.destroy(this.dragulaGroup);
        this.subscription.unsubscribe();
    }

    reorderFiles() {
        this.reorderInProgress = true;
        this.subscription.add(
            this.financialReportFileApi.reorderFiles(this.orderedFileIds).subscribe(
                () => {
                    this.orderChanged = false;
                    this.reorder.emit(this.orderedFileIds);
                },
                err => (this.reorderInProgress = false),
                () => (this.reorderInProgress = false)
            )
        );
    }

    discard() {
        this.cancel.emit(true);
    }

    changeFile(crud: FileCrud) {
        if (this.fileInput.nativeElement.files.length > 0) {
            let file = this.fileInput.nativeElement.files[0];
            if (
                file.type === 'application/pdf' ||
                file.type === 'application/vnd.ms-excel' ||
                file.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                file.type === 'application/.zip' ||
                file.type === 'application/x-zip-compressed' ||
                file.type === 'application/xhtml+xml'
            ) {
                crud.data.item.file = file;
                crud.data.item.fileName = file.name;
            } else {
                crud.data.item.file = null;
                crud.data.item.fileName = null;
            }
        }
    }

    saveFile(crud: FileCrud) {
        this.subscription.add(
            crud.save().subscribe((res: IFinancialFile) => {
                crud.data.item.url = res.url;
                crud.savedData.item.url = res.url;
            })
        );
    }

    protected createCrud(): FileCrud {
        return new GuidCrud(this.financialReportFileApi);
    }
}
