import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { PublishingStatisticsApi } from '../../../api/report/PublishingStatisticsApi';
import { groupBy } from 'lodash';
import { ChartColors } from '../../../model/chart/ChartColors';
import { BasePublishing } from '../BasePublishing';
import { IExecutionTime } from '../../../model/report/publishing/IExecutionTime';
import { LineChartOptions } from '../../../model/chart/BaseLineChartOptions';
import { AdminTitle } from '../../../layout/AdminTitle';

@Component({
    templateUrl: 'ExecutionTime.component.html',
    providers: [PublishingStatisticsApi]
})
export class ExecutionTimeComponent extends BasePublishing<IExecutionTime>
    implements OnInit {
    allBrands: boolean;
    currentBrand: string;
    constructor(
        protected adminTitle: AdminTitle,
        protected publishingStatisticsApi: PublishingStatisticsApi
    ) {
        super(adminTitle, publishingStatisticsApi);
    }

    ngOnInit() {
        this.adminTitle.setTitle('Execution times');
        this.getChartData();
    }

    getChartData() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.chartData = [];

        if (this.brand.length === 0) {
            this.currentBrand = '';
            this.allBrands = false;
        } else if (this.brand === 'All Brands by Brand') {
            this.currentBrand = 'Essity,FemCare,Tena Consumer,Tena Professional,Tork,';
            this.allBrands = true;
        } else {
            this.currentBrand = this.brand;
            this.allBrands = false;
        }

        this.subscription = this.publishingStatisticsApi
            .getExecutionTimes(
                this.startDate,
                this.endDate,
                this.interval,
                this.currentBrand
            )
            .subscribe(executionTimes => {
                let datasets = this.prepareExecutionTimesDataset(executionTimes);
                this.setChartOptionInterval();

                if (this.chartData.datasets) {
                    this.chartData.datasets = datasets;

                    return this.chartComponent.chart.update();
                }

                this.chartData = {
                    datasets: datasets
                };
            });
    }

    // tslint:disable:max-func-body-length
    private prepareExecutionTimesDataset(executionTimes: IExecutionTime[]) {
        let transformedPriorityStatistics = groupBy(executionTimes, 'Brand');
        let datasets: ChartDataSets[] = [];

        Object.keys(transformedPriorityStatistics)
            .sort()
            .forEach((brand, index) => {
                let countPoints: any = [];
                let averageTimePoints: any = [];
                let totalTimePoints: any = [];

                transformedPriorityStatistics[brand].forEach((point: IExecutionTime) => {
                    countPoints.push({
                        x: new Date(point.Date),
                        y: point.Count
                    });

                    averageTimePoints.push({
                        x: new Date(point.Date),
                        y: point.AverageTime
                    });

                    totalTimePoints.push({
                        x: new Date(point.Date),
                        y: point.TotalTime
                    });
                });

                if (this.allBrands === false) {
                    datasets.push({
                        ...LineChartOptions,
                        label: 'Count',
                        data: countPoints,
                        borderColor: ChartColors[index],
                        pointBackgroundColor: ChartColors[index]
                    });

                    datasets.push({
                        ...LineChartOptions,
                        label: 'Average time (s)',
                        data: averageTimePoints,
                        borderColor: ChartColors[index + 1],
                        pointBackgroundColor: ChartColors[index + 1]
                    });

                    datasets.push({
                        ...LineChartOptions,
                        label: 'Total time (s)',
                        data: totalTimePoints,
                        borderColor: ChartColors[index + 2],
                        pointBackgroundColor: ChartColors[index + 2]
                    });
                } else {
                    if(brand.length>0){
                        datasets.push({
                            ...LineChartOptions,
                            label: brand,
                            data: averageTimePoints,
                            borderColor: ChartColors[index + 1],
                            pointBackgroundColor: ChartColors[index + 1]
                        });
                    }
                }
            });

        return datasets;
    }

    // tslint:enable
}
