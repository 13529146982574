import { BaseApi } from 'tridion.web.ui/src/app/angular/api/BaseApi';
import { IEnvironment } from '../../environment/IEnvironment';
import { Inject, Injectable } from '@angular/core';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { IVariantCDNUrl } from '../../model/celum/IVariantCDNUrl';

@Injectable()
export class CelumApi extends BaseApi<IEnvironment> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http
    ) {
        super(apiErrorHandler, environment, http);
    }

    getVariantUrls(celumId: string): Observable<IVariantCDNUrl[]> {
        return this.getJson<IVariantCDNUrl[]>(celumId);
    }

    public getBaseUrl(): string {
        return this.environment.api.celum + 'asset/';
    }
}
