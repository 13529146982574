import { Component, Input } from '@angular/core';

@Component({
    selector: 'admin-small-header',
    templateUrl: 'SmallHeader.component.html'
})
export class SmallHeaderComponent<I> {
    @Input() items: I[];
    @Input() itemType: string;
}
