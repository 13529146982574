import { ActivatedRoute } from '@angular/router';
import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Response } from '@angular/http';

import { AdminTitle } from '../layout/AdminTitle';
import { IFulfillmentApi } from '../api/fulfillment/IFulfillmentApi';
import { IFulfillmentItem } from '../model/fulfillment/IFulfillmentItem';
import { IFulfillmentSite } from '../model/fulfillment/IFulfillmentSite';
import { SiteMappingApi } from '../api/campaign/siteMapping/SiteMappingApi';
import saveResponseFile from '../api/fileManager/fileManagerApi';
import toIsoDate from '../date/toIsoDate';
import { FulfillmentAuthApi } from '../api/fulfillment/FulfillmentAuthApi';
import 'date-input-polyfill';
import { IFulfillmentTarget } from '../model/fulfillment/IFulfillmentTarget';

export abstract class BaseFulfillmentComponent<I extends IFulfillmentItem>
    implements OnInit {
    brand: string;
    sites$: BehaviorSubject<IFulfillmentSite[]> = new BehaviorSubject<IFulfillmentSite[]>(
        null
    );
    site?: IFulfillmentSite;
    target?: IFulfillmentTarget;
    items$: BehaviorSubject<I[]> = new BehaviorSubject<I[]>(null);
    startDate?: string;
    endDate?: string;
    texts: { title: string; itemTitle: string };
    title: string;
    pageSize: number;
    hasMoreItems = false;
    item: IFulfillmentItem;
    deleteButton: any;
    deleteItem = false;
    confirmModal = false;
    itemId: number;
    loadingItems = false;

    constructor(
        protected fulfillmentApi: IFulfillmentApi<I>,
        protected route: ActivatedRoute,
        protected adminTitle: AdminTitle,
        protected fulfillmentAuthApi: FulfillmentAuthApi,
        protected siteMappingApi: SiteMappingApi,
        texts: { title: string; itemTitle: string },
        pageSize: number
    ) {
        this.texts = texts;
        let endDate = new Date();
        let startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 1);
        this.startDate = toIsoDate(startDate);
        this.endDate = toIsoDate(endDate);
        this.pageSize = pageSize;
        this.route.data.subscribe(data => {
            this.brand = data.brand;
            this.updateTitle();
        });
    }

    ngOnInit() {
        this.adminTitle.setTitle(this.title);
        this.siteMappingApi.getAll().subscribe(sites => this.sites$.next(sites));
    }

    loadItems() {
        if (this.site && this.startDate) {
            this.fulfillmentApi
                .getItems(this.site, this.getTargetId(), this.pageSize, this.startDate, this.endDate)
                .subscribe(items => {
                    // Response can return pagesize + 1 number of items to indicate that next page exists.
                    this.hasMoreItems = items.length > this.pageSize;
                    if (this.hasMoreItems) {
                        items.pop();
                    }
                    this.items$.next(items);
                });
            this.showDeleteButton();
        }
    }

    loadMoreItems() {
        if (this.site && this.startDate) {
            this.loadingItems = true;
            this.fulfillmentApi
                .getMoreItems(
                    this.site,
                    this.getTargetId(),
                    this.pageSize,
                    this.items$.value.length,
                    this.startDate,
                    this.endDate
                )
                .subscribe(
                    items => {
                        // Response can return pagesize + 1 number of items to indicate that next page exists.
                        this.hasMoreItems = items.length > this.pageSize;
                        if (this.hasMoreItems) {
                            items.pop();
                        }
                        this.items$.next(this.items$.value.concat(items));
                    },
                    err => {
                        this.loadingItems = false;
                    },
                    () => {
                        this.loadingItems = false;
                    }
                );
        }
    }

    exportToCsv() {
        if (this.site && this.startDate) {
            this.fulfillmentApi
                .getCsv(this.site, this.getTargetId(), this.startDate, this.endDate)
                .subscribe((response: Response) => {
                    let fileName =
                        this.site.name + '_' + this.startDate + '_' + this.endDate;

                    saveResponseFile(response, fileName);
                });
        }
    }

    showDeleteButton() {
        this.fulfillmentAuthApi.getUserByRole().subscribe((response: Response) => {
            this.deleteButton = response;
        });
    }

    activateDeleteMode(activate: boolean) {
        if (activate) {
            this.deleteItem = true;
        } else {
            this.deleteItem = false;
        }
    }

    toggleConfirmModal(show: boolean, item: IFulfillmentItem, row: any) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
            row.classList.add('table__deleteRow');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
            this.activateDeleteMode(false);
        }

        this.item = item;
        this.itemId = item.id;
        this.confirmModal = show;
    }

    deleteFulfillment() {
        this.fulfillmentApi.removeItem(this.item).subscribe((reponse: Response) => {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
            this.deleteItem = false;
            this.loadItems();
        });
    }

    getTargetId(){
        return (this.target && this.target.id) || null;
    }

    private updateTitle(): void {
        this.title = `${this.texts.title} - ${this.brand}`;
    }
}
