import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITenaCatalog } from '../../../model/product/tena/ITenaCatalog';

@Injectable()
export class TenaCatalogApi extends BaseProductApi<ITenaCatalog> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscatalog/tena';
    }
}
