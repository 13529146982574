import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Headers, Http, RequestOptions } from '@angular/http';
import { FormGroup } from '@angular/forms';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { ICrudApi } from 'tridion.web.ui/src/app/angular/api/ICrudApi';
import { IDistributor } from '../../model/distributorFinder/IDistributor';
import { ICountry } from '../../model/distributorFinder/ICountry';
import { IEnvironment } from '../../environment/IEnvironment';
import { DistributorService } from '../../distributorFinder/DistributorFinder.service';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';

@Injectable()
export class DistributorFinderApi extends BaseAuthorizedApi<IEnvironment>
    implements ICrudApi<IDistributor, string> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        private distributorService: DistributorService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    getDistributors(limit: number, page: number, filter?: FormGroup) {
        return this.get('distributor', {
            headers: new Headers({
                'x-state': this.distributorService.getActiveCountry().code
            }),
            params: {
                page: page,
                limit: limit,
                key: filter && filter.value.searchField ? filter.value.searchFilter : '',
                value: filter && filter.value.searchField ? filter.value.searchField : ''
            }
        }).pipe(map(response => {
            return {
                totalCount: Number(response.headers.get('x-total-count')),
                distributors: response.json() as IDistributor[]
            };
        }));
    }

    importData(file: any): Observable<any> {
        return this.postJson('distributor/import', file);
    }

    exportFile() {
        return this.get('distributor/export');
    }

    getAll(): Observable<IDistributor[]> {
        return this.getJson('distributor');
    }

    getById(id: string): Observable<IDistributor> {
        return this.getJson('distributor/' + id);
    }

    add(item: IDistributor): Observable<IDistributor> {
        let headers = new Headers();
        headers.append('x-state', this.distributorService.getActiveCountry().code);
        let options = new RequestOptions({ headers: headers });

        return this.postJson('distributor/', item, options);
    }

    update(item: IDistributor): Observable<IDistributor> {
        return this.putJson('distributor/' + item.id, item);
    }

    activate(item: IDistributor): Observable<any> {
        return this.put('distributor/' + item.id + '/activate', undefined);
    }

    inactivate(item: IDistributor): Observable<any> {
        return this.delete('distributor/' + item.id);
    }

    publish() {
        return this.get('distributor/upload');
    }

    protected buildOptionsAsync(options: RequestOptions) {
        let headers = new Headers(options ? options.headers : undefined);

        if (this.distributorService.getActiveCountry()) {
            headers.append(
                'x-state-path',
                this.distributorService.getActiveCountry().authorizationPath
            );
        }

        options = new RequestOptions().merge(options).merge({
            headers: headers
        });

        return super.buildOptionsAsync(options);
    }

    protected getBaseUrl() {
        return this.environment.api.distributor;
    }
}
