import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { IActimoveSegment } from '../../../model/product/tena/actimove/IActimoveSegment';
import { ActimoveService } from './Actimove.service';
import { ActimoveUserSegmentApi } from '../../../api/product/tena/actimove/ActimoveUserSegmentApi';

@Component({
    selector: 'admin-actimove-segment-select',
    templateUrl: 'ActimoveSegmentSelect.component.html',
    providers: [ActimoveUserSegmentApi]
})
export class ActimoveSegmentSelectComponent implements OnInit, OnDestroy {
    segmentForm: FormGroup;
    segments: IActimoveSegment[];

    @Input()
    selectedSegment: IActimoveSegment;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public actimoveService: ActimoveService,
        private actimoveSegmentApi: ActimoveUserSegmentApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.actimoveService.segments.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((segments: IActimoveSegment[]) => {
                this.segments = segments;
            });

        this.segmentForm = this.fb.group({
            segment: new FormControl(
                this.selectedSegment ? this.selectedSegment.objectId.toString() : '',
                Validators.required
            )
        });

        this.actimoveSegmentApi.getAll().subscribe((res: IActimoveSegment[]) => {
            let ActimoveSegment = res.filter(segment => segment.isActimoveSegment);
            this.actimoveService.segments.next(ActimoveSegment);
        });

        this.actimoveService.segmentModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleSegmentModal(visible);
            });

        this.toggleSegmentModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectSegment(segment: IActimoveSegment) {
        if (this.segmentForm.valid) {
            segment = this.segments.find((item: IActimoveSegment) => {
                if (item.objectId === parseInt(this.segmentForm.value.segment)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('actimoveSelectedSegment', JSON.stringify(segment));
            this.actimoveService.segmentChanged.next(segment);

            this.actimoveService.segmentModalVisible.next(false);
        }
    }

    toggleSegmentModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
