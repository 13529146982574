import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { ICutimedSiteMap } from '../../../../model/product/tena/cutimed/ICutimedSiteMap';

@Injectable()
export class CutimedSiteMapApi extends BaseProductApi<ICutimedSiteMap> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmsglobalsitemap/cutimed';
    }
}
