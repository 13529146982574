import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { IJobstCatalog } from './../../../../model/product/tena/jobst/IJobstCatalog.d';

@Injectable()
export class JobstCatalogApi extends BaseProductApi<IJobstCatalog> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscatalog/jobst';
    }
}