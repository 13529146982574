import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { ICrudApi } from 'tridion.web.ui/src/app/angular/api/ICrudApi';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { IObjectIdModel } from '../model/product/IObjectIdModel';

export class ObjectIdCrud<
    I extends IObjectIdModel,
    D extends ICrudData<I>
> extends BaseCrud<I, D> {
    constructor(protected api: ICrudApi<I, any>, private authService: AuthService) {
        super(api);
    }

    protected performSave(data: D, savedData: D) {
        var observable: Observable<I>;

        var item = data.item,
            savedItem = savedData.item,
            add = !item.objectId;

        if (add) {
            observable = this.performAdd(data);
        } else {
            observable = this.performUpdate(data);
        }
        observable = observable.pipe(share());

        observable.subscribe(
            saved => {
                if (add) {
                    item.objectId = saved.objectId;
                    savedItem.objectId = saved.objectId;
                }

                var modifiedBy =
                        (saved && saved.lastModifiedBy) ||
                        this.authService.getUserFullName(),
                    modifiedDate =
                        (saved && saved.lastModifiedDate) || new Date().toISOString();

                item.lastModifiedBy = modifiedBy;
                savedItem.lastModifiedBy = modifiedBy;

                item.lastModifiedDate = modifiedDate;
                savedItem.lastModifiedDate = modifiedDate;
            },
            () => {
                // Do nothing.
            }
        );

        return observable;
    }
}
