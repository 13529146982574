import { Injectable } from '@angular/core';

import { BaseAuthorizedGuidApi } from '../BaseAuthorizedGuidApi';
import { IDomain } from '../../model/testResults/IDomain';

@Injectable()
export class DomainApi extends BaseAuthorizedGuidApi<IDomain> {
    protected getBaseUrl() {
        return this.environment.api.automation + 'Domain';
    }
}
