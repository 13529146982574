import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { ITorkWebService } from '../../model/product/tork/ITorkWebService';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TorkWebServiceApi } from '../../api/product/tork/TorkWebServiceApi';

@Component({
    templateUrl: 'TorkWebService.component.html',
    providers: [TorkWebServiceApi]
})
export class TorkWebServiceComponent
    extends BaseLiveStageCrudList<
        ITorkWebService,
        ICrudData<ITorkWebService>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private torkWebServiceApi: TorkWebServiceApi
    ) {
        super(elementRef, 'product', liveStageService, prettySubmitGuard, 'Web Service');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Web Services - Tork');

        this.filter.observable.subscribe(() => {
            this.torkWebServiceApi.getAll().subscribe(services => {
                this.init(sortBy(services, 'isProdEnv'));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.torkWebServiceApi, this.authService);
    }
}
