import { Inject, Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IObjectIdModel } from '../../../../model/product/IObjectIdModel';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../../../environment/IEnvironment';

import { TpwService } from '../../../../gdpr/Tpw.service';
import { BaseTPWApi } from './BaseTPWApi';

@Injectable()
export abstract class BaseTPWSampleApi<T extends IObjectIdModel> extends BaseTPWApi<T> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        public tpwService: TpwService
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    protected buildOptionsAsync(options: RequestOptions) {
        // Add selected CatalogId to every request.

        let headers = new Headers(options ? options.headers : undefined);
        let activeSample = this.tpwService.getActiveSample();
        if (activeSample) {
            headers.append('categoryId', activeSample.categoryId.toString());
        }

        options = new RequestOptions().merge(options).merge({
            headers: headers
        });

        return super.buildOptionsAsync(options);
    }
}
