import { Http, Response } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { LeukoplastService } from '../../../../product/tena/leukoplast/Leukoplast.service';
import { BaseTCWCatalogApi } from '../tcw/BaseTCWCatalogApi';
import { ILeukoplastUserData } from '../../../../model/product/tena/leukoplast/ILeukoplastUserData';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { LeukoplastTCWCatalogApi } from './LeukoplastTCWCatalogApi';
import { ILeukoplastTCWCatalog } from '../../../../model/product/tena/leukoplast/ILeukoplastTCWCatalog';

@Injectable()
export class LeukoplastLegacyIdExportApi extends BaseTCWCatalogApi<ILeukoplastUserData> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        leukoplastService: LeukoplastService,
        readonly leukoplastService1: LeukoplastService,
        liveStageService: LiveStageService,
        readonly leukoplastCatalogApi: LeukoplastTCWCatalogApi
    ) {
        super(apiErrorHandler, authService, environment, http, leukoplastService, liveStageService);
    }

    getItems(
        countryId: number,
    ): Observable<ILeukoplastUserData[]> {
        return this.getJson(`exportlegacymappings/${countryId}`);
    }

    getCsv(
        countryId: number,
    ): Observable<Response> {
        return this.get(`exportlegacymappings/${countryId}?excel=true`);
    }

    protected getBaseUrl() {
        this.leukoplastCatalogApi.getAll().subscribe((res: ILeukoplastTCWCatalog[]) => {
            this.leukoplastService1.countries.next(res);
        });

        return this.TCWBaseURl;
    }
}
