import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { CatalogSolrcoreMapping } from '../../mapping/CatalogSolrcoreMapping';
import { FemcareCatalogApi } from '../../../api/product/femcare/FemcareCatalogApi';
import { FemcareCatalogSolrcoreMappingApi } from '../../../api/product/femcare/FemcareCatalogSolrcoreMappingApi';
import { FemcareSolrcoreApi } from '../../../api/product/femcare/FemcareSolrcoreApi';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';

@Component({
    templateUrl: '../../mapping/CatalogSolrcoreMapping.component.html',
    providers: [FemcareCatalogApi, FemcareCatalogSolrcoreMappingApi, FemcareSolrcoreApi]
})
export class FemcareCatalogSolrcoreMappingComponent extends CatalogSolrcoreMapping
    implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        private femcareCatalogApi: FemcareCatalogApi,
        private femcareCatalogSolrcoreMappingApi: FemcareCatalogSolrcoreMappingApi,
        private femcareSolrcoreApi: FemcareSolrcoreApi,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard
    ) {
        super(elementRef, liveStageService, prettySubmitGuard);
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalog ⬌ Solr Cores - FemCare');

        this.filter.observable.subscribe(filter => {
            this.initAll(
                this.femcareCatalogApi.getAll(),
                this.femcareSolrcoreApi.getAll(),
                this.femcareCatalogSolrcoreMappingApi.getAll()
            );
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.femcareCatalogSolrcoreMappingApi, this.authService);
    }
}
