import { Component, OnInit } from '@angular/core';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { AdminTitle } from '../../layout/AdminTitle';
import { ITestData } from '../../model/testResults/ITestData';
import { DomainApi } from '../../api/testResults/DomainApi';
import { TestResultsApi } from '../../api/testResults/TestResultsApi';
import { IAutomationFilters } from '../../model/testResults/IAutomationFilters';
import * as moment from 'moment';
import { IDomain } from '../../model/testResults/IDomain';
import { IWebsite } from '../../model/testResults/IWebsite';
import { SlugifyPipe } from '../../pipe/slugify.pipe';
import { ITest } from '../../model/testResults/ITest';

@Component({
    templateUrl: 'AdminTestResults.component.html',
    providers: [DomainApi, TestResultsApi, SlugifyPipe]
})
export class AdminTestResultsComponent implements OnInit {
    filters: IAutomationFilters;
    domains: IDomain[];
    testData: ITestData;
    websites: IWebsite[];
    tests: ITest[];
    test: ITest;
    availableDomains: string[];
    availableTypes: string[];
    domain: IDomain;
    website: IWebsite;
    startDate: string = moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD');
    endDate: string = moment().format('YYYY-MM-DD');

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        private testResultsApi: TestResultsApi,
        private domainApi: DomainApi
    ) {
        this.filters = {
            startDate: this.startDate,
            endDate: this.endDate,
            domain: this.domain,
            website: this.website
        };
    }

    ngOnInit() {
        this.adminTitle.setTitle('Automation Results');
        this.getDomains();
    }

    getDomains() {
        this.domainApi.getAll().subscribe((domains: IDomain[]) => {
            this.domains = domains;
        });
    }

    get filtersAreDirty() {
        return !this.testFilters();
    }

    filtersChange() {
        if (this.filtersAreDirty) {
            this.getTestData();
        }
    }
    testFilters() {
        return (
            this.domain === this.filters.domain &&
            this.website === this.filters.website &&
            this.startDate === this.filters.startDate &&
            this.endDate === this.filters.endDate
        );
    }
    getTestData() {
        this.testResultsApi
            .getTestData(this.filters.domain.id, this.filters)
            .subscribe(testData => {
                // Update stored values
                this.domain = this.filters.domain;
                this.website = this.filters.website;
                this.startDate = this.filters.startDate;
                this.endDate = this.filters.endDate;

                this.testData = testData;

                if (this.website) {
                    const website: IWebsite = testData.domains[0].websites.find(
                        (localWebsite: IWebsite) => localWebsite.id === this.website.id
                    );
                    if (website) {
                        this.tests = website.tests;

                        if (this.test) {
                            this.getSingleTest(this.test.id);
                        }
                    }
                }
            });
    }

    getSingleTest(testId: string) {
        if (testId) {
            this.test = this.tests.find(test => test.id === testId);
        }
    }
}
