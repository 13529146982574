import { Component, ElementRef, OnInit } from '@angular/core';

import { AdminTitle } from '../../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseCatalog } from '../../BaseCatalog';
import { IActimoveCatalog } from '../../../model/product/tena/actimove/IActimoveCatalog';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../../sortCatalogs';
import { ActimoveCatalogApi } from '../../../api/product/tena/actimove/ActimoveCatalogApi';

@Component({
    templateUrl: '../../BaseCatalog.component.html',
    providers: [ActimoveCatalogApi]
})
export class ActimoveCatalogComponent extends BaseCatalog<IActimoveCatalog> implements OnInit {
    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private actimoveCatalogApi: ActimoveCatalogApi
    ) {
        super(elementRef, liveStageService, prettySubmitGuard, 'Actimove');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Catalogs - Actimove');

        this.filter.observable.subscribe(filter => {
            this.actimoveCatalogApi.getAll().subscribe(catalogs => {
                this.init(sortCatalogs(catalogs));
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.actimoveCatalogApi, this.authService);
    }
}
