
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { ElementRef } from '@angular/core';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { BaseAdminCrudList } from '../../../crud/list/BaseAdminCrudList';
import { IGuidSolrCoreConfig } from '../../../model/product/distributionHub/IGuidSolrCoreConfig';
import { keyBy, sortBy } from 'lodash';
import { IGuidDomain } from '../../../model/product/IGuidDomain';

export abstract class BaseDistributionHubSolrCoreConfig<
    I extends IGuidSolrCoreConfig
> extends BaseAdminCrudList<I, ICrudData<I>> {
    domainsById: { [id: string]: IGuidDomain };
    domains: IGuidDomain[];    
    solrCores: IGuidSolrCoreConfig[];

    protected constructor(
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        public readonly domainId: string
    ) {
        super(elementRef, prettySubmitGuard, 'SolrCoreConfig');
    }

    protected createItem() {
        return {
            domainId: ''
        } as I;
    } 

    protected initAll(
        solrCores: Observable<IGuidSolrCoreConfig[]>,
        domains: Observable<IGuidDomain[]>,        
    ) {
        
        const join: Observable<
            [IGuidSolrCoreConfig[], IGuidDomain[]]
        > = observableForkJoin(solrCores, domains)

        join.subscribe(results => {
            const solrCoreResults = results[0],
                domainResults = results[1];            

            this.domains = sortBy(domainResults, 'coreName');
            this.domainsById = keyBy(domainResults, 'id');
                    
            solrCoreResults.forEach(catalog => {
                if (catalog.domainId === this.domainsById[catalog.domainId].id) {
                                       
                    catalog.domain = this.domainsById[catalog.domainId]
                        ? this.domainsById[catalog.domainId].name
                        : catalog.domainId;
                } else {
                    catalog.domainId = null;
                    catalog.domain = null;
                }
            });
            const sortedSolrCoreConfig = sortBy(solrCoreResults, ['filename']) as I[];
            this.init(sortedSolrCoreConfig);
        });
    }
}
