import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';

import { Tool } from '../../model/auth/login/Tool';
import { CutimedCatalogComponent } from '../../product/tena/cutimed/CutimedCatalog.component';
import { CutimedCatalogIdentificationMappingComponent } from '../../product/tena/mapping/CutimedCatalogIdentificationMapping.component';
import { CutimedSiteMapComponent } from '../../product/tena/cutimed/CutimedSiteMap.component';
import { CutimedFulFillmentComponent } from '../../product/tena/cutimed/CutimedFulFillment.component';
import { CutimedWebShopComponent } from '../../product/tena/cutimed/CutimedWebShop.component';
import { CutimedLegacyIdExportComponent } from '../../product/tena/cutimed/CutimedLegacyIdExport.component';
import { CutimedSampleOrderComponent } from '../../product/tena/cutimed/CutimedSampleOrder.component';

export const CutimedChildRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Catalogs',
        path: 'catalogs',
        component: CutimedCatalogComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592398/4.2.1+Pim+Tena+Catalogs',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Catalog Segment ⬌ Identification',
        path: 'mapping/catalog-segment-identification',
        component: CutimedCatalogIdentificationMappingComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Site Maps',
        path: 'site-maps',
        component: CutimedSiteMapComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592738/4.2.5+Pim+Tena+Site+Maps',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Buy Now',
        path: 'webshop',
        component: CutimedWebShopComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.PIM]
        }
    },
    {
        navbarName: 'Fulfillment Report',
        path: 'fulfillmentReport',
        component: CutimedFulFillmentComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.TenaConsumerUserInformation]
        }
    },
    {
        navbarName: 'Sample order',
        path: 'sampleOrder',
        component: CutimedSampleOrderComponent,
        canDeactivate: [PrettySubmitGuard],
    },
    {
        navbarName: 'Legacy ID export',
        path: 'legacyIdExport',
        component: CutimedLegacyIdExportComponent,
        canDeactivate: [PrettySubmitGuard],
    },
];
