import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { ILeukoplastCatalogIdentificationMapping } from '../../../../model/product/tena/leukoplast/ILeukoplastCatalogIdentificationMapping';

@Injectable()
export class LeukoplastCatalogIdentificationMappingApi extends BaseProductApi<
    ILeukoplastCatalogIdentificationMapping
> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscountrytoidentificationsourcemapping/leukoplast';
    }
}
