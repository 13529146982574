import { Injectable } from '@angular/core';

import { BaseProductApi } from '../../BaseProductApi';
import { ICutimedCatalog } from './../../../../model/product/tena/cutimed/ICutimedCatalog.d';

@Injectable()
export class CutimedCatalogApi extends BaseProductApi<ICutimedCatalog> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmscatalog/cutimed';
    }
}
