
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TenaService } from './Tena.service';
import { ITenaEpiRule } from '../../model/product/tena/ITenaEpiRule';
import { TenaEpiRulesApi } from '../../api/product/tena/tcw/TenaEpiRulesApi';
import { TenaEpiRules } from '../../model/product/tena/TenaEpiRules';
import { TenaTCWCatalogApi } from '../../api/product/tena/tcw/TenaTCWCatalogApi';
import { ITenaTCWCatalog } from '../../model/product/tena/tcw/ITenaTCWCatalog';

@Component({
    templateUrl: 'TenaEpiRules.component.html',
    providers: [TenaEpiRulesApi, TenaTCWCatalogApi]
})
export class TenaEpiRulesComponent
    extends BaseLiveStageCrudList<
        ITenaEpiRule,
        ICrudData<ITenaEpiRule>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    countries: ITenaTCWCatalog[];
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tenaEpiRulesApi: TenaEpiRulesApi,
        readonly tenaCatalogApi: TenaTCWCatalogApi,
        readonly tenaService: TenaService
    ) {
        super(elementRef, 'hms', liveStageService, prettySubmitGuard, 'EPI Rule');
    }

    ngOnInit() {
        this.adminTitle.setTitle('EPI Rules - Tena');

        this.tenaCatalogApi.getAll().subscribe((res: ITenaTCWCatalog[]) => {
            this.tenaService.countries.next(res);
        });

        this.tenaService.countryChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedCountry => {
                if (selectedCountry) {
                    this.getEpiRules();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    getEpiRules() {
        this.filter.observable.subscribe(() => {
            this.tenaEpiRulesApi
                .getEpiRules(this.tenaService.getActiveCountry().objectId)
                .subscribe(data => {
                    this.clear();
                    this.init(data);
                });
        });
    }

    getRuleLabel(rule: keyof typeof TenaEpiRules) {
        return TenaEpiRules[rule] || rule;
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tenaEpiRulesApi, this.authService);
    }
}
