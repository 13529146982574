export enum SiteMapFrequency {
    always = 'always',
    hourly = 'hourly',
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    yearly = 'yearly',
    never = 'never'
}

export const SiteMapFrequencyDescription = {
    always: 'Always (Changes Every Page Load)',
    hourly: 'Hourly',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
    never: 'Never (Archived)'
};
