import { Component, Input } from '@angular/core';
import { BaseListHeaderComponent } from '../list/BaseListHeader.component';

@Component({
    selector: 'admin-tpw-fullfillmentsetting-list-header',
    templateUrl: 'TpwFullfillmentSettingListHeader.component.html'
})
export class TpwFullfillmentSettingListHeaderComponent<I> extends BaseListHeaderComponent<
    I
> {
    @Input()
    useChannel = true;
}
