export default function splitUrlForOverflow(url: string) {
    var splitUrl: string[];

    if (url) {
        splitUrl = [];

        for (let index = 1; index < url.length - 1; index++) {
            let before = url.substring(0, index),
                after = url.substring(index);

            if (
                (before.endsWith('/') && !after.startsWith('/')) ||
                (before.endsWith(' ') && !after.startsWith(' ')) ||
                after.startsWith('?') ||
                after.startsWith('&')
            ) {
                splitUrl.push(before);
                url = after;
                index = 1;
            }
        }

        splitUrl.push(url);
    }

    return splitUrl;
}
