import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { BaseTitle } from 'tridion.web.ui/src/app/angular/layout/BaseTitle';

@Injectable()
export class AdminTitle extends BaseTitle {
    constructor(title: Title) {
        super(title, 'Essity Central Admin');
    }
}
