import { BehaviorSubject, Observable } from 'rxjs';

import { BaseCrudList } from 'tridion.web.ui/src/app/angular/crud/list/BaseCrudList';
import equalMeaning from 'tridion.web.ui/src/app/angular/save/equalMeaning';

// FIXME: Move to tridion.web.ui.
// FIXME: Document and unit test.
export default class CrudListFilter<F extends {}> {
    readonly observable: Observable<F>;
    private readonly subject: BehaviorSubject<F>;

    constructor(private crudList: BaseCrudList<any, any>, filter: F = {} as any) {
        var subject = new BehaviorSubject<F>(filter);

        this.observable = subject.asObservable();
        this.subject = subject;
    }

    getFilter() {
        return this.subject.getValue();
    }

    setFilter(filter: F) {
        var changed = false,
            subject = this.subject;

        if (!equalMeaning(filter, subject.getValue())) {
            changed = this.crudList.clear();

            if (changed) {
                subject.next(filter);
            }
        }

        return changed;
    }
}
