
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ISite } from '../../model/site/ISite';
import { SubmittedFormsService } from './SubmittedForms.service';

@Component({
    selector: 'admin-submitted-forms-sites',
    templateUrl: 'SubmittedFormsSites.component.html'
})
export class SubmittedFormsSitesComponent implements OnInit, OnDestroy {
    siteForm: FormGroup;
    sites: ISite[];
    @Input()
    selectedSite: ISite;
    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private fb: FormBuilder,
        private submittedFormsService: SubmittedFormsService
    ) {}

    ngOnInit() {
        this.submittedFormsService.sites.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((sites: ISite[]) => {
                this.sites = sites;
            });

        this.siteForm = this.fb.group({
            site: new FormControl(
                this.selectedSite ? this.selectedSite.domainUrl : '',
                Validators.required
            )
        });

        this.submittedFormsService.siteModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleSiteModal(visible);
            });

        this.toggleSiteModal(this.modalVisible);
    }

    selectSite(site: ISite) {
        if (this.siteForm.valid) {
            site = this.sites.find((item: ISite) => {
                if (item.domainUrl === this.siteForm.value.site) {
                    return true;
                }
            });
            this.submittedFormsService.setActiveSite(this.selectedSite);

            this.submittedFormsService.siteChanged.next(site);
            this.submittedFormsService.siteModalVisible.next(false);
        }
    }

    toggleSiteModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
