import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';
import { throwError as _throw } from 'rxjs';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';

@Injectable()
export class QuietApiErrorHandler extends ApiErrorHandler {
    /**
     * Tell the user that an error occurred.
     *
     * @param error - The error that occurred.
     * @param requestOptions - Options used when making the request.
     */
    handleError(error: any, requestOptions: RequestOptions) {
        return _throw(error);
    }
}
