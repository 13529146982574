import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITorkColorSorting } from '../../../model/product/tork/ITorkColorSorting';

@Injectable()
export class TorkColorSortingApi extends BaseProductApi<ITorkColorSorting> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'tork/lkpcolorthemesortorder';
    }
}
