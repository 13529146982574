import { Http, Response } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ActimoveJobType } from '../../../../model/product/tena/actimove/ActimoveJobType';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { ActimoveService } from '../../../../product/tena/actimove/Actimove.service';
import { ActimoveBaseTCWCatalogApi } from './ActimoveBaseTCWCatalogApi';
import { IActimoveUserData } from '../../../../model/product/tena/actimove/IActimoveUserData';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { ActimoveTCWCatalogApi } from './ActimoveTCWCatalogApi';
import { IActimoveTCWCatalog } from '../../../../model/product/tena/actimove/IActimoveTCWCatalog';

@Injectable()
export class ActimoveFulFillmentApi extends ActimoveBaseTCWCatalogApi<IActimoveUserData> {

    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        actimoveService: ActimoveService,
        readonly actimoveService1: ActimoveService,
        liveStageService: LiveStageService,
        readonly actimoveCatalogApi: ActimoveTCWCatalogApi
    ) {
        super(apiErrorHandler, authService, environment, http, actimoveService, liveStageService);
    }

    getItems(
        countryId: number,
        jobType: ActimoveJobType,
        pageSize: number,
        startDate: string,
        endDate: string
    ): Observable<IActimoveUserData[]> {
        return this.getJson(`/${countryId}/${jobType}/${startDate}/${endDate}`, {
            params: {
                pageSize: pageSize,
                offset: 0
            }
        });
    }

    getMoreItems(
        countryId: number,
        jobType: ActimoveJobType,
        pageSize: number,
        offset: number,
        startDate: string,
        endDate: string
    ): Observable<IActimoveUserData[]> {
        return this.getJson(`/${countryId}/${jobType}/${startDate}/${endDate}`, {
            params: {
                pageSize: pageSize,
                offset: offset
            }
        });
    }

    getCsv(
        countryId: number,
        jobType: ActimoveJobType,
        startDate: string,
        endDate: string,
        showPersonalData: boolean
    ): Observable<Response> {
        if (showPersonalData) {
            return this.get(`/${countryId}/${jobType}/${startDate}/${endDate}/excel/includepersonaldata`);
        }
        else {
            return this.get(`/${countryId}/${jobType}/${startDate}/${endDate}/excel`);
        }
    }

    protected getBaseUrl() {
        this.actimoveCatalogApi.getAll().subscribe((res: IActimoveTCWCatalog[]) => {
            this.actimoveService1.countries.next(res);
        });

        return this.TCWBaseURl + 'countryuserdata';
    }
}
