import { Component, OnInit } from '@angular/core';

import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { AdminTitle } from './AdminTitle';
import { IAccess } from '../model/auth/login/IAccess';
import { Tool } from '../model/auth/login/Tool';
import { UserAccessService } from '../auth/login/UserAccess.service';

@Component({
    templateUrl: 'Dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    public readonly Tool = Tool;
    private userAccess: IAccess;

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        private userAccessService: UserAccessService
    ) {}

    ngOnInit() {
        this.adminTitle.setTitle();

        this.userAccessService
            .getAccess()
            .subscribe(access => (this.userAccess = access));
    }

    getName() {
        return this.authService.getUserFirstName();
    }

    hasAccess(tool: Tool) {
        return UserAccessService.hasAccess(this.userAccess, {
            tool: [tool]
        });
    }
}
