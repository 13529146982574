import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { ActimoveService } from './Actimove.service';
import { ActimoveWebShopCatalogApi } from '../../../api/product/tena/actimove/ActimoveWebShopCatalogApi';
import { IActimoveWebShopCatalog } from '../../../model/product/tena/actimove/IActimoveWebShopCatalog';

@Component({
    selector: 'admin-actimove-country-select',
    templateUrl: 'ActimoveCountrySelect.component.html',
    providers: [ActimoveWebShopCatalogApi]
})
export class ActimoveCountrySelectComponent implements OnInit, OnDestroy {
    countryForm: FormGroup;
    countries: IActimoveWebShopCatalog[];

    @Input()
    selectedCountry: IActimoveWebShopCatalog;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public actimoveService: ActimoveService,
        private actimoveCatalogApi: ActimoveWebShopCatalogApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.actimoveService.countries.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((countries: IActimoveWebShopCatalog[]) => {
                this.countries = countries;
            });

        this.countryForm = this.fb.group({
            country: new FormControl(
                this.selectedCountry ? this.selectedCountry.objectId.toString() : '',
                Validators.required
            )
        });

        this.actimoveCatalogApi.getAll().subscribe((res: IActimoveWebShopCatalog[]) => {
            this.actimoveService.countries.next(res);
        });

        this.actimoveService.countryModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleCountryModal(visible);
            });

        this.toggleCountryModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectCountry(country: IActimoveWebShopCatalog) {
        if (this.countryForm.valid) {
            country = this.countries.find((item: IActimoveWebShopCatalog) => {
                if (item.objectId === parseInt(this.countryForm.value.country)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('actimoveSelectedCountry', JSON.stringify(country));
            this.actimoveService.countryChanged.next(country);

            this.actimoveService.countryModalVisible.next(false);
        }
    }

    toggleCountryModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
