import { Http } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { BaseApi } from 'tridion.web.ui/src/app/angular/api/BaseApi';
import { Brand } from '../../model/cache/Brand';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../environment/IEnvironment';
import { LiveStageService } from '../../environment/LiveStage.service';

@Injectable()
export class RedisCacheApi extends BaseApi<IEnvironment> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        private liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, environment, http);
    }

    getTags(brand: Brand): Observable<string[]> {
        return this.getJson(brand);
    }

    getKeys(brand: Brand, tag: string): Observable<string[]> {
        return this.getJson(brand + '/' + tag);
    }

    getValue(brand: Brand, tag: string, key: string): Observable<string> {
        return this.getJson(brand + '/' + tag + '/' + key);
    }

    protected getBaseUrl() {
        return this.environment.api.redis[this.liveStageService.get('redis')];
    }
}
