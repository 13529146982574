
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BaseLiveStageCrudList } from '../crud/list/BaseLiveStageCrudList';
import { Subject } from 'rxjs';
import { LiveStage } from '../environment/LiveStage';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { AdminTitle } from '../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { LiveStageService } from '../environment/LiveStage.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ITpwChannel } from '../model/product/tena/tpw/ITpwChannel';
import { TpwChannelApi } from '../api/product/tena/tpw/TpwChannelApi';
import { ObjectIdCrud } from '../crud/ObjectIdCrud';
import { TpwService } from './Tpw.service';
import { ITpwKitQuota } from '../model/product/tena/tpw/ITpwKitQuota';
import { ITpwTier } from '../model/product/tena/tpw/ITpwTier';
import { TpwTierApi } from '../api/product/tena/tpw/TpwTierApi';
import { TpwKitQuotaApi } from '../api/product/tena/tpw/TpwKitQuotaApi';

@Component({
    templateUrl: 'TpwKitQuota.component.html',
    providers: [TpwKitQuotaApi, TpwTierApi, TpwChannelApi]
})
export class TpwKitQuotaComponent
    extends BaseLiveStageCrudList<
        ITpwKitQuota,
        ICrudData<ITpwKitQuota>,
        { liveStage: LiveStage }
    >
    implements OnInit, OnDestroy {
    channels: ITpwChannel[];
    tiers: ITpwTier[];
    disabled = false;
    Isdeleteflag = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        private tpwKitQuotaApi: TpwKitQuotaApi,
        private tpwService: TpwService,
        private tpwTierApi: TpwTierApi
    ) {
        super(
            elementRef,
            'sampleorder',
            liveStageService,
            prettySubmitGuard,
            'Kit Quota'
        );
    }

    ngOnInit() {
        this.adminTitle.setTitle('Kit Quota - Tpw');

        this.tpwTierApi.getAll().subscribe((res: ITpwTier[]) => {
            this.tpwService.tiers.next(res);
            this.tiers = res;
        });

        this.tpwService.countryChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedCountry => {
                if (selectedCountry) {
                    this.clear();
                    this.getKitQuota();
                }
            });

        this.tpwService.channelChanged.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(selectedChannel => {
                if (selectedChannel) {
                    this.clear();
                    this.disabled = false;
                    this.getKitQuota();
                }
            });
    }

    getKitQuota() {
        let country = this.tpwService.getActiveCountry();
        let channel = this.tpwService.getActiveChannel();
        let tier = this.tpwService.getActiveTier();
        if (country !== null && channel !== null) {
            this.tpwKitQuotaApi
                .getKitQuota(country.countryId, channel.channelId, 0)
                .subscribe(kitquota => {
                    this.clear();
                    this.init(kitquota);
                });
        }
    }

    get isActionCodeNeeded() {
        let country = this.tpwService.getActiveCountry();

        return country && country.isActionCodeNeeded;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    protected createCrud() {
        return new ObjectIdCrud(this.tpwKitQuotaApi, this.authService);
    }
}
