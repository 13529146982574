import { Pipe, PipeTransform } from '@angular/core';
import { ITenaTCWCatalog } from '../../../../model/product/tena/tcw/ITenaTCWCatalog';

@Pipe({
    name: 'platformTenaCountryNamePipe'
})
export class TenaCountryNamePipe implements PipeTransform {
    transform(value: ITenaTCWCatalog[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: ITenaTCWCatalog) => {
                if (item.countryName.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
