import { Injectable } from '@angular/core';
import { IQuiz } from '../../../model/campaign/quiz/IQuiz';
import { BaseAuthorizedGuidApi } from '../../BaseAuthorizedGuidApi';
import { Observable } from 'rxjs';
import { IQuizStats } from '../../../model/campaign/quiz/IQuizStats';

@Injectable()
export class QuizApi extends BaseAuthorizedGuidApi<IQuiz>{

    getQuizzesBySite(siteName: string): Observable<IQuiz[]> {
        return this.getJson<IQuiz[]>('/site/' + siteName);
    }

    getQuizStats(
        id: string,     
        siteName: string,
        startDate: string,
        endDate: string
    ): Observable<IQuizStats> {
        return this.getJson('/' + id + '/stats', {
            params: {
                siteName: siteName,
                startDate: startDate,
                endDate: endDate
            }
        });
    }

    protected getBaseUrl() {
        return this.environment.api.quiz + 'quiz';
    }
}