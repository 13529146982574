import * as moment from 'moment';
import { Observable ,  Subscription } from 'rxjs';
import { OnInit } from '@angular/core';

import { BaseAdminList } from '../list/BaseAdminList';
import groupBySiteAndDate from './groupBySiteAndDate';
import { IDateRangeList } from '../date/IDateRangeList';
import { ISiteByDate } from '../model/monitor/ISiteByDate';
import { ISiteOnDate } from '../model/monitor/ISiteOnDate';
import toIsoDate from '../date/toIsoDate';

export abstract class BasePerformanceUptimeController<D extends ISiteOnDate>
    extends BaseAdminList<ISiteByDate<D>>
    implements IDateRangeList, OnInit {
    startDate: string;
    endDate: string;
    maxDate: string;
    loading: boolean;
    private getAllSubscription: Subscription;

    constructor(private initialDays: number, itemType: string, itemTypeLong = itemType) {
        super(itemType, itemTypeLong);

        var maxDate = moment().subtract(1, 'day'),
            endDate = maxDate.clone(),
            startDate = endDate.clone().subtract(initialDays - 1, 'day');

        this.endDate = toIsoDate(endDate);
        this.maxDate = toIsoDate(maxDate);
        this.startDate = toIsoDate(startDate);
    }

    ngOnInit() {
        this.load();
    }

    getByDate(item: ISiteByDate<D>, date: string) {
        return item.byDate[date];
    }

    getDateRange() {
        var dateRange: string[] = [],
            endDate = moment.utc(this.endDate),
            date = moment.utc(this.startDate);

        for (; date.isSameOrBefore(endDate); date = date.clone().add(1, 'day')) {
            dateRange.push(toIsoDate(date));
        }

        return dateRange.reverse();
    }

    load() {
        this.loading = true;

        var oldSubscription = this.getAllSubscription;
        if (oldSubscription) {
            oldSubscription.unsubscribe();
        }

        var startDate = this.startDate,
            endDate = this.endDate;

        this.getAllSubscription = this.getAll(startDate, endDate).subscribe(all => {
            var items = groupBySiteAndDate(all, this.startDate, this.endDate);

            items = this.transformItems(items);

            this.items = items;
            this.loading = false;
        });
    }

    protected abstract getAll(startDate: string, endDate: string): Observable<D[]>;

    protected transformItems(items: ISiteByDate<D>[]) {
        return items;
    }
}
