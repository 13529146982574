import { Component, Input, OnInit } from '@angular/core';
import { ITest } from '../model/testResults/ITest';
import { Status } from '../model/testResults/Status';

@Component({
    selector: 'admin-single-test',
    templateUrl: 'SingleTest.component.html'
})
export class SingleTestComponent implements OnInit {
    @Input()
    test: ITest;
    availableStatuses: string[];
    currentStatus: string;
    status?: string;
    readonly listStatus = Object.keys(Status);
    ngOnInit() {
        this.availableStatuses = ['all', 'pass', 'fail', 'skip'];
        this.status = this.availableStatuses[0];
    }
}
