import { Component, ElementRef, OnInit } from '@angular/core';
import { forkJoin ,  Observable } from 'rxjs';
import { keyBy, sortBy } from 'lodash';

import { AdminTitle } from '../../layout/AdminTitle';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseLiveStageCrudList } from '../../crud/list/BaseLiveStageCrudList';
import { Country } from '../../model/product/Country';
import { FemcareCatalogApi } from '../../api/product/femcare/FemcareCatalogApi';
import { FemcareSiteApi } from '../../api/product/femcare/FemcareSiteApi';
import getCatalogName from '../getCatalogName';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { IFemcareSite } from '../../model/product/femcare/IFemcareSite';
import { ICatalog } from '../../model/product/ICatalog';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { Locale } from '../../model/product/Locale';
import { ObjectIdCrud } from '../../crud/ObjectIdCrud';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import sortCatalogs from '../sortCatalogs';

@Component({
    templateUrl: 'FemcareSites.component.html',
    providers: [FemcareCatalogApi, FemcareSiteApi]
})
export class FemcareSitesComponent
    extends BaseLiveStageCrudList<
        IFemcareSite,
        ICrudData<IFemcareSite>,
        { liveStage: LiveStage }
    >
    implements OnInit {
    catalogs: ICatalog[];
    catalogsById: { [id: number]: ICatalog };
    readonly countryOptions = Object.keys(Country);
    readonly getCatalogName = getCatalogName;
    readonly localeOptions = Object.keys(Locale);

    constructor(
        private adminTitle: AdminTitle,
        private authService: AuthService,
        private femcareCatalogApi: FemcareCatalogApi,
        private femcareSiteApi: FemcareSiteApi,
        elementRef: ElementRef,
        liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard
    ) {
        super(elementRef, 'product', liveStageService, prettySubmitGuard, 'Site');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Sites - FemCare');

        this.filter.observable.subscribe(filter => {
            forkJoin(
                this.femcareSiteApi.getAll(),
                this.femcareCatalogApi.getAll()
            ).subscribe(results => {
                var sites = results[0],
                    catalogs = results[1];

                this.init(sortBy(sites, 'name'));

                this.catalogs = sortCatalogs(catalogs);
                this.catalogsById = keyBy(catalogs, 'objectId');
            });
        });
    }

    protected createCrud() {
        return new ObjectIdCrud(this.femcareSiteApi, this.authService);
    }
}
