import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedGuidApi } from '../../BaseAuthorizedGuidApi';
import { IUptimeDaily } from '../../../model/monitor/uptime/IUptimeDaily';

@Injectable()
export class UptimeDailyApi extends BaseAuthorizedGuidApi<IUptimeDaily> {
    getByDateRange(startDate: string, endDate: string): Observable<IUptimeDaily[]> {
        return this.getJson('/startdate/' + startDate + '/enddate/' + endDate);
    }

    protected getBaseUrl() {
        return this.environment.api.monitor.performanceUptime + 'uptimedaily';
    }
}
