import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedGuidApi } from '../../BaseAuthorizedGuidApi';
import { IResponseDaily } from '../../../model/monitor/performance/IResponseDaily';

@Injectable()
export class ResponseDailyApi extends BaseAuthorizedGuidApi<IResponseDaily> {
    getByDateRange(startDate: string, endDate: string): Observable<IResponseDaily[]> {
        return this.getJson('/startdate/' + startDate + '/enddate/' + endDate);
    }

    protected getBaseUrl() {
        return this.environment.api.monitor.performanceUptime + 'responsedaily';
    }
}
