export enum Tool {
    // FIXME: Ask the backend to rename `ActivityTracking` to `SubmittedForms`.
    ActivityTracking = 'ActivityTracking',
    Capacity = 'Capacity',
    DistributorFinder = 'DistributorFinder',
    Fulfillment = 'Fulfillment',
    PerformanceUptime = 'PerformanceUptime',
    ProductConfiguration = 'ProductConfiguration',
    ProductImporter = 'ProductImporter',
    HmsProductUploader = 'HmsProductUploader',
    DealerLocator = 'DealerLocator',
    Redirects = 'Redirects',
    RedisCache = 'RedisCache',
    SamplesConfiguration = 'SamplesConfiguration',
    Security = 'Security',
    Reports = 'Reports',
    FinancialReports = 'FinancialReports',
    PublishingStatistics = 'PublishingStatistics',
    Expert = 'Expert',
    PIM = 'PIM',
    TenaConsumerUserInformation = 'TenaConsumerUserInformation',
    SampleorderTPW='Sampleorder-TPW',
}
