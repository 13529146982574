import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ISolrcore } from '../../../model/product/ISolrcore';

@Injectable()
export class FemcareSolrcoreApi extends BaseProductApi<ISolrcore> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'femcare/lkpsolrcore';
    }
}
