import { Pipe, PipeTransform } from '@angular/core';
import { IJobstTCWCatalog } from '../../../model/product/tena/jobst/IJobstTCWCatalog';

@Pipe({
    name: 'platformJobstCountryNamePipe'
})
export class JobstCountryNamePipe implements PipeTransform {
    transform(value: IJobstTCWCatalog[], args: string) {
        if (args !== undefined && args !== null) {
            return value.filter((item: IJobstTCWCatalog) => {
                if (item.countryName.toLowerCase().includes(args.toLocaleLowerCase())) {
                    return item;
                }
            });
        } else {
            return value;
        }
    }
}
