import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';

import { AdminTitle } from '../../layout/AdminTitle';
import { BaseFulfillmentComponent } from '../../fulfillment/BaseFulfillment.component';
import { ContactSubmissionsApi } from '../../api/submitted/contactUs/ContactSubmissionsApi';
import { SiteMappingApi } from '../../api/campaign/siteMapping/SiteMappingApi';
import { IContactSubmission } from '../../model/submitted/contactUs/IContactSubmission';
import { FulfillmentAuthApi } from '../../api/fulfillment/FulfillmentAuthApi';

@Component({
    templateUrl: '../../fulfillment/BaseFulfillment.component.html',
    providers: [ContactSubmissionsApi, SiteMappingApi, FulfillmentAuthApi]
})
export class ContactSubmissionsComponent extends BaseFulfillmentComponent<IContactSubmission> {
    static readonly componentTitle: string = 'Contacts';
    static readonly itemTitle: string = 'contact submission';
    static readonly pageSize: number = 20;

    constructor(
        protected contactSubmissionApi: ContactSubmissionsApi,
        protected route: ActivatedRoute,
        protected adminTitle: AdminTitle,
        protected fulfillmentAuthApi: FulfillmentAuthApi,
        protected siteMappingApi: SiteMappingApi
    ) {
        super(
            contactSubmissionApi,
            route,
            adminTitle,
            fulfillmentAuthApi,
            siteMappingApi,
            {
                title: ContactSubmissionsComponent.componentTitle,
                itemTitle: ContactSubmissionsComponent.itemTitle
            },
            ContactSubmissionsComponent.pageSize
        );
    }
}
