import { Injectable } from '@angular/core';

import { BaseProductApi } from '../BaseProductApi';
import { ITenaSiteMap } from '../../../model/product/tena/ITenaSiteMap';

@Injectable()
export class TenaSiteMapApi extends BaseProductApi<ITenaSiteMap> {
    protected getBaseUrl() {
        return super.getBaseUrl() + 'hmsglobalsitemap/tena';
    }
}
