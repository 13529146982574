
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { ElementRef } from '@angular/core';
import { keyBy, sortBy } from 'lodash';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { IGuidCatalogSolrcoreMapping } from '../../model/product/IGuidCatalogSolrcoreMapping';
import { IGuidCatalog } from '../../model/product/IGuidCatalog';
import { IGuidSolrcore } from '../../model/product/IGuidSolrcore';
import { BaseAdminCrudList } from '../../crud/list/BaseAdminCrudList';

export abstract class GuidCatalogSolrcoreMapping extends BaseAdminCrudList<
    IGuidCatalogSolrcoreMapping,
    ICrudData<IGuidCatalogSolrcoreMapping>
> {
    catalogs: IGuidCatalog[];
    catalogsById: { [id: string]: IGuidCatalog };
    cores: IGuidSolrcore[];
    coresById: { [id: string]: IGuidSolrcore };

    protected constructor(elementRef: ElementRef, prettySubmitGuard: PrettySubmitGuard) {
        super(elementRef, prettySubmitGuard, 'Catalog ⬌ Solr Core');
    }

    protected initAll(
        catalogs: Observable<IGuidCatalog[]>,
        cores: Observable<IGuidSolrcore[]>,
        mappings: Observable<IGuidCatalogSolrcoreMapping[]>
    ) {
        const join: Observable<
            [IGuidCatalog[], IGuidSolrcore[], IGuidCatalogSolrcoreMapping[]]
        > = observableForkJoin(catalogs, cores, mappings);

        join.subscribe(results => {
            let catalogResults = results[0],
                coreResults = results[1],
                mappingResults = results[2];

            this.catalogs = sortBy(catalogResults, ['country', 'languageCode']);
            this.catalogsById = keyBy(catalogResults, 'id');

            this.cores = sortBy(coreResults, 'coreName');
            this.coresById = keyBy(coreResults, 'id');

            this.init(
                sortBy(mappingResults, mapping => {
                    const catalog = this.catalogsById[mapping.catalogId],
                        core = this.coresById[mapping.solrCoreId];

                    return [
                        catalog && catalog.country,
                        catalog && catalog.languageCode,
                        core && core.coreName
                    ];
                })
            );
        });
    }
}
