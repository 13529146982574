import { Component, ElementRef, OnInit } from '@angular/core';
import { sortBy } from 'lodash';

import { ActiveDirectoryGroupApi } from '../../api/auth/ActiveDirectoryGroupApi';
import { AdminTitle } from '../../layout/AdminTitle';
import { BaseAdminCrudList } from '../../crud/list/BaseAdminCrudList';
import getUniqueOptions from 'tridion.web.ui/src/app/angular/crud/getUniqueOptions';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { IActiveDirectoryGroup } from '../../model/auth/IActiveDirectoryGroup';
import { IAuthEntity } from '../../model/auth/IAuthEntity';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';

@Component({
    templateUrl: 'ActiveDirectoryGroups.component.html',
    providers: [ActiveDirectoryGroupApi]
})
export class ActiveDirectoryGroupsComponent
    extends BaseAdminCrudList<IActiveDirectoryGroup, ICrudData<IActiveDirectoryGroup>>
    implements OnInit {
    constructor(
        private activeDirectoryGroupApi: ActiveDirectoryGroupApi,
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard
    ) {
        super(elementRef, prettySubmitGuard, 'Group', 'Active Directory Group');
    }

    ngOnInit() {
        this.adminTitle.setTitle(['Active Directory Groups', 'Security']);

        this.activeDirectoryGroupApi.getAll().subscribe(groups => {
            this.init(sortBy(groups, 'name'));
        });
    }

    protected createCrud() {
        return new GuidCrud(this.activeDirectoryGroupApi);
    }
}
