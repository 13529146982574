import { find } from 'lodash';
import joinUrl from 'tridion.web.ui/src/app/url/joinUrl';
import { Route, Router } from '@angular/router';

import { IAccess } from '../../model/auth/login/IAccess';
import { INavbarItem } from './INavbarItem';
import { INavbarLevel } from './INavbarLevel';
import { INavbarRoute } from './INavbarRoute';
import { UserAccessService } from '../../auth/login/UserAccess.service';

type Routes = (INavbarRoute | Route)[];

export function getItemsAndNextLevel(
    routes: Routes,
    router: Router,
    userAccess: IAccess,
    basePath: string
) {
    var items: INavbarItem[] = [],
        nextLevel: { routes: Routes; name: string; basePath: string };

    routes.forEach(route => {
        var path = joinUrl(basePath, route.path),
            active = router.isActive(path, false),
            requiredAccess = (route as INavbarRoute).requiredAccess,
            fullWidth = (route as INavbarRoute).fullWidth;

        if (UserAccessService.hasAccess(userAccess, requiredAccess)) {
            let name = (route as INavbarRoute).navbarName;

            if (name) {
                items.push({
                    name: name,
                    path: path,
                    navbarLink: getNavbarLink(route, userAccess, path),
                    active: active,
                    helpUrl: (route as INavbarRoute).helpUrl,
                    fullWidth: fullWidth
                });
            }

            if (active && route.children) {
                nextLevel = {
                    routes: route.children,
                    name: name,
                    basePath: path
                };
            }
        }
    });

    return {
        items: items,
        nextLevel: nextLevel
    };
}

export default function getNavbar(
    allRoutes: Routes,
    router: Router,
    userAccess: IAccess
) {
    var levels: INavbarLevel[] = [];

    function addLevel(routes: Routes, name: string, path: string, pathLink: boolean) {
        var itemsAndNextLevel = getItemsAndNextLevel(routes, router, userAccess, path),
            items = itemsAndNextLevel.items,
            nextLevel = itemsAndNextLevel.nextLevel;

        if (items.length) {
            levels.push({
                name: name,
                path: pathLink ? path : undefined,
                items: items
            });
        }

        if (nextLevel) {
            addLevel(nextLevel.routes, nextLevel.name, nextLevel.basePath, false);
        }
    }

    addLevel(allRoutes, 'Essity Central Admin', '/', true);

    return levels;
}

export function getNavbarLink(
    route: INavbarRoute | Route,
    userAccess: IAccess,
    path: string
) {
    var navbarLink = path,
        children: Routes = route.children;

    if (children) {
        let firstChild = find(children, child => {
            var requiredAccess = (child as INavbarRoute).requiredAccess;

            return UserAccessService.hasAccess(userAccess, requiredAccess);
        });

        if (firstChild) {
            let firstChildPath = joinUrl(path, firstChild.path);

            navbarLink = getNavbarLink(firstChild, userAccess, firstChildPath);
        }
    }

    return navbarLink;
}
