import { Injectable } from '@angular/core';
import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { IFulfillmentApi } from '../../fulfillment/IFulfillmentApi';
import { IQuizSubmission } from '../../../model/campaign/quiz/IQuizSubmission';
import { IEnvironment } from '../../../environment/IEnvironment';
import { IFulfillmentSite } from '../../../model/fulfillment/IFulfillmentSite';
import { Observable } from 'rxjs';
import { Response, ResponseContentType } from '@angular/http';

@Injectable()
export class QuizSubmissionApi extends BaseAuthorizedApi<IEnvironment>
    implements IFulfillmentApi<IQuizSubmission>{

    getItems(
        site: IFulfillmentSite,
        targetId: string,
        pageSize: number,
        startDate: string,
        endDate: string
    ): Observable<IQuizSubmission[]> {
        return this.getJson('submission', {
            params: {
                siteId: site.name,
                quizId: targetId,
                startDate: startDate,
                endDate: endDate,
                pageSize: pageSize
            }
        });
    }

    getMoreItems(
        site: IFulfillmentSite,
        targetId: string,
        pageSize: number,
        offset: number,
        startDate: string,
        endDate: string
    ): Observable<IQuizSubmission[]> {
        return this.getJson('submission/loadmoreresults', {
            params: {
                siteId: site.name,
                quizId: targetId,
                startDate: startDate,
                endDate: endDate,
                pageSize: pageSize,
                itemCount: offset
            }
        });
    }

    getCsv(
        site: IFulfillmentSite,
        targetId: string,
        startDate: string,
        endDate?: string
    ) : Observable<Response>{
        return this.get('submission/csv', {
            params: {
                siteId: site.name,
                quizId: targetId,
                startDate: startDate,
                endDate: endDate
            }
        });        
    }

    removeItem(item: IQuizSubmission): Observable<Response> {
        return this.delete('submission/' + item.id);
    }

    protected getBaseUrl() {
        return this.environment.api.quiz;
    }
}