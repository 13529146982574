import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';

import { Tool } from '../../model/auth/login/Tool';
import { LeukoplastCatalogComponent } from '../../product/tena/leukoplast/LeukoplastCatalog.component';
import { LeukoplastCatalogIdentificationMappingComponent } from '../../product/tena/mapping/LeukoplastCatalogIdentificationMapping.component';
import { LeukoplastWebShopComponent } from '../../product/tena/leukoplast/LeukoplastWebShop.component';
import { LeukoplastSiteMapComponent } from '../../product/tena/leukoplast/LeukoplastSiteMap.component';
import { LeukoplastFulFillmentComponent } from '../../product/tena/leukoplast/LeukoplastFulFillment.component';
import { LeukoplastLegacyIdExportComponent } from '../../product/tena/leukoplast/LeukoplastLegacyIdExport.component';

export const LeukoplastChildRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Catalogs',
        path: 'catalogs',
        component: LeukoplastCatalogComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592398/4.2.1+Pim+Tena+Catalogs',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    // {
    //     navbarName: 'Catalog ⬌ Segment',
    //     path: 'mapping/catalog-segment',
    //     component: TenaCatalogSegmentMappingComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     helpUrl:
    //         'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
    //     requiredAccess: {
    //         tool: [Tool.ProductConfiguration]
    //     }
    // },
    {
        navbarName: 'Catalog Segment ⬌ Identification',
        path: 'mapping/catalog-segment-identification',
        component: LeukoplastCatalogIdentificationMappingComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    // {
    //     navbarName: 'Colors',
    //     path: 'colors',
    //     component: TenaColorCodeComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     helpUrl:
    //         'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592521/4.2.2+Pim+Tena+Colors',
    //     requiredAccess: {
    //         tool: [Tool.ProductConfiguration]
    //     }
    // },
    // {
    //     navbarName: 'Data',
    //     path: 'data',
    //     component: TenaBootstrapComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     helpUrl:
    //         'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592580/4.2.3+Pim+Tena+Data+Records',
    //     requiredAccess: {
    //         tool: [Tool.ProductConfiguration]
    //     }
    // },
    // {
    //     navbarName: 'Segments',
    //     path: 'segments',
    //     component: TenaSegmentComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     helpUrl:
    //         'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592633/4.2.4+Pim+Tena+Segments',
    //     requiredAccess: {
    //         tool: [Tool.ProductConfiguration]
    //     }
    // },
    {
        navbarName: 'Site Maps',
        path: 'site-maps',
        component: LeukoplastSiteMapComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592738/4.2.5+Pim+Tena+Site+Maps',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Buy Now',
        path: 'webshop',
        component: LeukoplastWebShopComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.PIM]
        }
    },
    {
        navbarName: 'Fulfillment Report',
        path: 'fulfillmentReport',
        component: LeukoplastFulFillmentComponent,
        canDeactivate: [PrettySubmitGuard],
        requiredAccess: {
            tool: [Tool.TenaConsumerUserInformation]
        }
    },
    // {
    //     navbarName: 'GDPR',
    //     path: 'gdpr',
    //     component: TenaGDPRComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     requiredAccess: {
    //         tool: [Tool.TenaConsumerUserInformation]
    //     }
    // },
    // {
    //     navbarName: 'Blacklist',
    //     path: 'blacklist',
    //     component: TenaBlacklistComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     requiredAccess: {
    //         tool: [Tool.TenaConsumerUserInformation]
    //     }
    // },
    // {
    //     navbarName: 'EPI Rules',
    //     path: 'epirules',
    //     component: TenaEpiRulesComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     requiredAccess: {
    //         tool: [Tool.PIM]
    //     }
    // },
    // {
    //     navbarName: 'Sample order',
    //     path: 'sampleOrder',
    //     component: TenaSampleOrderComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     requiredAccess: {
    //         tool: [Tool.PIM]
    //     }
    // },
    // {
    //     navbarName: 'External Sample Registration',
    //     path: 'externalSampleRegistration',
    //     component: TenaExternalSampleComponent,
    //     canDeactivate: [PrettySubmitGuard],
    //     requiredAccess: {
    //         tool: [Tool.PIM]
    //     }
    // }
    {
        navbarName: 'Legacy ID export',
        path: 'legacyIdExport',
        component: LeukoplastLegacyIdExportComponent,
        canDeactivate: [PrettySubmitGuard],
    },
];
