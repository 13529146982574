import { IQuestion } from '../../../model/expert/question/IQuestion';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

@Injectable()
export class SendEmailApi {
    constructor(private http: Http) {}

    sendEmail(url: string, crud: IQuestion) {
        return this.http.post(url, crud);
    }
}