import { Component, OnInit } from '@angular/core';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { AdminTitle } from '../../../layout/AdminTitle';

@Component({
    templateUrl: 'AdminCapacity.component.html'
})
export class AdminCapacityComponent implements OnInit {
    constructor(private adminTitle: AdminTitle, private authService: AuthService) {}

    ngOnInit() {
        this.adminTitle.setTitle('Capacity');
    }

    getName() {
        return this.authService.getUserFirstName();
    }
}
