import { Injectable } from '@angular/core';

import { IGuidDomain } from '../../../model/product/IGuidDomain';
import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';

@Injectable()
export class DistributionHubDomainApi extends BaseAuthorizedGuidCrudApi<IGuidDomain> {
    protected getBaseUrl() {
        return this.environment.api.distributionHub + 'domain';
    }
}
