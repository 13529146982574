import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import Count from 'tridion.web.ui/src/app/angular/pipe/Count';
import Plural from './Plural';

@Pipe({
    name: 'platformPrettyCount',
    pure: false
})
export class PrettyCountPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}

    transform(count: number | any[] | any, name?: string, namePlural?: string): string {
        var pretty: string;

        count = Count(count);

        if (count) {
            pretty = this.decimalPipe.transform(count);
        } else if (count === 0) {
            pretty = 'No';
        } else {
            pretty = '';
        }

        if (name) {
            if (pretty) {
                pretty += ' ';
            }

            pretty += Plural(count, name, namePlural);
        }

        return pretty;
    }
}
