import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';
import { IQuestion } from '../../../model/expert/question/IQuestion';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFulfillmentSite } from '../../../model/fulfillment/IFulfillmentSite';

@Injectable()
export class AskTheExpertApi extends BaseAuthorizedGuidCrudApi<IQuestion> {
    getItems(
        site: IFulfillmentSite,
        status: string,
        category: string,
        offset: number,
        hits: number
    ): Observable<IQuestion[]> {
        return this.getJson('', {
            params: {
                websiteId: site.name,
                status,
                category,
                offset,
                hits,
            }
        });
    }

    protected getBaseUrl(): string {
        return this.environment.api.expert + 'request';
    }
}
