/* tslint:disable:max-file-line-count */

import { AgmCoreModule } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { ChartModule } from 'angular2-chartjs';
import { DatePipe, DecimalPipe, LowerCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PrettySubmitDirective } from 'tridion.web.ui/src/app/angular/save/PrettySubmit.directive';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ActiveDirectoryGroupsComponent } from './auth/group/ActiveDirectoryGroups.component';
import { AdminAuthGuard } from './auth/login/AdminAuthGuard';
import { AdminTitle } from './layout/AdminTitle';
import { AlertsComponent } from 'tridion.web.ui/src/app/angular/layout/alert/Alerts.component';
import { AlertService } from 'tridion.web.ui/src/app/angular/layout/alert/Alert.service';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AppRoutingModule } from './AppRouting.module';
import { AuthEntitiesComponent } from './auth/entity/AuthEntities.component';
import { AuthEntityApi } from './api/auth/AuthEntityApi';
import { AuthModuleApi } from './api/auth/AuthModuleApi';
import { DistributorCountriesApi } from './api/distributorFinder/DistributorCountriesApi';
import { DealerCountriesApi } from './api/dealerLocator/DealerCountriesApi';
import { AuthModulesComponent } from './auth/module/AuthModules.component';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { AutofocusDirective } from 'tridion.web.ui/src/app/angular/event/Autofocus.directive';
import { CapacityComponent } from './monitor/capacity/Capacity.component';
import { ContactSubmissionsComponent } from './report/contactUs/ContactSubmission.component';
import { CrudActionsComponent } from './crud/CrudActions.component';
import { CrudDeleteWarningComponent } from './crud/CrudDeleteWarning.component';
import { CrudListActionsComponent } from './crud/list/CrudListActions.component';
import { DashboardComponent } from './layout/Dashboard.component';
import { DateRangeComponent } from './date/DateRange.component';
import { DistributorCountrySelectComponent } from './distributorFinder/distributorCountrySelect/DistributorCountrySelect.component';
import { DealerCountrySelectComponent } from './product/tena/jobst/dealerLocator/distributorCountrySelect/DistributorCountrySelect.component';
import { CountriesComponent } from './distributorFinder/Countries.component';
import { DealerCountriesComponent } from './product/tena/jobst/dealerLocator/Countries.component';
import { CountryModalEditComponent } from './distributorFinder/countryEdit/CountryModalEdit.component';
import { DealerCountryModalEditComponent } from './product/tena/jobst/dealerLocator/countryEdit/CountryModalEdit.component';
import { DistributorFinderComponent } from './distributorFinder/DistributorFinder.component';
import { DealerLocatorComponent } from './product/tena/jobst/dealerLocator/DistributorFinder.component';
import { DistributorModalEditComponent } from './distributorFinder/distributorEdit/DistributorModalEdit.component';
import { DealerLocatorModalEditComponent } from './product/tena/jobst/dealerLocator/distributorEdit/DistributorModalEdit.component';
import { DistributorMapComponent } from './distributorFinder/distributorMap/DistributorMapp.component';
import { DealerMapComponent } from './product/tena/jobst/dealerLocator/distributorMap/DistributorMapp.component';
import { DistributorService } from './distributorFinder/DistributorFinder.service';
import { DealerLocatorService } from './product/tena/jobst/dealerLocator/DistributorFinder.service';
import { CountriesService } from './distributorFinder/Countries.service';
import { DealerCountriesService } from './product/tena/jobst/dealerLocator/Countries.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { FemcareCatalogComponent } from './product/femcare/FemcareCatalog.component';
import { FemcareCatalogSolrcoreMappingComponent } from './product/femcare/mapping/FemcareCatalogSolrcoreMapping.component';
import { FemcareSamplesComponent } from './campaign/sample/FemcareSamples.component';
import { FemcareSitesComponent } from './product/femcare/FemcareSites.component';
import { FemcareSolrComponent } from './product/femcare/FemcareSolr.component';
import { CountryNamePipe } from './distributorFinder/distributorCountrySelect/name.pipe';
import { DealerCountryNamePipe } from './product/tena/jobst/dealerLocator/distributorCountrySelect/name.pipe';
import { ImportLogsComponent } from './product/importLog/ImportLogs.component';
import { LayoutComponent } from './layout/Layout.component';
import { ListHeaderComponent } from './list/ListHeader.component';
import { LiveStageService } from './environment/LiveStage.service';
import { LogoutComponent } from './auth/login/Logout.component';
import { PerformanceComponent } from './monitor/performance/Performance.component';
import { PrettyCountPipe } from './pipe/PrettyCount.pipe';
import { PrettyDatePipe } from 'tridion.web.ui/src/app/angular/pipe/PrettyDate.pipe';
import { ProductImporterComponent } from './productImporter/ProductImporter.component';
import { ProductImporterFormComponent } from './productImporter/ProductImporterForm.component';
import { RedirectsComponent } from './site/redirect/Redirects.component';
import { RedisComponent } from './cache/Redis.component';
import { SamplesComponent } from './campaign/sample/fulfillment/Samples.component';
import { SitesComponent } from './site/Sites.component';
import { SiteMappingComponent } from './campaign/siteMapping/SiteMapping.component';
import { SmallHeaderComponent } from './crud/small/SmallHeader.component';
import { SmallRemoveActionComponent } from './crud/small/SmallRemoveAction.component';
import { TenaBootstrapComponent } from './product/tena/TenaBootstrap.component';
import { TenaCatalogComponent } from './product/tena/TenaCatalog.component';
import { TenaCatalogSegmentMappingComponent } from './product/tena/mapping/TenaCatalogSegmentMapping.component';
import { TenaColorCodeComponent } from './product/tena/TenaColorCode.component';
import { TenaCatalogIdentificationMappingComponent } from './product/tena/mapping/TenaCatalogIdentificationMapping.component';
import { TenaSegmentComponent } from './product/tena/TenaSegment.component';
import { TenaSiteMapComponent } from './product/tena/TenaSiteMap.component';
import { TorkBrandsComponent } from './product/tork/TorkBrands.component';
import { TorkCatalogComponent } from './product/tork/TorkCatalog.component';
import { TorkCatalogSiteMapMappingComponent } from './product/tork/mapping/TorkCatalogSiteMapMapping.component';
import { TorkCatalogSolrcoreMappingComponent } from './product/tork/mapping/TorkCatalogSolrcoreMapping.component';
import { TorkColorSortingComponent } from './product/tork/TorkColorSorting.component';
import { TorkSiteMapComponent } from './product/tork/TorkSiteMap.component';
import { TorkSolrComponent } from './product/tork/TorkSolr.component';
import { TorkWebServiceComponent } from './product/tork/TorkWebService.component';
import { TorkWebServiceCredentialsComponent } from './product/tork/TorkWebServiceCredentials.component';
import { UptimeComponent } from './monitor/uptime/Uptime.component';
import { UrlStubComponent } from './url/UrlStub.component';
import { UserAccessService } from './auth/login/UserAccess.service';
import { ReportComponent } from './report/Report.component';
import { SubmittedFormsComponent } from './report/submitted/SubmittedForms.component';
import { WaitingTransactionsComponent } from './report/publishing/waitingTransactions/WaitingTransactions.component';
import { PriorityComponent } from './report/publishing/priority/Priority.component';
import { ItemComponent } from './report/publishing/item/Item.component';
import { ExecutionTimeComponent } from './report/publishing/executionTime/ExecutionTime.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { AskTheExpertComponent } from './report/experts/questions/AskTheExpert.component';
import { AdminUptimeComponent } from './report/platform/admin-uptime/AdminUptime.component';
import { AdminCapacityComponent } from './report/platform/admin-capacity/AdminCapacity.component';
import { AdminPerformanceComponent } from './report/platform/admin-performance/AdminPerformance.component';
import { AdminTestResultsComponent } from './report/testResults/AdminTestResults.component';
import { FinancialFileComponent } from './report/financial/FinancialFile.component';
import { FinancialComponent } from './report/financial/Financial.component';
import { FinancialFileLinkComponent } from './report/financial/FinancialFileLink.component';
import { DistributionHubDomainsComponent } from './product/distributionHub/DistributionHubDomains.component';
import { DistributionHubCatalogComponent } from './product/distributionHub/DistributionHubCatalog.component';
import { DistributionHubSolrComponent } from './product/distributionHub/DistributionHubSolr.component';
import { DistributionHubCatalogSolrcoreMappingComponent } from './product/distributionHub/mapping/DistributionHubCatalogSolrcoreMapping.component';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PromotionsComponent } from './campaign/promotion/Promotions.component';
import { PromotionSubmissionsComponent } from './campaign/promotion/fulfillment/PromotionSubmissions.component';
import { TenaWebShopComponent } from './product/tena/TenaWebShop.component';
import { TenaService } from './product/tena/Tena.service';
import { TenaCountrySegmentMenuComponent } from './product/tena/tenaCountrySegment/TenaCountrySegmentMenu.component';
import { TenaCountrySelectComponent } from './product/tena/tenaCountrySegment/tenaCountrySelect/TenaCountrySelect.component';
import { TenaSegmentSelectComponent } from './product/tena/tenaCountrySegment/tenaSegmentSelect/TenaSegmentSelect.component';
import { TenaSegmentNamePipe } from './product/tena/tenaCountrySegment/tenaSegmentSelect/tenaSegmentName.pipe';
import { TenaCountryNamePipe } from './product/tena/tenaCountrySegment/tenaCountrySelect/tenaCountryName.pipe';
import { TenaListHeaderComponent } from './product/tena/tenaCountrySegment/TenaListHeader.component';
import { TestResultDashboardComponent } from './testResults/TestResultDashboard.component';
import { TestResultsComponent } from './testResults/TestResults.component';
import { TestResultListComponent } from './testResults/testResultList.component';
import { SingleTestComponent } from './testResults/SingleTest.component';
import { QuizComponent } from './campaign/quiz/Quiz.component';
import { QuizSubmissionsComponent } from './campaign/quiz/submissions/QuizSubmissions.component';
import { FilterCrudActionsComponent } from './crud/FilterCrudActions.component';
import { TenaBlacklistComponent } from './product/tena/TenaBlacklist.component';
import { TenaEpiRulesComponent } from './product/tena/TenaEpiRules.component';
import { TenaGDPRComponent } from './product/tena/TenaGDPR.component';
import { TenaFulFillmentComponent } from './product/tena/TenaFulFillment.component';
import { TenaLegacyIdExportComponent } from './product/tena/TenaLegacyIdExport.component';
import { TenaExternalSampleComponent } from './product/tena/TenaExternalSample.component';
import { TenaFullFilmentSchedulerComponent } from './product/tena/TenaFullFilmentScheduler.component';
import { QuietApiErrorHandler } from './api/error/QuietApiErrorHandler';
import { TenaSampleOrderComponent } from './product/tena/TenaSampleOrder.component';

import { SlugifyPipe } from './pipe/slugify.pipe';
import { TestResultsApi } from './api/testResults/TestResultsApi';

import { AdminTestNavigationComponent } from './testResults/TestNavigation.component';
import { AdminTestFiltersComponent } from './testResults/TestFilters.component';
import { SubmittedFormsSitesComponent } from './report/submitted/SubmittedFormsSites.component';
import { SiteNamePipe } from './report/submitted/siteName.pipe';
import { SubmittedFormsService } from './report/submitted/SubmittedForms.service';
import { ElearningComponent } from './report/elearning/Elearning.component';
import { TpwGdprDeleteComponent } from './gdpr/TpwGdprDelete.component';
import { StatsComponent } from './product/distributionHub/stats/stats.component';
import { StatsApi } from './api/product/distributionHub/stats/StatsApi';
import { CampaignComponent } from './campaign/Campaign.component';
import { DistributionHubSolrCoreConfigComponent } from './product/distributionHub/SolrCoreConfig/DistributionHubSolrCoreConfig.component';
import { DistributionHubSolrCoreConfigApi } from './api/product/distributionHub/DistributionHubSolrCoreConfigApi';
import { TpwGdprBlacklistComponent } from './gdpr/TpwGdprBlacklist.component';
import { TpwCountryNamePipe } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwCountrySelect/tpwCountryName.pipe';
import { TpwProductFamilyPipe } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwProductFamilySelect/TpwProductFamily.pipe';
import { TpwSortCategoryPipe } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwSortCategorySelect/TpwSortCategory.pipe';
import { TpwCountrySelectComponent } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwCountrySelect/TpwCountrySelect.component';
import { TpwProductFamilySelectComponent } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwProductFamilySelect/TpwProductFamilySelect.component';
import { TpwSortCategorySelectComponent } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwSortCategorySelect/TpwSortCategorySelect.component';
import { TpwService } from './gdpr/Tpw.service';
import { TpwSampleSelectComponent } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwSampleSelect/TpwSampleSelect.component';
import { TpwChannelSelectComponent } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwChannelSelect/TpwChannelSelect.component';
import { TpwTierSelectComponent } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwTierSelect/TpwTierSelect.component';
import { TpwSampleTypePipe } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwSampleSelect/TpwSampleType.pipe';
import { TpwChannelNamePipe } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwChannelSelect/tpwChannelName.pipe';
import { TpwTierPipe } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/tpwTierSelect/TpwTier.pipe';
import { TpwFullfillmentSettingComponent } from './gdpr/TpwFullfillmentSetting.component';
import { TpwFullfillmentSettingListHeaderComponent } from './gdpr/TpwFullfillmentSettingListHeader.component';
import { TpwFullfillmentSettingMenuComponent } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/TpwFullfillmentSettingMenu.component';
import { TpwKitQuotaComponent } from './gdpr/TpwKitQuota.component';
import { TpwKitQuotaListHeaderComponent } from './gdpr/TpwKitQuotaListHeader.component';
import { TpwKitQuotaMenuComponent } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/TpwKitQuotaMenu.component';
import { TpwSampleOrderComponent } from './gdpr/TpwSampleOrder.component';
import { TpwSampleOrderListHeaderComponent } from './gdpr/TpwSampleOrderListHeader.component';
import { TpwSampleOrderMenuComponent } from './gdpr/tpwSamplesCountryChannelTierProductFamilyBrochures/TpwSampleOrderMenu.component';
import { TpwGetSampleOrderReportComponent } from './gdpr/TpwGetSampleOrderReport.component';
import { TpwGetSampleOrderReportListHeaderComponent } from './gdpr/TpwGetSampleOrderReportListHeader.component';
import { LeukoplastCatalogComponent } from './product/tena/leukoplast/LeukoplastCatalog.component';
import { LeukoplastCatalogIdentificationMappingComponent } from './product/tena/mapping/LeukoplastCatalogIdentificationMapping.component';
import { LeukoplastWebShopComponent } from './product/tena/leukoplast/LeukoplastWebShop.component';
import { LeukoplastSegmentSelectComponent } from './product/tena/leukoplast/LeukoplastSegmentSelect.component';
import { LeukoplastCountrySelectComponent } from './product/tena/leukoplast/LeukoplastCountrySelect.component';
import { LeukoplastCountrySegmentMenuComponent } from './product/tena/leukoplast/LeukoplastCountrySegmentMenu.component';
import { LeukoplastListHeaderComponent } from './product/tena/leukoplast/LeukoplastListHeader.component';
import { CutimedListHeaderComponent } from './product/tena/cutimed/CutimedListHeader.component';
import { LeukoplastSiteMapComponent } from './product/tena/leukoplast/LeukoplastSiteMap.component';
import { LeukoplastFulFillmentComponent } from './product/tena/leukoplast/LeukoplastFulFillment.component';
import { LeukoplastLegacyIdExportComponent } from './product/tena/leukoplast/LeukoplastLegacyIdExport.component';
import { LeukoplastService } from './product/tena/leukoplast/Leukoplast.service';
import { LeukoplastCountryNamePipe } from './product/tena/leukoplast/leukoplastCountryName.pipe';
import { CutimedSegmentSelectComponent } from './product/tena/cutimed/CutimedSegmentSelect.component';
import { CutimedSiteMapComponent } from './product/tena/cutimed/CutimedSiteMap.component';
import { CutimedCatalogComponent } from './product/tena/cutimed/CutimedCatalog.component';
import { CutimedCatalogIdentificationMappingComponent } from './product/tena/mapping/CutimedCatalogIdentificationMapping.component';
import { CutimedFulFillmentComponent } from './product/tena/cutimed/CutimedFulFillment.component';
import { CutimedLegacyIdExportComponent } from './product/tena/cutimed/CutimedLegacyIdExport.component';
import { CutimedCountrySegmentMenuComponent } from './product/tena/cutimed/CutimedCountrySegmentMenu.component';
import { CutimedCountrySelectComponent } from './product/tena/cutimed/CutimedCountrySelect.component';
import { CutimedService } from './product/tena/cutimed/Cutimed.service';
import { CutimedCountryNamePipe } from './product/tena/cutimed/cutimedCountryName.pipe';
import { CutimedWebShopComponent } from './product/tena/cutimed/CutimedWebShop.component';
import { CutimedSampleOrderComponent } from './product/tena/cutimed/CutimedSampleOrder.component';
import { ActimoveListHeaderComponent } from './product/tena/actimove/ActimoveListHeader.component';
import { ActimoveSegmentSelectComponent } from './product/tena/actimove/ActimoveSegmentSelect.component';
import { ActimoveCountrySegmentMenuComponent } from './product/tena/actimove/ActimoveCountrySegmentMenu.component';
import { ActimoveCountrySelectComponent } from './product/tena/actimove/ActimoveCountrySelect.component';
import { ActimoveService } from './product/tena/actimove/Actimove.service';
import { ActimoveCountryNamePipe } from './product/tena/actimove/actimoveCountryName.pipe';
import { ActimoveCatalogComponent } from './product/tena/actimove/ActimoveCatalog.component';
import { ActimoveCatalogIdentificationMappingComponent } from './product/tena/mapping/ActimoveCatalogIdentificationMapping.component';
import { ActimoveWebShopComponent } from './product/tena/actimove/ActimoveWebShop.component';
import { ActimoveSiteMapComponent } from './product/tena/actimove/ActimoveSiteMap.component';
import { ActimoveFulFillmentComponent } from './product/tena/actimove/ActimoveFulFillment.component';
import { JobstListHeaderComponent } from './product/tena/jobst/JobstListHeader.component';
import { JobstSegmentSelectComponent } from './product/tena/jobst/JobstSegmentSelect.component';
import { JobstCountrySelectComponent } from './product/tena/jobst/JobstCountrySelect.component';
import { JobstService } from './product/tena/jobst/Jobst.service';
import { JobstCountryNamePipe } from './product/tena/jobst/jobstCountryName.pipe';
import { CelumApi } from './api/celum/CelumApi';
import { NgSelectModule } from '@ng-select/ng-select';
import { JobstCatalogComponent } from './product/tena/jobst/JobstCatalog.component';
import { JobstCatalogIdentificationMappingComponent } from './product/tena/mapping/JobstCatalogIdentificationMapping.component';
import { JobstSiteMapComponent } from './product/tena/jobst/JobstSiteMap.component';
import { JobstCountrySegmentMenuComponent } from './product/tena/jobst/JobstCountrySegmentMenu.component';
import { JobstTCWCatalogApi } from './api/product/tena/jobst/JobstTCWCatalogApi';
import { JobstSegmentApi } from './api/product/tena/jobst/JobstSegmentApi';
import { JobstProductImporterComponent } from './product/tena/jobst/JobstProductImporter.component';
import { JobstProductImporterFormComponent } from './product/tena/jobst/JobstProductImporterForm.component';

@NgModule({
    imports: [
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyA5997lnYUUnvUMj565TivY-yBWTs5AS5E'
        }),
        AppRoutingModule,
        BrowserModule,
        ChartModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        PopoverModule.forRoot(),
        TabsModule.forRoot(),
        CKEditorModule,
        DragulaModule.forRoot(),
        NgSelectModule
    ],
    declarations: [
        ActiveDirectoryGroupsComponent,
        AlertsComponent,
        AuthEntitiesComponent,
        AuthModulesComponent,
        AutofocusDirective,
        AdminUptimeComponent,
        AdminCapacityComponent,
        AdminPerformanceComponent,
        AdminTestResultsComponent,
        CapacityComponent,
        ContactSubmissionsComponent,
        CrudActionsComponent,
        FilterCrudActionsComponent,
        CrudDeleteWarningComponent,
        CrudListActionsComponent,
        DashboardComponent,
        DateRangeComponent,
        DistributorCountrySelectComponent,
        DealerCountrySelectComponent,
        DistributorModalEditComponent,
        DealerLocatorModalEditComponent,
        DistributionHubDomainsComponent,
        DistributionHubCatalogComponent,
        DistributionHubSolrComponent,
        DistributionHubCatalogSolrcoreMappingComponent,
        DistributorMapComponent,
        DealerMapComponent,
        CountryModalEditComponent,
        DealerCountryModalEditComponent,
        CountriesComponent,
        DealerCountriesComponent,
        DistributorFinderComponent,
        DealerLocatorComponent,
        FemcareCatalogComponent,
        FemcareCatalogSolrcoreMappingComponent,
        FemcareSamplesComponent,
        FemcareSitesComponent,
        FemcareSolrComponent,
        PromotionsComponent,
        PromotionSubmissionsComponent,
        ProductImporterComponent,
        ProductImporterFormComponent,
        CountryNamePipe,
        DealerCountryNamePipe,
        ImportLogsComponent,
        LayoutComponent,
        ListHeaderComponent,
        LogoutComponent,
        PerformanceComponent,
        PrettyCountPipe,
        PrettyDatePipe,
        PrettySubmitDirective,
        RedirectsComponent,
        RedisComponent,
        SamplesComponent,
        SlugifyPipe,
        SitesComponent,
        SiteMappingComponent,
        SmallHeaderComponent,
        SmallRemoveActionComponent,
        TenaBootstrapComponent,
        TenaCatalogComponent,
        TenaCatalogSegmentMappingComponent,
        TenaColorCodeComponent,
        TenaCatalogIdentificationMappingComponent,
        TenaSegmentComponent,
        TenaSiteMapComponent,
        TenaWebShopComponent,
        TenaBlacklistComponent,
        TenaEpiRulesComponent,
        TenaGDPRComponent,
        TenaFulFillmentComponent,
        TenaLegacyIdExportComponent,
        TenaCountrySelectComponent,
        TenaSegmentSelectComponent,
        TenaExternalSampleComponent,
        TenaSampleOrderComponent,
        TenaSegmentNamePipe,
        TenaCountryNamePipe,
        TenaCountrySegmentMenuComponent,
        TenaListHeaderComponent,
        TenaFullFilmentSchedulerComponent,
        LeukoplastFulFillmentComponent,
        LeukoplastLegacyIdExportComponent,
        LeukoplastCatalogComponent,
        LeukoplastCatalogIdentificationMappingComponent,
        LeukoplastWebShopComponent,
        LeukoplastSegmentSelectComponent,
        LeukoplastCountrySelectComponent,
        LeukoplastCountrySegmentMenuComponent,
        LeukoplastSiteMapComponent,
        LeukoplastListHeaderComponent,
        LeukoplastCountryNamePipe,
        CutimedWebShopComponent,
        CutimedSegmentSelectComponent,
        CutimedCountrySegmentMenuComponent,
        CutimedCountrySelectComponent,
        CutimedSiteMapComponent,
        CutimedListHeaderComponent,
        CutimedCatalogComponent,
        CutimedCatalogIdentificationMappingComponent,
        CutimedFulFillmentComponent,
        CutimedSampleOrderComponent,
        CutimedLegacyIdExportComponent,
        CutimedCountryNamePipe,
        ActimoveListHeaderComponent,
        ActimoveCountrySegmentMenuComponent,
        ActimoveCountrySelectComponent,
        ActimoveSegmentSelectComponent,
        ActimoveCatalogComponent,
        ActimoveCatalogIdentificationMappingComponent,
        ActimoveWebShopComponent,
        ActimoveCountryNamePipe,
        ActimoveSiteMapComponent,
        ActimoveFulFillmentComponent,
        JobstListHeaderComponent,
        JobstSegmentSelectComponent,
        JobstCountryNamePipe,
        JobstCatalogComponent,
        JobstCatalogComponent,
        JobstCatalogIdentificationMappingComponent,
        JobstSiteMapComponent,
        JobstCountrySegmentMenuComponent,
        JobstCountrySelectComponent,
        JobstProductImporterComponent,
        JobstProductImporterFormComponent,
        TorkBrandsComponent,
        TorkCatalogComponent,
        TorkCatalogSiteMapMappingComponent,
        TorkCatalogSolrcoreMappingComponent,
        TorkColorSortingComponent,
        TorkSiteMapComponent,
        TorkSolrComponent,
        TorkWebServiceComponent,
        TorkWebServiceCredentialsComponent,
        UptimeComponent,
        UrlStubComponent,
        AskTheExpertComponent,
        UrlStubComponent,
        ReportComponent,
        CampaignComponent,
        FinancialComponent,
        FinancialFileComponent,
        FinancialFileLinkComponent,
        SubmittedFormsComponent,
        SubmittedFormsSitesComponent,
        ElearningComponent,
        SiteNamePipe,
        WaitingTransactionsComponent,
        PriorityComponent,
        ItemComponent,
        ExecutionTimeComponent,
        TestResultsComponent,
        TestResultDashboardComponent,
        TestResultListComponent,
        SingleTestComponent,
        AdminTestNavigationComponent,
        AdminTestFiltersComponent,
        ExecutionTimeComponent,
        TpwGdprDeleteComponent,
        TpwGdprBlacklistComponent,
        StatsComponent,
        QuizComponent,
        QuizSubmissionsComponent,
        DistributionHubSolrCoreConfigComponent,
        TpwCountrySelectComponent,
        TpwProductFamilySelectComponent,
        TpwSortCategorySelectComponent,
        TpwSampleSelectComponent,
        TpwChannelSelectComponent,
        TpwTierSelectComponent,
        TpwCountryNamePipe,
        TpwProductFamilyPipe,
        TpwSortCategoryPipe,
        TpwSampleTypePipe,
        TpwChannelNamePipe,
        TpwTierPipe,
        TpwFullfillmentSettingComponent,
        TpwFullfillmentSettingListHeaderComponent,
        TpwFullfillmentSettingMenuComponent,
        TpwKitQuotaComponent,
        TpwKitQuotaListHeaderComponent,
        TpwKitQuotaMenuComponent,
        TpwSampleOrderComponent,
        TpwSampleOrderListHeaderComponent,
        TpwSampleOrderMenuComponent,
        TpwGetSampleOrderReportComponent,
        TpwGetSampleOrderReportListHeaderComponent
    ],
    entryComponents: [
        DistributorModalEditComponent,
        DealerLocatorModalEditComponent,
        CountryModalEditComponent,
        DistributorMapComponent,
        DealerMapComponent
    ],
    providers: [
        AdminAuthGuard,
        AdminTitle,
        AlertService,
        ApiErrorHandler,
        QuietApiErrorHandler,
        AuthEntityApi,
        AuthModuleApi,
        DistributorCountriesApi,
        DealerCountriesApi,
        AuthService,
        DatePipe,
        DecimalPipe,
        DistributorService,
        DealerLocatorService,
        JobstTCWCatalogApi,
        JobstSegmentApi,
        {
            provide: ENVIRONMENT,
            useValue: (window as any).environment
        },
        CountriesService,
        DealerCountriesService,
        LiveStageService,
        LowerCasePipe,
        PrettySubmitGuard,
        UserAccessService,
        DragulaService,
        TenaService,
        LeukoplastService,
        CutimedService,
        ActimoveService,
        JobstService,
        TestResultsApi,
        SubmittedFormsService,
        StatsApi,
        DistributionHubSolrCoreConfigApi,
        TpwService,
        CelumApi
    ],
    bootstrap: [LayoutComponent]
})
export class AppModule {}
