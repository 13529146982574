import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';

import { Tool } from '../../model/auth/login/Tool';
import { TorkBrandsComponent } from '../../product/tork/TorkBrands.component';
import { TorkCatalogComponent } from '../../product/tork/TorkCatalog.component';
import { TorkCatalogSiteMapMappingComponent } from '../../product/tork/mapping/TorkCatalogSiteMapMapping.component';
import { TorkCatalogSolrcoreMappingComponent } from '../../product/tork/mapping/TorkCatalogSolrcoreMapping.component';
import { TorkColorSortingComponent } from '../../product/tork/TorkColorSorting.component';
import { TorkSiteMapComponent } from '../../product/tork/TorkSiteMap.component';
import { TorkSolrComponent } from '../../product/tork/TorkSolr.component';
import { TorkWebServiceComponent } from '../../product/tork/TorkWebService.component';
import { TorkWebServiceCredentialsComponent } from '../../product/tork/TorkWebServiceCredentials.component';

export const TorkChildRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Brands',
        path: 'brands',
        component: TorkBrandsComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107593058/4.3.1+Pim+Tork+Brands',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Catalogs',
        path: 'catalogs',
        component: TorkCatalogComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107593177/4.3.2+Pim+Tork+Catalogs',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Catalog ⬌ Site Map',
        path: 'mapping/catalog-site-map',
        component: TorkCatalogSiteMapMappingComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Catalog ⬌ Solr',
        path: 'mapping/catalog-solr',
        component: TorkCatalogSolrcoreMappingComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Colors',
        path: 'colors',
        component: TorkColorSortingComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107593256/4.3.3+Pim+Tork+Color+Themes',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Credentials',
        path: 'credentials',
        component: TorkWebServiceCredentialsComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107593351/4.3.4+Pim+Tork+Web+Service+Credentials',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Services',
        path: 'services',
        component: TorkWebServiceComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107593461/4.3.5+Pim+Tork+Web+Services',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Site Maps',
        path: 'site-maps',
        component: TorkSiteMapComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107593537/4.3.6+Pim+Tork+Site+Maps',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Solr',
        path: 'solr',
        component: TorkSolrComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107593649/4.3.7+Pim+Tork+Solr+Cores',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    }
];
