import { Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { IFulfillmentApi } from '../../../fulfillment/IFulfillmentApi';
import { IFulfillmentSite } from '../../../../model/fulfillment/IFulfillmentSite';
import { ISampleRequest } from '../../../../model/campaign/sample/ISampleRequest';

@Injectable()
export class SamplesApi extends BaseAuthorizedApi<IEnvironment>
    implements IFulfillmentApi<ISampleRequest> {
    getItems(
        site: IFulfillmentSite,
        targetId: string,
        pageSize: number,
        startDate: string,
        endDate?: string
    ): Observable<ISampleRequest[]> {
        return this.getJson('request', {
            params: {
                SiteId: site.name,
                StartDate: startDate,
                EndDate: endDate,
                pageSize: pageSize
            }
        });
    }

    getMoreItems(
        site: IFulfillmentSite,
        targetId: string,
        pageSize: number,
        offset: number,
        startDate: string,
        endDate?: string
    ): Observable<ISampleRequest[]> {
        return this.getJson('request/loadmoreresults', {
            params: {
                SiteId: site.name,
                StartDate: startDate,
                EndDate: endDate,
                pageSize: pageSize,
                itemCount: offset
            }
        });
    }

    getCsv(
        site: IFulfillmentSite,
        targetId: string,
        startDate: string,
        endDate?: string
    ): Observable<Response> {
        return this.get('request/csv/', {
            params: {
                SiteId: site.name,
                StartDate: startDate,
                EndDate: endDate
            }
        });
    }

    removeItem(item: ISampleRequest): Observable<Response> {
        return this.delete('request/' + item.id);
    }

    protected getBaseUrl() {
        return this.environment.api.samples;
    }
}
