import { Http } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ITpwChannel } from '../../../../model/product/tena/tpw/ITpwChannel';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { BaseTPWApi } from './BaseTPWApi';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TpwChannelApi extends BaseTPWApi<ITpwChannel> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        public httpClient: HttpClient
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    getChannelList() {
        // Json file path 'dist/assets/json/channelList.json'

        return this.httpClient.get('./assets/json/channelList.json');
    }

    protected getBaseUrl() {
        return this.TPWBaseURl + 'SampleOrder';
    }
}
