import { Component, Input } from '@angular/core';

import getUniqueId from 'tridion.web.ui/src/app/utility/getUniqueId';
import { IDateRangeList } from './IDateRangeList';

@Component({
    selector: 'admin-date-range',
    templateUrl: 'DateRange.component.html'
})
export class DateRangeComponent {
    @Input() list: IDateRangeList;
    @Input() startDateRequired = false;
    @Input() endDateRequired = false;
    readonly uniqueId = getUniqueId(null, null);

    changed() {
        var list = this.list,
            valid = true;

        if (this.startDateRequired && !list.startDate) {
            valid = false;
        }
        if (this.endDateRequired && !list.endDate) {
            valid = false;
        }

        if (valid) {
            list.load();
        }
    }
}
