import { ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BaseAdminCrudList } from './BaseAdminCrudList';
import CrudListFilter from './CrudListFilter';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { IEnvironment } from '../../environment/IEnvironment';
import { LiveStage } from '../../environment/LiveStage';
import { LiveStageService } from '../../environment/LiveStage.service';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';

export abstract class BaseLiveStageCrudList<
    I,
    D extends ICrudData<I>,
    F extends { liveStage: LiveStage }
> extends BaseAdminCrudList<I, D> implements OnDestroy {
    protected readonly filter: CrudListFilter<F>;
    private liveStage: LiveStage;
    private liveStageSubscription: Subscription;

    constructor(
        elementRef: ElementRef,
        liveStageApi: keyof IEnvironment['api'],
        private liveStageService: LiveStageService,
        prettySubmitGuard: PrettySubmitGuard,
        itemType: string,
        itemTypeLong = itemType
    ) {
        super(elementRef, prettySubmitGuard, itemType, itemTypeLong);

        liveStageService.addListener(this, liveStageApi);
        this.liveStage = liveStageService.get();

        var filter = new CrudListFilter<F>(this, {
            liveStage: liveStageService.get()
        } as F);

        this.liveStageSubscription = liveStageService.liveStage.subscribe(liveStage => {
            if (this.liveStage !== liveStage) {
                if (
                    filter.setFilter({
                        liveStage: liveStage
                    } as F)
                ) {
                    this.liveStage = liveStage;
                } else {
                    liveStageService.toggle();
                }
            }
        });

        this.filter = filter;
    }

    ngOnDestroy() {
        this.liveStageService.removeListener(this);
        this.liveStageSubscription.unsubscribe();
    }
}
