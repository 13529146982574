import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Headers, Http, RequestOptions } from '@angular/http';
import { FormGroup } from '@angular/forms';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { ICrudApi } from 'tridion.web.ui/src/app/angular/api/ICrudApi';
import { IDistributor } from '../../model/dealerLocator/IDistributor';
import { IEnvironment } from '../../environment/IEnvironment';
import { DealerLocatorService } from '../../../app/product/tena/jobst/dealerLocator/DistributorFinder.service';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { LiveStageService } from '../../environment/LiveStage.service';

@Injectable()
export class DistributorFinderApi extends BaseAuthorizedApi<IEnvironment>
    implements ICrudApi<IDistributor, string> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        private dealerLocatorService: DealerLocatorService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        private liveStageService: LiveStageService,
        http: Http
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    getDistributors(limit: number, page: number, filter?: FormGroup, country?: string, segmet?: string) {
        return this.get(`DealerLocator/jobst/${country}/${segmet}`, {
            params: {
                page: page,
                limit: limit,
                key: filter && filter.value.searchField ? filter.value.searchFilter : '',
                value: filter && filter.value.searchField ? filter.value.searchField : ''
            }
        }).pipe(map(response => {
            return {
                totalCount: Number(response.headers.get('x-total-count')),
                distributors: response.json() as IDistributor[]
            };
        }));
    }

    importData(file: any, country?: string, segmet?: string): Observable<any> {
        return this.postJson(`DealerLocator/jobst/${country}/${segmet}/import`, file);
    }

    exportFile(country?: string, segmet?: string) {
        return this.get(`DealerLocator/jobst/${country}/${segmet}/export`);
    }

    getAll(country?: string, segmet?: string): Observable<IDistributor[]> {
        return this.getJson(`DealerLocator/jobst/${country}/${segmet}`);
    }

    getById(id: string, country?: string, segmet?: string): Observable<IDistributor> {
        return this.getJson(`DealerLocator/jobst/${country}/${segmet}/${id}`);
    }

    add(item: IDistributor, country?: string, segmet?: string): Observable<IDistributor> {
        let headers = new Headers();
        headers.append('x-state', this.dealerLocatorService.getActiveCountry().code);
        let options = new RequestOptions({ headers: headers });

        return this.postJson(`DealerLocator/jobst/${country}/${segmet}/`, item, options);
    }

    update(item: IDistributor, country?: string, segmet?: string): Observable<IDistributor> {
        return this.putJson(`DealerLocator/jobst/${country}/${segmet}/${item.id}`, item);
    }

    activate(item: IDistributor, country?: string, segmet?: string): Observable<any> {
        return this.put(`DealerLocator/jobst/${country}/${segmet}/${item.id}/activate`, undefined);
    }

    inactivate(item: IDistributor, country?: string, segmet?: string): Observable<any> {
        return this.delete(`DealerLocator/jobst/${country}/${segmet}/${item.objectId}`);
    }

    publish(country?: string, segmet?: string) {
        return this.get(`DealerLocator/jobst/${country}/${segmet}/upload`);
    }

    protected buildOptionsAsync(options: RequestOptions) {
        let headers = new Headers(options ? options.headers : undefined);

        if (this.dealerLocatorService.getActiveCountry()) {
            headers.append(
                'x-state-path',
                this.dealerLocatorService.getActiveCountry().authorizationPath
            );
        }

        options = new RequestOptions().merge(options).merge({
            headers: headers
        });

        return super.buildOptionsAsync(options);
    }

    protected getBaseUrl() {
        return (
            this.environment.api.DealerLocator[
                this.liveStageService.get('DealerLocator')
            ]
        );
    }
}
