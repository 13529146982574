import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAuthorizedGuidCrudApi } from '../../BaseAuthorizedGuidCrudApi';
import { IPromotion } from '../../../model/campaign/promotion/IPromotion';

@Injectable()
export class PromotionsApi extends BaseAuthorizedGuidCrudApi<IPromotion> {
    protected getBaseUrl() {
        return this.environment.api.promotions + 'promotion';
    }
}
